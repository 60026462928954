import React from "react"
import { DashboardTypes } from "../../constants"
import { BuildingFragment } from "../../graphql"
import { useDashboardContext } from "../../hooks"
import { BuildingFloorItemAssetRecords } from "./BuildingFloorItemAssetRecords"
import { BuildingFloorItemUserRecords } from "./BuildingFloorItemUserRecords"

export type BuildingFloorItemProps = {
	floor: BuildingFragment["floors"][0]
	building: BuildingFragment
}

export const BuildingFloorItem: React.FC<BuildingFloorItemProps> = ({ floor, building }) => {
	const { type } = useDashboardContext()

	return type === DashboardTypes.ASSETS ? <BuildingFloorItemAssetRecords floor={floor} buildingId={building._id} /> : <BuildingFloorItemUserRecords floor={floor} building={building} />
}
