import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useBuildingByIdQuery, ZoneFragment } from "../../graphql"

export type ZoneListItemProps = {
	zone: ZoneFragment
}

export const ZoneListItem: React.FC<ZoneListItemProps> = ({ zone }) => {
	const [{ data: buildingData }] = useBuildingByIdQuery({
		variables: { buildingId: zone.buildingId ?? "" },
		pause: !zone.buildingId,
	})

	const floor = useMemo(() => buildingData?.buildingById?.floors.find((f) => f._id === zone.floorId), [zone.floorId, JSON.stringify(buildingData?.buildingById?.floors)])

	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/zones/${zone._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start">
					<Heading fontSize="md" color="grayscale.title">
						{zone.label.name}
					</Heading>

					<Heading fontSize="sm" color="grayscale.label">
						{buildingData?.buildingById && `${buildingData.buildingById.label.name}${floor ? ` (${floor.label.name})` : ""}`}
					</Heading>
				</VStack>
				<VStack align="flex-end">
					<Text fontSize="sm">{zone.type}</Text>
				</VStack>
			</HStack>
		</ListItem>
	)
}
