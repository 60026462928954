import React, { PropsWithChildren } from "react"
import { DashboardContextProvider } from "./Dashboard"
import { IntervalContextProvider } from "./Interval"
import { PermissionsContextProvider } from "./Permissions"
import { TopBannerContextProvider } from "./TopBanner"

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<TopBannerContextProvider>
			<DashboardContextProvider>
				<IntervalContextProvider>
					<PermissionsContextProvider>{children}</PermissionsContextProvider>
				</IntervalContextProvider>
			</DashboardContextProvider>
		</TopBannerContextProvider>
	)
}
