/* eslint-disable @typescript-eslint/indent */
import { Box, Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { useUserGroupsByFilterQuery, UserGroupsByFilterQueryVariables, UserGroupsFilterInput } from "../../graphql"

export type UserGroupsSelectorProps = {
	onUpdate: (userGroupIds: string[], filter: UserGroupsFilterInput) => void
	value: string[]
}

export const UserGroupsSelector: React.FC<UserGroupsSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<UserGroupsByFilterQueryVariables>({ filter: { keyword: "" }, pagination: { limit: 20, page: 1 } })

	const [{ data, fetching, error }] = useUserGroupsByFilterQuery({ variables })

	const nextPage = () => {
		if (data?.userGroupsByFilter.hasNextPage && !fetching) {
			setVariables((prev) => ({
				...prev,
				pagination: {
					...prev.pagination,
					page: (prev.pagination.page || 0) + 1,
				},
			}))
		}
	}
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			nextPage()
		}
	}, [inViewport])

	const [keyword, setKeyword] = useState("")

	const isUserGroupSelected = useCallback((userGroupId: string) => value.includes(userGroupId), [value])

	const isAllSelected = useMemo(() => data?.userGroupsByFilter.userGroups.map((r) => r._id).every((rid) => value.includes(rid)), [data, value])

	useEffect(() => {
		onUpdate(value, { ...variables.filter, isAllSelected })
	}, [isAllSelected])

	const isNoneSelected = useMemo(() => !value.length, [data, value])

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				filter: {
					...prev.filter,
					keyword,
				},
			}))
			onUpdate([...value], { isAllSelected, keyword })
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (userGroupId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, userGroupId], { isAllSelected, keyword })
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(userGroupId), 1)
			onUpdate(_value, { isAllSelected, keyword })
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
			</HStack>
			{fetching && !data?.userGroupsByFilter.userGroups.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading user groups</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.userGroupsByFilter.userGroups.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any user group.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked
												? onUpdate(
														data.userGroupsByFilter.userGroups.map((r) => r._id),
														{ isAllSelected, keyword }
												  )
												: onUpdate([], { isAllSelected, keyword })
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>User Group</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.userGroupsByFilter.userGroups.map((userGroup) => (
								<Tr key={userGroup._id}>
									<Td>
										<Checkbox isChecked={isUserGroupSelected(userGroup._id)} onChange={(e) => handleChange(userGroup._id, e.target.checked)} />
									</Td>
									<Td>{userGroup.label.name}</Td>
								</Tr>
							))}
							<Box w="full" h="2" ref={ref as any} />
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more user groups</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
