import { useContext, useEffect } from "react"
import * as intervalActions from "../context/Permissions/actions"
import { PermissionsContext } from "../context/Permissions/context"
import { PermissionTags, useMeQuery, useUserPermissionsGroupsQuery } from "../graphql"

export const usePermissionsContext = (shouldInit = false) => {
	const [state, dispatch] = useContext(PermissionsContext)

	const [{ data: meData }] = useMeQuery({ pause: !shouldInit })

	const [{ data: userPermissionsGroupsData, fetching, error }] = useUserPermissionsGroupsQuery({ variables: { userId: meData?.me?._id ?? "" }, pause: !meData?.me?._id })

	useEffect(() => {
		if (!fetching && (!!userPermissionsGroupsData?.userPermissionsGroups || !!error)) {
			const permissionTags = userPermissionsGroupsData?.userPermissionsGroups?.map((group) => group.tags).flat() || []
			dispatch(intervalActions.setPermissions(permissionTags as PermissionTags[]))
		}
	}, [JSON.stringify(userPermissionsGroupsData)])

	return { ...state, fetching, error }
}
