import { Button, ButtonGroup, Flex, Link, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, ReaderFragment, useZoneByIdQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type ReaderDetailsProps = {
	reader: ReaderFragment
	disableActions?: boolean
}

export const ReaderDetails: React.FC<ReaderDetailsProps> = ({ reader, disableActions }) => {
	const { checkForSomePermissions } = useUserPermissions()

	const canUpdate = useMemo(() => checkForSomePermissions(PermissionTags.UpdateReader)[0], [])

	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: reader.assignedToZoneId ?? "" }, pause: !reader.assignedToZoneId })

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/readers/${reader._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(reader.type)} />
				<Stat label="UID" value={reader.readerDecimalId} />

				<Stat
					label="Zone"
					value={
						reader.assignedToZoneId ? (
							fetchingZone ? (
								<Spinner size="sm" />
							) : zoneError ? (
								<Text color="error.500">{zoneError.message.replace("[GraphQL] ", "")}</Text>
							) : !zoneData?.zoneById ? (
								<Text color="error.500">No zone</Text>
							) : (
								<Link as={NavLink} to={`/zones/${reader.assignedToZoneId}`}>
									{zoneData.zoneById.label.name}
								</Link>
							)
						) : (
							"Not assigned"
						)
					}
				/>

				<Stat label="Status" value={reader.isActive ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				<Stat label="Last Heartbeat" value={reader.lastHeartbeatAt ? format(new Date(reader.lastHeartbeatAt), "MMM dd, yyyy p") : "Not available"} />
				<Stat label="Last Connection" value={reader.lastConnectedAt ? format(new Date(reader.lastConnectedAt), "MMM dd, yyyy p") : "Not available"} />

				<Stat label="Since" value={format(new Date(reader.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
