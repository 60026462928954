import { List } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { AlertFragment, AlertTypes } from "../../graphql"
import { Card } from "../common"
import { AssetOfAlert } from "./AssetOfAlert"
import { ReaderOfAlert } from "./ReaderOfAlert"
import { ServiceOfAlert } from "./ServiceOfAlert"
import { TagOfAlert } from "./TagOfAlert"
import { UserLocationRecordOfAlert } from "./UserLocationRecordOfAlert"
import { UserRoleOfAlert } from "./UserRoleOfAlert"

export type AlertSourceProps = {
	alert: AlertFragment
}

export const AlertSource: React.FC<AlertSourceProps> = ({ alert }) => {
	const renderComponent = useMemo(
		() =>
			alert.type === AlertTypes.InactiveReader && alert.readerId ? (
				<ReaderOfAlert readerId={alert.readerId} />
			) : alert.type === AlertTypes.RestrictedEntry && alert.userLocationRecordId ? (
				<UserLocationRecordOfAlert userLocationRecordId={alert.userLocationRecordId} />
			) : alert.type === AlertTypes.TagBatteryLow && alert.tagId ? (
				<TagOfAlert tagId={alert.tagId} />
			) : alert.type === AlertTypes.UserRoleExpiry && alert.userRoleId ? (
				<UserRoleOfAlert userRoleId={alert.userRoleId} />
			) : alert.type === AlertTypes.InactiveService && alert.serviceId ? (
				<ServiceOfAlert serviceId={alert.serviceId} />
			) : alert.type === AlertTypes.UnauthorizedAssetMovement && alert.assetId ? (
				<AssetOfAlert assetId={alert.assetId} />
			) : (
				<></>
			),
		[JSON.stringify(alert)]
	)
	return (
		<Card title="Source">
			<List>{renderComponent}</List>
		</Card>
	)
}
