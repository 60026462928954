/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import startCase from "lodash/startCase"
import React, { FormEvent, useEffect } from "react"
import DatePicker from "react-date-picker"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AssignUserRoleMutationVariables, UserFragment, useAssignUserRoleMutation, UserRoleTypes, useUserRoleGroupsQuery } from "../graphql"

type AssignUserRoleFormValues = AssignUserRoleMutationVariables["input"]

const validationSchema = yup.object<Record<keyof AssignUserRoleFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	type: yup.string().oneOf(Object.values(UserRoleTypes)).required().label("Type"),
	userRoleGroupId: yup.string().required().label("User Role Group"),
	designation: yup.string().nullable().label("Designation"),
	startAt: yup.date().required().label("Start"),
	endAt: yup.date().nullable().label("End"),
})

export type AssignUserRoleFormProps = {
	user: UserFragment
	onSelectGroup: (id: string) => void
}

export const AssignUserRoleForm: React.FC<AssignUserRoleFormProps> = ({ user, onSelectGroup }) => {
	const initialValues: AssignUserRoleFormValues = {
		type: user.roleType,
		userRoleGroupId: "",
		designation: "",
		startAt: new Date(),
		endAt: null,
	}

	const [{ fetching }, assignUserRole] = useAssignUserRoleMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: AssignUserRoleFormValues) => {
		const { data, error } = await assignUserRole({ userId: user._id, input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.assignUserRole) {
			navigate(`/users/${user._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<AssignUserRoleFormValues>({ initialValues, validationSchema, onSubmit })

	useEffect(() => {
		onSelectGroup(formik.values.userRoleGroupId)
	}, [formik.values.userRoleGroupId])

	const [{ data: userRoleGroupsData, error: userRoleGroupsError, fetching: userRoleGroupsFetching }] = useUserRoleGroupsQuery({ variables: { type: formik.values.type }, pause: !formik.values.type })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)}>
					<FormLabel fontWeight="bold">User Role Type</FormLabel>

					<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select type" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("type")} isDisabled>
						{Object.values(UserRoleTypes)
							.filter((r) => r !== UserRoleTypes.Admin)
							.map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{startCase(type)}
								</option>
							))}
					</Select>

					<FormErrorMessage>{formik.errors.userRoleGroupId}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.userRoleGroupId && formik.errors.userRoleGroupId)}>
					<FormLabel fontWeight="bold">User Role Group</FormLabel>

					{userRoleGroupsFetching ? (
						<Text>Fetching groups</Text>
					) : userRoleGroupsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch groups</Text>
							<Text>{userRoleGroupsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !userRoleGroupsData?.userRoleGroups.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch groups</Text>
							<Text>Please try again</Text>
						</VStack>
					) : (
						<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select group" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("userRoleGroupId")}>
							{userRoleGroupsData.userRoleGroups.map((group) => (
								<option key={group._id} style={{ backgroundColor: "transparent" }} value={group._id}>
									{group.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.userRoleGroupId}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.designation && formik.errors.designation)}>
					<FormLabel fontWeight="bold">Designation</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter designation" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("designation")} />

					<FormErrorMessage>{formik.errors.designation}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.startAt && formik.errors.startAt)}>
					<FormLabel fontWeight="bold">Start</FormLabel>

					<DatePicker onChange={(date: Date) => formik.setFieldValue("startAt", date)} value={formik.values.startAt} format="MMM dd, y" minDate={new Date()} clearIcon={null} />

					<FormErrorMessage>{formik.errors.startAt as string}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.endAt && formik.errors.endAt)}>
					<FormLabel fontWeight="bold">End</FormLabel>

					<DatePicker onChange={(date: Date) => formik.setFieldValue("endAt", date)} value={formik.values.endAt} format="MMM dd, y" minDate={new Date()} clearIcon={null} />

					<FormErrorMessage>{formik.errors.endAt as string}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Assign
			</Button>
		</VStack>
	)
}
