import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useReaderByIdQuery } from "../../graphql"
import { ReaderListItem } from "../readers/ReaderListItem"

export const ReaderOfAlert: React.FC<{ readerId: string }> = ({ readerId }) => {
	const [{ data, fetching, error }] = useReaderByIdQuery({ variables: { readerId } })

	return (
		<>
			{fetching && !data?.readerById ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading reader</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.readerById ? (
				<ReaderListItem reader={data.readerById} />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find the reader.
					</Text>
				</Center>
			)}
		</>
	)
}
