import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateZoneForm } from "../../forms"
import { ZoneFragment } from "../../graphql"

export type UpdateZoneProps = {
	zone: ZoneFragment
}

export const UpdateZone: React.FC<UpdateZoneProps> = ({ zone }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateZoneForm zone={zone} />
		</VStack>
	)
}
