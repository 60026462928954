import { Checkbox, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useMemo } from "react"
import { useMeQuery, UserRoleTypes } from "../../graphql"

export type UserRoleTypesSelectorProps = {
	onUpdate: (userRoleTypes: UserRoleTypes[]) => void
	value: UserRoleTypes[]
}

export const UserRoleTypesSelector: React.FC<UserRoleTypesSelectorProps> = ({ value, onUpdate }) => {
	const [{ data: meQueryData }] = useMeQuery()

	const userRoleTypes = useMemo(() => {
		if (meQueryData?.me?.roleType !== UserRoleTypes.Admin) {
			return [UserRoleTypes.ShortTerm, UserRoleTypes.Working]
		}

		return Object.values(UserRoleTypes)
	}, [meQueryData?.me])

	const handleChange = (userRoleType: UserRoleTypes, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, userRoleType])
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(userRoleType), 1)
			onUpdate(_value)
		}
	}

	const isUserRoleTypeSelected = useCallback((userRoleType: UserRoleTypes) => value.includes(userRoleType), [value])

	const isAllSelected = useMemo(() => userRoleTypes.every((t) => value.includes(t)), [value])
	const isNoneSelected = useMemo(() => !value.length, [value])

	return (
		<VStack w="full" align="stretch">
			<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
				<Table>
					<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
						<Tr>
							<Th>
								<Checkbox
									isChecked={isAllSelected}
									isIndeterminate={!isAllSelected && !isNoneSelected}
									onChange={(e) => {
										e.target.checked ? onUpdate(userRoleTypes) : onUpdate([])
									}}
								>
									<Text fontSize="xs" textTransform="lowercase">
										{value.length} selected
									</Text>
								</Checkbox>
							</Th>
							<Th>Type</Th>
						</Tr>
					</Thead>
					<Tbody>
						{userRoleTypes.map((userRoleType) => (
							<Tr key={userRoleType}>
								<Td>
									<Checkbox isChecked={isUserRoleTypeSelected(userRoleType)} onChange={(e) => handleChange(userRoleType, e.target.checked)} />
								</Td>
								<Td>{userRoleType}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</VStack>
		</VStack>
	)
}
