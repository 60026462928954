import { AspectRatio, Button, ButtonGroup, Center, Icon, Img, Text, useDisclosure, VStack } from "@chakra-ui/react"
import React from "react"
import { MapPin, RefreshCcw } from "react-feather"
import { NavLink } from "react-router-dom"
import { TileFragment } from "../../graphql"
import { UploadTilePictureModal } from "./UploadTilePictureModal"

export type TileProps = {
	tile: TileFragment
}

export const Tile: React.FC<TileProps> = ({ tile }) => {
	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<VStack w="full" alignItems="flex-start">
				<AspectRatio w="full" ratio={16 / 9}>
					{tile.picture ? (
						<Img src={tile.picture?.original.url} rounded="xl" style={{ objectFit: "contain" }} bgColor="red.200" />
					) : (
						<Center bgColor="gray.50" rounded="xl" borderWidth={2} borderStyle="dashed" borderColor="gray.400">
							<VStack spacing="4">
								<Text fontSize="sm">No picture available for the tile</Text>
								<Button {...getButtonProps()}>Upload Picture</Button>
							</VStack>
						</Center>
					)}
				</AspectRatio>
				{tile.picture && (
					<ButtonGroup>
						<Button as={NavLink} to={`/tiles/${tile._id}/embedZones`} rightIcon={<Icon as={MapPin} />}>
							Embed Zones
						</Button>
						<Button rightIcon={<Icon as={RefreshCcw} />} {...getButtonProps()}>
							Replace Picture
						</Button>
					</ButtonGroup>
				)}
			</VStack>
			<UploadTilePictureModal isOpen={isOpen} onClose={onClose} tileId={tile._id} />
		</>
	)
}
