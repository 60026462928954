import { Button, Flex, Link, Spinner, Text } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, useDepartmentByIdQuery, UserRoleGroupFragment, UserRoleTypes } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type UserRoleGroupDetailsProps = {
	userRoleGroup: UserRoleGroupFragment
	disableActions?: boolean
}

export const UserRoleGroupDetails: React.FC<UserRoleGroupDetailsProps> = ({ userRoleGroup, disableActions }) => {
	const { checkForSomePermissions } = useUserPermissions()

	const canUpdate = useMemo(() => {
		if (userRoleGroup.type === UserRoleTypes.ShortTerm) {
			return checkForSomePermissions(PermissionTags.UpdateShortTermRoleGroup)[0]
		} else {
			return checkForSomePermissions(PermissionTags.UpdateWorkingRoleGroup)[0]
		}
	}, [])

	const [{ data: departmentData, fetching: fetchingDepartment, error: departmentError }] = useDepartmentByIdQuery({
		variables: { departmentId: userRoleGroup.departmentId },
	})

	return (
		<Card
			title="Details"
			action={
				!disableActions &&
				canUpdate && (
					<Button as={NavLink} to={`/userRoleGroups/${userRoleGroup._id}/update`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(userRoleGroup.type)} />
				<Stat
					label="Department"
					value={
						fetchingDepartment ? (
							<Spinner size="sm" />
						) : departmentError ? (
							<Text color="error.500">{departmentError.message.replace("[GraphQL] ", "")}</Text>
						) : !departmentData?.departmentById ? (
							<Text color="error.500">No department</Text>
						) : (
							<Link as={NavLink} to={`/departments/${userRoleGroup.departmentId}`}>
								{departmentData.departmentById.label.name}
							</Link>
						)
					}
				/>
			</Flex>
		</Card>
	)
}
