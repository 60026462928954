import { Button, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import Cropper, { Area } from "react-easy-crop"

type PictureCropperProps = {
	imageSrc: string
	onCropDone: (croppedImage: Blob) => void
}
export const PictureCropper: React.FC<PictureCropperProps> = ({ imageSrc, onCropDone }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

	const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}

	const onDone = async () => {
		try {
			if (croppedAreaPixels) {
				const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
				if (croppedImage) onCropDone(croppedImage)
			}
		} catch (e) {
			console.error(e)
		}
	}

	const createImage = (url: string): Promise<HTMLImageElement> =>
		new Promise((resolve, reject) => {
			const image = new Image()
			image.addEventListener("load", () => resolve(image))
			image.addEventListener("error", (error) => reject(error))
			image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
			image.src = url
		})

	const getCroppedImg = async (imageSrc: string, pixelCrop: Area): Promise<Blob | null> => {
		const image = await createImage(imageSrc)
		const canvas = document.createElement("canvas")
		const ctx = canvas.getContext("2d")

		if (!ctx) {
			return null
		}

		// set canvas size to match the bounding box
		canvas.width = image.width
		canvas.height = image.height

		// translate canvas context to a central location to allow rotating and flipping around the center
		ctx.translate(image.width / 2, image.height / 2)
		ctx.translate(-image.width / 2, -image.height / 2)

		// draw rotated image
		ctx.drawImage(image, 0, 0)

		const croppedCanvas = document.createElement("canvas")

		const croppedCtx = croppedCanvas.getContext("2d")

		if (!croppedCtx) {
			return null
		}

		// Set the size of the cropped canvas
		croppedCanvas.width = pixelCrop.width
		croppedCanvas.height = pixelCrop.height

		// Draw the cropped image onto the new canvas
		croppedCtx.drawImage(canvas, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height)

		// As Base64 string
		// return croppedCanvas.toDataURL('image/jpeg');

		// As a blob
		return new Promise<Blob | null>((resolve) => {
			croppedCanvas.toBlob((file) => {
				resolve(file)
			}, "image/jpeg")
		})
	}

	return (
		<VStack position="relative" w="full" h={355} bgColor="#333">
			<Cropper style={{ containerStyle: { height: 355, margin: 0 } }} objectFit="cover" image={imageSrc} crop={crop} aspect={16 / 9} onCropChange={setCrop} onCropComplete={onCropComplete} />
			<Button position="absolute" bottom={4} onClick={() => onDone()}>
				Done
			</Button>
		</VStack>
	)
}
