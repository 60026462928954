import { Heading, HStack, LinkBox, LinkOverlay, ListItem, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertTargetGroupFragment } from "../../graphql"

export type AlertTargetGroupListItemProps = {
	alertTargetGroup: AlertTargetGroupFragment
}

export const AlertTargetGroupListItem: React.FC<AlertTargetGroupListItemProps> = ({ alertTargetGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/alerts/targetGroups/${alertTargetGroup._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{alertTargetGroup.label.name}
					</Heading>
				</VStack>
			</HStack>
		</ListItem>
	)
}
