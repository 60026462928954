import { Button, ButtonGroup, Center, List, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { UserFragment, PermissionTags, useMeQuery, UserRoleTypes, useUserCurrentUserRoleQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card } from "../common"
import { UserRoleListItem } from "./UserRoleListItem"

export type UserRoleDetailsProps = {
	user: UserFragment
	disableActions?: boolean
}

export const UserRoleDetails: React.FC<UserRoleDetailsProps> = ({ user, disableActions }) => {
	const [{ data, fetching, error }] = useUserCurrentUserRoleQuery({ variables: { userId: user._id } })

	const [{ data: meData }] = useMeQuery()
	const { checkForPermissions } = useUserPermissions()

	const canAssign = useMemo(() => {
		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.AssignWorkingRole)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.AssignShortTermRole)[0]
		}

		return false
	}, [meData])

	const canExtend = useMemo(() => {
		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (!data?.userCurrentUserRole) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.ExtendWorkingRole)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.ExtendShortTermRole)[0]
		}

		return false
	}, [meData])

	const canRemove = useMemo(() => {
		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (!data?.userCurrentUserRole) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.RemoveWorkingRole)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.RemoveShortTermRole)[0]
		}

		return false
	}, [meData])

	return (
		<Card
			title="Current User Role"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/users/${user._id}/assignUserRole`} colorScheme="primary" size="sm">
								Assign New
							</Button>
						)}
						{canExtend && (
							<Button as={NavLink} to={`/users/${user._id}/extendUserRole`} colorScheme="primary" size="sm">
								Extend
							</Button>
						)}
						{canRemove && data?.userCurrentUserRole && (
							<Button as={NavLink} to={`/users/${user._id}/removeUserRole`} variant="link" colorScheme="error" size="sm">
								Remove
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading details</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userCurrentUserRole ? (
				<List>
					<UserRoleListItem userRole={data.userCurrentUserRole} />{" "}
				</List>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						The user has no current user role
					</Text>
				</Center>
			)}
		</Card>
	)
}
