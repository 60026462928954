import { useCallback } from "react"
import { PermissionTags } from "../graphql"
import { usePermissionsContext } from "./usePermissionsContext"

export const useUserPermissions = () => {
	const { permissionTags } = usePermissionsContext()

	const checkForPermissions = useCallback(
		(...requiredPermissionTags: PermissionTags[]): [boolean, string[]] => {
			const unavailablePermissionTags = new Set<string>()

			requiredPermissionTags.forEach((permissionTags) => {
				if (!permissionTags.includes(permissionTags)) {
					unavailablePermissionTags.add(permissionTags)
				}
			})

			return [[...unavailablePermissionTags].length === 0, [...unavailablePermissionTags]]
		},
		[permissionTags]
	)

	const checkForSomePermissions = useCallback(
		(...requiredPermissionTags: string[]): [boolean, string[]] => {
			const unavailablePermissionTags = new Set<string>()

			requiredPermissionTags.forEach((permissionTags) => {
				if (!permissionTags.includes(permissionTags)) {
					unavailablePermissionTags.add(permissionTags)
				}
			})

			return [[...unavailablePermissionTags].length < requiredPermissionTags.length, [...unavailablePermissionTags]]
		},
		[permissionTags]
	)

	return { checkForPermissions, checkForSomePermissions }
}
