import { Center, Heading, HStack, Link, Select, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { INTERVALS } from "../../context/Interval"
import { useActiveAssetIdsInLastIntervalQuery } from "../../graphql"
import { useIntervalContext } from "../../hooks"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"

export const ActiveAssetsCountInLastInterval: React.FC = () => {
	const [interval, setInterval] = useIntervalContext()

	const [{ data, fetching, error }, refresh] = useActiveAssetIdsInLastIntervalQuery({ variables: { intervalInMinutes: interval } })

	useQueryRefresher(() => refresh({ requestPolicy: "network-only" }))

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<HStack>
				<Heading fontSize="sm">Active assets in last </Heading>
				<Select size="xs" w="32" fontWeight="bold" value={interval} onChange={(e) => setInterval(Number(e.target.value))}>
					{Object.entries(INTERVALS).map(([label, minutes]) => (
						<option key={minutes} value={minutes}>
							{label}
						</option>
					))}
				</Select>
			</HStack>
			{!data?.activeAssetIdsInLastInterval && fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeAssetIdsInLastInterval ? (
				<VStack align="stretch">
					<Link as={NavLink} to="/assets/activeInLastInterval">
						<Heading color="primary.400">{data.activeAssetIdsInLastInterval.length || 0}</Heading>
					</Link>
					<Text fontSize="sm">{format(new Date(), "MMM dd, yyyy")}</Text>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
