import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { BuildingFragment, useDeleteBuildingMutation } from "../../graphql"
import { BuildingDetails } from "./BuildingDetails"

export type DeleteBuildingProps = {
	building: BuildingFragment
}

export const DeleteBuilding: React.FC<DeleteBuildingProps> = ({ building }) => {
	const [{ fetching }, deleteBuilding] = useDeleteBuildingMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleDelete = async () => {
		const { error, data } = await deleteBuilding({ buildingId: building._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.deleteBuilding) {
			toast({ description: "Deleted the building successfully", status: "success" })
			navigate("/zones/buildings")
			return
		} else {
			toast({ description: "Couldn't delete the building", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<BuildingDetails building={building} disableActions />
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to delete {building.label.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/zones/buildings/${building._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="error" isLoading={fetching} onClick={handleDelete}>
						Delete
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
