/* eslint-disable @typescript-eslint/indent */
import { Box, Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { BuildingsByFilterQueryVariables, BuildingsFilterInput, useBuildingsByFilterQuery } from "../../graphql"

export type BuildingSelectorProps = {
	onUpdate: (buildingIds: string[], filter: BuildingsFilterInput) => void
	value: string[]
}

export const BuildingSelector: React.FC<BuildingSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<BuildingsByFilterQueryVariables>({ filter: { keyword: "" }, pagination: { limit: 20, page: 1 } })

	const [{ data, fetching, error }] = useBuildingsByFilterQuery({ variables })

	const nextPage = () => {
		if (data?.buildingsByFilter.hasNextPage && !fetching) {
			setVariables((prev) => ({
				...prev,
				pagination: {
					...prev.pagination,
					page: (prev.pagination.page || 0) + 1,
				},
			}))
		}
	}
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			nextPage()
		}
	}, [inViewport])

	const [keyword, setKeyword] = useState("")

	const isBuildingSelected = useCallback((buildingId: string) => value.includes(buildingId), [value])

	const isAllSelected = useMemo(() => data?.buildingsByFilter.buildings.map((b) => b._id).every((bid) => value.includes(bid)), [data, value])

	useEffect(() => {
		onUpdate(value, { ...variables.filter, isAllSelected })
	}, [isAllSelected])

	const isNoneSelected = useMemo(() => !value.length, [data, value])

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				filter: {
					...prev.filter,
					keyword,
				},
				pagination: { ...prev.pagination, page: 1 },
			}))
			onUpdate([...value], { keyword, isAllSelected })
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (buildingId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, buildingId], { keyword, isAllSelected })
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(buildingId), 1)
			onUpdate(_value, { keyword, isAllSelected })
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
			</HStack>
			{fetching && !data?.buildingsByFilter.buildings.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading buildings</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.buildingsByFilter.buildings.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any building.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked
												? onUpdate(
														data.buildingsByFilter.buildings.map((r) => r._id),
														{ keyword, isAllSelected }
												  )
												: onUpdate([], { keyword, isAllSelected })
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>Building</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.buildingsByFilter.buildings.map((building) => (
								<Tr key={building._id}>
									<Td>
										<Checkbox isChecked={isBuildingSelected(building._id)} onChange={(e) => handleChange(building._id, e.target.checked)} />
									</Td>
									<Td>{building.label.name}</Td>
								</Tr>
							))}
							<Box w="full" h="2" ref={ref as any} />
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more buildings</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
