import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { TileFragment } from "../../graphql"

export type TileListItemProps = {
	tile: TileFragment
}

export const TileListItem: React.FC<TileListItemProps> = ({ tile }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/tiles/${tile._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{tile.label.name}
					</Heading>

					<Text fontSize="sm" color="grayscale.title">
						{tile.levelWiseZoneEmbeddings.length} Levels | {tile.levelWiseZoneEmbeddings.map((o) => o.zoneEmbeddings.length).reduce((acc, value) => acc + value, 0)} Zones
					</Text>
				</VStack>
			</HStack>
		</ListItem>
	)
}
