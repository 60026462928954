import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateAssetCategoryForm } from "../../forms"
import { AssetCategoryFragment } from "../../graphql"

export type UpdateAssetCategoryProps = {
	assetCategory: AssetCategoryFragment
}

export const UpdateAssetCategory: React.FC<UpdateAssetCategoryProps> = ({ assetCategory }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateAssetCategoryForm assetCategory={assetCategory} />
		</VStack>
	)
}
