/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ZoneSelector } from "../components"
import { GenerateActiveUsersAtZonesReportMutationVariables, useGenerateActiveUsersAtZonesReportMutation } from "../graphql"

type GenerateActiveUsersAtZonesReportFormValues = GenerateActiveUsersAtZonesReportMutationVariables["input"]

const validationSchema = yup.object<Record<keyof GenerateActiveUsersAtZonesReportFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	zoneIds: yup.array().of(yup.string()).min(1).required().label("Zones"),
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	sinceDate: yup.date().required().label("Since Date"),
	untilDate: yup.date().required().label("Until Date"),
	zonesFilter: yup.object({
		keyword: yup.string().nullable(),
		type: yup.string().label("Type").nullable(),
		isAllSelected: yup.boolean().nullable(),
	}),
})

const initialValues: GenerateActiveUsersAtZonesReportFormValues = {
	zoneIds: [],
	label: {
		name: "",
		description: "",
	},
	sinceDate: new Date(),
	untilDate: new Date(),
	zonesFilter: { isAllSelected: false, keyword: "" },
}

export const GenerateActiveUsersAtZonesReportForm: React.FC = () => {
	const [{ fetching }, generateActiveUsersAtZonesReport] = useGenerateActiveUsersAtZonesReportMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: GenerateActiveUsersAtZonesReportFormValues) => {
		const { data, error } = await generateActiveUsersAtZonesReport({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.generateActiveUsersAtZonesReport) {
			navigate(`/reports/past/${data.generateActiveUsersAtZonesReport._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<GenerateActiveUsersAtZonesReportFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
						<FormLabel fontWeight="bold">Name</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

						<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
						<FormLabel fontWeight="bold">Description</FormLabel>

						<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

						<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
					</FormControl>
				</VStack>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.zoneIds && formik.errors.zoneIds)}>
						<FormLabel fontWeight="bold">Zones</FormLabel>

						<ZoneSelector
							value={formik.values.zoneIds}
							onUpdate={(zoneIds, filter) => {
								formik.setFieldValue("zoneIds", zoneIds)
								formik.setFieldValue("zonesFilter", filter)
							}}
						/>
						<Text fontSize="sm" color="error.400">
							{formik.errors.zoneIds}
						</Text>
					</FormControl>
					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Generate
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
