import { useContext, useEffect, useState } from "react"
import { useFeatures, useUserPermissions } from "."
import { DashboardTypes } from "../constants"
import * as dashboardActions from "../context/Dashboard/actions"
import { DashboardContext } from "../context/Dashboard/context"
import { Features, PermissionTags } from "../graphql"

export const useDashboardContext = (shouldInit = false) => {
	const [state, dispatch] = useContext(DashboardContext)

	const [availableDashboardTypes, setAvailableDashboardTypes] = useState<DashboardTypes[]>([])

	const { isFeatureEnabled } = useFeatures()
	const { checkForPermissions } = useUserPermissions()

	useEffect(() => {
		const _: DashboardTypes[] = []

		if (isFeatureEnabled(Features.DisasterManagement) && checkForPermissions(PermissionTags.ReadDisasterManagementDashboard)) {
			_.push(DashboardTypes.DISASTER)
		}

		if (isFeatureEnabled(Features.Confidentiality) && checkForPermissions(PermissionTags.ReadConfidentialityDashboard)) {
			_.push(DashboardTypes.CONFIDENTIALITY)
		}

		if (isFeatureEnabled(Features.Assets) && checkForPermissions(PermissionTags.ReadAssetManagementDashboard)) {
			_.push(DashboardTypes.ASSETS)
		}

		setAvailableDashboardTypes(_)
	}, [isFeatureEnabled, checkForPermissions])

	useEffect(() => {
		if (!shouldInit) return

		const type = localStorage.getItem("@LIGHTHOUSE_MSIL/DASHBOARD_TYPE")

		if (type && Object.values(DashboardTypes).includes(type as DashboardTypes) && availableDashboardTypes.includes(type as DashboardTypes)) {
			if (state.type !== type) dispatch(dashboardActions.setDashboardType(type as DashboardTypes))
		} else {
			if (state.type !== availableDashboardTypes[0]) dispatch(dashboardActions.setDashboardType(availableDashboardTypes[0]))
		}
	}, [shouldInit, JSON.stringify(availableDashboardTypes)])

	return { ...state, dispatch, ...dashboardActions, availableDashboardTypes }
}
