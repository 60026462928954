import { Center, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AssignUserRoleForm } from "../../forms"
import { UserFragment, useUserRoleGroupByIdQuery } from "../../graphql"
import { UserRoleGroupDetails } from "../userRoleGroups"

export type AssignUserRoleProps = {
	user: UserFragment
}

export const AssignUserRole: React.FC<AssignUserRoleProps> = ({ user }) => {
	const [selectedUserRoleGroupId, setSelectedUserRoleGroupId] = useState("")

	const [{ data, fetching, error }] = useUserRoleGroupByIdQuery({ variables: { userRoleGroupId: selectedUserRoleGroupId }, pause: !selectedUserRoleGroupId })

	return (
		<VStack w="full" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }} spacing={6}>
				<VStack w="full" maxW="sm">
					<AssignUserRoleForm user={user} onSelectGroup={setSelectedUserRoleGroupId} />
				</VStack>
				{selectedUserRoleGroupId && (
					<VStack w="full" maxW="sm">
						{fetching ? (
							<Center w="full" py="4">
								<VStack w="full" color="grayscale.label">
									<Text fontSize="sm">Loading details</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : error ? (
							<Center py="4">
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									{error.message.replace("[GraphQL] ", "")}
								</Text>
							</Center>
						) : data?.userRoleGroupById ? (
							<UserRoleGroupDetails userRoleGroup={data.userRoleGroupById} disableActions />
						) : (
							<Center py="4">
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									Couldn&apos;t find details
								</Text>
							</Center>
						)}
					</VStack>
				)}
			</Stack>
		</VStack>
	)
}
