import { Heading, HStack, LinkBox, LinkOverlay, ListItem, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { UserRoleGroupFragment } from "../../graphql"

export type UserRoleGroupListItemProps = {
	userRoleGroup: UserRoleGroupFragment
}

export const UserRoleGroupListItem: React.FC<UserRoleGroupListItemProps> = ({ userRoleGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/userRoleGroups/${userRoleGroup._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{userRoleGroup.label.name}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{startCase(userRoleGroup.type)}
					</Heading>
				</VStack>
			</HStack>
		</ListItem>
	)
}
