import { useCallback, useEffect } from "react"

export const useQueryRefresher = (refreshFn: () => void, interval = 1000 * 10) => {
	const refresh = useCallback(() => {
		const intervalId = setInterval(() => {
			refreshFn()
		}, interval)

		return intervalId
	}, [])

	useEffect(() => {
		const intervalId = refresh()

		return () => {
			clearInterval(intervalId)
		}
	}, [])
}
