import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateVisitorDetailsForm } from "../../forms"
import { UserFragment } from "../../graphql"

export type UpdateVisitorDetailsProps = {
	user: UserFragment
}

export const UpdateVisitorDetails: React.FC<UpdateVisitorDetailsProps> = ({ user }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateVisitorDetailsForm user={user} />
		</VStack>
	)
}
