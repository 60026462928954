import React from "react"
import { DashboardTypes } from "../../constants"
import { ZoneFragment } from "../../graphql"
import { useDashboardContext } from "../../hooks"
import { ZoneItemAssetRecords } from "./ZoneItemAssetRecords"
import { ZoneItemUserRecords } from "./ZoneItemUserRecords"

export type ZoneItemProps = {
	zone: ZoneFragment
}

export const ZoneItem: React.FC<ZoneItemProps> = ({ zone }) => {
	const { type } = useDashboardContext()

	return type === DashboardTypes.ASSETS ? <ZoneItemAssetRecords zone={zone} /> : <ZoneItemUserRecords zone={zone} />
}
