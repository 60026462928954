import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useTagByIdQuery } from "../../graphql"
import { TagListItem } from "../tags/TagListItem"

export const TagOfAlert: React.FC<{ tagId: string }> = ({ tagId }) => {
	const [{ data, fetching, error }] = useTagByIdQuery({ variables: { tagId } })

	return (
		<>
			{fetching && !data?.tagById ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading tag</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.tagById ? (
				<TagListItem tag={data.tagById} />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find the tag.
					</Text>
				</Center>
			)}
		</>
	)
}
