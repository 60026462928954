/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Select, Text, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UserFragment, usePermissionsGroupsQuery, useUpdateUserPermissionsGroupsMutation } from "../graphql"

type UpdateUserPermissionsGroupsFormValues = {
	permissionsGroupIds: string[]
}

const validationSchema = yup.object<Record<keyof UpdateUserPermissionsGroupsFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	permissionsGroupIds: yup.array(yup.string()).required().min(1).label("Permissions Groups"),
})

export type UpdateUserPermissionsGroupsFormProps = {
	user: UserFragment
	userPermissionsGroupIds: string[]
}

export const UpdateUserPermissionsGroupsForm: React.FC<UpdateUserPermissionsGroupsFormProps> = ({ user, userPermissionsGroupIds }) => {
	const initialValues: UpdateUserPermissionsGroupsFormValues = {
		permissionsGroupIds: userPermissionsGroupIds,
	}

	const [{ fetching }, updateUserPermissionsGroups] = useUpdateUserPermissionsGroupsMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ permissionsGroupIds }: UpdateUserPermissionsGroupsFormValues) => {
		const { data, error } = await updateUserPermissionsGroups({ userId: user._id, permissionsGroupIds })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateUserPermissionsGroups) {
			navigate(`/users/${user._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateUserPermissionsGroupsFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: groupsData, error: groupsError, fetching: groupsFetching }] = usePermissionsGroupsQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.permissionsGroupIds && formik.errors.permissionsGroupIds)}>
					<FormLabel fontWeight="bold">Permissions Groups</FormLabel>

					{groupsFetching ? (
						<Text>Fetching groups</Text>
					) : groupsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch groups</Text>
							<Text>{groupsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !groupsData?.permissionsGroups.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch groups</Text>
						</VStack>
					) : (
						<Select multiple resize="vertical" variant="filled" bgColor="grayscale.input-background" {...formik.getFieldProps("permissionsGroupIds")}>
							{groupsData.permissionsGroups.map((group) => (
								<option key={group._id} style={{ backgroundColor: "transparent" }} value={group._id}>
									{group.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.permissionsGroupIds}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
