/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, InputGroup, InputRightAddon, NumberInput, NumberInputField, Select, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AssetTypes, CreateAssetMutationVariables, Features, useAssetCategoriesQuery, useCreateAssetMutation } from "../graphql"
import { useFeatures } from "../hooks"

type CreateAssetFormValues = CreateAssetMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateAssetFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	type: yup.string().oneOf(Object.values(AssetTypes)).required().label("Type"),
	name: yup.string().required().label("Name"),
	categoryId: yup.string().label("Category"),
	vendorId: yup.string().label("Vendor"),
	weightInKG: yup
		.number()
		.label("Weight (in KG)")
		.when("type", {
			is: (type: AssetTypes) => type === AssetTypes.Weighed,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
})

const initialValues: CreateAssetFormValues = {
	type: AssetTypes.Tagged,
	name: "",
	weightInKG: 0,
}

export const CreateAssetForm: React.FC = () => {
	const [{ fetching }, createAsset] = useCreateAssetMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ type = AssetTypes.Tagged, name, categoryId, vendorId, weightInKG }: CreateAssetFormValues) => {
		const { data, error } = await createAsset({
			input: {
				type,
				name,
				categoryId: categoryId || undefined,
				vendorId: vendorId || undefined,
				weightInKG: type === AssetTypes.Weighed ? parseFloat(weightInKG as unknown as string) : undefined,
			},
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createAsset) {
			navigate(`/assets/${data.createAsset._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateAssetFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: assetCategoriesData, fetching: assetCategoriesFetching, error: assetCategoriesError }] = useAssetCategoriesQuery()

	const { isFeatureEnabled } = useFeatures()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				{isFeatureEnabled(Features.WeighedAssets) && (
					<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)} isRequired>
						<FormLabel fontWeight="bold">Type</FormLabel>

						<Select placeholder="Select type" variant="filled" bgColor="grayscale.input-background" {...formik.getFieldProps("type")}>
							{[AssetTypes.Tagged, AssetTypes.Weighed].map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{type}
								</option>
							))}
						</Select>

						<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
					</FormControl>
				)}

				<FormControl isInvalid={Boolean(formik.touched.name && formik.errors.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("name")} />

					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>

				{formik.values.type === AssetTypes.Weighed && (
					<FormControl isInvalid={Boolean(formik.touched.weightInKG && formik.errors.weightInKG)} isRequired>
						<FormLabel fontWeight="bold">Unit Weight</FormLabel>

						<InputGroup>
							<NumberInput
								roundedLeft="xl"
								variant="filled"
								bgColor="grayscale.input-background"
								placeholder="Enter weight"
								_placeholder={{ color: "grayscale.placeholer" }}
								{...formik.getFieldProps("weightInKG")}
								onChange={(valueAsString) => formik.setFieldValue("weightInKG", valueAsString || 0)}
							>
								<NumberInputField />
							</NumberInput>
							<InputRightAddon>
								<Text>KG</Text>
							</InputRightAddon>
						</InputGroup>

						<FormErrorMessage>{formik.errors.weightInKG}</FormErrorMessage>
					</FormControl>
				)}

				<FormControl isInvalid={Boolean(formik.touched.categoryId && formik.errors.categoryId)}>
					<FormLabel fontWeight="bold">Category</FormLabel>

					{assetCategoriesFetching ? (
						<HStack>
							<Text>Fetching categories</Text>
							<Spinner size="sm" />
						</HStack>
					) : assetCategoriesError ? (
						<VStack>
							<Text>Couldn&apos;t fetch categories</Text>
							<Text>{assetCategoriesError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !assetCategoriesData?.assetCategories.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch categories</Text>
						</VStack>
					) : (
						<Select placeholder="Select category" variant="filled" bgColor="grayscale.input-background" {...formik.getFieldProps("categoryId")}>
							{assetCategoriesData.assetCategories.map((category) => (
								<option key={category._id} style={{ backgroundColor: "transparent" }} value={category._id}>
									{category.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.categoryId}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
