import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateAlertFlowForm } from "../../forms"
import { AlertFlowFragment } from "../../graphql"

export type UpdateAlertFlowProps = {
	alertFlow: AlertFlowFragment
}

export const UpdateAlertFlow: React.FC<UpdateAlertFlowProps> = ({ alertFlow }) => {
	return (
		<VStack w="full" spacing={6}>
			<UpdateAlertFlowForm alertFlow={alertFlow} />
		</VStack>
	)
}
