import { Flex, HStack, Icon, IconButton, LinkBox, LinkOverlay, ListItem, Text, Link, Spinner } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useState } from "react"
import { X } from "react-feather"
import { NavLink } from "react-router-dom"
import { ReaderFragment, useZoneByIdQuery } from "../../graphql"
import { Stat } from "../common"

export type ReaderListItemProps = {
	reader: ReaderFragment
	zoneId?: string
}

export const ReaderListItem: React.FC<ReaderListItemProps> = ({ reader, zoneId }) => {
	const [isHovered, setIsHovered] = useState(false)

	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: reader.assignedToZoneId ?? "" }, pause: !reader.assignedToZoneId })

	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<LinkOverlay as={NavLink} to={`/readers/${reader._id}`} />
			<HStack w="full" justify="space-between" px="2">
				<Flex flex="1" justify="center" align="center" flexWrap="wrap">
					<Stat label="Name" value={reader.label.name} />
					<Stat label="UID" value={reader.readerDecimalId} />
					<Stat label="Type" value={reader.type} />
					<Stat
						label="Zone"
						value={
							reader.assignedToZoneId ? (
								fetchingZone ? (
									<Spinner size="sm" />
								) : zoneError ? (
									<Text color="error.500">{zoneError.message.replace("[GraphQL] ", "")}</Text>
								) : !zoneData?.zoneById ? (
									<Text color="error.500">No zone</Text>
								) : (
									<Link as={NavLink} to={`/zones/${reader.assignedToZoneId}`}>
										{zoneData.zoneById.label.name}
									</Link>
								)
							) : (
								"Not assigned"
							)
						}
					/>
					<Stat label="Status" value={reader.isActive ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
					<Stat label="Last Heartbeat" value={reader.lastHeartbeatAt ? format(new Date(reader.lastHeartbeatAt), "MMM dd, yyyy p") : "Not available"} />
					<Stat label="Last Connection" value={reader.lastConnectedAt ? format(new Date(reader.lastConnectedAt), "MMM dd, yyyy p") : "Not available"} />
				</Flex>
				{zoneId && isHovered && (
					<IconButton aria-label="remove-reader-from-zone-btn" as={NavLink} to={`/zones/${zoneId}/${reader._id}/remove`} colorScheme="error" size="xs">
						<Icon as={X} />
					</IconButton>
				)}
			</HStack>
		</ListItem>
	)
}
