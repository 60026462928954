import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useUsersOfAlertTargetGroupQuery } from "../../graphql"
import { UsersList } from "../users/UsersList"

export type UsersOfAlertTargetGroupProps = {
	alertTargetGroupId: string
}

export const UsersOfAlertTargetGroup: React.FC<UsersOfAlertTargetGroupProps> = ({ alertTargetGroupId }) => {
	const [{ data, fetching, error }] = useUsersOfAlertTargetGroupQuery({ variables: { alertTargetGroupId } })
	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.usersOfAlertTargetGroup ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.usersOfAlertTargetGroup.length ? (
				<UsersList users={data.usersOfAlertTargetGroup} />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any users.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
