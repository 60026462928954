import { Button, ButtonGroup, Flex, Link, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { DepartmentFragment, PermissionTags, useUserByIdQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type DepartmentDetailsProps = {
	department: DepartmentFragment
	disableActions?: boolean
}

export const DepartmentDetails: React.FC<DepartmentDetailsProps> = ({ department, disableActions }) => {
	const { checkForPermissions } = useUserPermissions()

	const canAssignHead = useMemo(() => {
		if (department.departmentHeadId) return false

		return checkForPermissions(PermissionTags.AssignDepartmentHead)[0]
	}, [])

	const canRemoveHead = useMemo(() => {
		if (!department.departmentHeadId) return false

		return checkForPermissions(PermissionTags.RemoveDepartmentHead)[0]
	}, [])

	const [{ data, fetching, error }] = useUserByIdQuery({ variables: { userId: department.departmentHeadId ?? "" }, pause: !department.departmentHeadId })

	return (
		<Card
			title="Details"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssignHead && (
							<Button as={NavLink} to={`/departments/${department._id}/assignHead`} colorScheme="primary" size="sm">
								Assign Head
							</Button>
						)}
						{canRemoveHead && (
							<Button as={NavLink} to={`/departments/${department._id}/removeHead`} colorScheme="error" size="sm">
								Remove Head
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="flex-start" flexWrap="wrap">
				<Stat
					label="Head"
					value={
						department.departmentHeadId ? (
							fetching ? (
								<Spinner size="sm" />
							) : error ? (
								<Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text>
							) : !data?.userById ? (
								<Text color="error.500">No department head</Text>
							) : (
								<Link as={NavLink} to={`/users/${department.departmentHeadId}`}>
									{data.userById.name}
								</Link>
							)
						) : (
							"Not available"
						)
					}
				/>

				<Stat label="Created" value={format(new Date(department.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
