import { DashboardTypes } from "../../constants"

export enum DashboardContextActions {
	SET_DASHBOARD_TYPE,
}

export type DashboardContextState = {
	type?: DashboardTypes
}

export type DashboardContextAction = { type: DashboardContextActions; payload: DashboardTypes }
