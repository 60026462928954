import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateAlertTargetGroupForm } from "../../forms"
import { AlertTargetGroupFragment } from "../../graphql"

export type UpdateAlertTargetGroupProps = {
	alertTargetGroup: AlertTargetGroupFragment
}

export const UpdateAlertTargetGroup: React.FC<UpdateAlertTargetGroupProps> = ({ alertTargetGroup }) => {
	return (
		<VStack w="full" spacing={6}>
			<UpdateAlertTargetGroupForm alertTargetGroup={alertTargetGroup} />
		</VStack>
	)
}
