/* eslint-disable @typescript-eslint/indent */
import { Center, chakra, Flex, Heading, LinkBox, LinkOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { AssetInventoryAuditFragment, useAssetByIdQuery, useLatestAssetInventoryAuditsQuery } from "../../graphql"

export const WeighedAssets: React.FC = () => {
	const [{ data, fetching, error }] = useLatestAssetInventoryAuditsQuery()

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">All weighed assets</Heading>
			<Flex w="full" flexWrap="wrap">
				{!data?.latestAssetInventoryAudits && fetching ? (
					<Center w="full" py="4">
						<VStack w="full" color="grayscale.label">
							<Text fontSize="sm">Loading assets</Text>
							<Spinner size="sm" />
						</VStack>
					</Center>
				) : error ? (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				) : data?.latestAssetInventoryAudits.length ? (
					data.latestAssetInventoryAudits.map((audit) => <O key={audit._id} audit={audit} />)
				) : (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							Couldn&apos;t find any records.
						</Text>
					</Center>
				)}
			</Flex>
		</VStack>
	)
}

const O: React.FC<{ audit: AssetInventoryAuditFragment }> = ({ audit }) => {
	const [{ data, fetching, error }] = useAssetByIdQuery({ variables: { assetId: audit.assetId } })

	return (
		<VStack
			w="xs"
			maxW="3xs"
			as={LinkBox}
			align="flex-start"
			px="6"
			py="4"
			m="1"
			bgColor="grayscale.background"
			transition="background-color, border-color .1s ease-in"
			borderWidth="4px"
			borderStyle="solid"
			borderColor="primary.200"
			_hover={{ bgColor: "primary.50", borderColor: "primary.200" }}
			rounded="xl"
			cursor="pointer"
			justify="space-between"
		>
			<LinkOverlay as={NavLink} to={`/assets/${audit.assetId}`} />
			<VStack align="flex-start" spacing="1">
				<Heading fontSize="sm" color="grayscale.title">
					{fetching ? <Spinner size="sm" /> : error ? <Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text> : !data?.assetById ? <Text color="error.500">No asset</Text> : data.assetById.name}
				</Heading>
				<Text fontSize="xs" color="grayscale.label">
					{audit.assetWeightInKG} KG Unit | {audit.inventoryWeightInKG} KG Total
				</Text>
			</VStack>

			<VStack align="stretch">
				<Heading fontSize="2xl" color="primary.400">
					{Intl.NumberFormat("en-IN").format(audit.assetCount)}{" "}
					<chakra.span fontSize="sm" fontWeight="normal">
						assets
					</chakra.span>
				</Heading>
			</VStack>
		</VStack>
	)
}
