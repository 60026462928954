import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useUserRoleByIdQuery } from "../../graphql"
import { UserRoleListItem } from "../users"

export const UserRoleOfAlert: React.FC<{ userRoleId: string }> = ({ userRoleId }) => {
	const [{ data, fetching, error }] = useUserRoleByIdQuery({ variables: { userRoleId } })

	return (
		<>
			{fetching && !data?.userRoleById ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading user role</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userRoleById ? (
				<UserRoleListItem userRole={data.userRoleById} includeUser />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find the user role.
					</Text>
				</Center>
			)}
		</>
	)
}
