import gql from "graphql-tag"
import * as Urql from "urql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	DateTime: any
}

export type ActiveAssetsResponse = {
	__typename?: "ActiveAssetsResponse"
	assetIds: Array<Scalars["String"]>
	zoneWiseAssets: Array<ZoneWiseAssetsResponse>
}

export type ActiveRestrictedUserLocationRecordsResponse = {
	__typename?: "ActiveRestrictedUserLocationRecordsResponse"
	recordIds: Array<Scalars["String"]>
	zoneWiseRecordIds: Array<ZoneWiseActiveRestrictedUserLocationRecordsResponse>
}

export type ActiveUsersAtBuildingsReportInput = {
	buildingIds: Array<Scalars["String"]>
	buildingsFilter: BuildingsFilterInput
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
}

export type ActiveUsersAtZoneGroupsReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneGroupIds: Array<Scalars["String"]>
	zoneGroupsFilter: ZoneGroupsFilterInput
}

export type ActiveUsersAtZonesReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
	zonesFilter: ZonesFilterInput
}

export type ActiveUsersResponse = {
	__typename?: "ActiveUsersResponse"
	userIds: Array<Scalars["ID"]>
	zoneWiseUsers: Array<ZoneWiseUsersResponse>
}

export type Address = {
	__typename?: "Address"
	city?: Maybe<Scalars["String"]>
	country?: Maybe<Scalars["String"]>
	pincode?: Maybe<Scalars["String"]>
	state?: Maybe<Scalars["String"]>
}

export type AddressInput = {
	city?: InputMaybe<Scalars["String"]>
	country?: InputMaybe<Scalars["String"]>
	pincode?: InputMaybe<Scalars["String"]>
	state?: InputMaybe<Scalars["String"]>
}

export type Alert = {
	__typename?: "Alert"
	_id: Scalars["ID"]
	alertFlowIds: Array<Scalars["ID"]>
	alertFlows: Array<AlertFlow>
	alertedUserIds: Array<Scalars["ID"]>
	alertedUsers: Array<User>
	asset?: Maybe<Asset>
	assetId?: Maybe<Scalars["ID"]>
	createdAt: Scalars["DateTime"]
	emailMessage: Scalars["String"]
	emailedUserIds?: Maybe<Array<Scalars["ID"]>>
	emailedUsers: Array<User>
	notificationMessage: Scalars["String"]
	notifiedUserIds?: Maybe<Array<Scalars["ID"]>>
	notifiedUsers: Array<User>
	reader?: Maybe<Reader>
	readerId?: Maybe<Scalars["ID"]>
	service?: Maybe<Service>
	serviceId?: Maybe<Scalars["ID"]>
	site: Site
	siteId: Scalars["ID"]
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["ID"]>
	type: AlertTypes
	updatedAt: Scalars["DateTime"]
	user?: Maybe<User>
	userId?: Maybe<Scalars["ID"]>
	userLocationRecord?: Maybe<UserLocationRecord>
	userLocationRecordId?: Maybe<Scalars["ID"]>
	userRole?: Maybe<UserRole>
	userRoleId?: Maybe<Scalars["ID"]>
	zone?: Maybe<Zone>
	zoneId?: Maybe<Scalars["ID"]>
}

export type AlertFlow = {
	__typename?: "AlertFlow"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	isDeleted?: Maybe<Scalars["Boolean"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	source?: Maybe<AlertSource>
	targetGroupIds: Array<Scalars["ID"]>
	targetGroups: Array<AlertTargetGroup>
	type: AlertTypes
	updatedAt: Scalars["DateTime"]
}

export type AlertFlowInput = {
	label: LabelInput
	source?: InputMaybe<AlertSourceInput>
	targetGroupIds: Array<Scalars["String"]>
	type: AlertTypes
}

export type AlertSource = {
	__typename?: "AlertSource"
	assetCategories: Array<AssetCategory>
	assetCategoryIds?: Maybe<Array<Scalars["ID"]>>
	assetIds?: Maybe<Array<Scalars["ID"]>>
	assets: Array<Asset>
	departmentIds?: Maybe<Array<Scalars["ID"]>>
	departments: Array<Department>
	type: AlertSourceTypes
	userGroupIds?: Maybe<Array<Scalars["ID"]>>
	userGroups: Array<UserGroup>
	userIds?: Maybe<Array<Scalars["ID"]>>
	userRoleTypes?: Maybe<Array<UserRoleTypes>>
	userTypeIds?: Maybe<Array<Scalars["ID"]>>
	userTypes: Array<UserType>
	users: Array<User>
	zoneGroupIds?: Maybe<Array<Scalars["ID"]>>
	zoneGroups: Array<ZoneGroup>
	zoneIds?: Maybe<Array<Scalars["ID"]>>
	zones: Array<Zone>
}

export type AlertSourceInput = {
	assetCategoryIds?: InputMaybe<Array<Scalars["String"]>>
	assetIds?: InputMaybe<Array<Scalars["String"]>>
	departmentIds?: InputMaybe<Array<Scalars["String"]>>
	type?: InputMaybe<AlertSourceTypes>
	userGroupIds?: InputMaybe<Array<Scalars["String"]>>
	userIds?: InputMaybe<Array<Scalars["String"]>>
	userRoleTypes?: InputMaybe<Array<UserRoleTypes>>
	userTypeIds?: InputMaybe<Array<Scalars["String"]>>
	zoneGroupIds?: InputMaybe<Array<Scalars["String"]>>
	zoneIds?: InputMaybe<Array<Scalars["String"]>>
}

export enum AlertSourceTypes {
	AssetCategories = "AssetCategories",
	Assets = "Assets",
	Departments = "Departments",
	UserGroups = "UserGroups",
	UserRoleTypes = "UserRoleTypes",
	UserTypes = "UserTypes",
	Users = "Users",
	ZoneGroups = "ZoneGroups",
	Zones = "Zones",
}

export type AlertTargetGroup = {
	__typename?: "AlertTargetGroup"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
	userIds: Array<Scalars["ID"]>
	users: Array<User>
}

export type AlertTargetGroupInput = {
	label: LabelInput
	userIds: Array<Scalars["String"]>
}

export enum AlertTypes {
	DailyStatisticsReport = "DailyStatisticsReport",
	InactiveReader = "InactiveReader",
	InactiveService = "InactiveService",
	LowInventory = "LowInventory",
	RestrictedEntry = "RestrictedEntry",
	TagBatteryLow = "TagBatteryLow",
	UnauthorizedAssetMovement = "UnauthorizedAssetMovement",
	Unknown = "Unknown",
	UserRoleExpiry = "UserRoleExpiry",
}

export type AlertsFilterInput = {
	type?: InputMaybe<AlertTypes>
}

export type Asset = {
	__typename?: "Asset"
	_id: Scalars["ID"]
	category?: Maybe<AssetCategory>
	categoryId?: Maybe<Scalars["ID"]>
	createdAt: Scalars["DateTime"]
	currentPlacement?: Maybe<AssetPlacementRecord>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	name: Scalars["String"]
	picture?: Maybe<Picture>
	site: Site
	siteId: Scalars["ID"]
	status: AssetStatus
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["ID"]>
	type: AssetTypes
	updatedAt: Scalars["DateTime"]
	vendor?: Maybe<Vendor>
	vendorId?: Maybe<Scalars["ID"]>
	weightInKG?: Maybe<Scalars["Float"]>
}

export type AssetAttendanceRecord = {
	__typename?: "AssetAttendanceRecord"
	_id: Scalars["ID"]
	asset: Asset
	assetId: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	site: Site
	siteId: Scalars["ID"]
	startAt: Scalars["DateTime"]
	status: AssetAttendanceRecordStatus
	updatedAt: Scalars["DateTime"]
	vendor?: Maybe<Vendor>
	vendorId?: Maybe<Scalars["ID"]>
}

export enum AssetAttendanceRecordStatus {
	Active = "Active",
	Final = "Final",
}

export type AssetCategoriesFilterInput = {
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
}

export type AssetCategory = {
	__typename?: "AssetCategory"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	isDeleted?: Maybe<Scalars["Boolean"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
}

export type AssetCategoryInput = {
	label: LabelInput
}

export type AssetInput = {
	categoryId?: InputMaybe<Scalars["String"]>
	name: Scalars["String"]
	type: AssetTypes
	vendorId?: InputMaybe<Scalars["String"]>
	weightInKG?: InputMaybe<Scalars["Float"]>
}

export type AssetInventoryAudit = {
	__typename?: "AssetInventoryAudit"
	_id: Scalars["ID"]
	asset: Asset
	assetCount: Scalars["Float"]
	assetId: Scalars["ID"]
	assetWeightInKG: Scalars["Float"]
	createdAt: Scalars["DateTime"]
	inventoryWeightInKG: Scalars["Float"]
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
}

export type AssetInventoryAuditInput = {
	assetId: Scalars["String"]
	inventoryWeightInKG: Scalars["Float"]
}

export type AssetInventoryAuditStat = {
	__typename?: "AssetInventoryAuditStat"
	audit: AssetInventoryAudit
	auditId: Scalars["ID"]
	auditedAt: Scalars["DateTime"]
	count: Scalars["Float"]
}

export type AssetInventoryAuditStats = {
	__typename?: "AssetInventoryAuditStats"
	_id: Scalars["ID"]
	asset: Asset
	assetId: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	site: Site
	siteId: Scalars["ID"]
	stats: Array<AssetInventoryAuditStat>
	updatedAt: Scalars["DateTime"]
}

export type AssetLocationRecord = {
	__typename?: "AssetLocationRecord"
	_id: Scalars["ID"]
	asset: Asset
	assetId: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	isRestricted?: Maybe<Scalars["Boolean"]>
	site: Site
	siteId: Scalars["ID"]
	startAt: Scalars["DateTime"]
	status: AssetLocationRecordStatus
	updatedAt: Scalars["DateTime"]
	zone: Zone
	zoneId: Scalars["ID"]
}

export enum AssetLocationRecordStatus {
	Active = "Active",
	Final = "Final",
	Stale = "Stale",
}

export type AssetLocationRecordsReportInput = {
	assetIds: Array<Scalars["String"]>
	assetsFilter: AssetsFilterInput
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
}

export type AssetPlacementRecord = {
	__typename?: "AssetPlacementRecord"
	_id: Scalars["ID"]
	assetId: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	destinationZoneId: Scalars["ID"]
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	placementTill: Scalars["DateTime"]
	siteId: Scalars["ID"]
	sourceZoneId?: Maybe<Scalars["ID"]>
	updatedAt: Scalars["DateTime"]
}

export type AssetPlacementRecordInput = {
	assetId: Scalars["String"]
	placementTill: Scalars["DateTime"]
	zoneId: Scalars["String"]
}

export enum AssetStatus {
	Active = "Active",
	Inactive = "Inactive",
}

export enum AssetTypes {
	Tagged = "Tagged",
	Unknown = "Unknown",
	Weighed = "Weighed",
}

export type AssetsFilterInput = {
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
	types?: InputMaybe<Array<AssetTypes>>
}

export type Building = {
	__typename?: "Building"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	floors: Array<Floor>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
	zones: Array<Zone>
}

export type BuildingInput = {
	label: LabelInput
}

export type BuildingLocationRecordsReportInput = {
	buildingIds: Array<Scalars["String"]>
	buildingsFilter: BuildingsFilterInput
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
}

export type BuildingsFilterInput = {
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
}

export type DailyUsersResponse = {
	__typename?: "DailyUsersResponse"
	date: Scalars["DateTime"]
	typeWiseUsers: Array<TypeWiseUsersResponse>
	userIds: Array<Scalars["ID"]>
}

export type Department = {
	__typename?: "Department"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	departmentHead?: Maybe<User>
	departmentHeadId?: Maybe<Scalars["ID"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
	zoneIds: Array<Scalars["ID"]>
	zones: Array<Zone>
}

export type DepartmentInput = {
	label: LabelInput
	zoneIds: Array<Scalars["String"]>
}

export type DepartmentsFilterInput = {
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
}

export type Device = {
	__typename?: "Device"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	deviceId: Scalars["String"]
	isActive?: Maybe<Scalars["Boolean"]>
	label: Label
	lastHeartbeatAt?: Maybe<Scalars["DateTime"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	type: DeviceTypes
	updatedAt: Scalars["DateTime"]
}

export type DeviceInput = {
	deviceId: Scalars["String"]
	label: LabelInput
	type: DeviceTypes
}

export enum DeviceTypes {
	StockEnrolling = "StockEnrolling",
	TagEnrolling = "TagEnrolling",
}

export type DevicesFilterInput = {
	keyword?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<DeviceTypes>
}

export type Email = {
	__typename?: "Email"
	address: Scalars["String"]
	createdAt: Scalars["DateTime"]
	isVerified: Scalars["Boolean"]
	updatedAt: Scalars["DateTime"]
	verifiedAt?: Maybe<Scalars["DateTime"]>
}

export type EmailInput = {
	address: Scalars["String"]
}

export type EnrolledTag = {
	__typename?: "EnrolledTag"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	deviceId?: Maybe<Scalars["ID"]>
	readerId?: Maybe<Scalars["ID"]>
	siteId: Scalars["ID"]
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export enum Features {
	Assets = "Assets",
	Confidentiality = "Confidentiality",
	DisasterManagement = "DisasterManagement",
	Guards = "Guards",
	WeighedAssets = "WeighedAssets",
}

export type FieldError = {
	__typename?: "FieldError"
	error: Scalars["String"]
	field: Scalars["String"]
}

export type FinishUploadInput = {
	key: Scalars["String"]
}

export type Floor = {
	__typename?: "Floor"
	_id: Scalars["ID"]
	label: Label
}

export type FloorInput = {
	label: LabelInput
}

export type Label = {
	__typename?: "Label"
	description?: Maybe<Scalars["String"]>
	name: Scalars["String"]
}

export type LabelInput = {
	description?: InputMaybe<Scalars["String"]>
	name: Scalars["String"]
}

export type LevelWiseZoneEmbeddings = {
	__typename?: "LevelWiseZoneEmbeddings"
	level: Scalars["Int"]
	zoneEmbeddings: Array<ZoneEmbedding>
}

export type Mutation = {
	__typename?: "Mutation"
	activateAsset: Asset
	activateTagAndUser: Tag
	activateTagOfAsset: Tag
	activateTagOfUser: Tag
	activateUser: User
	addFCMToken: Scalars["Boolean"]
	addReaderToVendor: Reader
	addReaderToZone: Reader
	assignDepartmentHead: Department
	assignTag: Tag
	assignTagByDecimalId: Tag
	assignTagToAsset: Tag
	assignTagToAssetByDecimalId: Tag
	assignUserRole: UserRole
	changePassword: Scalars["Boolean"]
	createAlertFlow: AlertFlow
	createAlertTargetGroup: AlertTargetGroup
	createAsset: Asset
	createAssetCategory: AssetCategory
	createAssetInventoryAudit: AssetInventoryAudit
	createAssetPlacementRecord: AssetPlacementRecord
	createBuilding: Building
	createBuildingFloor: Building
	createDepartment: Department
	createDevice: Device
	createEnrolledTag: EnrolledTag
	createPermissionsGroup: PermissionsGroup
	createReader: Reader
	createService: Service
	createTag: Tag
	createTile: Tile
	createUser: User
	createUserGroup: UserGroup
	createUserRoleGroup: UserRoleGroup
	createUserType: UserType
	createVendor: Vendor
	createZone: Zone
	createZoneGroup: ZoneGroup
	deactivateAsset: Asset
	deactivateTagAndUser: Tag
	deactivateTagOfAsset: Tag
	deactivateTagOfUser: Tag
	deactivateUser: User
	deactivateUserAndFlushTag: User
	deleteAlertFlow: Scalars["Boolean"]
	deleteAlertTargetGroup: Scalars["Boolean"]
	deleteAssetCategory: Scalars["Boolean"]
	deleteBuilding: Scalars["Boolean"]
	deleteBuildingFloor: Building
	deleteEmbeddedZoneOnTile: Tile
	deletePermissionsGroup: Scalars["Boolean"]
	deleteService: Scalars["Boolean"]
	deleteTile: Tile
	deleteUserType: Scalars["Boolean"]
	embedZoneOnTile: Tile
	extendUserRole: UserRole
	finishAssetAvatarUpload: Picture
	finishAvatarUpload: Picture
	finishTilePictureUpload: Picture
	flushTag: Tag
	flushTagOfAsset: Tag
	generateActiveUsersAtBuildingsReport: Report
	generateActiveUsersAtZoneGroupsReport: Report
	generateActiveUsersAtZonesReport: Report
	generateAssetLocationRecordsReport: Report
	generateBuildingLocationRecordsReport: Report
	generateDashboardReport: Report
	generateReadersStatusLogsReport: Report
	generateUserAttendanceReport: Report
	generateUserLocationRecordsReport: Report
	generateUserRestrictedEntriesReport: Report
	generateVisitorReport: Report
	generateZoneGroupLocationRecordsReport: Report
	generateZoneGroupRestrictedEntriesReport: Report
	generateZoneLocationRecordsReport: Report
	generateZoneRestrictedEntriesReport: Report
	login: UserAuthResponse
	logout: Scalars["Boolean"]
	removeCurrentUserRole: Scalars["Boolean"]
	removeDepartmentHead: Scalars["Boolean"]
	removeReaderFromVendor: Scalars["Boolean"]
	removeReaderFromZone: Scalars["Boolean"]
	sendDeviceHeartbeat: Scalars["Boolean"]
	sendEmailVerificationSecretCode: Scalars["Boolean"]
	signAssetAvatarUpload: UploadSignatureResponse
	signAvatarUpload: UploadSignatureResponse
	signTilePictureUpload: UploadSignatureResponse
	updateAlertFlow: AlertFlow
	updateAlertTargetGroup: AlertTargetGroup
	updateAsset: Asset
	updateAssetCategory: AssetCategory
	updateBuilding: Building
	updateBuildingFloor: Building
	updateDepartment: Department
	updateDevice: Device
	updatePermissionsGroup: PermissionsGroup
	updateReader: Reader
	updateService: Service
	updateTile: Tile
	updateUser: User
	updateUserGroup: UserGroup
	updateUserPermissionsGroups: Array<PermissionsGroup>
	updateUserRoleGroup: UserRoleGroup
	updateUserType: UserType
	updateVendor: Vendor
	updateVisitorDetails: User
	updateZone: Zone
	updateZoneGroup: ZoneGroup
	verifyEmailWithSecretCode: Scalars["Boolean"]
}

export type MutationActivateAssetArgs = {
	assetId: Scalars["String"]
}

export type MutationActivateTagAndUserArgs = {
	tagDecimalId: Scalars["String"]
}

export type MutationActivateTagOfAssetArgs = {
	assetId: Scalars["String"]
}

export type MutationActivateTagOfUserArgs = {
	userId: Scalars["String"]
}

export type MutationActivateUserArgs = {
	userId: Scalars["String"]
}

export type MutationAddFcmTokenArgs = {
	token: Scalars["String"]
}

export type MutationAddReaderToVendorArgs = {
	readerDecimalId: Scalars["String"]
	vendorId: Scalars["String"]
}

export type MutationAddReaderToZoneArgs = {
	readerDecimalId: Scalars["String"]
	zoneId: Scalars["String"]
}

export type MutationAssignDepartmentHeadArgs = {
	departmentId: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationAssignTagArgs = {
	enrolledTagId: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationAssignTagByDecimalIdArgs = {
	tagDecimalId: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationAssignTagToAssetArgs = {
	assetId: Scalars["String"]
	enrolledTagId: Scalars["String"]
}

export type MutationAssignTagToAssetByDecimalIdArgs = {
	assetId: Scalars["String"]
	tagDecimalId: Scalars["String"]
}

export type MutationAssignUserRoleArgs = {
	input: UserRoleInput
	userId: Scalars["String"]
}

export type MutationChangePasswordArgs = {
	currentPassword: Scalars["String"]
	newPassword: Scalars["String"]
	shouldLogOutOnOtherDevices?: InputMaybe<Scalars["Boolean"]>
}

export type MutationCreateAlertFlowArgs = {
	input: AlertFlowInput
}

export type MutationCreateAlertTargetGroupArgs = {
	input: AlertTargetGroupInput
}

export type MutationCreateAssetArgs = {
	input: AssetInput
}

export type MutationCreateAssetCategoryArgs = {
	input: AssetCategoryInput
}

export type MutationCreateAssetInventoryAuditArgs = {
	input: AssetInventoryAuditInput
}

export type MutationCreateAssetPlacementRecordArgs = {
	input: AssetPlacementRecordInput
}

export type MutationCreateBuildingArgs = {
	input: BuildingInput
}

export type MutationCreateBuildingFloorArgs = {
	buildingId: Scalars["String"]
	input: FloorInput
}

export type MutationCreateDepartmentArgs = {
	input: DepartmentInput
}

export type MutationCreateDeviceArgs = {
	input: DeviceInput
}

export type MutationCreateEnrolledTagArgs = {
	deviceId: Scalars["String"]
	tagDecimalId: Scalars["String"]
}

export type MutationCreatePermissionsGroupArgs = {
	input: PermissionsGroupInput
}

export type MutationCreateReaderArgs = {
	input: ReaderInput
}

export type MutationCreateServiceArgs = {
	input: ServiceInput
}

export type MutationCreateTagArgs = {
	tagDecimalId: Scalars["String"]
}

export type MutationCreateTileArgs = {
	label: LabelInput
}

export type MutationCreateUserArgs = {
	input: UserInput
}

export type MutationCreateUserGroupArgs = {
	input: UserGroupInput
}

export type MutationCreateUserRoleGroupArgs = {
	input: UserRoleGroupInput
}

export type MutationCreateUserTypeArgs = {
	input: UserTypeInput
}

export type MutationCreateVendorArgs = {
	input: VendorInput
}

export type MutationCreateZoneArgs = {
	input: ZoneInput
}

export type MutationCreateZoneGroupArgs = {
	input: ZoneGroupInput
}

export type MutationDeactivateAssetArgs = {
	assetId: Scalars["String"]
}

export type MutationDeactivateTagAndUserArgs = {
	tagDecimalId: Scalars["String"]
}

export type MutationDeactivateTagOfAssetArgs = {
	assetId: Scalars["String"]
}

export type MutationDeactivateTagOfUserArgs = {
	userId: Scalars["String"]
}

export type MutationDeactivateUserArgs = {
	userId: Scalars["String"]
}

export type MutationDeactivateUserAndFlushTagArgs = {
	userId: Scalars["String"]
}

export type MutationDeleteAlertFlowArgs = {
	alertFlowId: Scalars["String"]
}

export type MutationDeleteAlertTargetGroupArgs = {
	alertTargetGroupId: Scalars["String"]
}

export type MutationDeleteAssetCategoryArgs = {
	assetCategoryId: Scalars["String"]
}

export type MutationDeleteBuildingArgs = {
	buildingId: Scalars["String"]
}

export type MutationDeleteBuildingFloorArgs = {
	buildingId: Scalars["String"]
	floorId: Scalars["String"]
}

export type MutationDeleteEmbeddedZoneOnTileArgs = {
	level: Scalars["Int"]
	tileId: Scalars["String"]
	zoneId: Scalars["String"]
}

export type MutationDeletePermissionsGroupArgs = {
	permissionsGroupId: Scalars["String"]
}

export type MutationDeleteServiceArgs = {
	serviceId: Scalars["String"]
}

export type MutationDeleteTileArgs = {
	tileId: Scalars["String"]
}

export type MutationDeleteUserTypeArgs = {
	userTypeId: Scalars["String"]
}

export type MutationEmbedZoneOnTileArgs = {
	level: Scalars["Int"]
	position: PositionInput
	tileId: Scalars["String"]
	zoneId: Scalars["String"]
}

export type MutationExtendUserRoleArgs = {
	endAt: Scalars["DateTime"]
	userId: Scalars["String"]
}

export type MutationFinishAssetAvatarUploadArgs = {
	assetId: Scalars["String"]
	input: FinishUploadInput
}

export type MutationFinishAvatarUploadArgs = {
	input: FinishUploadInput
	userId: Scalars["String"]
}

export type MutationFinishTilePictureUploadArgs = {
	input: FinishUploadInput
	tileId: Scalars["String"]
}

export type MutationFlushTagArgs = {
	userId: Scalars["String"]
}

export type MutationFlushTagOfAssetArgs = {
	assetId: Scalars["String"]
}

export type MutationGenerateActiveUsersAtBuildingsReportArgs = {
	input: ActiveUsersAtBuildingsReportInput
}

export type MutationGenerateActiveUsersAtZoneGroupsReportArgs = {
	input: ActiveUsersAtZoneGroupsReportInput
}

export type MutationGenerateActiveUsersAtZonesReportArgs = {
	input: ActiveUsersAtZonesReportInput
}

export type MutationGenerateAssetLocationRecordsReportArgs = {
	input: AssetLocationRecordsReportInput
}

export type MutationGenerateBuildingLocationRecordsReportArgs = {
	input: BuildingLocationRecordsReportInput
}

export type MutationGenerateDashboardReportArgs = {
	input: ReportInput
}

export type MutationGenerateReadersStatusLogsReportArgs = {
	input: ReadersStatusLogsReportInput
}

export type MutationGenerateUserAttendanceReportArgs = {
	input: UserAttendanceReportInput
}

export type MutationGenerateUserLocationRecordsReportArgs = {
	input: UserLocationRecordsReportInput
}

export type MutationGenerateUserRestrictedEntriesReportArgs = {
	input: UserRestrictedEntriesReportInput
}

export type MutationGenerateVisitorReportArgs = {
	input: VisitorReportInput
}

export type MutationGenerateZoneGroupLocationRecordsReportArgs = {
	input: ZoneGroupLocationRecordsReportInput
}

export type MutationGenerateZoneGroupRestrictedEntriesReportArgs = {
	input: ZoneGroupRestrictedEntriesReportInput
}

export type MutationGenerateZoneLocationRecordsReportArgs = {
	input: ZoneLocationRecordsReportInput
}

export type MutationGenerateZoneRestrictedEntriesReportArgs = {
	input: ZoneRestrictedEntriesReportInput
}

export type MutationLoginArgs = {
	password: Scalars["String"]
	username: Scalars["String"]
}

export type MutationRemoveCurrentUserRoleArgs = {
	userId: Scalars["String"]
}

export type MutationRemoveDepartmentHeadArgs = {
	departmentId: Scalars["String"]
}

export type MutationRemoveReaderFromVendorArgs = {
	readerId: Scalars["String"]
	vendorId: Scalars["String"]
}

export type MutationRemoveReaderFromZoneArgs = {
	readerId: Scalars["String"]
	zoneId: Scalars["String"]
}

export type MutationSendDeviceHeartbeatArgs = {
	deviceId: Scalars["String"]
}

export type MutationSignAssetAvatarUploadArgs = {
	assetId: Scalars["String"]
	format: Scalars["String"]
}

export type MutationSignAvatarUploadArgs = {
	format: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationSignTilePictureUploadArgs = {
	format: Scalars["String"]
	tileId: Scalars["String"]
}

export type MutationUpdateAlertFlowArgs = {
	alertFlowId: Scalars["String"]
	input: AlertFlowInput
}

export type MutationUpdateAlertTargetGroupArgs = {
	alertTargetGroupId: Scalars["String"]
	input: AlertTargetGroupInput
}

export type MutationUpdateAssetArgs = {
	assetId: Scalars["String"]
	input: AssetInput
}

export type MutationUpdateAssetCategoryArgs = {
	assetCategoryId: Scalars["String"]
	input: AssetCategoryInput
}

export type MutationUpdateBuildingArgs = {
	buildingId: Scalars["String"]
	input: BuildingInput
}

export type MutationUpdateBuildingFloorArgs = {
	buildingId: Scalars["String"]
	floorId: Scalars["String"]
	input: FloorInput
}

export type MutationUpdateDepartmentArgs = {
	departmentId: Scalars["String"]
	input: DepartmentInput
}

export type MutationUpdateDeviceArgs = {
	deviceId: Scalars["String"]
	input: DeviceInput
}

export type MutationUpdatePermissionsGroupArgs = {
	input: PermissionsGroupInput
	permissionsGroupId: Scalars["String"]
}

export type MutationUpdateReaderArgs = {
	input: ReaderInput
	readerId: Scalars["String"]
}

export type MutationUpdateServiceArgs = {
	input: ServiceInput
	serviceId: Scalars["String"]
}

export type MutationUpdateTileArgs = {
	label: LabelInput
	tileId: Scalars["String"]
}

export type MutationUpdateUserArgs = {
	input: UpdateUserInput
	userId: Scalars["String"]
}

export type MutationUpdateUserGroupArgs = {
	input: UserGroupInput
	userGroupId: Scalars["String"]
}

export type MutationUpdateUserPermissionsGroupsArgs = {
	permissionsGroupIds: Array<Scalars["String"]>
	userId: Scalars["String"]
}

export type MutationUpdateUserRoleGroupArgs = {
	input: UpdateUserRoleGroupInput
	userRoleGroupId: Scalars["String"]
}

export type MutationUpdateUserTypeArgs = {
	input: UserTypeInput
	userTypeId: Scalars["String"]
}

export type MutationUpdateVendorArgs = {
	input: VendorInput
	vendorId: Scalars["String"]
}

export type MutationUpdateVisitorDetailsArgs = {
	input: VisitorDetailsInput
	userId: Scalars["String"]
}

export type MutationUpdateZoneArgs = {
	input: ZoneInput
	zoneId: Scalars["String"]
}

export type MutationUpdateZoneGroupArgs = {
	input: ZoneGroupInput
	zoneGroupId: Scalars["String"]
}

export type MutationVerifyEmailWithSecretCodeArgs = {
	code: Scalars["String"]
}

export type PaginatedAlertFlowsResponse = {
	__typename?: "PaginatedAlertFlowsResponse"
	alertFlows: Array<AlertFlow>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAlertTargetGroupsResponse = {
	__typename?: "PaginatedAlertTargetGroupsResponse"
	alertTargetGroups: Array<AlertTargetGroup>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAlertsResponse = {
	__typename?: "PaginatedAlertsResponse"
	alerts: Array<Alert>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAssetAttendanceRecordsResponse = {
	__typename?: "PaginatedAssetAttendanceRecordsResponse"
	assetAttendanceRecords: Array<AssetAttendanceRecord>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAssetCategoriesResponse = {
	__typename?: "PaginatedAssetCategoriesResponse"
	assetCategories: Array<AssetCategory>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAssetInventoryAuditsResponse = {
	__typename?: "PaginatedAssetInventoryAuditsResponse"
	assetInventoryAudits: Array<AssetInventoryAudit>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAssetLocationRecordsResponse = {
	__typename?: "PaginatedAssetLocationRecordsResponse"
	assetLocationRecords: Array<AssetLocationRecord>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAssetPlacementRecordsResponse = {
	__typename?: "PaginatedAssetPlacementRecordsResponse"
	assetPlacementRecords: Array<AssetPlacementRecord>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAssetsResponse = {
	__typename?: "PaginatedAssetsResponse"
	assets: Array<Asset>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedBuildingsResponse = {
	__typename?: "PaginatedBuildingsResponse"
	buildings: Array<Building>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedDepartmentsResponse = {
	__typename?: "PaginatedDepartmentsResponse"
	currentPage: Scalars["Float"]
	departments: Array<Department>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedDevicesResponse = {
	__typename?: "PaginatedDevicesResponse"
	currentPage: Scalars["Float"]
	devices: Array<Device>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedInput = {
	limit?: InputMaybe<Scalars["Int"]>
	page?: InputMaybe<Scalars["Float"]>
}

export type PaginatedPermissionsGroupsResponse = {
	__typename?: "PaginatedPermissionsGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	permissionsGroups: Array<PermissionsGroup>
}

export type PaginatedReaderStatusLogsResponse = {
	__typename?: "PaginatedReaderStatusLogsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	readerStatusLogs: Array<ReaderStatusLog>
}

export type PaginatedReadersResponse = {
	__typename?: "PaginatedReadersResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	readers: Array<Reader>
}

export type PaginatedReportsResponse = {
	__typename?: "PaginatedReportsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	reports: Array<Report>
}

export type PaginatedTagsResponse = {
	__typename?: "PaginatedTagsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	tags: Array<Tag>
}

export type PaginatedTilesResponse = {
	__typename?: "PaginatedTilesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	tiles: Array<Tile>
}

export type PaginatedUserAttendanceRecordsResponse = {
	__typename?: "PaginatedUserAttendanceRecordsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userAttendanceRecords: Array<UserAttendanceRecord>
}

export type PaginatedUserGroupsResponse = {
	__typename?: "PaginatedUserGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userGroups: Array<UserGroup>
}

export type PaginatedUserLocationRecordsResponse = {
	__typename?: "PaginatedUserLocationRecordsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userLocationRecords: Array<UserLocationRecord>
}

export type PaginatedUserRoleGroupsResponse = {
	__typename?: "PaginatedUserRoleGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userRoleGroups: Array<UserRoleGroup>
}

export type PaginatedUserTypesResponse = {
	__typename?: "PaginatedUserTypesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userTypes: Array<UserType>
}

export type PaginatedUsersResponse = {
	__typename?: "PaginatedUsersResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	users: Array<User>
}

export type PaginatedVendorsResponse = {
	__typename?: "PaginatedVendorsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	vendors: Array<Vendor>
}

export type PaginatedZoneGroupsResponse = {
	__typename?: "PaginatedZoneGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	zoneGroups: Array<ZoneGroup>
}

export type PaginatedZonesResponse = {
	__typename?: "PaginatedZonesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	zones: Array<Zone>
}

export enum PermissionTags {
	ActivateAdminUser = "ActivateAdminUser",
	ActivateAsset = "ActivateAsset",
	ActivateAssetTag = "ActivateAssetTag",
	ActivateReader = "ActivateReader",
	ActivateShortTermUser = "ActivateShortTermUser",
	ActivateShortTermUserTag = "ActivateShortTermUserTag",
	ActivateTagAndUser = "ActivateTagAndUser",
	ActivateWorkingUser = "ActivateWorkingUser",
	ActivateWorkingUserTag = "ActivateWorkingUserTag",
	AddReaderToVendor = "AddReaderToVendor",
	AddReaderToZone = "AddReaderToZone",
	AssignDepartmentHead = "AssignDepartmentHead",
	AssignShortTermRole = "AssignShortTermRole",
	AssignTagToAsset = "AssignTagToAsset",
	AssignTagToShortTermUser = "AssignTagToShortTermUser",
	AssignTagToWorkingUser = "AssignTagToWorkingUser",
	AssignWorkingRole = "AssignWorkingRole",
	CreateActiveTagsReport = "CreateActiveTagsReport",
	CreateActiveUsersReport = "CreateActiveUsersReport",
	CreateAdminPermissionsGroup = "CreateAdminPermissionsGroup",
	CreateAdminUser = "CreateAdminUser",
	CreateAdminUserType = "CreateAdminUserType",
	CreateAlertFlow = "CreateAlertFlow",
	CreateAlertTargetGroup = "CreateAlertTargetGroup",
	CreateAsset = "CreateAsset",
	CreateAssetCategory = "CreateAssetCategory",
	CreateAssetInventoryAudit = "CreateAssetInventoryAudit",
	CreateAssetLocationRecordsReport = "CreateAssetLocationRecordsReport",
	CreateAssetPlacementRecord = "CreateAssetPlacementRecord",
	CreateBlockedTagsReport = "CreateBlockedTagsReport",
	CreateBuilding = "CreateBuilding",
	CreateBuildingFloor = "CreateBuildingFloor",
	CreateBuildingLocationRecordsReport = "CreateBuildingLocationRecordsReport",
	CreateDashboardReport = "CreateDashboardReport",
	CreateDepartment = "CreateDepartment",
	CreateDevice = "CreateDevice",
	CreateLostTagsReport = "CreateLostTagsReport",
	CreateLowBatteryTagsReport = "CreateLowBatteryTagsReport",
	CreatePermissionsGroup = "CreatePermissionsGroup",
	CreateReader = "CreateReader",
	CreateReaderStatusLogsReport = "CreateReaderStatusLogsReport",
	CreateService = "CreateService",
	CreateShortTermRoleGroup = "CreateShortTermRoleGroup",
	CreateShortTermUser = "CreateShortTermUser",
	CreateShortTermUserType = "CreateShortTermUserType",
	CreateTag = "CreateTag",
	CreateTagsLifecycleReport = "CreateTagsLifecycleReport",
	CreateTile = "CreateTile",
	CreateTileEmbeddedZone = "CreateTileEmbeddedZone",
	CreateUserAttendanceReport = "CreateUserAttendanceReport",
	CreateUserGroup = "CreateUserGroup",
	CreateUserLocationRecordsReport = "CreateUserLocationRecordsReport",
	CreateUserRestrictedEntryReport = "CreateUserRestrictedEntryReport",
	CreateVendor = "CreateVendor",
	CreateVisitorReport = "CreateVisitorReport",
	CreateWorkingRoleGroup = "CreateWorkingRoleGroup",
	CreateWorkingUser = "CreateWorkingUser",
	CreateWorkingUserType = "CreateWorkingUserType",
	CreateZone = "CreateZone",
	CreateZoneGroup = "CreateZoneGroup",
	CreateZoneGroupLocationRecordsReport = "CreateZoneGroupLocationRecordsReport",
	CreateZoneGroupRestrictedEntryReport = "CreateZoneGroupRestrictedEntryReport",
	CreateZoneLocationRecordsReport = "CreateZoneLocationRecordsReport",
	CreateZoneRestrictedEntryReport = "CreateZoneRestrictedEntryReport",
	DeactivateAdminUser = "DeactivateAdminUser",
	DeactivateAsset = "DeactivateAsset",
	DeactivateAssetTag = "DeactivateAssetTag",
	DeactivateReader = "DeactivateReader",
	DeactivateShortTermUser = "DeactivateShortTermUser",
	DeactivateShortTermUserTag = "DeactivateShortTermUserTag",
	DeactivateTagAndUser = "DeactivateTagAndUser",
	DeactivateWorkingUser = "DeactivateWorkingUser",
	DeactivateWorkingUserTag = "DeactivateWorkingUserTag",
	DeleteAdminPermissionsGroup = "DeleteAdminPermissionsGroup",
	DeleteAdminUserType = "DeleteAdminUserType",
	DeleteAlertFlow = "DeleteAlertFlow",
	DeleteAlertTargetGroup = "DeleteAlertTargetGroup",
	DeleteAssetCategory = "DeleteAssetCategory",
	DeleteBuilding = "DeleteBuilding",
	DeleteBuildingFloor = "DeleteBuildingFloor",
	DeleteDepartment = "DeleteDepartment",
	DeleteDevice = "DeleteDevice",
	DeletePermissionsGroup = "DeletePermissionsGroup",
	DeleteService = "DeleteService",
	DeleteShortTermUserType = "DeleteShortTermUserType",
	DeleteTile = "DeleteTile",
	DeleteTileEmbeddedZone = "DeleteTileEmbeddedZone",
	DeleteUserGroup = "DeleteUserGroup",
	DeleteVendor = "DeleteVendor",
	DeleteWorkingUserType = "DeleteWorkingUserType",
	DeleteZoneGroup = "DeleteZoneGroup",
	ExtendShortTermRole = "ExtendShortTermRole",
	ExtendWorkingRole = "ExtendWorkingRole",
	FlushTagFromAsset = "FlushTagFromAsset",
	FlushTagFromShortTermUser = "FlushTagFromShortTermUser",
	FlushTagFromWorkingUser = "FlushTagFromWorkingUser",
	LaptopVerification = "LaptopVerification",
	ReadActiveTagsReport = "ReadActiveTagsReport",
	ReadActiveUsersReport = "ReadActiveUsersReport",
	ReadAdminPermissionsGroup = "ReadAdminPermissionsGroup",
	ReadAdminPermissionsTags = "ReadAdminPermissionsTags",
	ReadAdminUser = "ReadAdminUser",
	ReadAdminUserPermissionsGroup = "ReadAdminUserPermissionsGroup",
	ReadAdminUserType = "ReadAdminUserType",
	ReadAlert = "ReadAlert",
	ReadAlertFlow = "ReadAlertFlow",
	ReadAlertTargetGroup = "ReadAlertTargetGroup",
	ReadAsset = "ReadAsset",
	ReadAssetAttendance = "ReadAssetAttendance",
	ReadAssetCategory = "ReadAssetCategory",
	ReadAssetInventoryAudit = "ReadAssetInventoryAudit",
	ReadAssetLocationRecordsReport = "ReadAssetLocationRecordsReport",
	ReadAssetManagementDashboard = "ReadAssetManagementDashboard",
	ReadAssetPlacementRecord = "ReadAssetPlacementRecord",
	ReadAssetTrailMap = "ReadAssetTrailMap",
	ReadAttendance = "ReadAttendance",
	ReadAttendanceByDepartment = "ReadAttendanceByDepartment",
	ReadBlockedTagsReport = "ReadBlockedTagsReport",
	ReadBuilding = "ReadBuilding",
	ReadBuildingFloor = "ReadBuildingFloor",
	ReadBuildingLocationRecordsReport = "ReadBuildingLocationRecordsReport",
	ReadConfidentialityDashboard = "ReadConfidentialityDashboard",
	ReadDashboardReport = "ReadDashboardReport",
	ReadDepartment = "ReadDepartment",
	ReadDevice = "ReadDevice",
	ReadDisasterManagementDashboard = "ReadDisasterManagementDashboard",
	ReadLostTagsReport = "ReadLostTagsReport",
	ReadLowBatteryTagsReport = "ReadLowBatteryTagsReport",
	ReadPermissionsGroup = "ReadPermissionsGroup",
	ReadPermissionsTags = "ReadPermissionsTags",
	ReadReader = "ReadReader",
	ReadReaderStatusLogsReport = "ReadReaderStatusLogsReport",
	ReadRestrictedEntry = "ReadRestrictedEntry",
	ReadService = "ReadService",
	ReadShortTermRoleGroup = "ReadShortTermRoleGroup",
	ReadShortTermUser = "ReadShortTermUser",
	ReadShortTermUserPermissionsGroup = "ReadShortTermUserPermissionsGroup",
	ReadShortTermUserType = "ReadShortTermUserType",
	ReadTag = "ReadTag",
	ReadTagsLifecycleReport = "ReadTagsLifecycleReport",
	ReadTile = "ReadTile",
	ReadTileEmbeddedBuilding = "ReadTileEmbeddedBuilding",
	ReadTileEmbeddedVendor = "ReadTileEmbeddedVendor",
	ReadTileEmbeddedZone = "ReadTileEmbeddedZone",
	ReadTvScreen = "ReadTvScreen",
	ReadUserAttendanceReport = "ReadUserAttendanceReport",
	ReadUserByDepartment = "ReadUserByDepartment",
	ReadUserGroup = "ReadUserGroup",
	ReadUserLocationRecordsReport = "ReadUserLocationRecordsReport",
	ReadUserRestrictedEntryReport = "ReadUserRestrictedEntryReport",
	ReadUserTrailMap = "ReadUserTrailMap",
	ReadVendor = "ReadVendor",
	ReadVisitorReport = "ReadVisitorReport",
	ReadWorkingRoleGroup = "ReadWorkingRoleGroup",
	ReadWorkingUser = "ReadWorkingUser",
	ReadWorkingUserPermissionsGroup = "ReadWorkingUserPermissionsGroup",
	ReadWorkingUserType = "ReadWorkingUserType",
	ReadZone = "ReadZone",
	ReadZoneGroup = "ReadZoneGroup",
	ReadZoneGroupLocationRecordsReport = "ReadZoneGroupLocationRecordsReport",
	ReadZoneGroupRestrictedEntryReport = "ReadZoneGroupRestrictedEntryReport",
	ReadZoneLocationRecordsReport = "ReadZoneLocationRecordsReport",
	ReadZoneRecords = "ReadZoneRecords",
	ReadZoneRestrictedEntryReport = "ReadZoneRestrictedEntryReport",
	RemoveDepartmentHead = "RemoveDepartmentHead",
	RemoveReaderFromVendor = "RemoveReaderFromVendor",
	RemoveReaderFromZone = "RemoveReaderFromZone",
	RemoveShortTermRole = "RemoveShortTermRole",
	RemoveShortTermRoleGroup = "RemoveShortTermRoleGroup",
	RemoveWorkingRole = "RemoveWorkingRole",
	RemoveWorkingRoleGroup = "RemoveWorkingRoleGroup",
	UpdateAdminPermissionsGroup = "UpdateAdminPermissionsGroup",
	UpdateAdminUser = "UpdateAdminUser",
	UpdateAdminUserAvatar = "UpdateAdminUserAvatar",
	UpdateAdminUserPermissionsGroup = "UpdateAdminUserPermissionsGroup",
	UpdateAdminUserType = "UpdateAdminUserType",
	UpdateAlertFlow = "UpdateAlertFlow",
	UpdateAlertTargetGroup = "UpdateAlertTargetGroup",
	UpdateAsset = "UpdateAsset",
	UpdateAssetCategory = "UpdateAssetCategory",
	UpdateBuilding = "UpdateBuilding",
	UpdateBuildingFloor = "UpdateBuildingFloor",
	UpdateDepartment = "UpdateDepartment",
	UpdateDevice = "UpdateDevice",
	UpdatePermissionsGroup = "UpdatePermissionsGroup",
	UpdateReader = "UpdateReader",
	UpdateService = "UpdateService",
	UpdateShortTermRoleGroup = "UpdateShortTermRoleGroup",
	UpdateShortTermUser = "UpdateShortTermUser",
	UpdateShortTermUserAvatar = "UpdateShortTermUserAvatar",
	UpdateShortTermUserPermissionsGroup = "UpdateShortTermUserPermissionsGroup",
	UpdateShortTermUserType = "UpdateShortTermUserType",
	UpdateTile = "UpdateTile",
	UpdateTileEmbeddedZone = "UpdateTileEmbeddedZone",
	UpdateUserGroup = "UpdateUserGroup",
	UpdateVendor = "UpdateVendor",
	UpdateWorkingRoleGroup = "UpdateWorkingRoleGroup",
	UpdateWorkingUser = "UpdateWorkingUser",
	UpdateWorkingUserAvatar = "UpdateWorkingUserAvatar",
	UpdateWorkingUserPermissionsGroup = "UpdateWorkingUserPermissionsGroup",
	UpdateWorkingUserType = "UpdateWorkingUserType",
	UpdateZone = "UpdateZone",
	UpdateZoneGroup = "UpdateZoneGroup",
	UploadTilePicture = "UploadTilePicture",
}

export type PermissionsGroup = {
	__typename?: "PermissionsGroup"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	isPrivateToAdmin?: Maybe<Scalars["Boolean"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	tags: Array<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type PermissionsGroupInput = {
	isPrivateToAdmin?: InputMaybe<Scalars["Boolean"]>
	label: LabelInput
	tags: Array<PermissionTags>
}

export type Phone = {
	__typename?: "Phone"
	countryCode: Scalars["String"]
	number: Scalars["String"]
}

export type PhoneInput = {
	countryCode: Scalars["String"]
	number: Scalars["String"]
}

export type Picture = {
	__typename?: "Picture"
	original: PictureObject
	variants?: Maybe<PictureVariants>
}

export type PictureObject = {
	__typename?: "PictureObject"
	height: Scalars["Float"]
	key: Scalars["String"]
	url: Scalars["String"]
	width: Scalars["Float"]
}

export type PictureVariants = {
	__typename?: "PictureVariants"
	large?: Maybe<PictureObject>
	medium?: Maybe<PictureObject>
	small?: Maybe<PictureObject>
	thumbnail?: Maybe<PictureObject>
}

export type Position = {
	__typename?: "Position"
	x: Scalars["Float"]
	y: Scalars["Float"]
}

export type PositionInput = {
	x: Scalars["Float"]
	y: Scalars["Float"]
}

export type Query = {
	__typename?: "Query"
	activeAssetIds: ActiveAssetsResponse
	activeAssetIdsInLastInterval: Array<Scalars["String"]>
	activeAssets: PaginatedAssetsResponse
	activeAssetsByVendor: PaginatedAssetsResponse
	activeAssetsInLastInterval: PaginatedAssetsResponse
	activeAttendanceOfAsset?: Maybe<AssetAttendanceRecord>
	activeAttendanceOfUser?: Maybe<UserAttendanceRecord>
	activeRestrictedEntriesInBuilding: Array<UserLocationRecord>
	activeRestrictedEntryIds: ActiveRestrictedUserLocationRecordsResponse
	activeRestrictedUserLocationRecords: PaginatedUserLocationRecordsResponse
	activeUserIds: ActiveUsersResponse
	activeUserIdsInLastInterval: Array<Scalars["String"]>
	activeUsers: PaginatedUsersResponse
	activeUsersInLastInterval: PaginatedUsersResponse
	alertById?: Maybe<Alert>
	alertFlowById?: Maybe<AlertFlow>
	alertFlows: Array<AlertFlow>
	alertFlowsByKeyword: PaginatedAlertFlowsResponse
	alertFlowsOfAlert: Array<AlertFlow>
	alertSourceOfAlertFlow?: Maybe<AlertSource>
	alertTargetGroupById: AlertTargetGroup
	alertTargetGroups: Array<AlertTargetGroup>
	alertTargetGroupsByKeyword: PaginatedAlertTargetGroupsResponse
	alertTargetGroupsOfAlertFlow: Array<AlertTargetGroup>
	alertedUsersOfAlert: Array<User>
	alertsByFilter: PaginatedAlertsResponse
	allAlertFlows: PaginatedAlertFlowsResponse
	allAlertTargetGroups: PaginatedAlertTargetGroupsResponse
	allAlerts: PaginatedAlertsResponse
	allAssetCategories: PaginatedAssetCategoriesResponse
	allBuildings: PaginatedBuildingsResponse
	allDepartments: PaginatedDepartmentsResponse
	allDevices: PaginatedDevicesResponse
	allPermissionsGroups: PaginatedPermissionsGroupsResponse
	allReports: PaginatedReportsResponse
	allServices: Array<Service>
	allTags: PaginatedTagsResponse
	allUserGroups: PaginatedUserGroupsResponse
	allUserRoleGroups: PaginatedUserRoleGroupsResponse
	allUserTypes: PaginatedUserTypesResponse
	allUsers: PaginatedUsersResponse
	allVendors: PaginatedVendorsResponse
	allZoneGroups: PaginatedZoneGroupsResponse
	allZones: PaginatedZonesResponse
	assetAttendanceRecordsAtVendor: Array<AssetAttendanceRecord>
	assetById?: Maybe<Asset>
	assetCategories: Array<AssetCategory>
	assetCategoriesByFilter: PaginatedAssetCategoriesResponse
	assetCategoriesByKeyword: PaginatedAssetCategoriesResponse
	assetCategoryById: AssetCategory
	assetCurrentLocationRecord?: Maybe<AssetLocationRecord>
	assetLocationRecordsAtZone: Array<AssetLocationRecord>
	assetLocationRecordsInBuilding: Array<AssetLocationRecord>
	assetLocationRecordsTrail: Array<AssetLocationRecord>
	assetPastAttendanceRecords: PaginatedAssetAttendanceRecordsResponse
	assetPastLocationRecords: PaginatedAssetLocationRecordsResponse
	assets: PaginatedAssetsResponse
	assetsByFilter: PaginatedAssetsResponse
	buildingById?: Maybe<Building>
	buildings: Array<Building>
	buildingsByFilter: PaginatedBuildingsResponse
	buildingsByKeyword: PaginatedBuildingsResponse
	buildingsByKeywordWithoutPagination: Array<Building>
	departmentById: Department
	departments: Array<Department>
	departmentsByFilter: PaginatedDepartmentsResponse
	departmentsByKeyword: PaginatedDepartmentsResponse
	deviceById?: Maybe<Device>
	devices: Array<Device>
	devicesByFilter: PaginatedDevicesResponse
	devicesByType: Array<Device>
	emailedUsersOfAlert: Array<User>
	inventoryAuditStatsOfAsset: AssetInventoryAuditStats
	inventoryAuditsOfAsset: PaginatedAssetInventoryAuditsResponse
	lastWeekRestrictedUserLocationRecords: Array<UserLocationRecord>
	latestAssetInventoryAudits: Array<AssetInventoryAudit>
	latestInventoryAuditsOfAsset?: Maybe<AssetInventoryAudit>
	listOfRFIDCardsNotReadAfter: Scalars["String"]
	me?: Maybe<User>
	monthlyUserIds: Array<DailyUsersResponse>
	notifiedUsersOfAlert: Array<User>
	overTimeActiveUserIdsInLastInterval: Array<TypeWiseUsersResponse>
	overTimeActiveUsersInLastInterval: PaginatedUsersResponse
	permissionTags: Array<PermissionTags>
	permissionsGroupById: PermissionsGroup
	permissionsGroups: Array<PermissionsGroup>
	permissionsGroupsByKeyword: PaginatedPermissionsGroupsResponse
	placementRecordsOfAsset: PaginatedAssetPlacementRecordsResponse
	previousWeekRestrictedUserLocationRecords: Array<UserLocationRecord>
	readerById?: Maybe<Reader>
	readers: Array<Reader>
	readersAtVendor: Array<Reader>
	readersAtZone: Array<Reader>
	readersByFilter: PaginatedReadersResponse
	readersByType: Array<Reader>
	readersInBuilding: Array<Reader>
	reportById?: Maybe<Report>
	reportsByKeyword: PaginatedReportsResponse
	rootTile?: Maybe<Tile>
	serviceById: Service
	servicesByKeyword: Array<Service>
	session?: Maybe<Scalars["String"]>
	siteById: Site
	statusLogsOfReader: PaginatedReaderStatusLogsResponse
	tagByDecimalId?: Maybe<Tag>
	tagById?: Maybe<Tag>
	tagsByFilter: PaginatedTagsResponse
	tileById?: Maybe<Tile>
	tiles: Array<Tile>
	tilesByFilter: PaginatedTilesResponse
	todayRestrictedUserLocationRecordIds: Array<Scalars["String"]>
	todayRestrictedUserLocationRecords: PaginatedUserLocationRecordsResponse
	todayTrackedAssetIds: Array<Scalars["String"]>
	todayTrackedAssets: PaginatedAssetsResponse
	typeWiseActiveUsers: Array<TypeWiseUsersResponse>
	typeWiseActiveUsersInLastInterval: Array<TypeWiseUsersResponse>
	userById?: Maybe<User>
	userCurrentLocationRecord?: Maybe<UserLocationRecord>
	userCurrentUserRole?: Maybe<UserRole>
	userGroupById: UserGroup
	userGroupsByFilter: PaginatedUserGroupsResponse
	userLocationRecordById?: Maybe<UserLocationRecord>
	userLocationRecordsAtZone: Array<UserLocationRecord>
	userLocationRecordsInBuilding: Array<UserLocationRecord>
	userLocationRecordsTrail: Array<UserLocationRecord>
	userPastAttendanceRecords: PaginatedUserAttendanceRecordsResponse
	userPastLocationRecords: PaginatedUserLocationRecordsResponse
	userPermissionsGroups?: Maybe<Array<PermissionsGroup>>
	userRoleById?: Maybe<UserRole>
	userRoleGroupById: UserRoleGroup
	userRoleGroups: Array<UserRoleGroup>
	userRoleGroupsByKeyword: PaginatedUserRoleGroupsResponse
	userTypeById: UserType
	userTypes: Array<UserType>
	userTypesByKeyword: PaginatedUserTypesResponse
	users: PaginatedUsersResponse
	usersByCurrentUserRoleGroup: PaginatedUsersResponse
	usersByDateAndType: PaginatedUsersResponse
	usersByDepartment: PaginatedUsersResponse
	usersByKeyword: PaginatedUsersResponse
	usersByType: PaginatedUsersResponse
	usersOfAlertTargetGroup: Array<User>
	usersOfUserGroup: Array<User>
	vendorById: Vendor
	vendorWiseActiveAssetIds: VendorWiseActiveAssetsResponse
	vendorWiseActiveAssets: PaginatedAssetsResponse
	vendors: Array<Vendor>
	vendorsByFilter: Array<Vendor>
	vendorsByKeyword: PaginatedVendorsResponse
	zoneById?: Maybe<Zone>
	zoneGroupById?: Maybe<ZoneGroup>
	zoneGroups: Array<ZoneGroup>
	zoneGroupsByFilter: PaginatedZoneGroupsResponse
	zoneGroupsByKeyword: PaginatedZoneGroupsResponse
	zones: Array<Zone>
	zonesByFilter: Array<Zone>
	zonesByKeyword: PaginatedZonesResponse
	zonesInBuilding: Array<Zone>
	zonesOfZoneGroup: PaginatedZonesResponse
	zonesOnBuildingFloor: Array<Zone>
}

export type QueryActiveAssetIdsInLastIntervalArgs = {
	intervalInMinutes: Scalars["Float"]
}

export type QueryActiveAssetsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
	status?: InputMaybe<AssetStatus>
}

export type QueryActiveAssetsByVendorArgs = {
	pagination: PaginatedInput
	vendorId: Scalars["String"]
}

export type QueryActiveAssetsInLastIntervalArgs = {
	intervalInMinutes: Scalars["Float"]
	keyword: Scalars["String"]
	pagination: PaginatedInput
	status?: InputMaybe<AssetStatus>
}

export type QueryActiveAttendanceOfAssetArgs = {
	assetId: Scalars["String"]
}

export type QueryActiveAttendanceOfUserArgs = {
	userId: Scalars["String"]
}

export type QueryActiveRestrictedEntriesInBuildingArgs = {
	buildingId: Scalars["String"]
	userTypeIds?: InputMaybe<Array<Scalars["String"]>>
}

export type QueryActiveRestrictedUserLocationRecordsArgs = {
	pagination: PaginatedInput
}

export type QueryActiveUserIdsArgs = {
	isRestricted?: InputMaybe<Scalars["Boolean"]>
}

export type QueryActiveUserIdsInLastIntervalArgs = {
	intervalInMinutes: Scalars["Float"]
}

export type QueryActiveUsersArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
	roleType?: InputMaybe<UserRoleTypes>
	status?: InputMaybe<UserStatus>
	typeId?: InputMaybe<Scalars["String"]>
}

export type QueryActiveUsersInLastIntervalArgs = {
	intervalInMinutes: Scalars["Float"]
	keyword: Scalars["String"]
	pagination: PaginatedInput
	roleType?: InputMaybe<UserRoleTypes>
	status?: InputMaybe<UserStatus>
	typeId?: InputMaybe<Scalars["String"]>
}

export type QueryAlertByIdArgs = {
	alertId: Scalars["String"]
}

export type QueryAlertFlowByIdArgs = {
	alertFlowId: Scalars["String"]
}

export type QueryAlertFlowsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryAlertFlowsOfAlertArgs = {
	alertId: Scalars["String"]
}

export type QueryAlertSourceOfAlertFlowArgs = {
	alertFlowId: Scalars["String"]
}

export type QueryAlertTargetGroupByIdArgs = {
	alertTargetGroupId: Scalars["String"]
}

export type QueryAlertTargetGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryAlertTargetGroupsOfAlertFlowArgs = {
	alertFlowId: Scalars["String"]
}

export type QueryAlertedUsersOfAlertArgs = {
	alertId: Scalars["String"]
}

export type QueryAlertsByFilterArgs = {
	filter: AlertsFilterInput
	pagination: PaginatedInput
}

export type QueryAllAlertFlowsArgs = {
	pagination: PaginatedInput
}

export type QueryAllAlertTargetGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryAllAlertsArgs = {
	pagination: PaginatedInput
}

export type QueryAllAssetCategoriesArgs = {
	pagination: PaginatedInput
}

export type QueryAllBuildingsArgs = {
	pagination: PaginatedInput
}

export type QueryAllDepartmentsArgs = {
	pagination: PaginatedInput
}

export type QueryAllDevicesArgs = {
	pagination: PaginatedInput
}

export type QueryAllPermissionsGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryAllReportsArgs = {
	pagination: PaginatedInput
}

export type QueryAllTagsArgs = {
	pagination: PaginatedInput
}

export type QueryAllUserGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryAllUserRoleGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryAllUserTypesArgs = {
	pagination: PaginatedInput
}

export type QueryAllUsersArgs = {
	pagination: PaginatedInput
}

export type QueryAllVendorsArgs = {
	pagination: PaginatedInput
}

export type QueryAllZoneGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryAllZonesArgs = {
	pagination: PaginatedInput
}

export type QueryAssetAttendanceRecordsAtVendorArgs = {
	vendorId: Scalars["String"]
}

export type QueryAssetByIdArgs = {
	assetId: Scalars["String"]
}

export type QueryAssetCategoriesByFilterArgs = {
	filter: AssetCategoriesFilterInput
	pagination: PaginatedInput
}

export type QueryAssetCategoriesByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryAssetCategoryByIdArgs = {
	assetCategoryId: Scalars["String"]
}

export type QueryAssetCurrentLocationRecordArgs = {
	assetId: Scalars["String"]
}

export type QueryAssetLocationRecordsAtZoneArgs = {
	isRestricted?: InputMaybe<Scalars["Boolean"]>
	zoneId: Scalars["String"]
}

export type QueryAssetLocationRecordsInBuildingArgs = {
	buildingId: Scalars["String"]
	isRestricted?: InputMaybe<Scalars["Boolean"]>
}

export type QueryAssetLocationRecordsTrailArgs = {
	assetId: Scalars["String"]
}

export type QueryAssetPastAttendanceRecordsArgs = {
	assetId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}

export type QueryAssetPastLocationRecordsArgs = {
	assetId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}

export type QueryAssetsArgs = {
	filter: AssetsFilterInput
	pagination: PaginatedInput
}

export type QueryAssetsByFilterArgs = {
	categoryId?: InputMaybe<Scalars["String"]>
	keyword: Scalars["String"]
	pagination: PaginatedInput
	status?: InputMaybe<AssetStatus>
	type?: InputMaybe<AssetTypes>
	vendorId?: InputMaybe<Scalars["String"]>
}

export type QueryBuildingByIdArgs = {
	buildingId: Scalars["String"]
}

export type QueryBuildingsByFilterArgs = {
	filter: BuildingsFilterInput
	pagination: PaginatedInput
}

export type QueryBuildingsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryBuildingsByKeywordWithoutPaginationArgs = {
	keyword: Scalars["String"]
}

export type QueryDepartmentByIdArgs = {
	departmentId: Scalars["String"]
}

export type QueryDepartmentsByFilterArgs = {
	filter: DepartmentsFilterInput
	pagination: PaginatedInput
}

export type QueryDepartmentsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryDeviceByIdArgs = {
	deviceId: Scalars["String"]
}

export type QueryDevicesByFilterArgs = {
	filter: DevicesFilterInput
	pagination: PaginatedInput
}

export type QueryDevicesByTypeArgs = {
	type: DeviceTypes
}

export type QueryEmailedUsersOfAlertArgs = {
	alertId: Scalars["String"]
}

export type QueryInventoryAuditStatsOfAssetArgs = {
	assetId: Scalars["String"]
}

export type QueryInventoryAuditsOfAssetArgs = {
	assetId: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryLatestInventoryAuditsOfAssetArgs = {
	assetId: Scalars["String"]
}

export type QueryListOfRfidCardsNotReadAfterArgs = {
	date: Scalars["DateTime"]
	siteId: Scalars["String"]
}

export type QueryNotifiedUsersOfAlertArgs = {
	alertId: Scalars["String"]
}

export type QueryOverTimeActiveUserIdsInLastIntervalArgs = {
	forToday: Scalars["Boolean"]
	timeLimit: Scalars["Float"]
}

export type QueryOverTimeActiveUsersInLastIntervalArgs = {
	forToday: Scalars["Boolean"]
	keyword: Scalars["String"]
	pagination: PaginatedInput
	roleType?: InputMaybe<UserRoleTypes>
	status?: InputMaybe<UserStatus>
	timeLimit: Scalars["Float"]
	typeId?: InputMaybe<Scalars["String"]>
}

export type QueryPermissionsGroupByIdArgs = {
	permissionsGroupId: Scalars["String"]
}

export type QueryPermissionsGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryPlacementRecordsOfAssetArgs = {
	assetId: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryReaderByIdArgs = {
	readerId: Scalars["String"]
}

export type QueryReadersAtVendorArgs = {
	vendorId: Scalars["String"]
}

export type QueryReadersAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type QueryReadersByFilterArgs = {
	filter: ReadersFilterInput
	pagination: PaginatedInput
}

export type QueryReadersByTypeArgs = {
	type: ReaderTypes
}

export type QueryReadersInBuildingArgs = {
	buildingId: Scalars["String"]
}

export type QueryReportByIdArgs = {
	reportId: Scalars["String"]
}

export type QueryReportsByKeywordArgs = {
	endDate?: InputMaybe<Scalars["DateTime"]>
	keyword: Scalars["String"]
	pagination: PaginatedInput
	startDate?: InputMaybe<Scalars["DateTime"]>
	status?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<Scalars["String"]>
}

export type QueryServiceByIdArgs = {
	serviceId: Scalars["String"]
}

export type QueryServicesByKeywordArgs = {
	keyword: Scalars["String"]
}

export type QuerySiteByIdArgs = {
	siteId: Scalars["String"]
}

export type QueryStatusLogsOfReaderArgs = {
	date: Scalars["DateTime"]
	pagination: PaginatedInput
	readerId: Scalars["String"]
}

export type QueryTagByDecimalIdArgs = {
	tagDecimalId: Scalars["String"]
}

export type QueryTagByIdArgs = {
	tagId: Scalars["String"]
}

export type QueryTagsByFilterArgs = {
	filter: TagsFilterInput
	pagination: PaginatedInput
}

export type QueryTileByIdArgs = {
	tileId: Scalars["String"]
}

export type QueryTilesByFilterArgs = {
	filter: TilesFilterInput
	pagination: PaginatedInput
}

export type QueryTodayRestrictedUserLocationRecordsArgs = {
	pagination: PaginatedInput
}

export type QueryTodayTrackedAssetsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
	status?: InputMaybe<AssetStatus>
}

export type QueryTypeWiseActiveUsersInLastIntervalArgs = {
	intervalInMinutes: Scalars["Float"]
}

export type QueryUserByIdArgs = {
	userId: Scalars["String"]
}

export type QueryUserCurrentLocationRecordArgs = {
	userId: Scalars["String"]
}

export type QueryUserCurrentUserRoleArgs = {
	userId: Scalars["String"]
}

export type QueryUserGroupByIdArgs = {
	userGroupId: Scalars["String"]
}

export type QueryUserGroupsByFilterArgs = {
	filter: UserGroupsFilterInput
	pagination: PaginatedInput
}

export type QueryUserLocationRecordByIdArgs = {
	userLocationRecordId: Scalars["String"]
}

export type QueryUserLocationRecordsAtZoneArgs = {
	isRestricted?: InputMaybe<Scalars["Boolean"]>
	userTypeIds?: InputMaybe<Array<Scalars["String"]>>
	zoneId: Scalars["String"]
}

export type QueryUserLocationRecordsInBuildingArgs = {
	buildingId: Scalars["String"]
	isRestricted?: InputMaybe<Scalars["Boolean"]>
	userTypeIds?: InputMaybe<Array<Scalars["String"]>>
}

export type QueryUserLocationRecordsTrailArgs = {
	userId: Scalars["String"]
}

export type QueryUserPastAttendanceRecordsArgs = {
	date: Scalars["DateTime"]
	pagination: PaginatedInput
	userId: Scalars["String"]
}

export type QueryUserPastLocationRecordsArgs = {
	date: Scalars["DateTime"]
	pagination: PaginatedInput
	userId: Scalars["String"]
}

export type QueryUserPermissionsGroupsArgs = {
	userId: Scalars["String"]
}

export type QueryUserRoleByIdArgs = {
	userRoleId: Scalars["String"]
}

export type QueryUserRoleGroupByIdArgs = {
	userRoleGroupId: Scalars["String"]
}

export type QueryUserRoleGroupsArgs = {
	type?: InputMaybe<UserRoleTypes>
}

export type QueryUserRoleGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryUserTypeByIdArgs = {
	userTypeId: Scalars["String"]
}

export type QueryUserTypesByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryUsersArgs = {
	filter: UsersFilterInput
	pagination: PaginatedInput
}

export type QueryUsersByCurrentUserRoleGroupArgs = {
	pagination: PaginatedInput
	userRoleGroupId: Scalars["String"]
}

export type QueryUsersByDateAndTypeArgs = {
	filter: UsersByDateAndTypeFilterInput
	pagination: PaginatedInput
}

export type QueryUsersByDepartmentArgs = {
	departmentId: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryUsersByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
	roleType?: InputMaybe<UserRoleTypes>
	since?: InputMaybe<Scalars["DateTime"]>
	status?: InputMaybe<UserStatus>
	typeId?: InputMaybe<Scalars["String"]>
	until?: InputMaybe<Scalars["DateTime"]>
	visitorLevel?: InputMaybe<Scalars["String"]>
}

export type QueryUsersByTypeArgs = {
	pagination: PaginatedInput
	typeId: Scalars["String"]
}

export type QueryUsersOfAlertTargetGroupArgs = {
	alertTargetGroupId: Scalars["String"]
}

export type QueryUsersOfUserGroupArgs = {
	userGroupId: Scalars["String"]
}

export type QueryVendorByIdArgs = {
	vendorId: Scalars["String"]
}

export type QueryVendorWiseActiveAssetsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
	status?: InputMaybe<AssetStatus>
}

export type QueryVendorsByFilterArgs = {
	filter: VendorsFilterInput
}

export type QueryVendorsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryZoneByIdArgs = {
	zoneId: Scalars["String"]
}

export type QueryZoneGroupByIdArgs = {
	zoneGroupId: Scalars["String"]
}

export type QueryZoneGroupsByFilterArgs = {
	filter: ZoneGroupsFilterInput
	pagination: PaginatedInput
}

export type QueryZoneGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryZonesByFilterArgs = {
	filter: ZonesFilterInput
}

export type QueryZonesByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryZonesInBuildingArgs = {
	buildingId: Scalars["String"]
}

export type QueryZonesOfZoneGroupArgs = {
	pagination: PaginatedInput
	zoneGroupId: Scalars["String"]
}

export type QueryZonesOnBuildingFloorArgs = {
	buildingId: Scalars["String"]
	floorId: Scalars["String"]
}

export type Reader = {
	__typename?: "Reader"
	_id: Scalars["ID"]
	assignedAt?: Maybe<Scalars["DateTime"]>
	assignedToVendor?: Maybe<Vendor>
	assignedToVendorId?: Maybe<Scalars["ID"]>
	assignedToZone?: Maybe<Zone>
	assignedToZoneId?: Maybe<Scalars["ID"]>
	createdAt: Scalars["DateTime"]
	isActive?: Maybe<Scalars["Boolean"]>
	label: Label
	lastConnectedAt?: Maybe<Scalars["DateTime"]>
	lastConnectedEndpoint?: Maybe<Scalars["String"]>
	lastHeartbeatAt?: Maybe<Scalars["DateTime"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	readerDecimalId: Scalars["String"]
	readerHexId: Scalars["String"]
	site: Site
	siteId: Scalars["ID"]
	stockReaderId: Scalars["ID"]
	type: ReaderTypes
	updatedAt: Scalars["DateTime"]
}

export type ReaderInput = {
	label: LabelInput
	readerDecimalId: Scalars["String"]
	type: ReaderTypes
}

export type ReaderStatusLog = {
	__typename?: "ReaderStatusLog"
	_id: Scalars["ID"]
	connectedAt: Scalars["DateTime"]
	createdAt: Scalars["DateTime"]
	disconnectedAt?: Maybe<Scalars["DateTime"]>
	isActive?: Maybe<Scalars["Boolean"]>
	readerId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
}

export enum ReaderTypes {
	AssetTagEnrolling = "AssetTagEnrolling",
	Indoor = "Indoor",
	Outdoor = "Outdoor",
	Unknown = "Unknown",
}

export type ReadersFilterInput = {
	isActive?: InputMaybe<Scalars["Boolean"]>
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<ReaderTypes>
}

export type ReadersStatusLogsReportInput = {
	label: LabelInput
	readerIds: Array<Scalars["String"]>
	readersFilter: ReadersFilterInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
}

export type Report = {
	__typename?: "Report"
	_id: Scalars["ID"]
	assetIds?: Maybe<Array<Scalars["ID"]>>
	assets: Array<Asset>
	buildingIds?: Maybe<Array<Scalars["ID"]>>
	completeJson?: Maybe<ReportObject>
	createdAt: Scalars["DateTime"]
	csv?: Maybe<ReportObject>
	json?: Maybe<ReportObject>
	label: Label
	lastUpdatedAt?: Maybe<Scalars["DateTime"]>
	lastUpdatedByUser?: Maybe<User>
	lastUpdatedByUserId?: Maybe<Scalars["ID"]>
	readerIds?: Maybe<Array<Scalars["ID"]>>
	readers: Array<Reader>
	sinceDate: Scalars["DateTime"]
	site: Site
	siteId: Scalars["ID"]
	status: ReportStatus
	type: ReportTypes
	untilDate: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
	userIds?: Maybe<Array<Scalars["ID"]>>
	users: Array<User>
	workingHours?: Maybe<Scalars["Float"]>
	xlsx?: Maybe<ReportObject>
	zoneGroupIds?: Maybe<Array<Scalars["ID"]>>
	zoneGroups: Array<ZoneGroup>
	zoneIds?: Maybe<Array<Scalars["ID"]>>
	zones: Array<Zone>
}

export type ReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
}

export type ReportObject = {
	__typename?: "ReportObject"
	key: Scalars["String"]
	url: Scalars["String"]
}

export enum ReportStatus {
	Failed = "Failed",
	Generated = "Generated",
	Processing = "Processing",
	Queued = "Queued",
}

export enum ReportTypes {
	ActiveUsersAtBuildings = "ActiveUsersAtBuildings",
	ActiveUsersAtZoneGroups = "ActiveUsersAtZoneGroups",
	ActiveUsersAtZones = "ActiveUsersAtZones",
	AssetLocationRecords = "AssetLocationRecords",
	BuildingLocationRecords = "BuildingLocationRecords",
	Dashboard = "Dashboard",
	ReaderStatusLogs = "ReaderStatusLogs",
	UserAttendance = "UserAttendance",
	UserLocationRecords = "UserLocationRecords",
	UserRestrictedEntries = "UserRestrictedEntries",
	Visitor = "Visitor",
	ZoneGroupLocationRecords = "ZoneGroupLocationRecords",
	ZoneGroupRestrictedEntries = "ZoneGroupRestrictedEntries",
	ZoneLocationRecords = "ZoneLocationRecords",
	ZoneRestrictedEntries = "ZoneRestrictedEntries",
}

export type Service = {
	__typename?: "Service"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	isActive?: Maybe<Scalars["Boolean"]>
	key: Scalars["String"]
	label: Label
	lastHeartbeatAt?: Maybe<Scalars["DateTime"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
}

export type ServiceInput = {
	key: Scalars["String"]
	label: LabelInput
}

export type Site = {
	__typename?: "Site"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	features: Array<Features>
	label: Label
	updatedAt: Scalars["DateTime"]
}

export type Subscription = {
	__typename?: "Subscription"
	alertsUpdate: Alert
	assetLocationRecordUpdate: AssetLocationRecord
	assetLocationRecordUpdateAtZone: AssetLocationRecord
	assetLocationRecordsUpdate: AssetLocationRecord
	deviceUpdate: Device
	enrolledTagByDeviceId: EnrolledTag
	enrolledTagByReaderId: EnrolledTag
	readerUpdate: Reader
	readerUpdateAtVendor: Reader
	readerUpdateAtZone: Reader
	reportUpdate: Report
	restrictedUserLocationRecordsUpdate: UserLocationRecord
	userAuthSessionUpdate: UserAuthSession
	userLocationRecordUpdate: UserLocationRecord
	userLocationRecordUpdateAtZone: UserLocationRecord
	userLocationRecordsUpdate: UserLocationRecord
}

export type SubscriptionAssetLocationRecordUpdateArgs = {
	assetId: Scalars["String"]
}

export type SubscriptionAssetLocationRecordUpdateAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type SubscriptionDeviceUpdateArgs = {
	deviceId: Scalars["String"]
}

export type SubscriptionEnrolledTagByDeviceIdArgs = {
	deviceId: Scalars["String"]
}

export type SubscriptionEnrolledTagByReaderIdArgs = {
	readerId: Scalars["String"]
}

export type SubscriptionReaderUpdateArgs = {
	readerId: Scalars["String"]
}

export type SubscriptionReaderUpdateAtVendorArgs = {
	vendorId: Scalars["String"]
}

export type SubscriptionReaderUpdateAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type SubscriptionReportUpdateArgs = {
	reportId: Scalars["String"]
}

export type SubscriptionRestrictedUserLocationRecordsUpdateArgs = {
	recordIds: Array<Scalars["String"]>
}

export type SubscriptionUserAuthSessionUpdateArgs = {
	sessionId: Scalars["String"]
}

export type SubscriptionUserLocationRecordUpdateArgs = {
	userId: Scalars["String"]
}

export type SubscriptionUserLocationRecordUpdateAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type Tag = {
	__typename?: "Tag"
	_id: Scalars["ID"]
	asset?: Maybe<Asset>
	assignedAt?: Maybe<Scalars["DateTime"]>
	assignedToAssetId?: Maybe<Scalars["ID"]>
	assignedToUserId?: Maybe<Scalars["ID"]>
	batteryStatus: TagBatteryStatus
	createdAt: Scalars["DateTime"]
	lastUpdatedAt?: Maybe<Scalars["DateTime"]>
	lastUpdatedByUserId?: Maybe<Scalars["ID"]>
	site: Site
	siteId: Scalars["ID"]
	status: TagStatus
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	user?: Maybe<User>
}

export enum TagBatteryStatus {
	Low = "Low",
	Normal = "Normal",
	Unknown = "Unknown",
}

export enum TagStatus {
	Active = "Active",
	Delivered = "Delivered",
	Expired = "Expired",
	Inactive = "Inactive",
	Unknown = "Unknown",
	Virgin = "Virgin",
}

export type TagsFilterInput = {
	batteryStatus?: InputMaybe<TagBatteryStatus>
	keyword?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<TagStatus>
}

export type Tile = {
	__typename?: "Tile"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	isDeleted?: Maybe<Scalars["Boolean"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	levelWiseZoneEmbeddings: Array<LevelWiseZoneEmbeddings>
	picture?: Maybe<Picture>
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
}

export type TilesFilterInput = {
	keyword?: InputMaybe<Scalars["String"]>
}

export type TypeWiseUsersResponse = {
	__typename?: "TypeWiseUsersResponse"
	label: Label
	typeId: Scalars["ID"]
	userIds: Array<Scalars["ID"]>
}

export type UpdateUserInput = {
	address?: InputMaybe<Scalars["String"]>
	email?: InputMaybe<EmailInput>
	employeeId?: InputMaybe<Scalars["String"]>
	name: Scalars["String"]
	phone?: InputMaybe<PhoneInput>
	typeId: Scalars["String"]
}

export type UpdateUserRoleGroupInput = {
	allowedZoneGroupIds?: InputMaybe<Array<Scalars["String"]>>
	allowedZoneIds?: InputMaybe<Array<Scalars["String"]>>
	departmentId: Scalars["String"]
	label: LabelInput
}

export type UploadSignatureResponse = {
	__typename?: "UploadSignatureResponse"
	key: Scalars["String"]
	signedUrl: Scalars["String"]
}

export type User = {
	__typename?: "User"
	_id: Scalars["ID"]
	address?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	department?: Maybe<Department>
	departmentId?: Maybe<Scalars["ID"]>
	email?: Maybe<Email>
	employeeId?: Maybe<Scalars["String"]>
	fcmTokens?: Maybe<Array<Scalars["String"]>>
	isDeleted?: Maybe<Scalars["Boolean"]>
	isDepartmentHead?: Maybe<Scalars["Boolean"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	name: Scalars["String"]
	pastVisitorDetails?: Maybe<Array<VisitorDetails>>
	permissionsGroupIds?: Maybe<Array<Scalars["ID"]>>
	permissionsGroups?: Maybe<Array<PermissionsGroup>>
	phone?: Maybe<Phone>
	picture?: Maybe<Picture>
	roleType: UserRoleTypes
	site: Site
	siteId: Scalars["ID"]
	status: UserStatus
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["ID"]>
	type: UserType
	typeId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	userRole?: Maybe<UserRole>
	userRoleGroup?: Maybe<UserRoleGroup>
	userRoleGroupId?: Maybe<Scalars["ID"]>
	userRoleId?: Maybe<Scalars["ID"]>
	username: Scalars["String"]
	visitorDetails?: Maybe<VisitorDetails>
}

export type UserAttendanceRecord = {
	__typename?: "UserAttendanceRecord"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	site: Site
	siteId: Scalars["ID"]
	startAt: Scalars["DateTime"]
	status: UserAttendanceRecordStatus
	updatedAt: Scalars["DateTime"]
	user: User
	userDepartment?: Maybe<Department>
	userDepartmentId?: Maybe<Scalars["ID"]>
	userId: Scalars["ID"]
	userRoleType?: Maybe<UserRoleTypes>
	userType?: Maybe<UserType>
	userTypeId?: Maybe<Scalars["ID"]>
}

export enum UserAttendanceRecordStatus {
	Active = "Active",
	Final = "Final",
}

export type UserAttendanceReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
	usersFilter: UsersFilterInput
	workingHours: Scalars["Float"]
}

export type UserAuthResponse = {
	__typename?: "UserAuthResponse"
	errors?: Maybe<Array<FieldError>>
	sessionToken?: Maybe<Scalars["String"]>
	user?: Maybe<User>
}

export type UserAuthSession = {
	__typename?: "UserAuthSession"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	ip?: Maybe<Scalars["String"]>
	loggedInAt: Scalars["DateTime"]
	loggedOutAt?: Maybe<Scalars["DateTime"]>
	updatedAt: Scalars["DateTime"]
	userId: Scalars["ID"]
}

export type UserGroup = {
	__typename?: "UserGroup"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
	userIds: Array<Scalars["ID"]>
	users: Array<User>
}

export type UserGroupInput = {
	label: LabelInput
	userIds: Array<Scalars["String"]>
}

export type UserGroupsFilterInput = {
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
}

export type UserInput = {
	address?: InputMaybe<Scalars["String"]>
	email?: InputMaybe<EmailInput>
	employeeId?: InputMaybe<Scalars["String"]>
	name: Scalars["String"]
	phone?: InputMaybe<PhoneInput>
	typeId: Scalars["String"]
	visitorDetails?: InputMaybe<VisitorDetailsInput>
}

export type UserLocationRecord = {
	__typename?: "UserLocationRecord"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	isRestricted?: Maybe<Scalars["Boolean"]>
	site: Site
	siteId: Scalars["ID"]
	startAt: Scalars["DateTime"]
	status: UserLocationRecordStatus
	updatedAt: Scalars["DateTime"]
	user: User
	userDepartment?: Maybe<Department>
	userDepartmentId?: Maybe<Scalars["ID"]>
	userId: Scalars["ID"]
	userRoleType?: Maybe<UserRoleTypes>
	userType?: Maybe<UserType>
	userTypeId?: Maybe<Scalars["ID"]>
	zone: Zone
	zoneId: Scalars["ID"]
}

export enum UserLocationRecordStatus {
	Active = "Active",
	Final = "Final",
	Stale = "Stale",
}

export type UserLocationRecordsReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
	usersFilter: UsersFilterInput
}

export type UserRestrictedEntriesReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
	usersFilter: UsersFilterInput
}

export type UserRole = {
	__typename?: "UserRole"
	_id: Scalars["ID"]
	assignedAt: Scalars["DateTime"]
	createdAt: Scalars["DateTime"]
	designation?: Maybe<Scalars["String"]>
	endAt?: Maybe<Scalars["DateTime"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	removedAt?: Maybe<Scalars["DateTime"]>
	site: Site
	siteId: Scalars["ID"]
	startAt: Scalars["DateTime"]
	status: UserRoleStatus
	type: UserRoleTypes
	updatedAt: Scalars["DateTime"]
	user: User
	userId: Scalars["ID"]
	userRoleGroup: UserRoleGroup
	userRoleGroupId: Scalars["ID"]
}

export type UserRoleGroup = {
	__typename?: "UserRoleGroup"
	_id: Scalars["ID"]
	allowedZoneGroupIds?: Maybe<Array<Scalars["ID"]>>
	allowedZoneGroups: Array<ZoneGroup>
	allowedZoneIds?: Maybe<Array<Scalars["ID"]>>
	allowedZones: Array<Zone>
	createdAt: Scalars["DateTime"]
	department: Department
	departmentId: Scalars["ID"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	type: UserRoleTypes
	updatedAt: Scalars["DateTime"]
}

export type UserRoleGroupInput = {
	allowedZoneGroupIds?: InputMaybe<Array<Scalars["String"]>>
	allowedZoneIds?: InputMaybe<Array<Scalars["String"]>>
	departmentId: Scalars["String"]
	label: LabelInput
	type: UserRoleTypes
}

export type UserRoleInput = {
	designation: Scalars["String"]
	endAt?: InputMaybe<Scalars["DateTime"]>
	startAt: Scalars["DateTime"]
	type: UserRoleTypes
	userRoleGroupId: Scalars["String"]
}

export enum UserRoleStatus {
	Current = "Current",
	Expired = "Expired",
	Future = "Future",
	Past = "Past",
	Unknown = "Unknown",
}

export enum UserRoleTypes {
	Admin = "Admin",
	ShortTerm = "ShortTerm",
	Unknown = "Unknown",
	Working = "Working",
}

export enum UserStatus {
	Active = "Active",
	Inactive = "Inactive",
}

export type UserType = {
	__typename?: "UserType"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	roleType: UserRoleTypes
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
}

export type UserTypeInput = {
	label: LabelInput
	roleType: UserRoleTypes
}

export type UsersByDateAndTypeFilterInput = {
	keyword?: InputMaybe<Scalars["String"]>
	since: Scalars["DateTime"]
	until: Scalars["DateTime"]
	userTypeId?: InputMaybe<Scalars["String"]>
}

export type UsersFilterInput = {
	departmentId?: InputMaybe<Scalars["String"]>
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
	userTypeId?: InputMaybe<Scalars["String"]>
}

export type Vendor = {
	__typename?: "Vendor"
	_id: Scalars["ID"]
	address?: Maybe<Address>
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	picture?: Maybe<Picture>
	readerIds?: Maybe<Array<Scalars["ID"]>>
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
}

export type VendorInput = {
	address?: InputMaybe<AddressInput>
	label: LabelInput
}

export type VendorWiseActiveAssetsResponse = {
	__typename?: "VendorWiseActiveAssetsResponse"
	assetIds: Array<Scalars["String"]>
	vendorWiseAssets: Array<VendorWiseAssetsResponse>
}

export type VendorWiseAssetsResponse = {
	__typename?: "VendorWiseAssetsResponse"
	assetIds: Array<Scalars["String"]>
	vendorId: Scalars["String"]
}

export type VendorsFilterInput = {
	keyword?: InputMaybe<Scalars["String"]>
}

export type VisitorDetails = {
	__typename?: "VisitorDetails"
	buildingName?: Maybe<Scalars["String"]>
	companyName?: Maybe<Scalars["String"]>
	departmentName?: Maybe<Scalars["String"]>
	level?: Maybe<Scalars["String"]>
	meetingPerson?: Maybe<Scalars["String"]>
	visitDate?: Maybe<Scalars["DateTime"]>
	visitPurpose?: Maybe<Scalars["String"]>
	visitorCardNo?: Maybe<Scalars["String"]>
}

export type VisitorDetailsInput = {
	buildingName?: InputMaybe<Scalars["String"]>
	companyName?: InputMaybe<Scalars["String"]>
	level?: InputMaybe<Scalars["String"]>
	visitDate?: InputMaybe<Scalars["DateTime"]>
	visitorCardNo?: InputMaybe<Scalars["String"]>
}

export type VisitorReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
	usersFilter: UsersFilterInput
}

export type Zone = {
	__typename?: "Zone"
	_id: Scalars["ID"]
	building?: Maybe<Building>
	buildingId?: Maybe<Scalars["ID"]>
	createdAt: Scalars["DateTime"]
	floor?: Maybe<Floor>
	floorId?: Maybe<Scalars["ID"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	picture?: Maybe<Picture>
	readerIds?: Maybe<Array<Scalars["ID"]>>
	site: Site
	siteId: Scalars["ID"]
	type: ZoneTypes
	updatedAt: Scalars["DateTime"]
}

export type ZoneEmbedding = {
	__typename?: "ZoneEmbedding"
	polygon?: Maybe<Array<Position>>
	position: Position
	zoneId: Scalars["ID"]
}

export type ZoneGroup = {
	__typename?: "ZoneGroup"
	_id: Scalars["ID"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["ID"]
	site: Site
	siteId: Scalars["ID"]
	updatedAt: Scalars["DateTime"]
	zoneIds: Array<Scalars["ID"]>
	zones: Array<Zone>
}

export type ZoneGroupInput = {
	label: LabelInput
	zoneIds: Array<Scalars["String"]>
}

export type ZoneGroupLocationRecordsReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneGroupIds: Array<Scalars["String"]>
	zoneGroupsFilter: ZoneGroupsFilterInput
}

export type ZoneGroupRestrictedEntriesReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneGroupIds: Array<Scalars["String"]>
	zoneGroupsFilter: ZoneGroupsFilterInput
}

export type ZoneGroupsFilterInput = {
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
}

export type ZoneInput = {
	buildingId?: InputMaybe<Scalars["String"]>
	floorId?: InputMaybe<Scalars["String"]>
	label: LabelInput
	type: ZoneTypes
}

export type ZoneLocationRecordsReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
	zonesFilter: ZonesFilterInput
}

export type ZoneRestrictedEntriesReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
	zonesFilter: ZonesFilterInput
}

export enum ZoneTypes {
	Indoor = "Indoor",
	Outdoor = "Outdoor",
	Unknown = "Unknown",
}

export type ZoneWiseActiveRestrictedUserLocationRecordsResponse = {
	__typename?: "ZoneWiseActiveRestrictedUserLocationRecordsResponse"
	recordIds: Array<Scalars["String"]>
	zoneId: Scalars["String"]
}

export type ZoneWiseAssetsResponse = {
	__typename?: "ZoneWiseAssetsResponse"
	assetIds: Array<Scalars["String"]>
	zoneId: Scalars["String"]
}

export type ZoneWiseUsersResponse = {
	__typename?: "ZoneWiseUsersResponse"
	userIds: Array<Scalars["ID"]>
	zoneId: Scalars["ID"]
}

export type ZonesFilterInput = {
	isAllSelected?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<ZoneTypes>
}

export type AlertFragment = {
	__typename?: "Alert"
	_id: string
	siteId: string
	type: AlertTypes
	alertFlowIds: Array<string>
	emailMessage: string
	notificationMessage: string
	alertedUserIds: Array<string>
	emailedUserIds?: Array<string> | null
	notifiedUserIds?: Array<string> | null
	userId?: string | null
	userRoleId?: string | null
	zoneId?: string | null
	readerId?: string | null
	tagId?: string | null
	userLocationRecordId?: string | null
	serviceId?: string | null
	assetId?: string | null
	createdAt: any
	updatedAt: any
}

export type AlertFlowFragment = {
	__typename?: "AlertFlow"
	_id: string
	siteId: string
	type: AlertTypes
	targetGroupIds: Array<string>
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	source?: {
		__typename?: "AlertSource"
		type: AlertSourceTypes
		zoneIds?: Array<string> | null
		zoneGroupIds?: Array<string> | null
		userIds?: Array<string> | null
		userGroupIds?: Array<string> | null
		userTypeIds?: Array<string> | null
		userRoleTypes?: Array<UserRoleTypes> | null
		departmentIds?: Array<string> | null
		assetIds?: Array<string> | null
		assetCategoryIds?: Array<string> | null
	} | null
}

export type AlertTargetGroupFragment = { __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }

export type AssetFragment = {
	__typename?: "Asset"
	_id: string
	siteId: string
	type: AssetTypes
	name: string
	categoryId?: string | null
	tagId?: string | null
	vendorId?: string | null
	status: AssetStatus
	weightInKG?: number | null
	createdAt: any
	updatedAt: any
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		} | null
	} | null
	currentPlacement?: {
		__typename?: "AssetPlacementRecord"
		_id: string
		siteId: string
		assetId: string
		sourceZoneId?: string | null
		destinationZoneId: string
		placementTill: any
		lastUpdatedAt: any
		lastUpdatedByUserId: string
		createdAt: any
		updatedAt: any
	} | null
}

export type AssetCategoryFragment = {
	__typename?: "AssetCategory"
	_id: string
	siteId: string
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type AssetInventoryAuditFragment = { __typename?: "AssetInventoryAudit"; _id: string; siteId: string; assetId: string; assetWeightInKG: number; inventoryWeightInKG: number; assetCount: number; createdAt: any; updatedAt: any }

export type AssetInventoryAuditStatFragment = { __typename?: "AssetInventoryAuditStat"; auditId: string; count: number; auditedAt: any }

export type AssetInventoryAuditStatsFragment = {
	__typename?: "AssetInventoryAuditStats"
	_id: string
	siteId: string
	assetId: string
	createdAt: any
	updatedAt: any
	stats: Array<{ __typename?: "AssetInventoryAuditStat"; auditId: string; count: number; auditedAt: any }>
}

export type AssetLocationRecordFragment = {
	__typename?: "AssetLocationRecord"
	_id: string
	siteId: string
	assetId: string
	zoneId: string
	startAt: any
	endAt: any
	status: AssetLocationRecordStatus
	isRestricted?: boolean | null
	createdAt: any
	updatedAt: any
}

export type AssetPlacementRecordFragment = {
	__typename?: "AssetPlacementRecord"
	_id: string
	siteId: string
	assetId: string
	sourceZoneId?: string | null
	destinationZoneId: string
	placementTill: any
	lastUpdatedAt: any
	lastUpdatedByUserId: string
	createdAt: any
	updatedAt: any
}

export type BuildingFragment = {
	__typename?: "Building"
	_id: string
	siteId: string
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type DepartmentFragment = {
	__typename?: "Department"
	_id: string
	siteId: string
	departmentHeadId?: string | null
	zoneIds: Array<string>
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type DeviceFragment = {
	__typename?: "Device"
	_id: string
	siteId: string
	type: DeviceTypes
	deviceId: string
	isActive?: boolean | null
	lastHeartbeatAt?: any | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type EmailFragment = { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any }

export type EnrolledTagFragment = { __typename?: "EnrolledTag"; _id: string; siteId: string; readerId?: string | null; deviceId?: string | null; tagHexId: string; tagDecimalId: string; createdAt: any; updatedAt: any }

export type FieldErrorFragment = { __typename?: "FieldError"; field: string; error: string }

export type FloorFragment = { __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }

export type LabelFragment = { __typename?: "Label"; name: string; description?: string | null }

export type LevelWiseZoneEmbeddingsFragment = {
	__typename?: "LevelWiseZoneEmbeddings"
	level: number
	zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
}

export type PermissionsGroupFragment = { __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }

export type PictureFragment = {
	__typename?: "Picture"
	original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
	variants?: {
		__typename?: "PictureVariants"
		small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
	} | null
}

export type PictureObjectFragment = { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }

export type PositionFragment = { __typename?: "Position"; x: number; y: number }

export type ReaderFragment = {
	__typename?: "Reader"
	_id: string
	siteId: string
	type: ReaderTypes
	readerHexId: string
	readerDecimalId: string
	assignedToZoneId?: string | null
	assignedAt?: any | null
	isActive?: boolean | null
	lastConnectedEndpoint?: string | null
	lastConnectedAt?: any | null
	lastHeartbeatAt?: any | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type ReaderStatusLogFragment = { __typename?: "ReaderStatusLog"; _id: string; siteId: string; readerId: string; isActive?: boolean | null; connectedAt: any; disconnectedAt?: any | null; createdAt: any; updatedAt: any }

export type ReportFragment = {
	__typename?: "Report"
	_id: string
	siteId: string
	type: ReportTypes
	userIds?: Array<string> | null
	sinceDate: any
	untilDate: any
	status: ReportStatus
	lastUpdatedByUserId?: string | null
	lastUpdatedAt?: any | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
	json?: { __typename?: "ReportObject"; key: string; url: string } | null
	csv?: { __typename?: "ReportObject"; key: string; url: string } | null
	xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
}

export type ServiceFragment = {
	__typename?: "Service"
	_id: string
	key: string
	lastHeartbeatAt?: any | null
	isActive?: boolean | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type SiteFragment = { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }

export type TagFragment = {
	__typename?: "Tag"
	_id: string
	tagHexId: string
	tagDecimalId: string
	status: TagStatus
	batteryStatus: TagBatteryStatus
	assignedToUserId?: string | null
	assignedToAssetId?: string | null
	assignedAt?: any | null
	createdAt: any
	updatedAt: any
}

export type TileFragment = {
	__typename?: "Tile"
	_id: string
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		} | null
	} | null
	levelWiseZoneEmbeddings: Array<{
		__typename?: "LevelWiseZoneEmbeddings"
		level: number
		zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
	}>
}

export type UserFragment = {
	__typename?: "User"
	_id: string
	siteId: string
	name: string
	username: string
	address?: string | null
	typeId: string
	roleType: UserRoleTypes
	departmentId?: string | null
	isDepartmentHead?: boolean | null
	userRoleId?: string | null
	userRoleGroupId?: string | null
	employeeId?: string | null
	tagId?: string | null
	status: UserStatus
	createdAt: any
	updatedAt: any
	phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
	email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		} | null
	} | null
	visitorDetails?: {
		__typename?: "VisitorDetails"
		visitDate?: any | null
		companyName?: string | null
		level?: string | null
		visitorCardNo?: string | null
		meetingPerson?: string | null
		departmentName?: string | null
		buildingName?: string | null
		visitPurpose?: string | null
	} | null
	pastVisitorDetails?: Array<{
		__typename?: "VisitorDetails"
		visitDate?: any | null
		companyName?: string | null
		level?: string | null
		visitorCardNo?: string | null
		meetingPerson?: string | null
		departmentName?: string | null
		buildingName?: string | null
		visitPurpose?: string | null
	}> | null
}

export type UserAttendanceRecordFragment = { __typename?: "UserAttendanceRecord"; _id: string; siteId: string; userId: string; startAt: any; endAt: any; status: UserAttendanceRecordStatus; createdAt: any; updatedAt: any }

export type UserAuthResponseFragment = {
	__typename?: "UserAuthResponse"
	errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
	user?: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	} | null
}

export type UserAuthSessionFragment = { __typename?: "UserAuthSession"; _id: string; userId: string; ip?: string | null; loggedInAt: any; loggedOutAt?: any | null }

export type UserGroupFragment = { __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }

export type UserLocationRecordFragment = {
	__typename?: "UserLocationRecord"
	_id: string
	siteId: string
	zoneId: string
	userId: string
	startAt: any
	endAt: any
	status: UserLocationRecordStatus
	isRestricted?: boolean | null
	createdAt: any
	updatedAt: any
}

export type UserRoleFragment = {
	__typename?: "UserRole"
	_id: string
	siteId: string
	userId: string
	type: UserRoleTypes
	userRoleGroupId: string
	designation?: string | null
	startAt: any
	endAt?: any | null
	status: UserRoleStatus
	assignedAt: any
	removedAt?: any | null
}

export type UserRoleGroupFragment = {
	__typename?: "UserRoleGroup"
	_id: string
	siteId: string
	type: UserRoleTypes
	allowedZoneIds?: Array<string> | null
	allowedZoneGroupIds?: Array<string> | null
	departmentId: string
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type UserTypeFragment = { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }

export type VisitorDetailsFragment = {
	__typename?: "VisitorDetails"
	visitDate?: any | null
	companyName?: string | null
	level?: string | null
	visitorCardNo?: string | null
	meetingPerson?: string | null
	departmentName?: string | null
	buildingName?: string | null
	visitPurpose?: string | null
}

export type ZoneFragment = {
	__typename?: "Zone"
	_id: string
	siteId: string
	readerIds?: Array<string> | null
	type: ZoneTypes
	buildingId?: string | null
	floorId?: string | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		} | null
	} | null
}

export type ZoneEmbeddingFragment = { __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }

export type ZoneGroupFragment = { __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }

export type ActivateAssetMutationVariables = Exact<{
	assetId: Scalars["String"]
}>

export type ActivateAssetMutation = {
	__typename?: "Mutation"
	activateAsset: {
		__typename?: "Asset"
		_id: string
		siteId: string
		type: AssetTypes
		name: string
		categoryId?: string | null
		tagId?: string | null
		vendorId?: string | null
		status: AssetStatus
		weightInKG?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		currentPlacement?: {
			__typename?: "AssetPlacementRecord"
			_id: string
			siteId: string
			assetId: string
			sourceZoneId?: string | null
			destinationZoneId: string
			placementTill: any
			lastUpdatedAt: any
			lastUpdatedByUserId: string
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type ActivateTagOfAssetMutationVariables = Exact<{
	assetId: Scalars["String"]
}>

export type ActivateTagOfAssetMutation = {
	__typename?: "Mutation"
	activateTagOfAsset: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type ActivateTagOfUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type ActivateTagOfUserMutation = {
	__typename?: "Mutation"
	activateTagOfUser: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type ActivateUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type ActivateUserMutation = {
	__typename?: "Mutation"
	activateUser: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}
}

export type AddReaderToZoneMutationVariables = Exact<{
	zoneId: Scalars["String"]
	readerDecimalId: Scalars["String"]
}>

export type AddReaderToZoneMutation = {
	__typename?: "Mutation"
	addReaderToZone: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type AssignDepartmentHeadMutationVariables = Exact<{
	userId: Scalars["String"]
	departmentId: Scalars["String"]
}>

export type AssignDepartmentHeadMutation = {
	__typename?: "Mutation"
	assignDepartmentHead: {
		__typename?: "Department"
		_id: string
		siteId: string
		departmentHeadId?: string | null
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type AssignTagMutationVariables = Exact<{
	userId: Scalars["String"]
	enrolledTagId: Scalars["String"]
}>

export type AssignTagMutation = {
	__typename?: "Mutation"
	assignTag: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type AssignTagByDecimalIdMutationVariables = Exact<{
	userId: Scalars["String"]
	tagDecimalId: Scalars["String"]
}>

export type AssignTagByDecimalIdMutation = {
	__typename?: "Mutation"
	assignTagByDecimalId: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type AssignTagToAssetMutationVariables = Exact<{
	assetId: Scalars["String"]
	enrolledTagId: Scalars["String"]
}>

export type AssignTagToAssetMutation = {
	__typename?: "Mutation"
	assignTagToAsset: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type AssignTagToAssetByDecimalIdMutationVariables = Exact<{
	assetId: Scalars["String"]
	tagDecimalId: Scalars["String"]
}>

export type AssignTagToAssetByDecimalIdMutation = {
	__typename?: "Mutation"
	assignTagToAssetByDecimalId: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type AssignUserRoleMutationVariables = Exact<{
	userId: Scalars["String"]
	input: UserRoleInput
}>

export type AssignUserRoleMutation = {
	__typename?: "Mutation"
	assignUserRole: {
		__typename?: "UserRole"
		_id: string
		siteId: string
		userId: string
		type: UserRoleTypes
		userRoleGroupId: string
		designation?: string | null
		startAt: any
		endAt?: any | null
		status: UserRoleStatus
		assignedAt: any
		removedAt?: any | null
	}
}

export type ChangePasswordMutationVariables = Exact<{
	currentPassword: Scalars["String"]
	newPassword: Scalars["String"]
	shouldLogOutOnOtherDevices?: InputMaybe<Scalars["Boolean"]>
}>

export type ChangePasswordMutation = { __typename?: "Mutation"; changePassword: boolean }

export type CreateAlertFlowMutationVariables = Exact<{
	input: AlertFlowInput
}>

export type CreateAlertFlowMutation = {
	__typename?: "Mutation"
	createAlertFlow: {
		__typename?: "AlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		targetGroupIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "AlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			userTypeIds?: Array<string> | null
			userRoleTypes?: Array<UserRoleTypes> | null
			departmentIds?: Array<string> | null
			assetIds?: Array<string> | null
			assetCategoryIds?: Array<string> | null
		} | null
	}
}

export type CreateAlertTargetGroupMutationVariables = Exact<{
	input: AlertTargetGroupInput
}>

export type CreateAlertTargetGroupMutation = {
	__typename?: "Mutation"
	createAlertTargetGroup: { __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type CreateAssetMutationVariables = Exact<{
	input: AssetInput
}>

export type CreateAssetMutation = {
	__typename?: "Mutation"
	createAsset: {
		__typename?: "Asset"
		_id: string
		siteId: string
		type: AssetTypes
		name: string
		categoryId?: string | null
		tagId?: string | null
		vendorId?: string | null
		status: AssetStatus
		weightInKG?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		currentPlacement?: {
			__typename?: "AssetPlacementRecord"
			_id: string
			siteId: string
			assetId: string
			sourceZoneId?: string | null
			destinationZoneId: string
			placementTill: any
			lastUpdatedAt: any
			lastUpdatedByUserId: string
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type CreateAssetCategoryMutationVariables = Exact<{
	input: AssetCategoryInput
}>

export type CreateAssetCategoryMutation = {
	__typename?: "Mutation"
	createAssetCategory: {
		__typename?: "AssetCategory"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type CreateAssetInventoryAuditMutationVariables = Exact<{
	input: AssetInventoryAuditInput
}>

export type CreateAssetInventoryAuditMutation = {
	__typename?: "Mutation"
	createAssetInventoryAudit: { __typename?: "AssetInventoryAudit"; _id: string; siteId: string; assetId: string; assetWeightInKG: number; inventoryWeightInKG: number; assetCount: number; createdAt: any; updatedAt: any }
}

export type CreateAssetPlacementRecordMutationVariables = Exact<{
	input: AssetPlacementRecordInput
}>

export type CreateAssetPlacementRecordMutation = {
	__typename?: "Mutation"
	createAssetPlacementRecord: {
		__typename?: "AssetPlacementRecord"
		_id: string
		siteId: string
		assetId: string
		sourceZoneId?: string | null
		destinationZoneId: string
		placementTill: any
		lastUpdatedAt: any
		lastUpdatedByUserId: string
		createdAt: any
		updatedAt: any
	}
}

export type CreateBuildingMutationVariables = Exact<{
	input: BuildingInput
}>

export type CreateBuildingMutation = {
	__typename?: "Mutation"
	createBuilding: {
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type CreateBuildingFloorMutationVariables = Exact<{
	buildingId: Scalars["String"]
	input: FloorInput
}>

export type CreateBuildingFloorMutation = {
	__typename?: "Mutation"
	createBuildingFloor: {
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type CreateDepartmentMutationVariables = Exact<{
	input: DepartmentInput
}>

export type CreateDepartmentMutation = {
	__typename?: "Mutation"
	createDepartment: {
		__typename?: "Department"
		_id: string
		siteId: string
		departmentHeadId?: string | null
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type CreateDeviceMutationVariables = Exact<{
	input: DeviceInput
}>

export type CreateDeviceMutation = {
	__typename?: "Mutation"
	createDevice: {
		__typename?: "Device"
		_id: string
		siteId: string
		type: DeviceTypes
		deviceId: string
		isActive?: boolean | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type CreatePermissionsGroupMutationVariables = Exact<{
	input: PermissionsGroupInput
}>

export type CreatePermissionsGroupMutation = {
	__typename?: "Mutation"
	createPermissionsGroup: { __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type CreateReaderMutationVariables = Exact<{
	input: ReaderInput
}>

export type CreateReaderMutation = {
	__typename?: "Mutation"
	createReader: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type CreateServiceMutationVariables = Exact<{
	input: ServiceInput
}>

export type CreateServiceMutation = {
	__typename?: "Mutation"
	createService: { __typename?: "Service"; _id: string; key: string; lastHeartbeatAt?: any | null; isActive?: boolean | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type CreateTagMutationVariables = Exact<{
	tagDecimalId: Scalars["String"]
}>

export type CreateTagMutation = {
	__typename?: "Mutation"
	createTag: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type CreateTileMutationVariables = Exact<{
	label: LabelInput
}>

export type CreateTileMutation = {
	__typename?: "Mutation"
	createTile: {
		__typename?: "Tile"
		_id: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		levelWiseZoneEmbeddings: Array<{
			__typename?: "LevelWiseZoneEmbeddings"
			level: number
			zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
		}>
	}
}

export type CreateUserMutationVariables = Exact<{
	input: UserInput
}>

export type CreateUserMutation = {
	__typename?: "Mutation"
	createUser: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}
}

export type CreateUserGroupMutationVariables = Exact<{
	input: UserGroupInput
}>

export type CreateUserGroupMutation = {
	__typename?: "Mutation"
	createUserGroup: { __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type CreateUserRoleGroupMutationVariables = Exact<{
	input: UserRoleGroupInput
}>

export type CreateUserRoleGroupMutation = {
	__typename?: "Mutation"
	createUserRoleGroup: {
		__typename?: "UserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		departmentId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type CreateUserTypeMutationVariables = Exact<{
	input: UserTypeInput
}>

export type CreateUserTypeMutation = { __typename?: "Mutation"; createUserType: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } } }

export type CreateZoneMutationVariables = Exact<{
	input: ZoneInput
}>

export type CreateZoneMutation = {
	__typename?: "Mutation"
	createZone: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		buildingId?: string | null
		floorId?: string | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
	}
}

export type CreateZoneGroupMutationVariables = Exact<{
	input: ZoneGroupInput
}>

export type CreateZoneGroupMutation = {
	__typename?: "Mutation"
	createZoneGroup: { __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type DeactivateAssetMutationVariables = Exact<{
	assetId: Scalars["String"]
}>

export type DeactivateAssetMutation = {
	__typename?: "Mutation"
	deactivateAsset: {
		__typename?: "Asset"
		_id: string
		siteId: string
		type: AssetTypes
		name: string
		categoryId?: string | null
		tagId?: string | null
		vendorId?: string | null
		status: AssetStatus
		weightInKG?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		currentPlacement?: {
			__typename?: "AssetPlacementRecord"
			_id: string
			siteId: string
			assetId: string
			sourceZoneId?: string | null
			destinationZoneId: string
			placementTill: any
			lastUpdatedAt: any
			lastUpdatedByUserId: string
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type DeactivateTagOfAssetMutationVariables = Exact<{
	assetId: Scalars["String"]
}>

export type DeactivateTagOfAssetMutation = {
	__typename?: "Mutation"
	deactivateTagOfAsset: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type DeactivateTagOfUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type DeactivateTagOfUserMutation = {
	__typename?: "Mutation"
	deactivateTagOfUser: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type DeactivateUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type DeactivateUserMutation = {
	__typename?: "Mutation"
	deactivateUser: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}
}

export type DeleteBuildingMutationVariables = Exact<{
	buildingId: Scalars["String"]
}>

export type DeleteBuildingMutation = { __typename?: "Mutation"; deleteBuilding: boolean }

export type DeleteBuildingFloorMutationVariables = Exact<{
	buildingId: Scalars["String"]
	floorId: Scalars["String"]
}>

export type DeleteBuildingFloorMutation = {
	__typename?: "Mutation"
	deleteBuildingFloor: {
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type DeleteEmbeddedZoneOnTileMutationVariables = Exact<{
	tileId: Scalars["String"]
	zoneId: Scalars["String"]
	level: Scalars["Int"]
}>

export type DeleteEmbeddedZoneOnTileMutation = {
	__typename?: "Mutation"
	deleteEmbeddedZoneOnTile: {
		__typename?: "Tile"
		_id: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		levelWiseZoneEmbeddings: Array<{
			__typename?: "LevelWiseZoneEmbeddings"
			level: number
			zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
		}>
	}
}

export type DeleteServiceMutationVariables = Exact<{
	serviceId: Scalars["String"]
}>

export type DeleteServiceMutation = { __typename?: "Mutation"; deleteService: boolean }

export type EmbedZoneOnTileMutationVariables = Exact<{
	tileId: Scalars["String"]
	zoneId: Scalars["String"]
	level: Scalars["Int"]
	position: PositionInput
}>

export type EmbedZoneOnTileMutation = {
	__typename?: "Mutation"
	embedZoneOnTile: {
		__typename?: "Tile"
		_id: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		levelWiseZoneEmbeddings: Array<{
			__typename?: "LevelWiseZoneEmbeddings"
			level: number
			zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
		}>
	}
}

export type ExtendUserRoleMutationVariables = Exact<{
	userId: Scalars["String"]
	endAt: Scalars["DateTime"]
}>

export type ExtendUserRoleMutation = {
	__typename?: "Mutation"
	extendUserRole: {
		__typename?: "UserRole"
		_id: string
		siteId: string
		userId: string
		type: UserRoleTypes
		userRoleGroupId: string
		designation?: string | null
		startAt: any
		endAt?: any | null
		status: UserRoleStatus
		assignedAt: any
		removedAt?: any | null
	}
}

export type FinishAssetAvatarUploadMutationVariables = Exact<{
	assetId: Scalars["String"]
	input: FinishUploadInput
}>

export type FinishAssetAvatarUploadMutation = {
	__typename?: "Mutation"
	finishAssetAvatarUpload: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		} | null
	}
}

export type FinishAvatarUploadMutationVariables = Exact<{
	userId: Scalars["String"]
	input: FinishUploadInput
}>

export type FinishAvatarUploadMutation = {
	__typename?: "Mutation"
	finishAvatarUpload: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		} | null
	}
}

export type FinishTilePictureUploadMutationVariables = Exact<{
	tileId: Scalars["String"]
	input: FinishUploadInput
}>

export type FinishTilePictureUploadMutation = {
	__typename?: "Mutation"
	finishTilePictureUpload: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
		} | null
	}
}

export type FlushTagMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type FlushTagMutation = {
	__typename?: "Mutation"
	flushTag: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type FlushTagOfAssetMutationVariables = Exact<{
	assetId: Scalars["String"]
}>

export type FlushTagOfAssetMutation = {
	__typename?: "Mutation"
	flushTagOfAsset: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type GenerateActiveUsersAtBuildingsReportMutationVariables = Exact<{
	input: ActiveUsersAtBuildingsReportInput
}>

export type GenerateActiveUsersAtBuildingsReportMutation = {
	__typename?: "Mutation"
	generateActiveUsersAtBuildingsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateActiveUsersAtZoneGroupsReportMutationVariables = Exact<{
	input: ActiveUsersAtZoneGroupsReportInput
}>

export type GenerateActiveUsersAtZoneGroupsReportMutation = {
	__typename?: "Mutation"
	generateActiveUsersAtZoneGroupsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateActiveUsersAtZonesReportMutationVariables = Exact<{
	input: ActiveUsersAtZonesReportInput
}>

export type GenerateActiveUsersAtZonesReportMutation = {
	__typename?: "Mutation"
	generateActiveUsersAtZonesReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateAssetLocationRecordsReportMutationVariables = Exact<{
	input: AssetLocationRecordsReportInput
}>

export type GenerateAssetLocationRecordsReportMutation = {
	__typename?: "Mutation"
	generateAssetLocationRecordsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateBuildingLocationRecordsReportMutationVariables = Exact<{
	input: BuildingLocationRecordsReportInput
}>

export type GenerateBuildingLocationRecordsReportMutation = {
	__typename?: "Mutation"
	generateBuildingLocationRecordsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateDashboardReportMutationVariables = Exact<{
	input: ReportInput
}>

export type GenerateDashboardReportMutation = {
	__typename?: "Mutation"
	generateDashboardReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateReadersStatusLogsReportMutationVariables = Exact<{
	input: ReadersStatusLogsReportInput
}>

export type GenerateReadersStatusLogsReportMutation = {
	__typename?: "Mutation"
	generateReadersStatusLogsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateUserAttendanceReportMutationVariables = Exact<{
	input: UserAttendanceReportInput
}>

export type GenerateUserAttendanceReportMutation = {
	__typename?: "Mutation"
	generateUserAttendanceReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateUserLocationRecordsReportMutationVariables = Exact<{
	input: UserLocationRecordsReportInput
}>

export type GenerateUserLocationRecordsReportMutation = {
	__typename?: "Mutation"
	generateUserLocationRecordsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateUserRestrictedEntriesReportMutationVariables = Exact<{
	input: UserRestrictedEntriesReportInput
}>

export type GenerateUserRestrictedEntriesReportMutation = {
	__typename?: "Mutation"
	generateUserRestrictedEntriesReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateVisitorReportMutationVariables = Exact<{
	input: VisitorReportInput
}>

export type GenerateVisitorReportMutation = {
	__typename?: "Mutation"
	generateVisitorReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateZoneGroupLocationRecordsReportMutationVariables = Exact<{
	input: ZoneGroupLocationRecordsReportInput
}>

export type GenerateZoneGroupLocationRecordsReportMutation = {
	__typename?: "Mutation"
	generateZoneGroupLocationRecordsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateZoneGroupRestrictedEntriesReportMutationVariables = Exact<{
	input: ZoneGroupRestrictedEntriesReportInput
}>

export type GenerateZoneGroupRestrictedEntriesReportMutation = {
	__typename?: "Mutation"
	generateZoneGroupRestrictedEntriesReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateZoneLocationRecordsReportMutationVariables = Exact<{
	input: ZoneLocationRecordsReportInput
}>

export type GenerateZoneLocationRecordsReportMutation = {
	__typename?: "Mutation"
	generateZoneLocationRecordsReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type GenerateZoneRestrictedEntriesReportMutationVariables = Exact<{
	input: ZoneRestrictedEntriesReportInput
}>

export type GenerateZoneRestrictedEntriesReportMutation = {
	__typename?: "Mutation"
	generateZoneRestrictedEntriesReport: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type LoginMutationVariables = Exact<{
	username: Scalars["String"]
	password: Scalars["String"]
}>

export type LoginMutation = {
	__typename?: "Mutation"
	login: {
		__typename?: "UserAuthResponse"
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
		user?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		} | null
	}
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: "Mutation"; logout: boolean }

export type RemoveCurrentUserRoleMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type RemoveCurrentUserRoleMutation = { __typename?: "Mutation"; removeCurrentUserRole: boolean }

export type RemoveDepartmentHeadMutationVariables = Exact<{
	departmentId: Scalars["String"]
}>

export type RemoveDepartmentHeadMutation = { __typename?: "Mutation"; removeDepartmentHead: boolean }

export type RemoveReaderFromZoneMutationVariables = Exact<{
	zoneId: Scalars["String"]
	readerId: Scalars["String"]
}>

export type RemoveReaderFromZoneMutation = { __typename?: "Mutation"; removeReaderFromZone: boolean }

export type SendEmailVerificationSecretCodeMutationVariables = Exact<{ [key: string]: never }>

export type SendEmailVerificationSecretCodeMutation = { __typename?: "Mutation"; sendEmailVerificationSecretCode: boolean }

export type SignAssetAvatarUploadMutationVariables = Exact<{
	assetId: Scalars["String"]
	format: Scalars["String"]
}>

export type SignAssetAvatarUploadMutation = { __typename?: "Mutation"; signAssetAvatarUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string } }

export type SignAvatarUploadMutationVariables = Exact<{
	userId: Scalars["String"]
	format: Scalars["String"]
}>

export type SignAvatarUploadMutation = { __typename?: "Mutation"; signAvatarUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string } }

export type SignTilePictureUploadMutationVariables = Exact<{
	tileId: Scalars["String"]
	format: Scalars["String"]
}>

export type SignTilePictureUploadMutation = { __typename?: "Mutation"; signTilePictureUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string } }

export type UpdateAlertFlowMutationVariables = Exact<{
	alertFlowId: Scalars["String"]
	input: AlertFlowInput
}>

export type UpdateAlertFlowMutation = {
	__typename?: "Mutation"
	updateAlertFlow: {
		__typename?: "AlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		targetGroupIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "AlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			userTypeIds?: Array<string> | null
			userRoleTypes?: Array<UserRoleTypes> | null
			departmentIds?: Array<string> | null
			assetIds?: Array<string> | null
			assetCategoryIds?: Array<string> | null
		} | null
	}
}

export type UpdateAlertTargetGroupMutationVariables = Exact<{
	alertTargetGroupId: Scalars["String"]
	input: AlertTargetGroupInput
}>

export type UpdateAlertTargetGroupMutation = {
	__typename?: "Mutation"
	updateAlertTargetGroup: { __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type UpdateAssetCategoryMutationVariables = Exact<{
	assetCategoryId: Scalars["String"]
	input: AssetCategoryInput
}>

export type UpdateAssetCategoryMutation = {
	__typename?: "Mutation"
	updateAssetCategory: {
		__typename?: "AssetCategory"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type UpdateBuildingMutationVariables = Exact<{
	buildingId: Scalars["String"]
	input: BuildingInput
}>

export type UpdateBuildingMutation = {
	__typename?: "Mutation"
	updateBuilding: {
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type UpdateBuildingFloorMutationVariables = Exact<{
	buildingId: Scalars["String"]
	floorId: Scalars["String"]
	input: FloorInput
}>

export type UpdateBuildingFloorMutation = {
	__typename?: "Mutation"
	updateBuildingFloor: {
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type UpdateDeviceMutationVariables = Exact<{
	deviceId: Scalars["String"]
	input: DeviceInput
}>

export type UpdateDeviceMutation = {
	__typename?: "Mutation"
	updateDevice: {
		__typename?: "Device"
		_id: string
		siteId: string
		type: DeviceTypes
		deviceId: string
		isActive?: boolean | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type UpdateReaderMutationVariables = Exact<{
	readerId: Scalars["String"]
	input: ReaderInput
}>

export type UpdateReaderMutation = {
	__typename?: "Mutation"
	updateReader: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type UpdateServiceMutationVariables = Exact<{
	serviceId: Scalars["String"]
	input: ServiceInput
}>

export type UpdateServiceMutation = {
	__typename?: "Mutation"
	updateService: { __typename?: "Service"; _id: string; key: string; lastHeartbeatAt?: any | null; isActive?: boolean | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type UpdateUserMutationVariables = Exact<{
	userId: Scalars["String"]
	input: UpdateUserInput
}>

export type UpdateUserMutation = {
	__typename?: "Mutation"
	updateUser: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}
}

export type UpdateUserGroupMutationVariables = Exact<{
	userGroupId: Scalars["String"]
	input: UserGroupInput
}>

export type UpdateUserGroupMutation = {
	__typename?: "Mutation"
	updateUserGroup: { __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type UpdateUserPermissionsGroupsMutationVariables = Exact<{
	userId: Scalars["String"]
	permissionsGroupIds: Array<Scalars["String"]> | Scalars["String"]
}>

export type UpdateUserPermissionsGroupsMutation = {
	__typename?: "Mutation"
	updateUserPermissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type UpdateUserRoleGroupMutationVariables = Exact<{
	userRoleGroupId: Scalars["String"]
	input: UpdateUserRoleGroupInput
}>

export type UpdateUserRoleGroupMutation = {
	__typename?: "Mutation"
	updateUserRoleGroup: {
		__typename?: "UserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		departmentId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type UpdateVisitorDetailsMutationVariables = Exact<{
	userId: Scalars["String"]
	input: VisitorDetailsInput
}>

export type UpdateVisitorDetailsMutation = {
	__typename?: "Mutation"
	updateVisitorDetails: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}
}

export type UpdateZoneMutationVariables = Exact<{
	zoneId: Scalars["String"]
	input: ZoneInput
}>

export type UpdateZoneMutation = {
	__typename?: "Mutation"
	updateZone: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		buildingId?: string | null
		floorId?: string | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
	}
}

export type UpdateZoneGroupMutationVariables = Exact<{
	zoneGroupId: Scalars["String"]
	input: ZoneGroupInput
}>

export type UpdateZoneGroupMutation = {
	__typename?: "Mutation"
	updateZoneGroup: { __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type VerifyEmailWithSecretCodeMutationVariables = Exact<{
	code: Scalars["String"]
}>

export type VerifyEmailWithSecretCodeMutation = { __typename?: "Mutation"; verifyEmailWithSecretCode: boolean }

export type ActiveAssetIdsQueryVariables = Exact<{ [key: string]: never }>

export type ActiveAssetIdsQuery = {
	__typename?: "Query"
	activeAssetIds: { __typename?: "ActiveAssetsResponse"; assetIds: Array<string>; zoneWiseAssets: Array<{ __typename?: "ZoneWiseAssetsResponse"; zoneId: string; assetIds: Array<string> }> }
}

export type ActiveAssetIdsInLastIntervalQueryVariables = Exact<{
	intervalInMinutes: Scalars["Float"]
}>

export type ActiveAssetIdsInLastIntervalQuery = { __typename?: "Query"; activeAssetIdsInLastInterval: Array<string> }

export type ActiveAssetsQueryVariables = Exact<{
	keyword: Scalars["String"]
	status?: InputMaybe<AssetStatus>
	pagination: PaginatedInput
}>

export type ActiveAssetsQuery = {
	__typename?: "Query"
	activeAssets: {
		__typename?: "PaginatedAssetsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assets: Array<{
			__typename?: "Asset"
			_id: string
			siteId: string
			type: AssetTypes
			name: string
			categoryId?: string | null
			tagId?: string | null
			vendorId?: string | null
			status: AssetStatus
			weightInKG?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			currentPlacement?: {
				__typename?: "AssetPlacementRecord"
				_id: string
				siteId: string
				assetId: string
				sourceZoneId?: string | null
				destinationZoneId: string
				placementTill: any
				lastUpdatedAt: any
				lastUpdatedByUserId: string
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type ActiveAssetsInLastIntervalQueryVariables = Exact<{
	keyword: Scalars["String"]
	intervalInMinutes: Scalars["Float"]
	status?: InputMaybe<AssetStatus>
	pagination: PaginatedInput
}>

export type ActiveAssetsInLastIntervalQuery = {
	__typename?: "Query"
	activeAssetsInLastInterval: {
		__typename?: "PaginatedAssetsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assets: Array<{
			__typename?: "Asset"
			_id: string
			siteId: string
			type: AssetTypes
			name: string
			categoryId?: string | null
			tagId?: string | null
			vendorId?: string | null
			status: AssetStatus
			weightInKG?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			currentPlacement?: {
				__typename?: "AssetPlacementRecord"
				_id: string
				siteId: string
				assetId: string
				sourceZoneId?: string | null
				destinationZoneId: string
				placementTill: any
				lastUpdatedAt: any
				lastUpdatedByUserId: string
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type ActiveAttendanceOfUserQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type ActiveAttendanceOfUserQuery = {
	__typename?: "Query"
	activeAttendanceOfUser?: { __typename?: "UserAttendanceRecord"; _id: string; siteId: string; userId: string; startAt: any; endAt: any; status: UserAttendanceRecordStatus; createdAt: any; updatedAt: any } | null
}

export type ActiveRestrictedEntryIdsQueryVariables = Exact<{ [key: string]: never }>

export type ActiveRestrictedEntryIdsQuery = {
	__typename?: "Query"
	activeRestrictedEntryIds: {
		__typename?: "ActiveRestrictedUserLocationRecordsResponse"
		recordIds: Array<string>
		zoneWiseRecordIds: Array<{ __typename?: "ZoneWiseActiveRestrictedUserLocationRecordsResponse"; zoneId: string; recordIds: Array<string> }>
	}
}

export type ActiveRestrictedUserLocationRecordsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type ActiveRestrictedUserLocationRecordsQuery = {
	__typename?: "Query"
	activeRestrictedUserLocationRecords: {
		__typename?: "PaginatedUserLocationRecordsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		userLocationRecords: Array<{
			__typename?: "UserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type ActiveUserIdsQueryVariables = Exact<{
	isRestricted?: InputMaybe<Scalars["Boolean"]>
}>

export type ActiveUserIdsQuery = { __typename?: "Query"; activeUserIds: { __typename?: "ActiveUsersResponse"; userIds: Array<string>; zoneWiseUsers: Array<{ __typename?: "ZoneWiseUsersResponse"; zoneId: string; userIds: Array<string> }> } }

export type ActiveUserIdsInLastIntervalQueryVariables = Exact<{
	intervalInMinutes: Scalars["Float"]
}>

export type ActiveUserIdsInLastIntervalQuery = { __typename?: "Query"; activeUserIdsInLastInterval: Array<string> }

export type ActiveUsersQueryVariables = Exact<{
	keyword: Scalars["String"]
	typeId?: InputMaybe<Scalars["String"]>
	roleType?: InputMaybe<UserRoleTypes>
	status?: InputMaybe<UserStatus>
	pagination: PaginatedInput
}>

export type ActiveUsersQuery = {
	__typename?: "Query"
	activeUsers: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type ActiveUsersInLastIntervalQueryVariables = Exact<{
	keyword: Scalars["String"]
	intervalInMinutes: Scalars["Float"]
	typeId?: InputMaybe<Scalars["String"]>
	roleType?: InputMaybe<UserRoleTypes>
	status?: InputMaybe<UserStatus>
	pagination: PaginatedInput
}>

export type ActiveUsersInLastIntervalQuery = {
	__typename?: "Query"
	activeUsersInLastInterval: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type AlertByIdQueryVariables = Exact<{
	alertId: Scalars["String"]
}>

export type AlertByIdQuery = {
	__typename?: "Query"
	alertById?: {
		__typename?: "Alert"
		_id: string
		siteId: string
		type: AlertTypes
		alertFlowIds: Array<string>
		emailMessage: string
		notificationMessage: string
		alertedUserIds: Array<string>
		emailedUserIds?: Array<string> | null
		notifiedUserIds?: Array<string> | null
		userId?: string | null
		userRoleId?: string | null
		zoneId?: string | null
		readerId?: string | null
		tagId?: string | null
		userLocationRecordId?: string | null
		serviceId?: string | null
		assetId?: string | null
		createdAt: any
		updatedAt: any
	} | null
}

export type AlertFlowByIdQueryVariables = Exact<{
	alertFlowId: Scalars["String"]
}>

export type AlertFlowByIdQuery = {
	__typename?: "Query"
	alertFlowById?: {
		__typename?: "AlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		targetGroupIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "AlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			userTypeIds?: Array<string> | null
			userRoleTypes?: Array<UserRoleTypes> | null
			departmentIds?: Array<string> | null
			assetIds?: Array<string> | null
			assetCategoryIds?: Array<string> | null
		} | null
	} | null
}

export type AlertFlowsQueryVariables = Exact<{ [key: string]: never }>

export type AlertFlowsQuery = {
	__typename?: "Query"
	alertFlows: Array<{
		__typename?: "AlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		targetGroupIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "AlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			userTypeIds?: Array<string> | null
			userRoleTypes?: Array<UserRoleTypes> | null
			departmentIds?: Array<string> | null
			assetIds?: Array<string> | null
			assetCategoryIds?: Array<string> | null
		} | null
	}>
}

export type AlertFlowsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type AlertFlowsByKeywordQuery = {
	__typename?: "Query"
	alertFlowsByKeyword: {
		__typename?: "PaginatedAlertFlowsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertFlows: Array<{
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			targetGroupIds: Array<string>
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: {
				__typename?: "AlertSource"
				type: AlertSourceTypes
				zoneIds?: Array<string> | null
				zoneGroupIds?: Array<string> | null
				userIds?: Array<string> | null
				userGroupIds?: Array<string> | null
				userTypeIds?: Array<string> | null
				userRoleTypes?: Array<UserRoleTypes> | null
				departmentIds?: Array<string> | null
				assetIds?: Array<string> | null
				assetCategoryIds?: Array<string> | null
			} | null
		}>
	}
}

export type AlertFlowsOfAlertQueryVariables = Exact<{
	alertId: Scalars["String"]
}>

export type AlertFlowsOfAlertQuery = {
	__typename?: "Query"
	alertFlowsOfAlert: Array<{
		__typename?: "AlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		targetGroupIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "AlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			userTypeIds?: Array<string> | null
			userRoleTypes?: Array<UserRoleTypes> | null
			departmentIds?: Array<string> | null
			assetIds?: Array<string> | null
			assetCategoryIds?: Array<string> | null
		} | null
	}>
}

export type AlertSourceOfAlertFlowQueryVariables = Exact<{
	alertFlowId: Scalars["String"]
}>

export type AlertSourceOfAlertFlowQuery = {
	__typename?: "Query"
	alertSourceOfAlertFlow?: {
		__typename?: "AlertSource"
		type: AlertSourceTypes
		zoneIds?: Array<string> | null
		zoneGroupIds?: Array<string> | null
		userIds?: Array<string> | null
		userGroupIds?: Array<string> | null
		userTypeIds?: Array<string> | null
		userRoleTypes?: Array<UserRoleTypes> | null
		departmentIds?: Array<string> | null
		assetIds?: Array<string> | null
		assetCategoryIds?: Array<string> | null
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			buildingId?: string | null
			floorId?: string | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
		}>
		zoneGroups: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
		userGroups: Array<{ __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }>
		userTypes: Array<{ __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }>
		departments: Array<{
			__typename?: "Department"
			_id: string
			siteId: string
			departmentHeadId?: string | null
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
		assets: Array<{
			__typename?: "Asset"
			_id: string
			siteId: string
			type: AssetTypes
			name: string
			categoryId?: string | null
			tagId?: string | null
			vendorId?: string | null
			status: AssetStatus
			weightInKG?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			currentPlacement?: {
				__typename?: "AssetPlacementRecord"
				_id: string
				siteId: string
				assetId: string
				sourceZoneId?: string | null
				destinationZoneId: string
				placementTill: any
				lastUpdatedAt: any
				lastUpdatedByUserId: string
				createdAt: any
				updatedAt: any
			} | null
		}>
		assetCategories: Array<{
			__typename?: "AssetCategory"
			_id: string
			siteId: string
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	} | null
}

export type AlertTargetGroupByIdQueryVariables = Exact<{
	alertTargetGroupId: Scalars["String"]
}>

export type AlertTargetGroupByIdQuery = {
	__typename?: "Query"
	alertTargetGroupById: { __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type AlertTargetGroupsQueryVariables = Exact<{ [key: string]: never }>

export type AlertTargetGroupsQuery = {
	__typename?: "Query"
	alertTargetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type AlertTargetGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type AlertTargetGroupsByKeywordQuery = {
	__typename?: "Query"
	alertTargetGroupsByKeyword: {
		__typename?: "PaginatedAlertTargetGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertTargetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AlertTargetGroupsOfAlertFlowQueryVariables = Exact<{
	alertFlowId: Scalars["String"]
}>

export type AlertTargetGroupsOfAlertFlowQuery = {
	__typename?: "Query"
	alertTargetGroupsOfAlertFlow: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type AlertedUsersOfAlertQueryVariables = Exact<{
	alertId: Scalars["String"]
}>

export type AlertedUsersOfAlertQuery = {
	__typename?: "Query"
	alertedUsersOfAlert: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}>
}

export type AlertsByFilterQueryVariables = Exact<{
	filter: AlertsFilterInput
	pagination: PaginatedInput
}>

export type AlertsByFilterQuery = {
	__typename?: "Query"
	alertsByFilter: {
		__typename?: "PaginatedAlertsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alerts: Array<{
			__typename?: "Alert"
			_id: string
			siteId: string
			type: AlertTypes
			alertFlowIds: Array<string>
			emailMessage: string
			notificationMessage: string
			alertedUserIds: Array<string>
			emailedUserIds?: Array<string> | null
			notifiedUserIds?: Array<string> | null
			userId?: string | null
			userRoleId?: string | null
			zoneId?: string | null
			readerId?: string | null
			tagId?: string | null
			userLocationRecordId?: string | null
			serviceId?: string | null
			assetId?: string | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type AllAlertFlowsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAlertFlowsQuery = {
	__typename?: "Query"
	allAlertFlows: {
		__typename?: "PaginatedAlertFlowsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertFlows: Array<{
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			targetGroupIds: Array<string>
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: {
				__typename?: "AlertSource"
				type: AlertSourceTypes
				zoneIds?: Array<string> | null
				zoneGroupIds?: Array<string> | null
				userIds?: Array<string> | null
				userGroupIds?: Array<string> | null
				userTypeIds?: Array<string> | null
				userRoleTypes?: Array<UserRoleTypes> | null
				departmentIds?: Array<string> | null
				assetIds?: Array<string> | null
				assetCategoryIds?: Array<string> | null
			} | null
		}>
	}
}

export type AllAlertTargetGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAlertTargetGroupsQuery = {
	__typename?: "Query"
	allAlertTargetGroups: {
		__typename?: "PaginatedAlertTargetGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertTargetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllAlertsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAlertsQuery = {
	__typename?: "Query"
	allAlerts: {
		__typename?: "PaginatedAlertsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alerts: Array<{
			__typename?: "Alert"
			_id: string
			siteId: string
			type: AlertTypes
			alertFlowIds: Array<string>
			emailMessage: string
			notificationMessage: string
			alertedUserIds: Array<string>
			emailedUserIds?: Array<string> | null
			notifiedUserIds?: Array<string> | null
			userId?: string | null
			userRoleId?: string | null
			zoneId?: string | null
			readerId?: string | null
			tagId?: string | null
			userLocationRecordId?: string | null
			serviceId?: string | null
			assetId?: string | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type AllAssetCategoriesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAssetCategoriesQuery = {
	__typename?: "Query"
	allAssetCategories: {
		__typename?: "PaginatedAssetCategoriesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assetCategories: Array<{
			__typename?: "AssetCategory"
			_id: string
			siteId: string
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type AllBuildingsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllBuildingsQuery = {
	__typename?: "Query"
	allBuildings: {
		__typename?: "PaginatedBuildingsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		buildings: Array<{
			__typename?: "Building"
			_id: string
			siteId: string
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
		}>
	}
}

export type AllDepartmentsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllDepartmentsQuery = {
	__typename?: "Query"
	allDepartments: {
		__typename?: "PaginatedDepartmentsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		departments: Array<{
			__typename?: "Department"
			_id: string
			siteId: string
			departmentHeadId?: string | null
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type AllDevicesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllDevicesQuery = {
	__typename?: "Query"
	allDevices: {
		__typename?: "PaginatedDevicesResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		devices: Array<{
			__typename?: "Device"
			_id: string
			siteId: string
			type: DeviceTypes
			deviceId: string
			isActive?: boolean | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type AllPermissionsGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllPermissionsGroupsQuery = {
	__typename?: "Query"
	allPermissionsGroups: {
		__typename?: "PaginatedPermissionsGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		permissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllReportsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllReportsQuery = {
	__typename?: "Query"
	allReports: {
		__typename?: "PaginatedReportsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		reports: Array<{
			__typename?: "Report"
			_id: string
			siteId: string
			type: ReportTypes
			userIds?: Array<string> | null
			sinceDate: any
			untilDate: any
			status: ReportStatus
			lastUpdatedByUserId?: string | null
			lastUpdatedAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
			json?: { __typename?: "ReportObject"; key: string; url: string } | null
			csv?: { __typename?: "ReportObject"; key: string; url: string } | null
			xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
		}>
	}
}

export type AllServicesQueryVariables = Exact<{ [key: string]: never }>

export type AllServicesQuery = {
	__typename?: "Query"
	allServices: Array<{
		__typename?: "Service"
		_id: string
		key: string
		lastHeartbeatAt?: any | null
		isActive?: boolean | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type AllTagsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllTagsQuery = {
	__typename?: "Query"
	allTags: {
		__typename?: "PaginatedTagsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		tags: Array<{
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedToAssetId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type AllUserGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUserGroupsQuery = {
	__typename?: "Query"
	allUserGroups: {
		__typename?: "PaginatedUserGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userGroups: Array<{ __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllUserRoleGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUserRoleGroupsQuery = {
	__typename?: "Query"
	allUserRoleGroups: {
		__typename?: "PaginatedUserRoleGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userRoleGroups: Array<{
			__typename?: "UserRoleGroup"
			_id: string
			siteId: string
			type: UserRoleTypes
			allowedZoneIds?: Array<string> | null
			allowedZoneGroupIds?: Array<string> | null
			departmentId: string
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type AllUserTypesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUserTypesQuery = {
	__typename?: "Query"
	allUserTypes: {
		__typename?: "PaginatedUserTypesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userTypes: Array<{ __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllUsersQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUsersQuery = {
	__typename?: "Query"
	allUsers: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type AllZoneGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllZoneGroupsQuery = {
	__typename?: "Query"
	allZoneGroups: {
		__typename?: "PaginatedZoneGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zoneGroups: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllZonesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllZonesQuery = {
	__typename?: "Query"
	allZones: {
		__typename?: "PaginatedZonesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			buildingId?: string | null
			floorId?: string | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
		}>
	}
}

export type AssetByIdQueryVariables = Exact<{
	assetId: Scalars["String"]
}>

export type AssetByIdQuery = {
	__typename?: "Query"
	assetById?: {
		__typename?: "Asset"
		_id: string
		siteId: string
		type: AssetTypes
		name: string
		categoryId?: string | null
		tagId?: string | null
		vendorId?: string | null
		status: AssetStatus
		weightInKG?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		currentPlacement?: {
			__typename?: "AssetPlacementRecord"
			_id: string
			siteId: string
			assetId: string
			sourceZoneId?: string | null
			destinationZoneId: string
			placementTill: any
			lastUpdatedAt: any
			lastUpdatedByUserId: string
			createdAt: any
			updatedAt: any
		} | null
	} | null
}

export type AssetCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type AssetCategoriesQuery = {
	__typename?: "Query"
	assetCategories: Array<{
		__typename?: "AssetCategory"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type AssetCategoriesByFilterQueryVariables = Exact<{
	filter: AssetCategoriesFilterInput
	pagination: PaginatedInput
}>

export type AssetCategoriesByFilterQuery = {
	__typename?: "Query"
	assetCategoriesByFilter: {
		__typename?: "PaginatedAssetCategoriesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assetCategories: Array<{
			__typename?: "AssetCategory"
			_id: string
			siteId: string
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type AssetCategoriesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type AssetCategoriesByKeywordQuery = {
	__typename?: "Query"
	assetCategoriesByKeyword: {
		__typename?: "PaginatedAssetCategoriesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assetCategories: Array<{
			__typename?: "AssetCategory"
			_id: string
			siteId: string
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type AssetCategoryByIdQueryVariables = Exact<{
	assetCategoryId: Scalars["String"]
}>

export type AssetCategoryByIdQuery = {
	__typename?: "Query"
	assetCategoryById: {
		__typename?: "AssetCategory"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type AssetCurrentLocationRecordQueryVariables = Exact<{
	assetId: Scalars["String"]
}>

export type AssetCurrentLocationRecordQuery = {
	__typename?: "Query"
	assetCurrentLocationRecord?: {
		__typename?: "AssetLocationRecord"
		_id: string
		siteId: string
		assetId: string
		zoneId: string
		startAt: any
		endAt: any
		status: AssetLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	} | null
}

export type AssetLocationRecordsAtZoneQueryVariables = Exact<{
	zoneId: Scalars["String"]
	isRestricted?: InputMaybe<Scalars["Boolean"]>
}>

export type AssetLocationRecordsAtZoneQuery = {
	__typename?: "Query"
	assetLocationRecordsAtZone: Array<{
		__typename?: "AssetLocationRecord"
		_id: string
		siteId: string
		assetId: string
		zoneId: string
		startAt: any
		endAt: any
		status: AssetLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type AssetLocationRecordsInBuildingQueryVariables = Exact<{
	buildingId: Scalars["String"]
}>

export type AssetLocationRecordsInBuildingQuery = {
	__typename?: "Query"
	assetLocationRecordsInBuilding: Array<{
		__typename?: "AssetLocationRecord"
		_id: string
		siteId: string
		assetId: string
		zoneId: string
		startAt: any
		endAt: any
		status: AssetLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type AssetLocationRecordsTrailQueryVariables = Exact<{
	assetId: Scalars["String"]
}>

export type AssetLocationRecordsTrailQuery = {
	__typename?: "Query"
	assetLocationRecordsTrail: Array<{
		__typename?: "AssetLocationRecord"
		_id: string
		siteId: string
		assetId: string
		zoneId: string
		startAt: any
		endAt: any
		status: AssetLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type AssetPastLocationRecordsQueryVariables = Exact<{
	assetId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}>

export type AssetPastLocationRecordsQuery = {
	__typename?: "Query"
	assetPastLocationRecords: {
		__typename?: "PaginatedAssetLocationRecordsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assetLocationRecords: Array<{
			__typename?: "AssetLocationRecord"
			_id: string
			siteId: string
			assetId: string
			zoneId: string
			startAt: any
			endAt: any
			status: AssetLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type AssetsQueryVariables = Exact<{
	filter: AssetsFilterInput
	pagination: PaginatedInput
}>

export type AssetsQuery = {
	__typename?: "Query"
	assets: {
		__typename?: "PaginatedAssetsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assets: Array<{
			__typename?: "Asset"
			_id: string
			siteId: string
			type: AssetTypes
			name: string
			categoryId?: string | null
			tagId?: string | null
			vendorId?: string | null
			status: AssetStatus
			weightInKG?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			currentPlacement?: {
				__typename?: "AssetPlacementRecord"
				_id: string
				siteId: string
				assetId: string
				sourceZoneId?: string | null
				destinationZoneId: string
				placementTill: any
				lastUpdatedAt: any
				lastUpdatedByUserId: string
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type AssetsByFilterQueryVariables = Exact<{
	keyword: Scalars["String"]
	status?: InputMaybe<AssetStatus>
	type?: InputMaybe<AssetTypes>
	vendorId?: InputMaybe<Scalars["String"]>
	categoryId?: InputMaybe<Scalars["String"]>
	pagination: PaginatedInput
}>

export type AssetsByFilterQuery = {
	__typename?: "Query"
	assetsByFilter: {
		__typename?: "PaginatedAssetsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assets: Array<{
			__typename?: "Asset"
			_id: string
			siteId: string
			type: AssetTypes
			name: string
			categoryId?: string | null
			tagId?: string | null
			vendorId?: string | null
			status: AssetStatus
			weightInKG?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			currentPlacement?: {
				__typename?: "AssetPlacementRecord"
				_id: string
				siteId: string
				assetId: string
				sourceZoneId?: string | null
				destinationZoneId: string
				placementTill: any
				lastUpdatedAt: any
				lastUpdatedByUserId: string
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type BuildingByIdQueryVariables = Exact<{
	buildingId: Scalars["String"]
}>

export type BuildingByIdQuery = {
	__typename?: "Query"
	buildingById?: {
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	} | null
}

export type BuildingsQueryVariables = Exact<{ [key: string]: never }>

export type BuildingsQuery = {
	__typename?: "Query"
	buildings: Array<{
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}>
}

export type BuildingsByFilterQueryVariables = Exact<{
	filter: BuildingsFilterInput
	pagination: PaginatedInput
}>

export type BuildingsByFilterQuery = {
	__typename?: "Query"
	buildingsByFilter: {
		__typename?: "PaginatedBuildingsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		buildings: Array<{
			__typename?: "Building"
			_id: string
			siteId: string
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
		}>
	}
}

export type BuildingsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type BuildingsByKeywordQuery = {
	__typename?: "Query"
	buildingsByKeyword: {
		__typename?: "PaginatedBuildingsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		buildings: Array<{
			__typename?: "Building"
			_id: string
			siteId: string
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
		}>
	}
}

export type BuildingsByKeywordWithoutPaginationQueryVariables = Exact<{
	keyword: Scalars["String"]
}>

export type BuildingsByKeywordWithoutPaginationQuery = {
	__typename?: "Query"
	buildingsByKeywordWithoutPagination: Array<{
		__typename?: "Building"
		_id: string
		siteId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		floors: Array<{ __typename?: "Floor"; _id: string; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}>
}

export type DepartmentByIdQueryVariables = Exact<{
	departmentId: Scalars["String"]
}>

export type DepartmentByIdQuery = {
	__typename?: "Query"
	departmentById: {
		__typename?: "Department"
		_id: string
		siteId: string
		departmentHeadId?: string | null
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type DepartmentsQueryVariables = Exact<{ [key: string]: never }>

export type DepartmentsQuery = {
	__typename?: "Query"
	departments: Array<{
		__typename?: "Department"
		_id: string
		siteId: string
		departmentHeadId?: string | null
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type DepartmentsByFilterQueryVariables = Exact<{
	filter: DepartmentsFilterInput
	pagination: PaginatedInput
}>

export type DepartmentsByFilterQuery = {
	__typename?: "Query"
	departmentsByFilter: {
		__typename?: "PaginatedDepartmentsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		departments: Array<{
			__typename?: "Department"
			_id: string
			siteId: string
			departmentHeadId?: string | null
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type DepartmentsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type DepartmentsByKeywordQuery = {
	__typename?: "Query"
	departmentsByKeyword: {
		__typename?: "PaginatedDepartmentsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		departments: Array<{
			__typename?: "Department"
			_id: string
			siteId: string
			departmentHeadId?: string | null
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type DeviceByIdQueryVariables = Exact<{
	deviceId: Scalars["String"]
}>

export type DeviceByIdQuery = {
	__typename?: "Query"
	deviceById?: {
		__typename?: "Device"
		_id: string
		siteId: string
		type: DeviceTypes
		deviceId: string
		isActive?: boolean | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	} | null
}

export type DevicesQueryVariables = Exact<{ [key: string]: never }>

export type DevicesQuery = {
	__typename?: "Query"
	devices: Array<{
		__typename?: "Device"
		_id: string
		siteId: string
		type: DeviceTypes
		deviceId: string
		isActive?: boolean | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type DevicesByFilterQueryVariables = Exact<{
	filter: DevicesFilterInput
	pagination: PaginatedInput
}>

export type DevicesByFilterQuery = {
	__typename?: "Query"
	devicesByFilter: {
		__typename?: "PaginatedDevicesResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		devices: Array<{
			__typename?: "Device"
			_id: string
			siteId: string
			type: DeviceTypes
			deviceId: string
			isActive?: boolean | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type DevicesByTypeQueryVariables = Exact<{
	type: DeviceTypes
}>

export type DevicesByTypeQuery = {
	__typename?: "Query"
	devicesByType: Array<{
		__typename?: "Device"
		_id: string
		siteId: string
		type: DeviceTypes
		deviceId: string
		isActive?: boolean | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type EmailedUsersOfAlertQueryVariables = Exact<{
	alertId: Scalars["String"]
}>

export type EmailedUsersOfAlertQuery = {
	__typename?: "Query"
	emailedUsersOfAlert: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}>
}

export type InventoryAuditStatsOfAssetQueryVariables = Exact<{
	assetId: Scalars["String"]
}>

export type InventoryAuditStatsOfAssetQuery = {
	__typename?: "Query"
	inventoryAuditStatsOfAsset: {
		__typename?: "AssetInventoryAuditStats"
		_id: string
		siteId: string
		assetId: string
		createdAt: any
		updatedAt: any
		stats: Array<{ __typename?: "AssetInventoryAuditStat"; auditId: string; count: number; auditedAt: any }>
	}
}

export type InventoryAuditsOfAssetQueryVariables = Exact<{
	assetId: Scalars["String"]
	pagination: PaginatedInput
}>

export type InventoryAuditsOfAssetQuery = {
	__typename?: "Query"
	inventoryAuditsOfAsset: {
		__typename?: "PaginatedAssetInventoryAuditsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assetInventoryAudits: Array<{ __typename?: "AssetInventoryAudit"; _id: string; siteId: string; assetId: string; assetWeightInKG: number; inventoryWeightInKG: number; assetCount: number; createdAt: any; updatedAt: any }>
	}
}

export type LastWeekRestrictedUserLocationRecordsQueryVariables = Exact<{ [key: string]: never }>

export type LastWeekRestrictedUserLocationRecordsQuery = {
	__typename?: "Query"
	lastWeekRestrictedUserLocationRecords: Array<{
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type LatestAssetInventoryAuditsQueryVariables = Exact<{ [key: string]: never }>

export type LatestAssetInventoryAuditsQuery = {
	__typename?: "Query"
	latestAssetInventoryAudits: Array<{ __typename?: "AssetInventoryAudit"; _id: string; siteId: string; assetId: string; assetWeightInKG: number; inventoryWeightInKG: number; assetCount: number; createdAt: any; updatedAt: any }>
}

export type LatestInventoryAuditsOfAssetQueryVariables = Exact<{
	assetId: Scalars["String"]
}>

export type LatestInventoryAuditsOfAssetQuery = {
	__typename?: "Query"
	latestInventoryAuditsOfAsset?: { __typename?: "AssetInventoryAudit"; _id: string; siteId: string; assetId: string; assetWeightInKG: number; inventoryWeightInKG: number; assetCount: number; createdAt: any; updatedAt: any } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	} | null
}

export type MonthlyUserIdsQueryVariables = Exact<{ [key: string]: never }>

export type MonthlyUserIdsQuery = {
	__typename?: "Query"
	monthlyUserIds: Array<{
		__typename?: "DailyUsersResponse"
		date: any
		userIds: Array<string>
		typeWiseUsers: Array<{ __typename?: "TypeWiseUsersResponse"; typeId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}>
}

export type NotifiedUsersOfAlertQueryVariables = Exact<{
	alertId: Scalars["String"]
}>

export type NotifiedUsersOfAlertQuery = {
	__typename?: "Query"
	notifiedUsersOfAlert: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}>
}

export type OverTimeActiveUserIdsInLastIntervalQueryVariables = Exact<{
	timeLimit: Scalars["Float"]
	forToday: Scalars["Boolean"]
}>

export type OverTimeActiveUserIdsInLastIntervalQuery = {
	__typename?: "Query"
	overTimeActiveUserIdsInLastInterval: Array<{ __typename?: "TypeWiseUsersResponse"; typeId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type OverTimeActiveUsersInLastIntervalQueryVariables = Exact<{
	timeLimit: Scalars["Float"]
	forToday: Scalars["Boolean"]
	keyword: Scalars["String"]
	typeId?: InputMaybe<Scalars["String"]>
	roleType?: InputMaybe<UserRoleTypes>
	status?: InputMaybe<UserStatus>
	pagination: PaginatedInput
}>

export type OverTimeActiveUsersInLastIntervalQuery = {
	__typename?: "Query"
	overTimeActiveUsersInLastInterval: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type PermissionsGroupByIdQueryVariables = Exact<{
	permissionsGroupId: Scalars["String"]
}>

export type PermissionsGroupByIdQuery = {
	__typename?: "Query"
	permissionsGroupById: { __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type PermissionsGroupsQueryVariables = Exact<{ [key: string]: never }>

export type PermissionsGroupsQuery = {
	__typename?: "Query"
	permissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type PermissionsGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type PermissionsGroupsByKeywordQuery = {
	__typename?: "Query"
	permissionsGroupsByKeyword: {
		__typename?: "PaginatedPermissionsGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		permissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type PermissionTagsQueryVariables = Exact<{ [key: string]: never }>

export type PermissionTagsQuery = { __typename?: "Query"; permissionTags: Array<PermissionTags> }

export type PlacementRecordsOfAssetQueryVariables = Exact<{
	assetId: Scalars["String"]
	pagination: PaginatedInput
}>

export type PlacementRecordsOfAssetQuery = {
	__typename?: "Query"
	placementRecordsOfAsset: {
		__typename?: "PaginatedAssetPlacementRecordsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		assetPlacementRecords: Array<{
			__typename?: "AssetPlacementRecord"
			_id: string
			siteId: string
			assetId: string
			sourceZoneId?: string | null
			destinationZoneId: string
			placementTill: any
			lastUpdatedAt: any
			lastUpdatedByUserId: string
			createdAt: any
			updatedAt: any
		}>
	}
}

export type PreviousWeekRestrictedUserLocationRecordsQueryVariables = Exact<{ [key: string]: never }>

export type PreviousWeekRestrictedUserLocationRecordsQuery = {
	__typename?: "Query"
	previousWeekRestrictedUserLocationRecords: Array<{
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type ReaderByIdQueryVariables = Exact<{
	readerId: Scalars["String"]
}>

export type ReaderByIdQuery = {
	__typename?: "Query"
	readerById?: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	} | null
}

export type ReadersQueryVariables = Exact<{ [key: string]: never }>

export type ReadersQuery = {
	__typename?: "Query"
	readers: Array<{
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type ReadersAtZoneQueryVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type ReadersAtZoneQuery = {
	__typename?: "Query"
	readersAtZone: Array<{
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type ReadersByFilterQueryVariables = Exact<{
	filter: ReadersFilterInput
	pagination: PaginatedInput
}>

export type ReadersByFilterQuery = {
	__typename?: "Query"
	readersByFilter: {
		__typename?: "PaginatedReadersResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		readers: Array<{
			__typename?: "Reader"
			_id: string
			siteId: string
			type: ReaderTypes
			readerHexId: string
			readerDecimalId: string
			assignedToZoneId?: string | null
			assignedAt?: any | null
			isActive?: boolean | null
			lastConnectedEndpoint?: string | null
			lastConnectedAt?: any | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type ReadersByTypeQueryVariables = Exact<{
	type: ReaderTypes
}>

export type ReadersByTypeQuery = {
	__typename?: "Query"
	readersByType: Array<{
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type ReadersInBuildingQueryVariables = Exact<{
	buildingId: Scalars["String"]
}>

export type ReadersInBuildingQuery = {
	__typename?: "Query"
	readersInBuilding: Array<{
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type ReportByIdQueryVariables = Exact<{
	reportId: Scalars["String"]
}>

export type ReportByIdQuery = {
	__typename?: "Query"
	reportById?: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	} | null
}

export type ReportsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	type?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<Scalars["String"]>
	startDate?: InputMaybe<Scalars["DateTime"]>
	endDate?: InputMaybe<Scalars["DateTime"]>
	pagination: PaginatedInput
}>

export type ReportsByKeywordQuery = {
	__typename?: "Query"
	reportsByKeyword: {
		__typename?: "PaginatedReportsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		reports: Array<{
			__typename?: "Report"
			_id: string
			siteId: string
			type: ReportTypes
			userIds?: Array<string> | null
			sinceDate: any
			untilDate: any
			status: ReportStatus
			lastUpdatedByUserId?: string | null
			lastUpdatedAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
			json?: { __typename?: "ReportObject"; key: string; url: string } | null
			csv?: { __typename?: "ReportObject"; key: string; url: string } | null
			xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
		}>
	}
}

export type RootTileQueryVariables = Exact<{ [key: string]: never }>

export type RootTileQuery = {
	__typename?: "Query"
	rootTile?: {
		__typename?: "Tile"
		_id: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		levelWiseZoneEmbeddings: Array<{
			__typename?: "LevelWiseZoneEmbeddings"
			level: number
			zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
		}>
	} | null
}

export type ServiceByIdQueryVariables = Exact<{
	serviceId: Scalars["String"]
}>

export type ServiceByIdQuery = {
	__typename?: "Query"
	serviceById: { __typename?: "Service"; _id: string; key: string; lastHeartbeatAt?: any | null; isActive?: boolean | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type ServicesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
}>

export type ServicesByKeywordQuery = {
	__typename?: "Query"
	servicesByKeyword: Array<{
		__typename?: "Service"
		_id: string
		key: string
		lastHeartbeatAt?: any | null
		isActive?: boolean | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type SessionQueryVariables = Exact<{ [key: string]: never }>

export type SessionQuery = { __typename?: "Query"; session?: string | null }

export type SiteByIdQueryVariables = Exact<{
	siteId: Scalars["String"]
}>

export type SiteByIdQuery = { __typename?: "Query"; siteById: { __typename?: "Site"; _id: string; features: Array<Features>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } }

export type StatusLogsOfReaderQueryVariables = Exact<{
	readerId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}>

export type StatusLogsOfReaderQuery = {
	__typename?: "Query"
	statusLogsOfReader: {
		__typename?: "PaginatedReaderStatusLogsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		readerStatusLogs: Array<{ __typename?: "ReaderStatusLog"; _id: string; siteId: string; readerId: string; isActive?: boolean | null; connectedAt: any; disconnectedAt?: any | null; createdAt: any; updatedAt: any }>
	}
}

export type TagByIdQueryVariables = Exact<{
	tagId: Scalars["String"]
}>

export type TagByIdQuery = {
	__typename?: "Query"
	tagById?: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedToAssetId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	} | null
}

export type TagsByFilterQueryVariables = Exact<{
	filter: TagsFilterInput
	pagination: PaginatedInput
}>

export type TagsByFilterQuery = {
	__typename?: "Query"
	tagsByFilter: {
		__typename?: "PaginatedTagsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		tags: Array<{
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedToAssetId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type TileByIdQueryVariables = Exact<{
	tileId: Scalars["String"]
}>

export type TileByIdQuery = {
	__typename?: "Query"
	tileById?: {
		__typename?: "Tile"
		_id: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		levelWiseZoneEmbeddings: Array<{
			__typename?: "LevelWiseZoneEmbeddings"
			level: number
			zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
		}>
	} | null
}

export type TilesQueryVariables = Exact<{ [key: string]: never }>

export type TilesQuery = {
	__typename?: "Query"
	tiles: Array<{
		__typename?: "Tile"
		_id: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		levelWiseZoneEmbeddings: Array<{
			__typename?: "LevelWiseZoneEmbeddings"
			level: number
			zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
		}>
	}>
}

export type TilesByFilterQueryVariables = Exact<{
	filter: TilesFilterInput
	pagination: PaginatedInput
}>

export type TilesByFilterQuery = {
	__typename?: "Query"
	tilesByFilter: {
		__typename?: "PaginatedTilesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		tiles: Array<{
			__typename?: "Tile"
			_id: string
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			levelWiseZoneEmbeddings: Array<{
				__typename?: "LevelWiseZoneEmbeddings"
				level: number
				zoneEmbeddings: Array<{ __typename?: "ZoneEmbedding"; zoneId: string; position: { __typename?: "Position"; x: number; y: number }; polygon?: Array<{ __typename?: "Position"; x: number; y: number }> | null }>
			}>
		}>
	}
}

export type TodayRestrictedUserLocationRecordIdsQueryVariables = Exact<{ [key: string]: never }>

export type TodayRestrictedUserLocationRecordIdsQuery = { __typename?: "Query"; todayRestrictedUserLocationRecordIds: Array<string> }

export type TodayRestrictedUserLocationRecordsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type TodayRestrictedUserLocationRecordsQuery = {
	__typename?: "Query"
	todayRestrictedUserLocationRecords: {
		__typename?: "PaginatedUserLocationRecordsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		userLocationRecords: Array<{
			__typename?: "UserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type TodayTrackedAssetsQueryVariables = Exact<{
	keyword: Scalars["String"]
	status?: InputMaybe<AssetStatus>
	pagination: PaginatedInput
}>

export type TodayTrackedAssetsQuery = {
	__typename?: "Query"
	todayTrackedAssets: {
		__typename?: "PaginatedAssetsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		assets: Array<{
			__typename?: "Asset"
			_id: string
			siteId: string
			type: AssetTypes
			name: string
			categoryId?: string | null
			tagId?: string | null
			vendorId?: string | null
			status: AssetStatus
			weightInKG?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			currentPlacement?: {
				__typename?: "AssetPlacementRecord"
				_id: string
				siteId: string
				assetId: string
				sourceZoneId?: string | null
				destinationZoneId: string
				placementTill: any
				lastUpdatedAt: any
				lastUpdatedByUserId: string
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type TypeWiseActiveUsersQueryVariables = Exact<{ [key: string]: never }>

export type TypeWiseActiveUsersQuery = {
	__typename?: "Query"
	typeWiseActiveUsers: Array<{ __typename?: "TypeWiseUsersResponse"; typeId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type TypeWiseActiveUsersInLastIntervalQueryVariables = Exact<{
	intervalInMinutes: Scalars["Float"]
}>

export type TypeWiseActiveUsersInLastIntervalQuery = {
	__typename?: "Query"
	typeWiseActiveUsersInLastInterval: Array<{ __typename?: "TypeWiseUsersResponse"; typeId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type UpdateAssetMutationVariables = Exact<{
	assetId: Scalars["String"]
	input: AssetInput
}>

export type UpdateAssetMutation = {
	__typename?: "Mutation"
	updateAsset: {
		__typename?: "Asset"
		_id: string
		siteId: string
		type: AssetTypes
		name: string
		categoryId?: string | null
		tagId?: string | null
		vendorId?: string | null
		status: AssetStatus
		weightInKG?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		currentPlacement?: {
			__typename?: "AssetPlacementRecord"
			_id: string
			siteId: string
			assetId: string
			sourceZoneId?: string | null
			destinationZoneId: string
			placementTill: any
			lastUpdatedAt: any
			lastUpdatedByUserId: string
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type UserByIdQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserByIdQuery = {
	__typename?: "Query"
	userById?: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	} | null
}

export type UserCurrentLocationRecordQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserCurrentLocationRecordQuery = {
	__typename?: "Query"
	userCurrentLocationRecord?: {
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	} | null
}

export type UserCurrentUserRoleQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserCurrentUserRoleQuery = {
	__typename?: "Query"
	userCurrentUserRole?: {
		__typename?: "UserRole"
		_id: string
		siteId: string
		userId: string
		type: UserRoleTypes
		userRoleGroupId: string
		designation?: string | null
		startAt: any
		endAt?: any | null
		status: UserRoleStatus
		assignedAt: any
		removedAt?: any | null
	} | null
}

export type UserGroupByIdQueryVariables = Exact<{
	userGroupId: Scalars["String"]
}>

export type UserGroupByIdQuery = { __typename?: "Query"; userGroupById: { __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } } }

export type UserGroupsByFilterQueryVariables = Exact<{
	filter: UserGroupsFilterInput
	pagination: PaginatedInput
}>

export type UserGroupsByFilterQuery = {
	__typename?: "Query"
	userGroupsByFilter: {
		__typename?: "PaginatedUserGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userGroups: Array<{ __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type UserLocationRecordByIdQueryVariables = Exact<{
	userLocationRecordId: Scalars["String"]
}>

export type UserLocationRecordByIdQuery = {
	__typename?: "Query"
	userLocationRecordById?: {
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	} | null
}

export type UserLocationRecordsAtZoneQueryVariables = Exact<{
	zoneId: Scalars["String"]
	userTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
	isRestricted?: InputMaybe<Scalars["Boolean"]>
}>

export type UserLocationRecordsAtZoneQuery = {
	__typename?: "Query"
	userLocationRecordsAtZone: Array<{
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type UserLocationRecordsInBuildingQueryVariables = Exact<{
	buildingId: Scalars["String"]
	userTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
	isRestricted?: InputMaybe<Scalars["Boolean"]>
}>

export type UserLocationRecordsInBuildingQuery = {
	__typename?: "Query"
	userLocationRecordsInBuilding: Array<{
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type UserLocationRecordsTrailQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserLocationRecordsTrailQuery = {
	__typename?: "Query"
	userLocationRecordsTrail: Array<{
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}>
}

export type UserPastAttendanceRecordsQueryVariables = Exact<{
	userId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}>

export type UserPastAttendanceRecordsQuery = {
	__typename?: "Query"
	userPastAttendanceRecords: {
		__typename?: "PaginatedUserAttendanceRecordsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userAttendanceRecords: Array<{ __typename?: "UserAttendanceRecord"; _id: string; siteId: string; userId: string; startAt: any; endAt: any; status: UserAttendanceRecordStatus; createdAt: any; updatedAt: any }>
	}
}

export type UserPastLocationRecordsQueryVariables = Exact<{
	userId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}>

export type UserPastLocationRecordsQuery = {
	__typename?: "Query"
	userPastLocationRecords: {
		__typename?: "PaginatedUserLocationRecordsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userLocationRecords: Array<{
			__typename?: "UserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
		}>
	}
}

export type UserPermissionsGroupsQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserPermissionsGroupsQuery = {
	__typename?: "Query"
	userPermissionsGroups?: Array<{ __typename?: "PermissionsGroup"; _id: string; siteId: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
}

export type UserRoleByIdQueryVariables = Exact<{
	userRoleId: Scalars["String"]
}>

export type UserRoleByIdQuery = {
	__typename?: "Query"
	userRoleById?: {
		__typename?: "UserRole"
		_id: string
		siteId: string
		userId: string
		type: UserRoleTypes
		userRoleGroupId: string
		designation?: string | null
		startAt: any
		endAt?: any | null
		status: UserRoleStatus
		assignedAt: any
		removedAt?: any | null
	} | null
}

export type UserRoleGroupByIdQueryVariables = Exact<{
	userRoleGroupId: Scalars["String"]
}>

export type UserRoleGroupByIdQuery = {
	__typename?: "Query"
	userRoleGroupById: {
		__typename?: "UserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		departmentId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type UserRoleGroupsQueryVariables = Exact<{
	type?: InputMaybe<UserRoleTypes>
}>

export type UserRoleGroupsQuery = {
	__typename?: "Query"
	userRoleGroups: Array<{
		__typename?: "UserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		departmentId: string
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type UserRoleGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type UserRoleGroupsByKeywordQuery = {
	__typename?: "Query"
	userRoleGroupsByKeyword: {
		__typename?: "PaginatedUserRoleGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userRoleGroups: Array<{
			__typename?: "UserRoleGroup"
			_id: string
			siteId: string
			type: UserRoleTypes
			allowedZoneIds?: Array<string> | null
			allowedZoneGroupIds?: Array<string> | null
			departmentId: string
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type UserTypeByIdQueryVariables = Exact<{
	userTypeId: Scalars["String"]
}>

export type UserTypeByIdQuery = { __typename?: "Query"; userTypeById: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } } }

export type UserTypesQueryVariables = Exact<{ [key: string]: never }>

export type UserTypesQuery = { __typename?: "Query"; userTypes: Array<{ __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }> }

export type UserTypesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type UserTypesByKeywordQuery = {
	__typename?: "Query"
	userTypesByKeyword: {
		__typename?: "PaginatedUserTypesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userTypes: Array<{ __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type UsersQueryVariables = Exact<{
	filter: UsersFilterInput
	pagination: PaginatedInput
}>

export type UsersQuery = {
	__typename?: "Query"
	users: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type UsersByCurrentUserRoleGroupQueryVariables = Exact<{
	userRoleGroupId: Scalars["String"]
	pagination: PaginatedInput
}>

export type UsersByCurrentUserRoleGroupQuery = {
	__typename?: "Query"
	usersByCurrentUserRoleGroup: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type UsersByDateAndTypeQueryVariables = Exact<{
	filter: UsersByDateAndTypeFilterInput
	pagination: PaginatedInput
}>

export type UsersByDateAndTypeQuery = {
	__typename?: "Query"
	usersByDateAndType: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type UsersByDepartmentQueryVariables = Exact<{
	departmentId: Scalars["String"]
	pagination: PaginatedInput
}>

export type UsersByDepartmentQuery = {
	__typename?: "Query"
	usersByDepartment: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type UsersByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	typeId?: InputMaybe<Scalars["String"]>
	roleType?: InputMaybe<UserRoleTypes>
	visitorLevel?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<UserStatus>
	since?: InputMaybe<Scalars["DateTime"]>
	until?: InputMaybe<Scalars["DateTime"]>
	pagination: PaginatedInput
}>

export type UsersByKeywordQuery = {
	__typename?: "Query"
	usersByKeyword: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type UsersByTypeQueryVariables = Exact<{
	typeId: Scalars["String"]
	pagination: PaginatedInput
}>

export type UsersByTypeQuery = {
	__typename?: "Query"
	usersByType: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			typeId: string
			roleType: UserRoleTypes
			departmentId?: string | null
			isDepartmentHead?: boolean | null
			userRoleId?: string | null
			userRoleGroupId?: string | null
			employeeId?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
			visitorDetails?: {
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			} | null
			pastVisitorDetails?: Array<{
				__typename?: "VisitorDetails"
				visitDate?: any | null
				companyName?: string | null
				level?: string | null
				visitorCardNo?: string | null
				meetingPerson?: string | null
				departmentName?: string | null
				buildingName?: string | null
				visitPurpose?: string | null
			}> | null
		}>
	}
}

export type UsersOfAlertTargetGroupQueryVariables = Exact<{
	alertTargetGroupId: Scalars["String"]
}>

export type UsersOfAlertTargetGroupQuery = {
	__typename?: "Query"
	usersOfAlertTargetGroup: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}>
}

export type UsersOfUserGroupQueryVariables = Exact<{
	userGroupId: Scalars["String"]
}>

export type UsersOfUserGroupQuery = {
	__typename?: "Query"
	usersOfUserGroup: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		typeId: string
		roleType: UserRoleTypes
		departmentId?: string | null
		isDepartmentHead?: boolean | null
		userRoleId?: string | null
		userRoleGroupId?: string | null
		employeeId?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null; createdAt: any; updatedAt: any } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
		visitorDetails?: {
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		} | null
		pastVisitorDetails?: Array<{
			__typename?: "VisitorDetails"
			visitDate?: any | null
			companyName?: string | null
			level?: string | null
			visitorCardNo?: string | null
			meetingPerson?: string | null
			departmentName?: string | null
			buildingName?: string | null
			visitPurpose?: string | null
		}> | null
	}>
}

export type ZoneByIdQueryVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type ZoneByIdQuery = {
	__typename?: "Query"
	zoneById?: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		buildingId?: string | null
		floorId?: string | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
	} | null
}

export type ZoneGroupByIdQueryVariables = Exact<{
	zoneGroupId: Scalars["String"]
}>

export type ZoneGroupByIdQuery = {
	__typename?: "Query"
	zoneGroupById?: { __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
}

export type ZoneGroupsQueryVariables = Exact<{ [key: string]: never }>

export type ZoneGroupsQuery = {
	__typename?: "Query"
	zoneGroups: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type ZoneGroupsByFilterQueryVariables = Exact<{
	filter: ZoneGroupsFilterInput
	pagination: PaginatedInput
}>

export type ZoneGroupsByFilterQuery = {
	__typename?: "Query"
	zoneGroupsByFilter: {
		__typename?: "PaginatedZoneGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zoneGroups: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type ZoneGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type ZoneGroupsByKeywordQuery = {
	__typename?: "Query"
	zoneGroupsByKeyword: {
		__typename?: "PaginatedZoneGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zoneGroups: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type ZonesQueryVariables = Exact<{ [key: string]: never }>

export type ZonesQuery = {
	__typename?: "Query"
	zones: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		buildingId?: string | null
		floorId?: string | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
	}>
}

export type ZonesByFilterQueryVariables = Exact<{
	filter: ZonesFilterInput
}>

export type ZonesByFilterQuery = {
	__typename?: "Query"
	zonesByFilter: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		buildingId?: string | null
		floorId?: string | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
	}>
}

export type ZonesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type ZonesByKeywordQuery = {
	__typename?: "Query"
	zonesByKeyword: {
		__typename?: "PaginatedZonesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			buildingId?: string | null
			floorId?: string | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
		}>
	}
}

export type ZonesInBuildingQueryVariables = Exact<{
	buildingId: Scalars["String"]
}>

export type ZonesInBuildingQuery = {
	__typename?: "Query"
	zonesInBuilding: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		buildingId?: string | null
		floorId?: string | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
	}>
}

export type ZonesOfZoneGroupQueryVariables = Exact<{
	zoneGroupId: Scalars["String"]
	pagination: PaginatedInput
}>

export type ZonesOfZoneGroupQuery = {
	__typename?: "Query"
	zonesOfZoneGroup: {
		__typename?: "PaginatedZonesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			buildingId?: string | null
			floorId?: string | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				} | null
			} | null
		}>
	}
}

export type ZonesOnBuildingFloorQueryVariables = Exact<{
	buildingId: Scalars["String"]
	floorId: Scalars["String"]
}>

export type ZonesOnBuildingFloorQuery = {
	__typename?: "Query"
	zonesOnBuildingFloor: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		buildingId?: string | null
		floorId?: string | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height: number; width: number } | null
			} | null
		} | null
	}>
}

export type AlertsUpdateSubscriptionVariables = Exact<{ [key: string]: never }>

export type AlertsUpdateSubscription = {
	__typename?: "Subscription"
	alertsUpdate: {
		__typename?: "Alert"
		_id: string
		siteId: string
		type: AlertTypes
		alertFlowIds: Array<string>
		emailMessage: string
		notificationMessage: string
		alertedUserIds: Array<string>
		emailedUserIds?: Array<string> | null
		notifiedUserIds?: Array<string> | null
		userId?: string | null
		userRoleId?: string | null
		zoneId?: string | null
		readerId?: string | null
		tagId?: string | null
		userLocationRecordId?: string | null
		serviceId?: string | null
		assetId?: string | null
		createdAt: any
		updatedAt: any
	}
}

export type AssetLocationRecordUpdateSubscriptionVariables = Exact<{
	assetId: Scalars["String"]
}>

export type AssetLocationRecordUpdateSubscription = {
	__typename?: "Subscription"
	assetLocationRecordUpdate: {
		__typename?: "AssetLocationRecord"
		_id: string
		siteId: string
		assetId: string
		zoneId: string
		startAt: any
		endAt: any
		status: AssetLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}
}

export type AssetLocationRecordUpdateAtZoneSubscriptionVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type AssetLocationRecordUpdateAtZoneSubscription = {
	__typename?: "Subscription"
	assetLocationRecordUpdateAtZone: {
		__typename?: "AssetLocationRecord"
		_id: string
		siteId: string
		assetId: string
		zoneId: string
		startAt: any
		endAt: any
		status: AssetLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}
}

export type DeviceUpdateSubscriptionVariables = Exact<{
	deviceId: Scalars["String"]
}>

export type DeviceUpdateSubscription = {
	__typename?: "Subscription"
	deviceUpdate: {
		__typename?: "Device"
		_id: string
		siteId: string
		type: DeviceTypes
		deviceId: string
		isActive?: boolean | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type EnrolledTagByDeviceIdSubscriptionVariables = Exact<{
	deviceId: Scalars["String"]
}>

export type EnrolledTagByDeviceIdSubscription = {
	__typename?: "Subscription"
	enrolledTagByDeviceId: { __typename?: "EnrolledTag"; _id: string; siteId: string; readerId?: string | null; deviceId?: string | null; tagHexId: string; tagDecimalId: string; createdAt: any; updatedAt: any }
}

export type EnrolledTagByReaderIdSubscriptionVariables = Exact<{
	readerId: Scalars["String"]
}>

export type EnrolledTagByReaderIdSubscription = {
	__typename?: "Subscription"
	enrolledTagByReaderId: { __typename?: "EnrolledTag"; _id: string; siteId: string; readerId?: string | null; deviceId?: string | null; tagHexId: string; tagDecimalId: string; createdAt: any; updatedAt: any }
}

export type ReaderUpdateSubscriptionVariables = Exact<{
	readerId: Scalars["String"]
}>

export type ReaderUpdateSubscription = {
	__typename?: "Subscription"
	readerUpdate: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type ReaderUpdateAtZoneSubscriptionVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type ReaderUpdateAtZoneSubscription = {
	__typename?: "Subscription"
	readerUpdateAtZone: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type ReportUpdateSubscriptionVariables = Exact<{
	reportId: Scalars["String"]
}>

export type ReportUpdateSubscription = {
	__typename?: "Subscription"
	reportUpdate: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		lastUpdatedByUserId?: string | null
		lastUpdatedAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		xlsx?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type RestrictedUserLocationRecordsUpdateSubscriptionVariables = Exact<{
	recordIds: Array<Scalars["String"]> | Scalars["String"]
}>

export type RestrictedUserLocationRecordsUpdateSubscription = {
	__typename?: "Subscription"
	restrictedUserLocationRecordsUpdate: {
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}
}

export type UserAuthSessionUpdateSubscriptionVariables = Exact<{
	sessionId: Scalars["String"]
}>

export type UserAuthSessionUpdateSubscription = { __typename?: "Subscription"; userAuthSessionUpdate: { __typename?: "UserAuthSession"; _id: string; userId: string; ip?: string | null; loggedInAt: any; loggedOutAt?: any | null } }

export type UserLocationRecordUpdateSubscriptionVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserLocationRecordUpdateSubscription = {
	__typename?: "Subscription"
	userLocationRecordUpdate: {
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}
}

export type UserLocationRecordUpdateAtZoneSubscriptionVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type UserLocationRecordUpdateAtZoneSubscription = {
	__typename?: "Subscription"
	userLocationRecordUpdateAtZone: {
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}
}

export type UserLocationRecordsUpdateSubscriptionVariables = Exact<{ [key: string]: never }>

export type UserLocationRecordsUpdateSubscription = {
	__typename?: "Subscription"
	userLocationRecordsUpdate: {
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	}
}

export const AlertFragmentDoc = gql`
	fragment Alert on Alert {
		_id
		siteId
		type
		alertFlowIds
		emailMessage
		notificationMessage
		alertedUserIds
		emailedUserIds
		notifiedUserIds
		userId
		userRoleId
		zoneId
		readerId
		tagId
		userLocationRecordId
		serviceId
		assetId
		createdAt
		updatedAt
	}
`
export const LabelFragmentDoc = gql`
	fragment Label on Label {
		name
		description
	}
`
export const AlertFlowFragmentDoc = gql`
	fragment AlertFlow on AlertFlow {
		_id
		siteId
		label {
			...Label
		}
		source {
			type
			zoneIds
			zoneGroupIds
			userIds
			userGroupIds
			userTypeIds
			userRoleTypes
			departmentIds
			assetIds
			assetCategoryIds
		}
		type
		targetGroupIds
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const AlertTargetGroupFragmentDoc = gql`
	fragment AlertTargetGroup on AlertTargetGroup {
		_id
		siteId
		label {
			...Label
		}
		userIds
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const PictureObjectFragmentDoc = gql`
	fragment PictureObject on PictureObject {
		key
		url
		height
		width
	}
`
export const PictureFragmentDoc = gql`
	fragment Picture on Picture {
		original {
			...PictureObject
		}
		variants {
			small {
				...PictureObject
			}
			medium {
				...PictureObject
			}
			large {
				...PictureObject
			}
			thumbnail {
				...PictureObject
			}
		}
	}
	${PictureObjectFragmentDoc}
`
export const AssetPlacementRecordFragmentDoc = gql`
	fragment AssetPlacementRecord on AssetPlacementRecord {
		_id
		siteId
		assetId
		sourceZoneId
		destinationZoneId
		placementTill
		lastUpdatedAt
		lastUpdatedByUserId
		createdAt
		updatedAt
	}
`
export const AssetFragmentDoc = gql`
	fragment Asset on Asset {
		_id
		siteId
		type
		name
		picture {
			...Picture
		}
		categoryId
		tagId
		vendorId
		status
		weightInKG
		currentPlacement {
			...AssetPlacementRecord
		}
		createdAt
		updatedAt
	}
	${PictureFragmentDoc}
	${AssetPlacementRecordFragmentDoc}
`
export const SiteFragmentDoc = gql`
	fragment Site on Site {
		_id
		label {
			...Label
		}
		features
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const AssetCategoryFragmentDoc = gql`
	fragment AssetCategory on AssetCategory {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
`
export const AssetInventoryAuditFragmentDoc = gql`
	fragment AssetInventoryAudit on AssetInventoryAudit {
		_id
		siteId
		assetId
		assetWeightInKG
		inventoryWeightInKG
		assetCount
		createdAt
		updatedAt
	}
`
export const AssetInventoryAuditStatFragmentDoc = gql`
	fragment AssetInventoryAuditStat on AssetInventoryAuditStat {
		auditId
		count
		auditedAt
	}
`
export const AssetInventoryAuditStatsFragmentDoc = gql`
	fragment AssetInventoryAuditStats on AssetInventoryAuditStats {
		_id
		siteId
		assetId
		stats {
			...AssetInventoryAuditStat
		}
		createdAt
		updatedAt
	}
	${AssetInventoryAuditStatFragmentDoc}
`
export const AssetLocationRecordFragmentDoc = gql`
	fragment AssetLocationRecord on AssetLocationRecord {
		_id
		siteId
		assetId
		zoneId
		startAt
		endAt
		status
		isRestricted
		createdAt
		updatedAt
	}
`
export const FloorFragmentDoc = gql`
	fragment Floor on Floor {
		_id
		label {
			...Label
		}
	}
	${LabelFragmentDoc}
`
export const BuildingFragmentDoc = gql`
	fragment Building on Building {
		_id
		siteId
		label {
			...Label
		}
		floors {
			...Floor
		}
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
	${FloorFragmentDoc}
`
export const DepartmentFragmentDoc = gql`
	fragment Department on Department {
		_id
		siteId
		label {
			...Label
		}
		departmentHeadId
		zoneIds
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const DeviceFragmentDoc = gql`
	fragment Device on Device {
		_id
		siteId
		label {
			...Label
		}
		type
		deviceId
		isActive
		lastHeartbeatAt
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const EnrolledTagFragmentDoc = gql`
	fragment EnrolledTag on EnrolledTag {
		_id
		siteId
		readerId
		deviceId
		tagHexId
		tagDecimalId
		createdAt
		updatedAt
	}
`
export const PermissionsGroupFragmentDoc = gql`
	fragment PermissionsGroup on PermissionsGroup {
		_id
		siteId
		tags
		label {
			...Label
		}
		isPrivateToAdmin
	}
	${LabelFragmentDoc}
`
export const ReaderFragmentDoc = gql`
	fragment Reader on Reader {
		_id
		siteId
		label {
			...Label
		}
		type
		readerHexId
		readerDecimalId
		assignedToZoneId
		assignedAt
		isActive
		lastConnectedEndpoint
		lastConnectedAt
		lastHeartbeatAt
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ReaderStatusLogFragmentDoc = gql`
	fragment ReaderStatusLog on ReaderStatusLog {
		_id
		siteId
		readerId
		isActive
		connectedAt
		disconnectedAt
		createdAt
		updatedAt
	}
`
export const ReportFragmentDoc = gql`
	fragment Report on Report {
		_id
		siteId
		label {
			...Label
		}
		type
		userIds
		sinceDate
		untilDate
		status
		completeJson {
			key
			url
		}
		json {
			key
			url
		}
		csv {
			key
			url
		}
		xlsx {
			key
			url
		}
		lastUpdatedByUserId
		lastUpdatedAt
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ServiceFragmentDoc = gql`
	fragment Service on Service {
		_id
		key
		label {
			...Label
		}
		lastHeartbeatAt
		isActive
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const TagFragmentDoc = gql`
	fragment Tag on Tag {
		_id
		tagHexId
		tagDecimalId
		status
		batteryStatus
		assignedToUserId
		assignedToAssetId
		assignedAt
		createdAt
		updatedAt
	}
`
export const PositionFragmentDoc = gql`
	fragment Position on Position {
		x
		y
	}
`
export const ZoneEmbeddingFragmentDoc = gql`
	fragment ZoneEmbedding on ZoneEmbedding {
		zoneId
		position {
			...Position
		}
		polygon {
			...Position
		}
	}
	${PositionFragmentDoc}
`
export const LevelWiseZoneEmbeddingsFragmentDoc = gql`
	fragment LevelWiseZoneEmbeddings on LevelWiseZoneEmbeddings {
		level
		zoneEmbeddings {
			...ZoneEmbedding
		}
	}
	${ZoneEmbeddingFragmentDoc}
`
export const TileFragmentDoc = gql`
	fragment Tile on Tile {
		_id
		label {
			...Label
		}
		picture {
			...Picture
		}
		levelWiseZoneEmbeddings {
			...LevelWiseZoneEmbeddings
		}
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
	${PictureFragmentDoc}
	${LevelWiseZoneEmbeddingsFragmentDoc}
`
export const UserAttendanceRecordFragmentDoc = gql`
	fragment UserAttendanceRecord on UserAttendanceRecord {
		_id
		siteId
		userId
		startAt
		endAt
		status
		createdAt
		updatedAt
	}
`
export const FieldErrorFragmentDoc = gql`
	fragment FieldError on FieldError {
		field
		error
	}
`
export const EmailFragmentDoc = gql`
	fragment Email on Email {
		address
		isVerified
		verifiedAt
		createdAt
		updatedAt
	}
`
export const VisitorDetailsFragmentDoc = gql`
	fragment VisitorDetails on VisitorDetails {
		visitDate
		companyName
		level
		visitorCardNo
		meetingPerson
		departmentName
		buildingName
		visitPurpose
	}
`
export const UserFragmentDoc = gql`
	fragment User on User {
		_id
		siteId
		name
		phone {
			countryCode
			number
		}
		email {
			...Email
		}
		username
		address
		picture {
			...Picture
		}
		typeId
		roleType
		departmentId
		isDepartmentHead
		userRoleId
		userRoleGroupId
		employeeId
		tagId
		status
		visitorDetails {
			...VisitorDetails
		}
		pastVisitorDetails {
			...VisitorDetails
		}
		createdAt
		updatedAt
	}
	${EmailFragmentDoc}
	${PictureFragmentDoc}
	${VisitorDetailsFragmentDoc}
`
export const UserAuthResponseFragmentDoc = gql`
	fragment UserAuthResponse on UserAuthResponse {
		errors {
			...FieldError
		}
		user {
			...User
		}
	}
	${FieldErrorFragmentDoc}
	${UserFragmentDoc}
`
export const UserAuthSessionFragmentDoc = gql`
	fragment UserAuthSession on UserAuthSession {
		_id
		userId
		ip
		loggedInAt
		loggedOutAt
	}
`
export const UserGroupFragmentDoc = gql`
	fragment UserGroup on UserGroup {
		_id
		siteId
		label {
			...Label
		}
		userIds
	}
	${LabelFragmentDoc}
`
export const UserLocationRecordFragmentDoc = gql`
	fragment UserLocationRecord on UserLocationRecord {
		_id
		siteId
		zoneId
		userId
		startAt
		endAt
		status
		isRestricted
		createdAt
		updatedAt
	}
`
export const UserRoleFragmentDoc = gql`
	fragment UserRole on UserRole {
		_id
		siteId
		userId
		type
		userRoleGroupId
		designation
		startAt
		endAt
		status
		assignedAt
		removedAt
	}
`
export const UserRoleGroupFragmentDoc = gql`
	fragment UserRoleGroup on UserRoleGroup {
		_id
		siteId
		type
		label {
			...Label
		}
		allowedZoneIds
		allowedZoneGroupIds
		departmentId
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const UserTypeFragmentDoc = gql`
	fragment UserType on UserType {
		_id
		siteId
		label {
			...Label
		}
		roleType
	}
	${LabelFragmentDoc}
`
export const ZoneFragmentDoc = gql`
	fragment Zone on Zone {
		_id
		siteId
		label {
			...Label
		}
		readerIds
		picture {
			...Picture
		}
		type
		buildingId
		floorId
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
	${PictureFragmentDoc}
`
export const ZoneGroupFragmentDoc = gql`
	fragment ZoneGroup on ZoneGroup {
		_id
		siteId
		label {
			...Label
		}
		zoneIds
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ActivateAssetDocument = gql`
	mutation ActivateAsset($assetId: String!) {
		activateAsset(assetId: $assetId) {
			...Asset
		}
	}
	${AssetFragmentDoc}
`

export function useActivateAssetMutation() {
	return Urql.useMutation<ActivateAssetMutation, ActivateAssetMutationVariables>(ActivateAssetDocument)
}
export const ActivateTagOfAssetDocument = gql`
	mutation ActivateTagOfAsset($assetId: String!) {
		activateTagOfAsset(assetId: $assetId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useActivateTagOfAssetMutation() {
	return Urql.useMutation<ActivateTagOfAssetMutation, ActivateTagOfAssetMutationVariables>(ActivateTagOfAssetDocument)
}
export const ActivateTagOfUserDocument = gql`
	mutation activateTagOfUser($userId: String!) {
		activateTagOfUser(userId: $userId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useActivateTagOfUserMutation() {
	return Urql.useMutation<ActivateTagOfUserMutation, ActivateTagOfUserMutationVariables>(ActivateTagOfUserDocument)
}
export const ActivateUserDocument = gql`
	mutation ActivateUser($userId: String!) {
		activateUser(userId: $userId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useActivateUserMutation() {
	return Urql.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument)
}
export const AddReaderToZoneDocument = gql`
	mutation AddReaderToZone($zoneId: String!, $readerDecimalId: String!) {
		addReaderToZone(zoneId: $zoneId, readerDecimalId: $readerDecimalId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useAddReaderToZoneMutation() {
	return Urql.useMutation<AddReaderToZoneMutation, AddReaderToZoneMutationVariables>(AddReaderToZoneDocument)
}
export const AssignDepartmentHeadDocument = gql`
	mutation AssignDepartmentHead($userId: String!, $departmentId: String!) {
		assignDepartmentHead(userId: $userId, departmentId: $departmentId) {
			...Department
		}
	}
	${DepartmentFragmentDoc}
`

export function useAssignDepartmentHeadMutation() {
	return Urql.useMutation<AssignDepartmentHeadMutation, AssignDepartmentHeadMutationVariables>(AssignDepartmentHeadDocument)
}
export const AssignTagDocument = gql`
	mutation AssignTag($userId: String!, $enrolledTagId: String!) {
		assignTag(userId: $userId, enrolledTagId: $enrolledTagId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useAssignTagMutation() {
	return Urql.useMutation<AssignTagMutation, AssignTagMutationVariables>(AssignTagDocument)
}
export const AssignTagByDecimalIdDocument = gql`
	mutation AssignTagByDecimalId($userId: String!, $tagDecimalId: String!) {
		assignTagByDecimalId(userId: $userId, tagDecimalId: $tagDecimalId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useAssignTagByDecimalIdMutation() {
	return Urql.useMutation<AssignTagByDecimalIdMutation, AssignTagByDecimalIdMutationVariables>(AssignTagByDecimalIdDocument)
}
export const AssignTagToAssetDocument = gql`
	mutation AssignTagToAsset($assetId: String!, $enrolledTagId: String!) {
		assignTagToAsset(assetId: $assetId, enrolledTagId: $enrolledTagId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useAssignTagToAssetMutation() {
	return Urql.useMutation<AssignTagToAssetMutation, AssignTagToAssetMutationVariables>(AssignTagToAssetDocument)
}
export const AssignTagToAssetByDecimalIdDocument = gql`
	mutation AssignTagToAssetByDecimalId($assetId: String!, $tagDecimalId: String!) {
		assignTagToAssetByDecimalId(assetId: $assetId, tagDecimalId: $tagDecimalId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useAssignTagToAssetByDecimalIdMutation() {
	return Urql.useMutation<AssignTagToAssetByDecimalIdMutation, AssignTagToAssetByDecimalIdMutationVariables>(AssignTagToAssetByDecimalIdDocument)
}
export const AssignUserRoleDocument = gql`
	mutation assignUserRole($userId: String!, $input: UserRoleInput!) {
		assignUserRole(userId: $userId, input: $input) {
			...UserRole
		}
	}
	${UserRoleFragmentDoc}
`

export function useAssignUserRoleMutation() {
	return Urql.useMutation<AssignUserRoleMutation, AssignUserRoleMutationVariables>(AssignUserRoleDocument)
}
export const ChangePasswordDocument = gql`
	mutation ChangePassword($currentPassword: String!, $newPassword: String!, $shouldLogOutOnOtherDevices: Boolean) {
		changePassword(currentPassword: $currentPassword, newPassword: $newPassword, shouldLogOutOnOtherDevices: $shouldLogOutOnOtherDevices)
	}
`

export function useChangePasswordMutation() {
	return Urql.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument)
}
export const CreateAlertFlowDocument = gql`
	mutation CreateAlertFlow($input: AlertFlowInput!) {
		createAlertFlow(input: $input) {
			...AlertFlow
		}
	}
	${AlertFlowFragmentDoc}
`

export function useCreateAlertFlowMutation() {
	return Urql.useMutation<CreateAlertFlowMutation, CreateAlertFlowMutationVariables>(CreateAlertFlowDocument)
}
export const CreateAlertTargetGroupDocument = gql`
	mutation CreateAlertTargetGroup($input: AlertTargetGroupInput!) {
		createAlertTargetGroup(input: $input) {
			...AlertTargetGroup
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useCreateAlertTargetGroupMutation() {
	return Urql.useMutation<CreateAlertTargetGroupMutation, CreateAlertTargetGroupMutationVariables>(CreateAlertTargetGroupDocument)
}
export const CreateAssetDocument = gql`
	mutation CreateAsset($input: AssetInput!) {
		createAsset(input: $input) {
			...Asset
		}
	}
	${AssetFragmentDoc}
`

export function useCreateAssetMutation() {
	return Urql.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument)
}
export const CreateAssetCategoryDocument = gql`
	mutation createAssetCategory($input: AssetCategoryInput!) {
		createAssetCategory(input: $input) {
			...AssetCategory
		}
	}
	${AssetCategoryFragmentDoc}
`

export function useCreateAssetCategoryMutation() {
	return Urql.useMutation<CreateAssetCategoryMutation, CreateAssetCategoryMutationVariables>(CreateAssetCategoryDocument)
}
export const CreateAssetInventoryAuditDocument = gql`
	mutation CreateAssetInventoryAudit($input: AssetInventoryAuditInput!) {
		createAssetInventoryAudit(input: $input) {
			...AssetInventoryAudit
		}
	}
	${AssetInventoryAuditFragmentDoc}
`

export function useCreateAssetInventoryAuditMutation() {
	return Urql.useMutation<CreateAssetInventoryAuditMutation, CreateAssetInventoryAuditMutationVariables>(CreateAssetInventoryAuditDocument)
}
export const CreateAssetPlacementRecordDocument = gql`
	mutation CreateAssetPlacementRecord($input: AssetPlacementRecordInput!) {
		createAssetPlacementRecord(input: $input) {
			...AssetPlacementRecord
		}
	}
	${AssetPlacementRecordFragmentDoc}
`

export function useCreateAssetPlacementRecordMutation() {
	return Urql.useMutation<CreateAssetPlacementRecordMutation, CreateAssetPlacementRecordMutationVariables>(CreateAssetPlacementRecordDocument)
}
export const CreateBuildingDocument = gql`
	mutation CreateBuilding($input: BuildingInput!) {
		createBuilding(input: $input) {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useCreateBuildingMutation() {
	return Urql.useMutation<CreateBuildingMutation, CreateBuildingMutationVariables>(CreateBuildingDocument)
}
export const CreateBuildingFloorDocument = gql`
	mutation createBuildingFloor($buildingId: String!, $input: FloorInput!) {
		createBuildingFloor(buildingId: $buildingId, input: $input) {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useCreateBuildingFloorMutation() {
	return Urql.useMutation<CreateBuildingFloorMutation, CreateBuildingFloorMutationVariables>(CreateBuildingFloorDocument)
}
export const CreateDepartmentDocument = gql`
	mutation CreateDepartment($input: DepartmentInput!) {
		createDepartment(input: $input) {
			...Department
		}
	}
	${DepartmentFragmentDoc}
`

export function useCreateDepartmentMutation() {
	return Urql.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument)
}
export const CreateDeviceDocument = gql`
	mutation CreateDevice($input: DeviceInput!) {
		createDevice(input: $input) {
			...Device
		}
	}
	${DeviceFragmentDoc}
`

export function useCreateDeviceMutation() {
	return Urql.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument)
}
export const CreatePermissionsGroupDocument = gql`
	mutation CreatePermissionsGroup($input: PermissionsGroupInput!) {
		createPermissionsGroup(input: $input) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useCreatePermissionsGroupMutation() {
	return Urql.useMutation<CreatePermissionsGroupMutation, CreatePermissionsGroupMutationVariables>(CreatePermissionsGroupDocument)
}
export const CreateReaderDocument = gql`
	mutation CreateReader($input: ReaderInput!) {
		createReader(input: $input) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useCreateReaderMutation() {
	return Urql.useMutation<CreateReaderMutation, CreateReaderMutationVariables>(CreateReaderDocument)
}
export const CreateServiceDocument = gql`
	mutation createService($input: ServiceInput!) {
		createService(input: $input) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useCreateServiceMutation() {
	return Urql.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument)
}
export const CreateTagDocument = gql`
	mutation CreateTag($tagDecimalId: String!) {
		createTag(tagDecimalId: $tagDecimalId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useCreateTagMutation() {
	return Urql.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument)
}
export const CreateTileDocument = gql`
	mutation CreateTile($label: LabelInput!) {
		createTile(label: $label) {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useCreateTileMutation() {
	return Urql.useMutation<CreateTileMutation, CreateTileMutationVariables>(CreateTileDocument)
}
export const CreateUserDocument = gql`
	mutation CreateUser($input: UserInput!) {
		createUser(input: $input) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useCreateUserMutation() {
	return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument)
}
export const CreateUserGroupDocument = gql`
	mutation CreateUserGroup($input: UserGroupInput!) {
		createUserGroup(input: $input) {
			...UserGroup
		}
	}
	${UserGroupFragmentDoc}
`

export function useCreateUserGroupMutation() {
	return Urql.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CreateUserGroupDocument)
}
export const CreateUserRoleGroupDocument = gql`
	mutation CreateUserRoleGroup($input: UserRoleGroupInput!) {
		createUserRoleGroup(input: $input) {
			...UserRoleGroup
		}
	}
	${UserRoleGroupFragmentDoc}
`

export function useCreateUserRoleGroupMutation() {
	return Urql.useMutation<CreateUserRoleGroupMutation, CreateUserRoleGroupMutationVariables>(CreateUserRoleGroupDocument)
}
export const CreateUserTypeDocument = gql`
	mutation CreateUserType($input: UserTypeInput!) {
		createUserType(input: $input) {
			...UserType
		}
	}
	${UserTypeFragmentDoc}
`

export function useCreateUserTypeMutation() {
	return Urql.useMutation<CreateUserTypeMutation, CreateUserTypeMutationVariables>(CreateUserTypeDocument)
}
export const CreateZoneDocument = gql`
	mutation createZone($input: ZoneInput!) {
		createZone(input: $input) {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useCreateZoneMutation() {
	return Urql.useMutation<CreateZoneMutation, CreateZoneMutationVariables>(CreateZoneDocument)
}
export const CreateZoneGroupDocument = gql`
	mutation CreateZoneGroup($input: ZoneGroupInput!) {
		createZoneGroup(input: $input) {
			...ZoneGroup
		}
	}
	${ZoneGroupFragmentDoc}
`

export function useCreateZoneGroupMutation() {
	return Urql.useMutation<CreateZoneGroupMutation, CreateZoneGroupMutationVariables>(CreateZoneGroupDocument)
}
export const DeactivateAssetDocument = gql`
	mutation DeactivateAsset($assetId: String!) {
		deactivateAsset(assetId: $assetId) {
			...Asset
		}
	}
	${AssetFragmentDoc}
`

export function useDeactivateAssetMutation() {
	return Urql.useMutation<DeactivateAssetMutation, DeactivateAssetMutationVariables>(DeactivateAssetDocument)
}
export const DeactivateTagOfAssetDocument = gql`
	mutation DeactivateTagOfAsset($assetId: String!) {
		deactivateTagOfAsset(assetId: $assetId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useDeactivateTagOfAssetMutation() {
	return Urql.useMutation<DeactivateTagOfAssetMutation, DeactivateTagOfAssetMutationVariables>(DeactivateTagOfAssetDocument)
}
export const DeactivateTagOfUserDocument = gql`
	mutation deactivateTagOfUser($userId: String!) {
		deactivateTagOfUser(userId: $userId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useDeactivateTagOfUserMutation() {
	return Urql.useMutation<DeactivateTagOfUserMutation, DeactivateTagOfUserMutationVariables>(DeactivateTagOfUserDocument)
}
export const DeactivateUserDocument = gql`
	mutation DeactivateUser($userId: String!) {
		deactivateUser(userId: $userId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useDeactivateUserMutation() {
	return Urql.useMutation<DeactivateUserMutation, DeactivateUserMutationVariables>(DeactivateUserDocument)
}
export const DeleteBuildingDocument = gql`
	mutation deleteBuilding($buildingId: String!) {
		deleteBuilding(buildingId: $buildingId)
	}
`

export function useDeleteBuildingMutation() {
	return Urql.useMutation<DeleteBuildingMutation, DeleteBuildingMutationVariables>(DeleteBuildingDocument)
}
export const DeleteBuildingFloorDocument = gql`
	mutation deleteBuildingFloor($buildingId: String!, $floorId: String!) {
		deleteBuildingFloor(buildingId: $buildingId, floorId: $floorId) {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useDeleteBuildingFloorMutation() {
	return Urql.useMutation<DeleteBuildingFloorMutation, DeleteBuildingFloorMutationVariables>(DeleteBuildingFloorDocument)
}
export const DeleteEmbeddedZoneOnTileDocument = gql`
	mutation DeleteEmbeddedZoneOnTile($tileId: String!, $zoneId: String!, $level: Int!) {
		deleteEmbeddedZoneOnTile(tileId: $tileId, zoneId: $zoneId, level: $level) {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useDeleteEmbeddedZoneOnTileMutation() {
	return Urql.useMutation<DeleteEmbeddedZoneOnTileMutation, DeleteEmbeddedZoneOnTileMutationVariables>(DeleteEmbeddedZoneOnTileDocument)
}
export const DeleteServiceDocument = gql`
	mutation deleteService($serviceId: String!) {
		deleteService(serviceId: $serviceId)
	}
`

export function useDeleteServiceMutation() {
	return Urql.useMutation<DeleteServiceMutation, DeleteServiceMutationVariables>(DeleteServiceDocument)
}
export const EmbedZoneOnTileDocument = gql`
	mutation EmbedZoneOnTile($tileId: String!, $zoneId: String!, $level: Int!, $position: PositionInput!) {
		embedZoneOnTile(tileId: $tileId, zoneId: $zoneId, level: $level, position: $position) {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useEmbedZoneOnTileMutation() {
	return Urql.useMutation<EmbedZoneOnTileMutation, EmbedZoneOnTileMutationVariables>(EmbedZoneOnTileDocument)
}
export const ExtendUserRoleDocument = gql`
	mutation extendUserRole($userId: String!, $endAt: DateTime!) {
		extendUserRole(userId: $userId, endAt: $endAt) {
			...UserRole
		}
	}
	${UserRoleFragmentDoc}
`

export function useExtendUserRoleMutation() {
	return Urql.useMutation<ExtendUserRoleMutation, ExtendUserRoleMutationVariables>(ExtendUserRoleDocument)
}
export const FinishAssetAvatarUploadDocument = gql`
	mutation FinishAssetAvatarUpload($assetId: String!, $input: FinishUploadInput!) {
		finishAssetAvatarUpload(assetId: $assetId, input: $input) {
			...Picture
		}
	}
	${PictureFragmentDoc}
`

export function useFinishAssetAvatarUploadMutation() {
	return Urql.useMutation<FinishAssetAvatarUploadMutation, FinishAssetAvatarUploadMutationVariables>(FinishAssetAvatarUploadDocument)
}
export const FinishAvatarUploadDocument = gql`
	mutation FinishAvatarUpload($userId: String!, $input: FinishUploadInput!) {
		finishAvatarUpload(userId: $userId, input: $input) {
			...Picture
		}
	}
	${PictureFragmentDoc}
`

export function useFinishAvatarUploadMutation() {
	return Urql.useMutation<FinishAvatarUploadMutation, FinishAvatarUploadMutationVariables>(FinishAvatarUploadDocument)
}
export const FinishTilePictureUploadDocument = gql`
	mutation FinishTilePictureUpload($tileId: String!, $input: FinishUploadInput!) {
		finishTilePictureUpload(tileId: $tileId, input: $input) {
			...Picture
		}
	}
	${PictureFragmentDoc}
`

export function useFinishTilePictureUploadMutation() {
	return Urql.useMutation<FinishTilePictureUploadMutation, FinishTilePictureUploadMutationVariables>(FinishTilePictureUploadDocument)
}
export const FlushTagDocument = gql`
	mutation flushTag($userId: String!) {
		flushTag(userId: $userId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useFlushTagMutation() {
	return Urql.useMutation<FlushTagMutation, FlushTagMutationVariables>(FlushTagDocument)
}
export const FlushTagOfAssetDocument = gql`
	mutation FlushTagOfAsset($assetId: String!) {
		flushTagOfAsset(assetId: $assetId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useFlushTagOfAssetMutation() {
	return Urql.useMutation<FlushTagOfAssetMutation, FlushTagOfAssetMutationVariables>(FlushTagOfAssetDocument)
}
export const GenerateActiveUsersAtBuildingsReportDocument = gql`
	mutation generateActiveUsersAtBuildingsReport($input: ActiveUsersAtBuildingsReportInput!) {
		generateActiveUsersAtBuildingsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateActiveUsersAtBuildingsReportMutation() {
	return Urql.useMutation<GenerateActiveUsersAtBuildingsReportMutation, GenerateActiveUsersAtBuildingsReportMutationVariables>(GenerateActiveUsersAtBuildingsReportDocument)
}
export const GenerateActiveUsersAtZoneGroupsReportDocument = gql`
	mutation generateActiveUsersAtZoneGroupsReport($input: ActiveUsersAtZoneGroupsReportInput!) {
		generateActiveUsersAtZoneGroupsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateActiveUsersAtZoneGroupsReportMutation() {
	return Urql.useMutation<GenerateActiveUsersAtZoneGroupsReportMutation, GenerateActiveUsersAtZoneGroupsReportMutationVariables>(GenerateActiveUsersAtZoneGroupsReportDocument)
}
export const GenerateActiveUsersAtZonesReportDocument = gql`
	mutation generateActiveUsersAtZonesReport($input: ActiveUsersAtZonesReportInput!) {
		generateActiveUsersAtZonesReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateActiveUsersAtZonesReportMutation() {
	return Urql.useMutation<GenerateActiveUsersAtZonesReportMutation, GenerateActiveUsersAtZonesReportMutationVariables>(GenerateActiveUsersAtZonesReportDocument)
}
export const GenerateAssetLocationRecordsReportDocument = gql`
	mutation GenerateAssetLocationRecordsReport($input: AssetLocationRecordsReportInput!) {
		generateAssetLocationRecordsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateAssetLocationRecordsReportMutation() {
	return Urql.useMutation<GenerateAssetLocationRecordsReportMutation, GenerateAssetLocationRecordsReportMutationVariables>(GenerateAssetLocationRecordsReportDocument)
}
export const GenerateBuildingLocationRecordsReportDocument = gql`
	mutation GenerateBuildingLocationRecordsReport($input: BuildingLocationRecordsReportInput!) {
		generateBuildingLocationRecordsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateBuildingLocationRecordsReportMutation() {
	return Urql.useMutation<GenerateBuildingLocationRecordsReportMutation, GenerateBuildingLocationRecordsReportMutationVariables>(GenerateBuildingLocationRecordsReportDocument)
}
export const GenerateDashboardReportDocument = gql`
	mutation generateDashboardReport($input: ReportInput!) {
		generateDashboardReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateDashboardReportMutation() {
	return Urql.useMutation<GenerateDashboardReportMutation, GenerateDashboardReportMutationVariables>(GenerateDashboardReportDocument)
}
export const GenerateReadersStatusLogsReportDocument = gql`
	mutation generateReadersStatusLogsReport($input: ReadersStatusLogsReportInput!) {
		generateReadersStatusLogsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateReadersStatusLogsReportMutation() {
	return Urql.useMutation<GenerateReadersStatusLogsReportMutation, GenerateReadersStatusLogsReportMutationVariables>(GenerateReadersStatusLogsReportDocument)
}
export const GenerateUserAttendanceReportDocument = gql`
	mutation generateUserAttendanceReport($input: UserAttendanceReportInput!) {
		generateUserAttendanceReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateUserAttendanceReportMutation() {
	return Urql.useMutation<GenerateUserAttendanceReportMutation, GenerateUserAttendanceReportMutationVariables>(GenerateUserAttendanceReportDocument)
}
export const GenerateUserLocationRecordsReportDocument = gql`
	mutation GenerateUserLocationRecordsReport($input: UserLocationRecordsReportInput!) {
		generateUserLocationRecordsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateUserLocationRecordsReportMutation() {
	return Urql.useMutation<GenerateUserLocationRecordsReportMutation, GenerateUserLocationRecordsReportMutationVariables>(GenerateUserLocationRecordsReportDocument)
}
export const GenerateUserRestrictedEntriesReportDocument = gql`
	mutation generateUserRestrictedEntriesReport($input: UserRestrictedEntriesReportInput!) {
		generateUserRestrictedEntriesReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateUserRestrictedEntriesReportMutation() {
	return Urql.useMutation<GenerateUserRestrictedEntriesReportMutation, GenerateUserRestrictedEntriesReportMutationVariables>(GenerateUserRestrictedEntriesReportDocument)
}
export const GenerateVisitorReportDocument = gql`
	mutation generateVisitorReport($input: VisitorReportInput!) {
		generateVisitorReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateVisitorReportMutation() {
	return Urql.useMutation<GenerateVisitorReportMutation, GenerateVisitorReportMutationVariables>(GenerateVisitorReportDocument)
}
export const GenerateZoneGroupLocationRecordsReportDocument = gql`
	mutation GenerateZoneGroupLocationRecordsReport($input: ZoneGroupLocationRecordsReportInput!) {
		generateZoneGroupLocationRecordsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateZoneGroupLocationRecordsReportMutation() {
	return Urql.useMutation<GenerateZoneGroupLocationRecordsReportMutation, GenerateZoneGroupLocationRecordsReportMutationVariables>(GenerateZoneGroupLocationRecordsReportDocument)
}
export const GenerateZoneGroupRestrictedEntriesReportDocument = gql`
	mutation generateZoneGroupRestrictedEntriesReport($input: ZoneGroupRestrictedEntriesReportInput!) {
		generateZoneGroupRestrictedEntriesReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateZoneGroupRestrictedEntriesReportMutation() {
	return Urql.useMutation<GenerateZoneGroupRestrictedEntriesReportMutation, GenerateZoneGroupRestrictedEntriesReportMutationVariables>(GenerateZoneGroupRestrictedEntriesReportDocument)
}
export const GenerateZoneLocationRecordsReportDocument = gql`
	mutation GenerateZoneLocationRecordsReport($input: ZoneLocationRecordsReportInput!) {
		generateZoneLocationRecordsReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateZoneLocationRecordsReportMutation() {
	return Urql.useMutation<GenerateZoneLocationRecordsReportMutation, GenerateZoneLocationRecordsReportMutationVariables>(GenerateZoneLocationRecordsReportDocument)
}
export const GenerateZoneRestrictedEntriesReportDocument = gql`
	mutation generateZoneRestrictedEntriesReport($input: ZoneRestrictedEntriesReportInput!) {
		generateZoneRestrictedEntriesReport(input: $input) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useGenerateZoneRestrictedEntriesReportMutation() {
	return Urql.useMutation<GenerateZoneRestrictedEntriesReportMutation, GenerateZoneRestrictedEntriesReportMutationVariables>(GenerateZoneRestrictedEntriesReportDocument)
}
export const LoginDocument = gql`
	mutation Login($username: String!, $password: String!) {
		login(username: $username, password: $password) {
			...UserAuthResponse
		}
	}
	${UserAuthResponseFragmentDoc}
`

export function useLoginMutation() {
	return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument)
}
export const LogoutDocument = gql`
	mutation Logout {
		logout
	}
`

export function useLogoutMutation() {
	return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument)
}
export const RemoveCurrentUserRoleDocument = gql`
	mutation removeCurrentUserRole($userId: String!) {
		removeCurrentUserRole(userId: $userId)
	}
`

export function useRemoveCurrentUserRoleMutation() {
	return Urql.useMutation<RemoveCurrentUserRoleMutation, RemoveCurrentUserRoleMutationVariables>(RemoveCurrentUserRoleDocument)
}
export const RemoveDepartmentHeadDocument = gql`
	mutation RemoveDepartmentHead($departmentId: String!) {
		removeDepartmentHead(departmentId: $departmentId)
	}
`

export function useRemoveDepartmentHeadMutation() {
	return Urql.useMutation<RemoveDepartmentHeadMutation, RemoveDepartmentHeadMutationVariables>(RemoveDepartmentHeadDocument)
}
export const RemoveReaderFromZoneDocument = gql`
	mutation RemoveReaderFromZone($zoneId: String!, $readerId: String!) {
		removeReaderFromZone(zoneId: $zoneId, readerId: $readerId)
	}
`

export function useRemoveReaderFromZoneMutation() {
	return Urql.useMutation<RemoveReaderFromZoneMutation, RemoveReaderFromZoneMutationVariables>(RemoveReaderFromZoneDocument)
}
export const SendEmailVerificationSecretCodeDocument = gql`
	mutation sendEmailVerificationSecretCode {
		sendEmailVerificationSecretCode
	}
`

export function useSendEmailVerificationSecretCodeMutation() {
	return Urql.useMutation<SendEmailVerificationSecretCodeMutation, SendEmailVerificationSecretCodeMutationVariables>(SendEmailVerificationSecretCodeDocument)
}
export const SignAssetAvatarUploadDocument = gql`
	mutation SignAssetAvatarUpload($assetId: String!, $format: String!) {
		signAssetAvatarUpload(assetId: $assetId, format: $format) {
			signedUrl
			key
		}
	}
`

export function useSignAssetAvatarUploadMutation() {
	return Urql.useMutation<SignAssetAvatarUploadMutation, SignAssetAvatarUploadMutationVariables>(SignAssetAvatarUploadDocument)
}
export const SignAvatarUploadDocument = gql`
	mutation SignAvatarUpload($userId: String!, $format: String!) {
		signAvatarUpload(userId: $userId, format: $format) {
			signedUrl
			key
		}
	}
`

export function useSignAvatarUploadMutation() {
	return Urql.useMutation<SignAvatarUploadMutation, SignAvatarUploadMutationVariables>(SignAvatarUploadDocument)
}
export const SignTilePictureUploadDocument = gql`
	mutation SignTilePictureUpload($tileId: String!, $format: String!) {
		signTilePictureUpload(tileId: $tileId, format: $format) {
			signedUrl
			key
		}
	}
`

export function useSignTilePictureUploadMutation() {
	return Urql.useMutation<SignTilePictureUploadMutation, SignTilePictureUploadMutationVariables>(SignTilePictureUploadDocument)
}
export const UpdateAlertFlowDocument = gql`
	mutation updateAlertFlow($alertFlowId: String!, $input: AlertFlowInput!) {
		updateAlertFlow(alertFlowId: $alertFlowId, input: $input) {
			...AlertFlow
		}
	}
	${AlertFlowFragmentDoc}
`

export function useUpdateAlertFlowMutation() {
	return Urql.useMutation<UpdateAlertFlowMutation, UpdateAlertFlowMutationVariables>(UpdateAlertFlowDocument)
}
export const UpdateAlertTargetGroupDocument = gql`
	mutation updateAlertTargetGroup($alertTargetGroupId: String!, $input: AlertTargetGroupInput!) {
		updateAlertTargetGroup(alertTargetGroupId: $alertTargetGroupId, input: $input) {
			...AlertTargetGroup
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useUpdateAlertTargetGroupMutation() {
	return Urql.useMutation<UpdateAlertTargetGroupMutation, UpdateAlertTargetGroupMutationVariables>(UpdateAlertTargetGroupDocument)
}
export const UpdateAssetCategoryDocument = gql`
	mutation UpdateAssetCategory($assetCategoryId: String!, $input: AssetCategoryInput!) {
		updateAssetCategory(assetCategoryId: $assetCategoryId, input: $input) {
			...AssetCategory
		}
	}
	${AssetCategoryFragmentDoc}
`

export function useUpdateAssetCategoryMutation() {
	return Urql.useMutation<UpdateAssetCategoryMutation, UpdateAssetCategoryMutationVariables>(UpdateAssetCategoryDocument)
}
export const UpdateBuildingDocument = gql`
	mutation updateBuilding($buildingId: String!, $input: BuildingInput!) {
		updateBuilding(buildingId: $buildingId, input: $input) {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useUpdateBuildingMutation() {
	return Urql.useMutation<UpdateBuildingMutation, UpdateBuildingMutationVariables>(UpdateBuildingDocument)
}
export const UpdateBuildingFloorDocument = gql`
	mutation updateBuildingFloor($buildingId: String!, $floorId: String!, $input: FloorInput!) {
		updateBuildingFloor(buildingId: $buildingId, floorId: $floorId, input: $input) {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useUpdateBuildingFloorMutation() {
	return Urql.useMutation<UpdateBuildingFloorMutation, UpdateBuildingFloorMutationVariables>(UpdateBuildingFloorDocument)
}
export const UpdateDeviceDocument = gql`
	mutation UpdateDevice($deviceId: String!, $input: DeviceInput!) {
		updateDevice(deviceId: $deviceId, input: $input) {
			...Device
		}
	}
	${DeviceFragmentDoc}
`

export function useUpdateDeviceMutation() {
	return Urql.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument)
}
export const UpdateReaderDocument = gql`
	mutation updateReader($readerId: String!, $input: ReaderInput!) {
		updateReader(readerId: $readerId, input: $input) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useUpdateReaderMutation() {
	return Urql.useMutation<UpdateReaderMutation, UpdateReaderMutationVariables>(UpdateReaderDocument)
}
export const UpdateServiceDocument = gql`
	mutation updateService($serviceId: String!, $input: ServiceInput!) {
		updateService(serviceId: $serviceId, input: $input) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useUpdateServiceMutation() {
	return Urql.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument)
}
export const UpdateUserDocument = gql`
	mutation UpdateUser($userId: String!, $input: UpdateUserInput!) {
		updateUser(userId: $userId, input: $input) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useUpdateUserMutation() {
	return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument)
}
export const UpdateUserGroupDocument = gql`
	mutation UpdateUserGroup($userGroupId: String!, $input: UserGroupInput!) {
		updateUserGroup(userGroupId: $userGroupId, input: $input) {
			...UserGroup
		}
	}
	${UserGroupFragmentDoc}
`

export function useUpdateUserGroupMutation() {
	return Urql.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(UpdateUserGroupDocument)
}
export const UpdateUserPermissionsGroupsDocument = gql`
	mutation updateUserPermissionsGroups($userId: String!, $permissionsGroupIds: [String!]!) {
		updateUserPermissionsGroups(userId: $userId, permissionsGroupIds: $permissionsGroupIds) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useUpdateUserPermissionsGroupsMutation() {
	return Urql.useMutation<UpdateUserPermissionsGroupsMutation, UpdateUserPermissionsGroupsMutationVariables>(UpdateUserPermissionsGroupsDocument)
}
export const UpdateUserRoleGroupDocument = gql`
	mutation updateUserRoleGroup($userRoleGroupId: String!, $input: UpdateUserRoleGroupInput!) {
		updateUserRoleGroup(userRoleGroupId: $userRoleGroupId, input: $input) {
			...UserRoleGroup
		}
	}
	${UserRoleGroupFragmentDoc}
`

export function useUpdateUserRoleGroupMutation() {
	return Urql.useMutation<UpdateUserRoleGroupMutation, UpdateUserRoleGroupMutationVariables>(UpdateUserRoleGroupDocument)
}
export const UpdateVisitorDetailsDocument = gql`
	mutation UpdateVisitorDetails($userId: String!, $input: VisitorDetailsInput!) {
		updateVisitorDetails(userId: $userId, input: $input) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useUpdateVisitorDetailsMutation() {
	return Urql.useMutation<UpdateVisitorDetailsMutation, UpdateVisitorDetailsMutationVariables>(UpdateVisitorDetailsDocument)
}
export const UpdateZoneDocument = gql`
	mutation updateZone($zoneId: String!, $input: ZoneInput!) {
		updateZone(zoneId: $zoneId, input: $input) {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useUpdateZoneMutation() {
	return Urql.useMutation<UpdateZoneMutation, UpdateZoneMutationVariables>(UpdateZoneDocument)
}
export const UpdateZoneGroupDocument = gql`
	mutation updateZoneGroup($zoneGroupId: String!, $input: ZoneGroupInput!) {
		updateZoneGroup(zoneGroupId: $zoneGroupId, input: $input) {
			...ZoneGroup
		}
	}
	${ZoneGroupFragmentDoc}
`

export function useUpdateZoneGroupMutation() {
	return Urql.useMutation<UpdateZoneGroupMutation, UpdateZoneGroupMutationVariables>(UpdateZoneGroupDocument)
}
export const VerifyEmailWithSecretCodeDocument = gql`
	mutation verifyEmailWithSecretCode($code: String!) {
		verifyEmailWithSecretCode(code: $code)
	}
`

export function useVerifyEmailWithSecretCodeMutation() {
	return Urql.useMutation<VerifyEmailWithSecretCodeMutation, VerifyEmailWithSecretCodeMutationVariables>(VerifyEmailWithSecretCodeDocument)
}
export const ActiveAssetIdsDocument = gql`
	query activeAssetIds {
		activeAssetIds {
			assetIds
			zoneWiseAssets {
				zoneId
				assetIds
			}
		}
	}
`

export function useActiveAssetIdsQuery(options?: Omit<Urql.UseQueryArgs<ActiveAssetIdsQueryVariables>, "query">) {
	return Urql.useQuery<ActiveAssetIdsQuery, ActiveAssetIdsQueryVariables>({ query: ActiveAssetIdsDocument, ...options })
}
export const ActiveAssetIdsInLastIntervalDocument = gql`
	query activeAssetIdsInLastInterval($intervalInMinutes: Float!) {
		activeAssetIdsInLastInterval(intervalInMinutes: $intervalInMinutes)
	}
`

export function useActiveAssetIdsInLastIntervalQuery(options: Omit<Urql.UseQueryArgs<ActiveAssetIdsInLastIntervalQueryVariables>, "query">) {
	return Urql.useQuery<ActiveAssetIdsInLastIntervalQuery, ActiveAssetIdsInLastIntervalQueryVariables>({ query: ActiveAssetIdsInLastIntervalDocument, ...options })
}
export const ActiveAssetsDocument = gql`
	query activeAssets($keyword: String!, $status: AssetStatus, $pagination: PaginatedInput!) {
		activeAssets(keyword: $keyword, status: $status, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assets {
				...Asset
			}
		}
	}
	${AssetFragmentDoc}
`

export function useActiveAssetsQuery(options: Omit<Urql.UseQueryArgs<ActiveAssetsQueryVariables>, "query">) {
	return Urql.useQuery<ActiveAssetsQuery, ActiveAssetsQueryVariables>({ query: ActiveAssetsDocument, ...options })
}
export const ActiveAssetsInLastIntervalDocument = gql`
	query activeAssetsInLastInterval($keyword: String!, $intervalInMinutes: Float!, $status: AssetStatus, $pagination: PaginatedInput!) {
		activeAssetsInLastInterval(keyword: $keyword, intervalInMinutes: $intervalInMinutes, status: $status, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assets {
				...Asset
			}
		}
	}
	${AssetFragmentDoc}
`

export function useActiveAssetsInLastIntervalQuery(options: Omit<Urql.UseQueryArgs<ActiveAssetsInLastIntervalQueryVariables>, "query">) {
	return Urql.useQuery<ActiveAssetsInLastIntervalQuery, ActiveAssetsInLastIntervalQueryVariables>({ query: ActiveAssetsInLastIntervalDocument, ...options })
}
export const ActiveAttendanceOfUserDocument = gql`
	query activeAttendanceOfUser($userId: String!) {
		activeAttendanceOfUser(userId: $userId) {
			...UserAttendanceRecord
		}
	}
	${UserAttendanceRecordFragmentDoc}
`

export function useActiveAttendanceOfUserQuery(options: Omit<Urql.UseQueryArgs<ActiveAttendanceOfUserQueryVariables>, "query">) {
	return Urql.useQuery<ActiveAttendanceOfUserQuery, ActiveAttendanceOfUserQueryVariables>({ query: ActiveAttendanceOfUserDocument, ...options })
}
export const ActiveRestrictedEntryIdsDocument = gql`
	query activeRestrictedEntryIds {
		activeRestrictedEntryIds {
			recordIds
			zoneWiseRecordIds {
				zoneId
				recordIds
			}
		}
	}
`

export function useActiveRestrictedEntryIdsQuery(options?: Omit<Urql.UseQueryArgs<ActiveRestrictedEntryIdsQueryVariables>, "query">) {
	return Urql.useQuery<ActiveRestrictedEntryIdsQuery, ActiveRestrictedEntryIdsQueryVariables>({ query: ActiveRestrictedEntryIdsDocument, ...options })
}
export const ActiveRestrictedUserLocationRecordsDocument = gql`
	query activeRestrictedUserLocationRecords($pagination: PaginatedInput!) {
		activeRestrictedUserLocationRecords(pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			userLocationRecords {
				...UserLocationRecord
			}
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useActiveRestrictedUserLocationRecordsQuery(options: Omit<Urql.UseQueryArgs<ActiveRestrictedUserLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<ActiveRestrictedUserLocationRecordsQuery, ActiveRestrictedUserLocationRecordsQueryVariables>({ query: ActiveRestrictedUserLocationRecordsDocument, ...options })
}
export const ActiveUserIdsDocument = gql`
	query activeUserIds($isRestricted: Boolean) {
		activeUserIds(isRestricted: $isRestricted) {
			userIds
			zoneWiseUsers {
				zoneId
				userIds
			}
		}
	}
`

export function useActiveUserIdsQuery(options?: Omit<Urql.UseQueryArgs<ActiveUserIdsQueryVariables>, "query">) {
	return Urql.useQuery<ActiveUserIdsQuery, ActiveUserIdsQueryVariables>({ query: ActiveUserIdsDocument, ...options })
}
export const ActiveUserIdsInLastIntervalDocument = gql`
	query activeUserIdsInLastInterval($intervalInMinutes: Float!) {
		activeUserIdsInLastInterval(intervalInMinutes: $intervalInMinutes)
	}
`

export function useActiveUserIdsInLastIntervalQuery(options: Omit<Urql.UseQueryArgs<ActiveUserIdsInLastIntervalQueryVariables>, "query">) {
	return Urql.useQuery<ActiveUserIdsInLastIntervalQuery, ActiveUserIdsInLastIntervalQueryVariables>({ query: ActiveUserIdsInLastIntervalDocument, ...options })
}
export const ActiveUsersDocument = gql`
	query activeUsers($keyword: String!, $typeId: String, $roleType: UserRoleTypes, $status: UserStatus, $pagination: PaginatedInput!) {
		activeUsers(keyword: $keyword, typeId: $typeId, roleType: $roleType, status: $status, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useActiveUsersQuery(options: Omit<Urql.UseQueryArgs<ActiveUsersQueryVariables>, "query">) {
	return Urql.useQuery<ActiveUsersQuery, ActiveUsersQueryVariables>({ query: ActiveUsersDocument, ...options })
}
export const ActiveUsersInLastIntervalDocument = gql`
	query activeUsersInLastInterval($keyword: String!, $intervalInMinutes: Float!, $typeId: String, $roleType: UserRoleTypes, $status: UserStatus, $pagination: PaginatedInput!) {
		activeUsersInLastInterval(keyword: $keyword, intervalInMinutes: $intervalInMinutes, typeId: $typeId, roleType: $roleType, status: $status, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useActiveUsersInLastIntervalQuery(options: Omit<Urql.UseQueryArgs<ActiveUsersInLastIntervalQueryVariables>, "query">) {
	return Urql.useQuery<ActiveUsersInLastIntervalQuery, ActiveUsersInLastIntervalQueryVariables>({ query: ActiveUsersInLastIntervalDocument, ...options })
}
export const AlertByIdDocument = gql`
	query alertById($alertId: String!) {
		alertById(alertId: $alertId) {
			...Alert
		}
	}
	${AlertFragmentDoc}
`

export function useAlertByIdQuery(options: Omit<Urql.UseQueryArgs<AlertByIdQueryVariables>, "query">) {
	return Urql.useQuery<AlertByIdQuery, AlertByIdQueryVariables>({ query: AlertByIdDocument, ...options })
}
export const AlertFlowByIdDocument = gql`
	query alertFlowById($alertFlowId: String!) {
		alertFlowById(alertFlowId: $alertFlowId) {
			...AlertFlow
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAlertFlowByIdQuery(options: Omit<Urql.UseQueryArgs<AlertFlowByIdQueryVariables>, "query">) {
	return Urql.useQuery<AlertFlowByIdQuery, AlertFlowByIdQueryVariables>({ query: AlertFlowByIdDocument, ...options })
}
export const AlertFlowsDocument = gql`
	query alertFlows {
		alertFlows {
			...AlertFlow
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAlertFlowsQuery(options?: Omit<Urql.UseQueryArgs<AlertFlowsQueryVariables>, "query">) {
	return Urql.useQuery<AlertFlowsQuery, AlertFlowsQueryVariables>({ query: AlertFlowsDocument, ...options })
}
export const AlertFlowsByKeywordDocument = gql`
	query alertFlowsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		alertFlowsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertFlows {
				...AlertFlow
			}
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAlertFlowsByKeywordQuery(options: Omit<Urql.UseQueryArgs<AlertFlowsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<AlertFlowsByKeywordQuery, AlertFlowsByKeywordQueryVariables>({ query: AlertFlowsByKeywordDocument, ...options })
}
export const AlertFlowsOfAlertDocument = gql`
	query AlertFlowsOfAlert($alertId: String!) {
		alertFlowsOfAlert(alertId: $alertId) {
			...AlertFlow
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAlertFlowsOfAlertQuery(options: Omit<Urql.UseQueryArgs<AlertFlowsOfAlertQueryVariables>, "query">) {
	return Urql.useQuery<AlertFlowsOfAlertQuery, AlertFlowsOfAlertQueryVariables>({ query: AlertFlowsOfAlertDocument, ...options })
}
export const AlertSourceOfAlertFlowDocument = gql`
	query alertSourceOfAlertFlow($alertFlowId: String!) {
		alertSourceOfAlertFlow(alertFlowId: $alertFlowId) {
			type
			zoneIds
			zones {
				...Zone
			}
			zoneGroupIds
			zoneGroups {
				...ZoneGroup
			}
			userIds
			users {
				...User
			}
			userGroupIds
			userGroups {
				...UserGroup
			}
			userTypeIds
			userTypes {
				...UserType
			}
			userRoleTypes
			departmentIds
			departments {
				...Department
			}
			assetIds
			assets {
				...Asset
			}
			assetCategoryIds
			assetCategories {
				...AssetCategory
			}
		}
	}
	${ZoneFragmentDoc}
	${ZoneGroupFragmentDoc}
	${UserFragmentDoc}
	${UserGroupFragmentDoc}
	${UserTypeFragmentDoc}
	${DepartmentFragmentDoc}
	${AssetFragmentDoc}
	${AssetCategoryFragmentDoc}
`

export function useAlertSourceOfAlertFlowQuery(options: Omit<Urql.UseQueryArgs<AlertSourceOfAlertFlowQueryVariables>, "query">) {
	return Urql.useQuery<AlertSourceOfAlertFlowQuery, AlertSourceOfAlertFlowQueryVariables>({ query: AlertSourceOfAlertFlowDocument, ...options })
}
export const AlertTargetGroupByIdDocument = gql`
	query alertTargetGroupById($alertTargetGroupId: String!) {
		alertTargetGroupById(alertTargetGroupId: $alertTargetGroupId) {
			...AlertTargetGroup
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useAlertTargetGroupByIdQuery(options: Omit<Urql.UseQueryArgs<AlertTargetGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<AlertTargetGroupByIdQuery, AlertTargetGroupByIdQueryVariables>({ query: AlertTargetGroupByIdDocument, ...options })
}
export const AlertTargetGroupsDocument = gql`
	query alertTargetGroups {
		alertTargetGroups {
			...AlertTargetGroup
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useAlertTargetGroupsQuery(options?: Omit<Urql.UseQueryArgs<AlertTargetGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AlertTargetGroupsQuery, AlertTargetGroupsQueryVariables>({ query: AlertTargetGroupsDocument, ...options })
}
export const AlertTargetGroupsByKeywordDocument = gql`
	query alertTargetGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		alertTargetGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertTargetGroups {
				...AlertTargetGroup
			}
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useAlertTargetGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<AlertTargetGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<AlertTargetGroupsByKeywordQuery, AlertTargetGroupsByKeywordQueryVariables>({ query: AlertTargetGroupsByKeywordDocument, ...options })
}
export const AlertTargetGroupsOfAlertFlowDocument = gql`
	query AlertTargetGroupsOfAlertFlow($alertFlowId: String!) {
		alertTargetGroupsOfAlertFlow(alertFlowId: $alertFlowId) {
			...AlertTargetGroup
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useAlertTargetGroupsOfAlertFlowQuery(options: Omit<Urql.UseQueryArgs<AlertTargetGroupsOfAlertFlowQueryVariables>, "query">) {
	return Urql.useQuery<AlertTargetGroupsOfAlertFlowQuery, AlertTargetGroupsOfAlertFlowQueryVariables>({ query: AlertTargetGroupsOfAlertFlowDocument, ...options })
}
export const AlertedUsersOfAlertDocument = gql`
	query AlertedUsersOfAlert($alertId: String!) {
		alertedUsersOfAlert(alertId: $alertId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useAlertedUsersOfAlertQuery(options: Omit<Urql.UseQueryArgs<AlertedUsersOfAlertQueryVariables>, "query">) {
	return Urql.useQuery<AlertedUsersOfAlertQuery, AlertedUsersOfAlertQueryVariables>({ query: AlertedUsersOfAlertDocument, ...options })
}
export const AlertsByFilterDocument = gql`
	query alertsByFilter($filter: AlertsFilterInput!, $pagination: PaginatedInput!) {
		alertsByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alerts {
				...Alert
			}
		}
	}
	${AlertFragmentDoc}
`

export function useAlertsByFilterQuery(options: Omit<Urql.UseQueryArgs<AlertsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<AlertsByFilterQuery, AlertsByFilterQueryVariables>({ query: AlertsByFilterDocument, ...options })
}
export const AllAlertFlowsDocument = gql`
	query allAlertFlows($pagination: PaginatedInput!) {
		allAlertFlows(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertFlows {
				...AlertFlow
			}
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAllAlertFlowsQuery(options: Omit<Urql.UseQueryArgs<AllAlertFlowsQueryVariables>, "query">) {
	return Urql.useQuery<AllAlertFlowsQuery, AllAlertFlowsQueryVariables>({ query: AllAlertFlowsDocument, ...options })
}
export const AllAlertTargetGroupsDocument = gql`
	query allAlertTargetGroups($pagination: PaginatedInput!) {
		allAlertTargetGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertTargetGroups {
				...AlertTargetGroup
			}
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useAllAlertTargetGroupsQuery(options: Omit<Urql.UseQueryArgs<AllAlertTargetGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllAlertTargetGroupsQuery, AllAlertTargetGroupsQueryVariables>({ query: AllAlertTargetGroupsDocument, ...options })
}
export const AllAlertsDocument = gql`
	query allAlerts($pagination: PaginatedInput!) {
		allAlerts(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alerts {
				...Alert
			}
		}
	}
	${AlertFragmentDoc}
`

export function useAllAlertsQuery(options: Omit<Urql.UseQueryArgs<AllAlertsQueryVariables>, "query">) {
	return Urql.useQuery<AllAlertsQuery, AllAlertsQueryVariables>({ query: AllAlertsDocument, ...options })
}
export const AllAssetCategoriesDocument = gql`
	query AllAssetCategories($pagination: PaginatedInput!) {
		allAssetCategories(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assetCategories {
				...AssetCategory
			}
		}
	}
	${AssetCategoryFragmentDoc}
`

export function useAllAssetCategoriesQuery(options: Omit<Urql.UseQueryArgs<AllAssetCategoriesQueryVariables>, "query">) {
	return Urql.useQuery<AllAssetCategoriesQuery, AllAssetCategoriesQueryVariables>({ query: AllAssetCategoriesDocument, ...options })
}
export const AllBuildingsDocument = gql`
	query AllBuildings($pagination: PaginatedInput!) {
		allBuildings(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			buildings {
				...Building
			}
		}
	}
	${BuildingFragmentDoc}
`

export function useAllBuildingsQuery(options: Omit<Urql.UseQueryArgs<AllBuildingsQueryVariables>, "query">) {
	return Urql.useQuery<AllBuildingsQuery, AllBuildingsQueryVariables>({ query: AllBuildingsDocument, ...options })
}
export const AllDepartmentsDocument = gql`
	query allDepartments($pagination: PaginatedInput!) {
		allDepartments(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			departments {
				...Department
			}
		}
	}
	${DepartmentFragmentDoc}
`

export function useAllDepartmentsQuery(options: Omit<Urql.UseQueryArgs<AllDepartmentsQueryVariables>, "query">) {
	return Urql.useQuery<AllDepartmentsQuery, AllDepartmentsQueryVariables>({ query: AllDepartmentsDocument, ...options })
}
export const AllDevicesDocument = gql`
	query AllDevices($pagination: PaginatedInput!) {
		allDevices(pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			devices {
				...Device
			}
		}
	}
	${DeviceFragmentDoc}
`

export function useAllDevicesQuery(options: Omit<Urql.UseQueryArgs<AllDevicesQueryVariables>, "query">) {
	return Urql.useQuery<AllDevicesQuery, AllDevicesQueryVariables>({ query: AllDevicesDocument, ...options })
}
export const AllPermissionsGroupsDocument = gql`
	query AllPermissionsGroups($pagination: PaginatedInput!) {
		allPermissionsGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			permissionsGroups {
				...PermissionsGroup
			}
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useAllPermissionsGroupsQuery(options: Omit<Urql.UseQueryArgs<AllPermissionsGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllPermissionsGroupsQuery, AllPermissionsGroupsQueryVariables>({ query: AllPermissionsGroupsDocument, ...options })
}
export const AllReportsDocument = gql`
	query AllReports($pagination: PaginatedInput!) {
		allReports(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			reports {
				...Report
			}
		}
	}
	${ReportFragmentDoc}
`

export function useAllReportsQuery(options: Omit<Urql.UseQueryArgs<AllReportsQueryVariables>, "query">) {
	return Urql.useQuery<AllReportsQuery, AllReportsQueryVariables>({ query: AllReportsDocument, ...options })
}
export const AllServicesDocument = gql`
	query allServices {
		allServices {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useAllServicesQuery(options?: Omit<Urql.UseQueryArgs<AllServicesQueryVariables>, "query">) {
	return Urql.useQuery<AllServicesQuery, AllServicesQueryVariables>({ query: AllServicesDocument, ...options })
}
export const AllTagsDocument = gql`
	query AllTags($pagination: PaginatedInput!) {
		allTags(pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			tags {
				...Tag
			}
		}
	}
	${TagFragmentDoc}
`

export function useAllTagsQuery(options: Omit<Urql.UseQueryArgs<AllTagsQueryVariables>, "query">) {
	return Urql.useQuery<AllTagsQuery, AllTagsQueryVariables>({ query: AllTagsDocument, ...options })
}
export const AllUserGroupsDocument = gql`
	query AllUserGroups($pagination: PaginatedInput!) {
		allUserGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userGroups {
				...UserGroup
			}
		}
	}
	${UserGroupFragmentDoc}
`

export function useAllUserGroupsQuery(options: Omit<Urql.UseQueryArgs<AllUserGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>({ query: AllUserGroupsDocument, ...options })
}
export const AllUserRoleGroupsDocument = gql`
	query AllUserRoleGroups($pagination: PaginatedInput!) {
		allUserRoleGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userRoleGroups {
				...UserRoleGroup
			}
		}
	}
	${UserRoleGroupFragmentDoc}
`

export function useAllUserRoleGroupsQuery(options: Omit<Urql.UseQueryArgs<AllUserRoleGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllUserRoleGroupsQuery, AllUserRoleGroupsQueryVariables>({ query: AllUserRoleGroupsDocument, ...options })
}
export const AllUserTypesDocument = gql`
	query AllUserTypes($pagination: PaginatedInput!) {
		allUserTypes(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userTypes {
				...UserType
			}
		}
	}
	${UserTypeFragmentDoc}
`

export function useAllUserTypesQuery(options: Omit<Urql.UseQueryArgs<AllUserTypesQueryVariables>, "query">) {
	return Urql.useQuery<AllUserTypesQuery, AllUserTypesQueryVariables>({ query: AllUserTypesDocument, ...options })
}
export const AllUsersDocument = gql`
	query AllUsers($pagination: PaginatedInput!) {
		allUsers(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useAllUsersQuery(options: Omit<Urql.UseQueryArgs<AllUsersQueryVariables>, "query">) {
	return Urql.useQuery<AllUsersQuery, AllUsersQueryVariables>({ query: AllUsersDocument, ...options })
}
export const AllZoneGroupsDocument = gql`
	query AllZoneGroups($pagination: PaginatedInput!) {
		allZoneGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zoneGroups {
				...ZoneGroup
			}
		}
	}
	${ZoneGroupFragmentDoc}
`

export function useAllZoneGroupsQuery(options: Omit<Urql.UseQueryArgs<AllZoneGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllZoneGroupsQuery, AllZoneGroupsQueryVariables>({ query: AllZoneGroupsDocument, ...options })
}
export const AllZonesDocument = gql`
	query AllZones($pagination: PaginatedInput!) {
		allZones(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zones {
				...Zone
			}
		}
	}
	${ZoneFragmentDoc}
`

export function useAllZonesQuery(options: Omit<Urql.UseQueryArgs<AllZonesQueryVariables>, "query">) {
	return Urql.useQuery<AllZonesQuery, AllZonesQueryVariables>({ query: AllZonesDocument, ...options })
}
export const AssetByIdDocument = gql`
	query AssetById($assetId: String!) {
		assetById(assetId: $assetId) {
			...Asset
		}
	}
	${AssetFragmentDoc}
`

export function useAssetByIdQuery(options: Omit<Urql.UseQueryArgs<AssetByIdQueryVariables>, "query">) {
	return Urql.useQuery<AssetByIdQuery, AssetByIdQueryVariables>({ query: AssetByIdDocument, ...options })
}
export const AssetCategoriesDocument = gql`
	query AssetCategories {
		assetCategories {
			...AssetCategory
		}
	}
	${AssetCategoryFragmentDoc}
`

export function useAssetCategoriesQuery(options?: Omit<Urql.UseQueryArgs<AssetCategoriesQueryVariables>, "query">) {
	return Urql.useQuery<AssetCategoriesQuery, AssetCategoriesQueryVariables>({ query: AssetCategoriesDocument, ...options })
}
export const AssetCategoriesByFilterDocument = gql`
	query AssetCategoriesByFilter($filter: AssetCategoriesFilterInput!, $pagination: PaginatedInput!) {
		assetCategoriesByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assetCategories {
				...AssetCategory
			}
		}
	}
	${AssetCategoryFragmentDoc}
`

export function useAssetCategoriesByFilterQuery(options: Omit<Urql.UseQueryArgs<AssetCategoriesByFilterQueryVariables>, "query">) {
	return Urql.useQuery<AssetCategoriesByFilterQuery, AssetCategoriesByFilterQueryVariables>({ query: AssetCategoriesByFilterDocument, ...options })
}
export const AssetCategoriesByKeywordDocument = gql`
	query AssetCategoriesByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		assetCategoriesByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assetCategories {
				...AssetCategory
			}
		}
	}
	${AssetCategoryFragmentDoc}
`

export function useAssetCategoriesByKeywordQuery(options: Omit<Urql.UseQueryArgs<AssetCategoriesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<AssetCategoriesByKeywordQuery, AssetCategoriesByKeywordQueryVariables>({ query: AssetCategoriesByKeywordDocument, ...options })
}
export const AssetCategoryByIdDocument = gql`
	query assetCategoryById($assetCategoryId: String!) {
		assetCategoryById(assetCategoryId: $assetCategoryId) {
			...AssetCategory
		}
	}
	${AssetCategoryFragmentDoc}
`

export function useAssetCategoryByIdQuery(options: Omit<Urql.UseQueryArgs<AssetCategoryByIdQueryVariables>, "query">) {
	return Urql.useQuery<AssetCategoryByIdQuery, AssetCategoryByIdQueryVariables>({ query: AssetCategoryByIdDocument, ...options })
}
export const AssetCurrentLocationRecordDocument = gql`
	query AssetCurrentLocationRecord($assetId: String!) {
		assetCurrentLocationRecord(assetId: $assetId) {
			...AssetLocationRecord
		}
	}
	${AssetLocationRecordFragmentDoc}
`

export function useAssetCurrentLocationRecordQuery(options: Omit<Urql.UseQueryArgs<AssetCurrentLocationRecordQueryVariables>, "query">) {
	return Urql.useQuery<AssetCurrentLocationRecordQuery, AssetCurrentLocationRecordQueryVariables>({ query: AssetCurrentLocationRecordDocument, ...options })
}
export const AssetLocationRecordsAtZoneDocument = gql`
	query AssetLocationRecordsAtZone($zoneId: String!, $isRestricted: Boolean) {
		assetLocationRecordsAtZone(zoneId: $zoneId, isRestricted: $isRestricted) {
			...AssetLocationRecord
		}
	}
	${AssetLocationRecordFragmentDoc}
`

export function useAssetLocationRecordsAtZoneQuery(options: Omit<Urql.UseQueryArgs<AssetLocationRecordsAtZoneQueryVariables>, "query">) {
	return Urql.useQuery<AssetLocationRecordsAtZoneQuery, AssetLocationRecordsAtZoneQueryVariables>({ query: AssetLocationRecordsAtZoneDocument, ...options })
}
export const AssetLocationRecordsInBuildingDocument = gql`
	query AssetLocationRecordsInBuilding($buildingId: String!) {
		assetLocationRecordsInBuilding(buildingId: $buildingId) {
			...AssetLocationRecord
		}
	}
	${AssetLocationRecordFragmentDoc}
`

export function useAssetLocationRecordsInBuildingQuery(options: Omit<Urql.UseQueryArgs<AssetLocationRecordsInBuildingQueryVariables>, "query">) {
	return Urql.useQuery<AssetLocationRecordsInBuildingQuery, AssetLocationRecordsInBuildingQueryVariables>({ query: AssetLocationRecordsInBuildingDocument, ...options })
}
export const AssetLocationRecordsTrailDocument = gql`
	query assetLocationRecordsTrail($assetId: String!) {
		assetLocationRecordsTrail(assetId: $assetId) {
			...AssetLocationRecord
		}
	}
	${AssetLocationRecordFragmentDoc}
`

export function useAssetLocationRecordsTrailQuery(options: Omit<Urql.UseQueryArgs<AssetLocationRecordsTrailQueryVariables>, "query">) {
	return Urql.useQuery<AssetLocationRecordsTrailQuery, AssetLocationRecordsTrailQueryVariables>({ query: AssetLocationRecordsTrailDocument, ...options })
}
export const AssetPastLocationRecordsDocument = gql`
	query AssetPastLocationRecords($assetId: String!, $date: DateTime!, $pagination: PaginatedInput!) {
		assetPastLocationRecords(assetId: $assetId, date: $date, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assetLocationRecords {
				...AssetLocationRecord
			}
		}
	}
	${AssetLocationRecordFragmentDoc}
`

export function useAssetPastLocationRecordsQuery(options: Omit<Urql.UseQueryArgs<AssetPastLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<AssetPastLocationRecordsQuery, AssetPastLocationRecordsQueryVariables>({ query: AssetPastLocationRecordsDocument, ...options })
}
export const AssetsDocument = gql`
	query Assets($filter: AssetsFilterInput!, $pagination: PaginatedInput!) {
		assets(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assets {
				...Asset
			}
		}
	}
	${AssetFragmentDoc}
`

export function useAssetsQuery(options: Omit<Urql.UseQueryArgs<AssetsQueryVariables>, "query">) {
	return Urql.useQuery<AssetsQuery, AssetsQueryVariables>({ query: AssetsDocument, ...options })
}
export const AssetsByFilterDocument = gql`
	query AssetsByFilter($keyword: String!, $status: AssetStatus, $type: AssetTypes, $vendorId: String, $categoryId: String, $pagination: PaginatedInput!) {
		assetsByFilter(keyword: $keyword, status: $status, type: $type, vendorId: $vendorId, categoryId: $categoryId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assets {
				...Asset
			}
		}
	}
	${AssetFragmentDoc}
`

export function useAssetsByFilterQuery(options: Omit<Urql.UseQueryArgs<AssetsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<AssetsByFilterQuery, AssetsByFilterQueryVariables>({ query: AssetsByFilterDocument, ...options })
}
export const BuildingByIdDocument = gql`
	query buildingById($buildingId: String!) {
		buildingById(buildingId: $buildingId) {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useBuildingByIdQuery(options: Omit<Urql.UseQueryArgs<BuildingByIdQueryVariables>, "query">) {
	return Urql.useQuery<BuildingByIdQuery, BuildingByIdQueryVariables>({ query: BuildingByIdDocument, ...options })
}
export const BuildingsDocument = gql`
	query buildings {
		buildings {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useBuildingsQuery(options?: Omit<Urql.UseQueryArgs<BuildingsQueryVariables>, "query">) {
	return Urql.useQuery<BuildingsQuery, BuildingsQueryVariables>({ query: BuildingsDocument, ...options })
}
export const BuildingsByFilterDocument = gql`
	query buildingsByFilter($filter: BuildingsFilterInput!, $pagination: PaginatedInput!) {
		buildingsByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			buildings {
				...Building
			}
		}
	}
	${BuildingFragmentDoc}
`

export function useBuildingsByFilterQuery(options: Omit<Urql.UseQueryArgs<BuildingsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<BuildingsByFilterQuery, BuildingsByFilterQueryVariables>({ query: BuildingsByFilterDocument, ...options })
}
export const BuildingsByKeywordDocument = gql`
	query buildingsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		buildingsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			buildings {
				...Building
			}
		}
	}
	${BuildingFragmentDoc}
`

export function useBuildingsByKeywordQuery(options: Omit<Urql.UseQueryArgs<BuildingsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<BuildingsByKeywordQuery, BuildingsByKeywordQueryVariables>({ query: BuildingsByKeywordDocument, ...options })
}
export const BuildingsByKeywordWithoutPaginationDocument = gql`
	query buildingsByKeywordWithoutPagination($keyword: String!) {
		buildingsByKeywordWithoutPagination(keyword: $keyword) {
			...Building
		}
	}
	${BuildingFragmentDoc}
`

export function useBuildingsByKeywordWithoutPaginationQuery(options: Omit<Urql.UseQueryArgs<BuildingsByKeywordWithoutPaginationQueryVariables>, "query">) {
	return Urql.useQuery<BuildingsByKeywordWithoutPaginationQuery, BuildingsByKeywordWithoutPaginationQueryVariables>({ query: BuildingsByKeywordWithoutPaginationDocument, ...options })
}
export const DepartmentByIdDocument = gql`
	query DepartmentById($departmentId: String!) {
		departmentById(departmentId: $departmentId) {
			...Department
		}
	}
	${DepartmentFragmentDoc}
`

export function useDepartmentByIdQuery(options: Omit<Urql.UseQueryArgs<DepartmentByIdQueryVariables>, "query">) {
	return Urql.useQuery<DepartmentByIdQuery, DepartmentByIdQueryVariables>({ query: DepartmentByIdDocument, ...options })
}
export const DepartmentsDocument = gql`
	query Departments {
		departments {
			...Department
		}
	}
	${DepartmentFragmentDoc}
`

export function useDepartmentsQuery(options?: Omit<Urql.UseQueryArgs<DepartmentsQueryVariables>, "query">) {
	return Urql.useQuery<DepartmentsQuery, DepartmentsQueryVariables>({ query: DepartmentsDocument, ...options })
}
export const DepartmentsByFilterDocument = gql`
	query DepartmentsByFilter($filter: DepartmentsFilterInput!, $pagination: PaginatedInput!) {
		departmentsByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			departments {
				...Department
			}
		}
	}
	${DepartmentFragmentDoc}
`

export function useDepartmentsByFilterQuery(options: Omit<Urql.UseQueryArgs<DepartmentsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<DepartmentsByFilterQuery, DepartmentsByFilterQueryVariables>({ query: DepartmentsByFilterDocument, ...options })
}
export const DepartmentsByKeywordDocument = gql`
	query DepartmentsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		departmentsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			departments {
				...Department
			}
		}
	}
	${DepartmentFragmentDoc}
`

export function useDepartmentsByKeywordQuery(options: Omit<Urql.UseQueryArgs<DepartmentsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<DepartmentsByKeywordQuery, DepartmentsByKeywordQueryVariables>({ query: DepartmentsByKeywordDocument, ...options })
}
export const DeviceByIdDocument = gql`
	query DeviceById($deviceId: String!) {
		deviceById(deviceId: $deviceId) {
			...Device
		}
	}
	${DeviceFragmentDoc}
`

export function useDeviceByIdQuery(options: Omit<Urql.UseQueryArgs<DeviceByIdQueryVariables>, "query">) {
	return Urql.useQuery<DeviceByIdQuery, DeviceByIdQueryVariables>({ query: DeviceByIdDocument, ...options })
}
export const DevicesDocument = gql`
	query devices {
		devices {
			...Device
		}
	}
	${DeviceFragmentDoc}
`

export function useDevicesQuery(options?: Omit<Urql.UseQueryArgs<DevicesQueryVariables>, "query">) {
	return Urql.useQuery<DevicesQuery, DevicesQueryVariables>({ query: DevicesDocument, ...options })
}
export const DevicesByFilterDocument = gql`
	query DevicesByFilter($filter: DevicesFilterInput!, $pagination: PaginatedInput!) {
		devicesByFilter(filter: $filter, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			devices {
				...Device
			}
		}
	}
	${DeviceFragmentDoc}
`

export function useDevicesByFilterQuery(options: Omit<Urql.UseQueryArgs<DevicesByFilterQueryVariables>, "query">) {
	return Urql.useQuery<DevicesByFilterQuery, DevicesByFilterQueryVariables>({ query: DevicesByFilterDocument, ...options })
}
export const DevicesByTypeDocument = gql`
	query DevicesByType($type: DeviceTypes!) {
		devicesByType(type: $type) {
			...Device
		}
	}
	${DeviceFragmentDoc}
`

export function useDevicesByTypeQuery(options: Omit<Urql.UseQueryArgs<DevicesByTypeQueryVariables>, "query">) {
	return Urql.useQuery<DevicesByTypeQuery, DevicesByTypeQueryVariables>({ query: DevicesByTypeDocument, ...options })
}
export const EmailedUsersOfAlertDocument = gql`
	query EmailedUsersOfAlert($alertId: String!) {
		emailedUsersOfAlert(alertId: $alertId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useEmailedUsersOfAlertQuery(options: Omit<Urql.UseQueryArgs<EmailedUsersOfAlertQueryVariables>, "query">) {
	return Urql.useQuery<EmailedUsersOfAlertQuery, EmailedUsersOfAlertQueryVariables>({ query: EmailedUsersOfAlertDocument, ...options })
}
export const InventoryAuditStatsOfAssetDocument = gql`
	query InventoryAuditStatsOfAsset($assetId: String!) {
		inventoryAuditStatsOfAsset(assetId: $assetId) {
			...AssetInventoryAuditStats
		}
	}
	${AssetInventoryAuditStatsFragmentDoc}
`

export function useInventoryAuditStatsOfAssetQuery(options: Omit<Urql.UseQueryArgs<InventoryAuditStatsOfAssetQueryVariables>, "query">) {
	return Urql.useQuery<InventoryAuditStatsOfAssetQuery, InventoryAuditStatsOfAssetQueryVariables>({ query: InventoryAuditStatsOfAssetDocument, ...options })
}
export const InventoryAuditsOfAssetDocument = gql`
	query InventoryAuditsOfAsset($assetId: String!, $pagination: PaginatedInput!) {
		inventoryAuditsOfAsset(assetId: $assetId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assetInventoryAudits {
				...AssetInventoryAudit
			}
		}
	}
	${AssetInventoryAuditFragmentDoc}
`

export function useInventoryAuditsOfAssetQuery(options: Omit<Urql.UseQueryArgs<InventoryAuditsOfAssetQueryVariables>, "query">) {
	return Urql.useQuery<InventoryAuditsOfAssetQuery, InventoryAuditsOfAssetQueryVariables>({ query: InventoryAuditsOfAssetDocument, ...options })
}
export const LastWeekRestrictedUserLocationRecordsDocument = gql`
	query lastWeekRestrictedUserLocationRecords {
		lastWeekRestrictedUserLocationRecords {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useLastWeekRestrictedUserLocationRecordsQuery(options?: Omit<Urql.UseQueryArgs<LastWeekRestrictedUserLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<LastWeekRestrictedUserLocationRecordsQuery, LastWeekRestrictedUserLocationRecordsQueryVariables>({ query: LastWeekRestrictedUserLocationRecordsDocument, ...options })
}
export const LatestAssetInventoryAuditsDocument = gql`
	query LatestAssetInventoryAudits {
		latestAssetInventoryAudits {
			...AssetInventoryAudit
		}
	}
	${AssetInventoryAuditFragmentDoc}
`

export function useLatestAssetInventoryAuditsQuery(options?: Omit<Urql.UseQueryArgs<LatestAssetInventoryAuditsQueryVariables>, "query">) {
	return Urql.useQuery<LatestAssetInventoryAuditsQuery, LatestAssetInventoryAuditsQueryVariables>({ query: LatestAssetInventoryAuditsDocument, ...options })
}
export const LatestInventoryAuditsOfAssetDocument = gql`
	query LatestInventoryAuditsOfAsset($assetId: String!) {
		latestInventoryAuditsOfAsset(assetId: $assetId) {
			...AssetInventoryAudit
		}
	}
	${AssetInventoryAuditFragmentDoc}
`

export function useLatestInventoryAuditsOfAssetQuery(options: Omit<Urql.UseQueryArgs<LatestInventoryAuditsOfAssetQueryVariables>, "query">) {
	return Urql.useQuery<LatestInventoryAuditsOfAssetQuery, LatestInventoryAuditsOfAssetQueryVariables>({ query: LatestInventoryAuditsOfAssetDocument, ...options })
}
export const MeDocument = gql`
	query Me {
		me {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, "query">) {
	return Urql.useQuery<MeQuery, MeQueryVariables>({ query: MeDocument, ...options })
}
export const MonthlyUserIdsDocument = gql`
	query monthlyUserIds {
		monthlyUserIds {
			date
			userIds
			typeWiseUsers {
				typeId
				label {
					...Label
				}
				userIds
			}
		}
	}
	${LabelFragmentDoc}
`

export function useMonthlyUserIdsQuery(options?: Omit<Urql.UseQueryArgs<MonthlyUserIdsQueryVariables>, "query">) {
	return Urql.useQuery<MonthlyUserIdsQuery, MonthlyUserIdsQueryVariables>({ query: MonthlyUserIdsDocument, ...options })
}
export const NotifiedUsersOfAlertDocument = gql`
	query NotifiedUsersOfAlert($alertId: String!) {
		notifiedUsersOfAlert(alertId: $alertId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useNotifiedUsersOfAlertQuery(options: Omit<Urql.UseQueryArgs<NotifiedUsersOfAlertQueryVariables>, "query">) {
	return Urql.useQuery<NotifiedUsersOfAlertQuery, NotifiedUsersOfAlertQueryVariables>({ query: NotifiedUsersOfAlertDocument, ...options })
}
export const OverTimeActiveUserIdsInLastIntervalDocument = gql`
	query overTimeActiveUserIdsInLastInterval($timeLimit: Float!, $forToday: Boolean!) {
		overTimeActiveUserIdsInLastInterval(timeLimit: $timeLimit, forToday: $forToday) {
			typeId
			label {
				...Label
			}
			userIds
		}
	}
	${LabelFragmentDoc}
`

export function useOverTimeActiveUserIdsInLastIntervalQuery(options: Omit<Urql.UseQueryArgs<OverTimeActiveUserIdsInLastIntervalQueryVariables>, "query">) {
	return Urql.useQuery<OverTimeActiveUserIdsInLastIntervalQuery, OverTimeActiveUserIdsInLastIntervalQueryVariables>({ query: OverTimeActiveUserIdsInLastIntervalDocument, ...options })
}
export const OverTimeActiveUsersInLastIntervalDocument = gql`
	query overTimeActiveUsersInLastInterval($timeLimit: Float!, $forToday: Boolean!, $keyword: String!, $typeId: String, $roleType: UserRoleTypes, $status: UserStatus, $pagination: PaginatedInput!) {
		overTimeActiveUsersInLastInterval(timeLimit: $timeLimit, forToday: $forToday, keyword: $keyword, typeId: $typeId, roleType: $roleType, status: $status, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useOverTimeActiveUsersInLastIntervalQuery(options: Omit<Urql.UseQueryArgs<OverTimeActiveUsersInLastIntervalQueryVariables>, "query">) {
	return Urql.useQuery<OverTimeActiveUsersInLastIntervalQuery, OverTimeActiveUsersInLastIntervalQueryVariables>({ query: OverTimeActiveUsersInLastIntervalDocument, ...options })
}
export const PermissionsGroupByIdDocument = gql`
	query PermissionsGroupById($permissionsGroupId: String!) {
		permissionsGroupById(permissionsGroupId: $permissionsGroupId) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function usePermissionsGroupByIdQuery(options: Omit<Urql.UseQueryArgs<PermissionsGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<PermissionsGroupByIdQuery, PermissionsGroupByIdQueryVariables>({ query: PermissionsGroupByIdDocument, ...options })
}
export const PermissionsGroupsDocument = gql`
	query PermissionsGroups {
		permissionsGroups {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function usePermissionsGroupsQuery(options?: Omit<Urql.UseQueryArgs<PermissionsGroupsQueryVariables>, "query">) {
	return Urql.useQuery<PermissionsGroupsQuery, PermissionsGroupsQueryVariables>({ query: PermissionsGroupsDocument, ...options })
}
export const PermissionsGroupsByKeywordDocument = gql`
	query PermissionsGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		permissionsGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			permissionsGroups {
				...PermissionsGroup
			}
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function usePermissionsGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<PermissionsGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<PermissionsGroupsByKeywordQuery, PermissionsGroupsByKeywordQueryVariables>({ query: PermissionsGroupsByKeywordDocument, ...options })
}
export const PermissionTagsDocument = gql`
	query PermissionTags {
		permissionTags
	}
`

export function usePermissionTagsQuery(options?: Omit<Urql.UseQueryArgs<PermissionTagsQueryVariables>, "query">) {
	return Urql.useQuery<PermissionTagsQuery, PermissionTagsQueryVariables>({ query: PermissionTagsDocument, ...options })
}
export const PlacementRecordsOfAssetDocument = gql`
	query PlacementRecordsOfAsset($assetId: String!, $pagination: PaginatedInput!) {
		placementRecordsOfAsset(assetId: $assetId, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			assetPlacementRecords {
				...AssetPlacementRecord
			}
		}
	}
	${AssetPlacementRecordFragmentDoc}
`

export function usePlacementRecordsOfAssetQuery(options: Omit<Urql.UseQueryArgs<PlacementRecordsOfAssetQueryVariables>, "query">) {
	return Urql.useQuery<PlacementRecordsOfAssetQuery, PlacementRecordsOfAssetQueryVariables>({ query: PlacementRecordsOfAssetDocument, ...options })
}
export const PreviousWeekRestrictedUserLocationRecordsDocument = gql`
	query previousWeekRestrictedUserLocationRecords {
		previousWeekRestrictedUserLocationRecords {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function usePreviousWeekRestrictedUserLocationRecordsQuery(options?: Omit<Urql.UseQueryArgs<PreviousWeekRestrictedUserLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<PreviousWeekRestrictedUserLocationRecordsQuery, PreviousWeekRestrictedUserLocationRecordsQueryVariables>({ query: PreviousWeekRestrictedUserLocationRecordsDocument, ...options })
}
export const ReaderByIdDocument = gql`
	query ReaderById($readerId: String!) {
		readerById(readerId: $readerId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReaderByIdQuery(options: Omit<Urql.UseQueryArgs<ReaderByIdQueryVariables>, "query">) {
	return Urql.useQuery<ReaderByIdQuery, ReaderByIdQueryVariables>({ query: ReaderByIdDocument, ...options })
}
export const ReadersDocument = gql`
	query readers {
		readers {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersQuery(options?: Omit<Urql.UseQueryArgs<ReadersQueryVariables>, "query">) {
	return Urql.useQuery<ReadersQuery, ReadersQueryVariables>({ query: ReadersDocument, ...options })
}
export const ReadersAtZoneDocument = gql`
	query ReadersAtZone($zoneId: String!) {
		readersAtZone(zoneId: $zoneId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersAtZoneQuery(options: Omit<Urql.UseQueryArgs<ReadersAtZoneQueryVariables>, "query">) {
	return Urql.useQuery<ReadersAtZoneQuery, ReadersAtZoneQueryVariables>({ query: ReadersAtZoneDocument, ...options })
}
export const ReadersByFilterDocument = gql`
	query ReadersByFilter($filter: ReadersFilterInput!, $pagination: PaginatedInput!) {
		readersByFilter(filter: $filter, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			readers {
				...Reader
			}
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersByFilterQuery(options: Omit<Urql.UseQueryArgs<ReadersByFilterQueryVariables>, "query">) {
	return Urql.useQuery<ReadersByFilterQuery, ReadersByFilterQueryVariables>({ query: ReadersByFilterDocument, ...options })
}
export const ReadersByTypeDocument = gql`
	query ReadersByType($type: ReaderTypes!) {
		readersByType(type: $type) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersByTypeQuery(options: Omit<Urql.UseQueryArgs<ReadersByTypeQueryVariables>, "query">) {
	return Urql.useQuery<ReadersByTypeQuery, ReadersByTypeQueryVariables>({ query: ReadersByTypeDocument, ...options })
}
export const ReadersInBuildingDocument = gql`
	query readersInBuilding($buildingId: String!) {
		readersInBuilding(buildingId: $buildingId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersInBuildingQuery(options: Omit<Urql.UseQueryArgs<ReadersInBuildingQueryVariables>, "query">) {
	return Urql.useQuery<ReadersInBuildingQuery, ReadersInBuildingQueryVariables>({ query: ReadersInBuildingDocument, ...options })
}
export const ReportByIdDocument = gql`
	query ReportById($reportId: String!) {
		reportById(reportId: $reportId) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useReportByIdQuery(options: Omit<Urql.UseQueryArgs<ReportByIdQueryVariables>, "query">) {
	return Urql.useQuery<ReportByIdQuery, ReportByIdQueryVariables>({ query: ReportByIdDocument, ...options })
}
export const ReportsByKeywordDocument = gql`
	query ReportsByKeyword($keyword: String!, $type: String, $status: String, $startDate: DateTime, $endDate: DateTime, $pagination: PaginatedInput!) {
		reportsByKeyword(keyword: $keyword, type: $type, status: $status, startDate: $startDate, endDate: $endDate, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			reports {
				...Report
			}
		}
	}
	${ReportFragmentDoc}
`

export function useReportsByKeywordQuery(options: Omit<Urql.UseQueryArgs<ReportsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ReportsByKeywordQuery, ReportsByKeywordQueryVariables>({ query: ReportsByKeywordDocument, ...options })
}
export const RootTileDocument = gql`
	query rootTile {
		rootTile {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useRootTileQuery(options?: Omit<Urql.UseQueryArgs<RootTileQueryVariables>, "query">) {
	return Urql.useQuery<RootTileQuery, RootTileQueryVariables>({ query: RootTileDocument, ...options })
}
export const ServiceByIdDocument = gql`
	query serviceById($serviceId: String!) {
		serviceById(serviceId: $serviceId) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useServiceByIdQuery(options: Omit<Urql.UseQueryArgs<ServiceByIdQueryVariables>, "query">) {
	return Urql.useQuery<ServiceByIdQuery, ServiceByIdQueryVariables>({ query: ServiceByIdDocument, ...options })
}
export const ServicesByKeywordDocument = gql`
	query servicesByKeyword($keyword: String!) {
		servicesByKeyword(keyword: $keyword) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useServicesByKeywordQuery(options: Omit<Urql.UseQueryArgs<ServicesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ServicesByKeywordQuery, ServicesByKeywordQueryVariables>({ query: ServicesByKeywordDocument, ...options })
}
export const SessionDocument = gql`
	query Session {
		session
	}
`

export function useSessionQuery(options?: Omit<Urql.UseQueryArgs<SessionQueryVariables>, "query">) {
	return Urql.useQuery<SessionQuery, SessionQueryVariables>({ query: SessionDocument, ...options })
}
export const SiteByIdDocument = gql`
	query SiteById($siteId: String!) {
		siteById(siteId: $siteId) {
			...Site
		}
	}
	${SiteFragmentDoc}
`

export function useSiteByIdQuery(options: Omit<Urql.UseQueryArgs<SiteByIdQueryVariables>, "query">) {
	return Urql.useQuery<SiteByIdQuery, SiteByIdQueryVariables>({ query: SiteByIdDocument, ...options })
}
export const StatusLogsOfReaderDocument = gql`
	query statusLogsOfReader($readerId: String!, $date: DateTime!, $pagination: PaginatedInput!) {
		statusLogsOfReader(readerId: $readerId, date: $date, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			readerStatusLogs {
				...ReaderStatusLog
			}
		}
	}
	${ReaderStatusLogFragmentDoc}
`

export function useStatusLogsOfReaderQuery(options: Omit<Urql.UseQueryArgs<StatusLogsOfReaderQueryVariables>, "query">) {
	return Urql.useQuery<StatusLogsOfReaderQuery, StatusLogsOfReaderQueryVariables>({ query: StatusLogsOfReaderDocument, ...options })
}
export const TagByIdDocument = gql`
	query TagById($tagId: String!) {
		tagById(tagId: $tagId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useTagByIdQuery(options: Omit<Urql.UseQueryArgs<TagByIdQueryVariables>, "query">) {
	return Urql.useQuery<TagByIdQuery, TagByIdQueryVariables>({ query: TagByIdDocument, ...options })
}
export const TagsByFilterDocument = gql`
	query TagsByFilter($filter: TagsFilterInput!, $pagination: PaginatedInput!) {
		tagsByFilter(filter: $filter, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			tags {
				...Tag
			}
		}
	}
	${TagFragmentDoc}
`

export function useTagsByFilterQuery(options: Omit<Urql.UseQueryArgs<TagsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<TagsByFilterQuery, TagsByFilterQueryVariables>({ query: TagsByFilterDocument, ...options })
}
export const TileByIdDocument = gql`
	query TileById($tileId: String!) {
		tileById(tileId: $tileId) {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useTileByIdQuery(options: Omit<Urql.UseQueryArgs<TileByIdQueryVariables>, "query">) {
	return Urql.useQuery<TileByIdQuery, TileByIdQueryVariables>({ query: TileByIdDocument, ...options })
}
export const TilesDocument = gql`
	query Tiles {
		tiles {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useTilesQuery(options?: Omit<Urql.UseQueryArgs<TilesQueryVariables>, "query">) {
	return Urql.useQuery<TilesQuery, TilesQueryVariables>({ query: TilesDocument, ...options })
}
export const TilesByFilterDocument = gql`
	query TilesByFilter($filter: TilesFilterInput!, $pagination: PaginatedInput!) {
		tilesByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			tiles {
				...Tile
			}
		}
	}
	${TileFragmentDoc}
`

export function useTilesByFilterQuery(options: Omit<Urql.UseQueryArgs<TilesByFilterQueryVariables>, "query">) {
	return Urql.useQuery<TilesByFilterQuery, TilesByFilterQueryVariables>({ query: TilesByFilterDocument, ...options })
}
export const TodayRestrictedUserLocationRecordIdsDocument = gql`
	query todayRestrictedUserLocationRecordIds {
		todayRestrictedUserLocationRecordIds
	}
`

export function useTodayRestrictedUserLocationRecordIdsQuery(options?: Omit<Urql.UseQueryArgs<TodayRestrictedUserLocationRecordIdsQueryVariables>, "query">) {
	return Urql.useQuery<TodayRestrictedUserLocationRecordIdsQuery, TodayRestrictedUserLocationRecordIdsQueryVariables>({ query: TodayRestrictedUserLocationRecordIdsDocument, ...options })
}
export const TodayRestrictedUserLocationRecordsDocument = gql`
	query todayRestrictedUserLocationRecords($pagination: PaginatedInput!) {
		todayRestrictedUserLocationRecords(pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			userLocationRecords {
				...UserLocationRecord
			}
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useTodayRestrictedUserLocationRecordsQuery(options: Omit<Urql.UseQueryArgs<TodayRestrictedUserLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<TodayRestrictedUserLocationRecordsQuery, TodayRestrictedUserLocationRecordsQueryVariables>({ query: TodayRestrictedUserLocationRecordsDocument, ...options })
}
export const TodayTrackedAssetsDocument = gql`
	query todayTrackedAssets($keyword: String!, $status: AssetStatus, $pagination: PaginatedInput!) {
		todayTrackedAssets(keyword: $keyword, status: $status, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			assets {
				...Asset
			}
		}
	}
	${AssetFragmentDoc}
`

export function useTodayTrackedAssetsQuery(options: Omit<Urql.UseQueryArgs<TodayTrackedAssetsQueryVariables>, "query">) {
	return Urql.useQuery<TodayTrackedAssetsQuery, TodayTrackedAssetsQueryVariables>({ query: TodayTrackedAssetsDocument, ...options })
}
export const TypeWiseActiveUsersDocument = gql`
	query typeWiseActiveUsers {
		typeWiseActiveUsers {
			typeId
			label {
				...Label
			}
			userIds
		}
	}
	${LabelFragmentDoc}
`

export function useTypeWiseActiveUsersQuery(options?: Omit<Urql.UseQueryArgs<TypeWiseActiveUsersQueryVariables>, "query">) {
	return Urql.useQuery<TypeWiseActiveUsersQuery, TypeWiseActiveUsersQueryVariables>({ query: TypeWiseActiveUsersDocument, ...options })
}
export const TypeWiseActiveUsersInLastIntervalDocument = gql`
	query typeWiseActiveUsersInLastInterval($intervalInMinutes: Float!) {
		typeWiseActiveUsersInLastInterval(intervalInMinutes: $intervalInMinutes) {
			typeId
			label {
				...Label
			}
			userIds
		}
	}
	${LabelFragmentDoc}
`

export function useTypeWiseActiveUsersInLastIntervalQuery(options: Omit<Urql.UseQueryArgs<TypeWiseActiveUsersInLastIntervalQueryVariables>, "query">) {
	return Urql.useQuery<TypeWiseActiveUsersInLastIntervalQuery, TypeWiseActiveUsersInLastIntervalQueryVariables>({ query: TypeWiseActiveUsersInLastIntervalDocument, ...options })
}
export const UpdateAssetDocument = gql`
	mutation UpdateAsset($assetId: String!, $input: AssetInput!) {
		updateAsset(assetId: $assetId, input: $input) {
			...Asset
		}
	}
	${AssetFragmentDoc}
`

export function useUpdateAssetMutation() {
	return Urql.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(UpdateAssetDocument)
}
export const UserByIdDocument = gql`
	query UserById($userId: String!) {
		userById(userId: $userId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useUserByIdQuery(options: Omit<Urql.UseQueryArgs<UserByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserByIdQuery, UserByIdQueryVariables>({ query: UserByIdDocument, ...options })
}
export const UserCurrentLocationRecordDocument = gql`
	query UserCurrentLocationRecord($userId: String!) {
		userCurrentLocationRecord(userId: $userId) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserCurrentLocationRecordQuery(options: Omit<Urql.UseQueryArgs<UserCurrentLocationRecordQueryVariables>, "query">) {
	return Urql.useQuery<UserCurrentLocationRecordQuery, UserCurrentLocationRecordQueryVariables>({ query: UserCurrentLocationRecordDocument, ...options })
}
export const UserCurrentUserRoleDocument = gql`
	query userCurrentUserRole($userId: String!) {
		userCurrentUserRole(userId: $userId) {
			...UserRole
		}
	}
	${UserRoleFragmentDoc}
`

export function useUserCurrentUserRoleQuery(options: Omit<Urql.UseQueryArgs<UserCurrentUserRoleQueryVariables>, "query">) {
	return Urql.useQuery<UserCurrentUserRoleQuery, UserCurrentUserRoleQueryVariables>({ query: UserCurrentUserRoleDocument, ...options })
}
export const UserGroupByIdDocument = gql`
	query UserGroupById($userGroupId: String!) {
		userGroupById(userGroupId: $userGroupId) {
			...UserGroup
		}
	}
	${UserGroupFragmentDoc}
`

export function useUserGroupByIdQuery(options: Omit<Urql.UseQueryArgs<UserGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserGroupByIdQuery, UserGroupByIdQueryVariables>({ query: UserGroupByIdDocument, ...options })
}
export const UserGroupsByFilterDocument = gql`
	query userGroupsByFilter($filter: UserGroupsFilterInput!, $pagination: PaginatedInput!) {
		userGroupsByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userGroups {
				...UserGroup
			}
		}
	}
	${UserGroupFragmentDoc}
`

export function useUserGroupsByFilterQuery(options: Omit<Urql.UseQueryArgs<UserGroupsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<UserGroupsByFilterQuery, UserGroupsByFilterQueryVariables>({ query: UserGroupsByFilterDocument, ...options })
}
export const UserLocationRecordByIdDocument = gql`
	query UserLocationRecordById($userLocationRecordId: String!) {
		userLocationRecordById(userLocationRecordId: $userLocationRecordId) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserLocationRecordByIdQuery(options: Omit<Urql.UseQueryArgs<UserLocationRecordByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserLocationRecordByIdQuery, UserLocationRecordByIdQueryVariables>({ query: UserLocationRecordByIdDocument, ...options })
}
export const UserLocationRecordsAtZoneDocument = gql`
	query UserLocationRecordsAtZone($zoneId: String!, $userTypeIds: [String!], $isRestricted: Boolean) {
		userLocationRecordsAtZone(zoneId: $zoneId, userTypeIds: $userTypeIds, isRestricted: $isRestricted) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserLocationRecordsAtZoneQuery(options: Omit<Urql.UseQueryArgs<UserLocationRecordsAtZoneQueryVariables>, "query">) {
	return Urql.useQuery<UserLocationRecordsAtZoneQuery, UserLocationRecordsAtZoneQueryVariables>({ query: UserLocationRecordsAtZoneDocument, ...options })
}
export const UserLocationRecordsInBuildingDocument = gql`
	query UserLocationRecordsInBuilding($buildingId: String!, $userTypeIds: [String!], $isRestricted: Boolean) {
		userLocationRecordsInBuilding(buildingId: $buildingId, userTypeIds: $userTypeIds, isRestricted: $isRestricted) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserLocationRecordsInBuildingQuery(options: Omit<Urql.UseQueryArgs<UserLocationRecordsInBuildingQueryVariables>, "query">) {
	return Urql.useQuery<UserLocationRecordsInBuildingQuery, UserLocationRecordsInBuildingQueryVariables>({ query: UserLocationRecordsInBuildingDocument, ...options })
}
export const UserLocationRecordsTrailDocument = gql`
	query userLocationRecordsTrail($userId: String!) {
		userLocationRecordsTrail(userId: $userId) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserLocationRecordsTrailQuery(options: Omit<Urql.UseQueryArgs<UserLocationRecordsTrailQueryVariables>, "query">) {
	return Urql.useQuery<UserLocationRecordsTrailQuery, UserLocationRecordsTrailQueryVariables>({ query: UserLocationRecordsTrailDocument, ...options })
}
export const UserPastAttendanceRecordsDocument = gql`
	query userPastAttendanceRecords($userId: String!, $date: DateTime!, $pagination: PaginatedInput!) {
		userPastAttendanceRecords(userId: $userId, date: $date, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userAttendanceRecords {
				...UserAttendanceRecord
			}
		}
	}
	${UserAttendanceRecordFragmentDoc}
`

export function useUserPastAttendanceRecordsQuery(options: Omit<Urql.UseQueryArgs<UserPastAttendanceRecordsQueryVariables>, "query">) {
	return Urql.useQuery<UserPastAttendanceRecordsQuery, UserPastAttendanceRecordsQueryVariables>({ query: UserPastAttendanceRecordsDocument, ...options })
}
export const UserPastLocationRecordsDocument = gql`
	query UserPastLocationRecords($userId: String!, $date: DateTime!, $pagination: PaginatedInput!) {
		userPastLocationRecords(userId: $userId, date: $date, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userLocationRecords {
				...UserLocationRecord
			}
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserPastLocationRecordsQuery(options: Omit<Urql.UseQueryArgs<UserPastLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<UserPastLocationRecordsQuery, UserPastLocationRecordsQueryVariables>({ query: UserPastLocationRecordsDocument, ...options })
}
export const UserPermissionsGroupsDocument = gql`
	query UserPermissionsGroups($userId: String!) {
		userPermissionsGroups(userId: $userId) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useUserPermissionsGroupsQuery(options: Omit<Urql.UseQueryArgs<UserPermissionsGroupsQueryVariables>, "query">) {
	return Urql.useQuery<UserPermissionsGroupsQuery, UserPermissionsGroupsQueryVariables>({ query: UserPermissionsGroupsDocument, ...options })
}
export const UserRoleByIdDocument = gql`
	query UserRoleById($userRoleId: String!) {
		userRoleById(userRoleId: $userRoleId) {
			...UserRole
		}
	}
	${UserRoleFragmentDoc}
`

export function useUserRoleByIdQuery(options: Omit<Urql.UseQueryArgs<UserRoleByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserRoleByIdQuery, UserRoleByIdQueryVariables>({ query: UserRoleByIdDocument, ...options })
}
export const UserRoleGroupByIdDocument = gql`
	query userRoleGroupById($userRoleGroupId: String!) {
		userRoleGroupById(userRoleGroupId: $userRoleGroupId) {
			...UserRoleGroup
		}
	}
	${UserRoleGroupFragmentDoc}
`

export function useUserRoleGroupByIdQuery(options: Omit<Urql.UseQueryArgs<UserRoleGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserRoleGroupByIdQuery, UserRoleGroupByIdQueryVariables>({ query: UserRoleGroupByIdDocument, ...options })
}
export const UserRoleGroupsDocument = gql`
	query UserRoleGroups($type: UserRoleTypes) {
		userRoleGroups(type: $type) {
			...UserRoleGroup
		}
	}
	${UserRoleGroupFragmentDoc}
`

export function useUserRoleGroupsQuery(options?: Omit<Urql.UseQueryArgs<UserRoleGroupsQueryVariables>, "query">) {
	return Urql.useQuery<UserRoleGroupsQuery, UserRoleGroupsQueryVariables>({ query: UserRoleGroupsDocument, ...options })
}
export const UserRoleGroupsByKeywordDocument = gql`
	query userRoleGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		userRoleGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userRoleGroups {
				...UserRoleGroup
			}
		}
	}
	${UserRoleGroupFragmentDoc}
`

export function useUserRoleGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<UserRoleGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<UserRoleGroupsByKeywordQuery, UserRoleGroupsByKeywordQueryVariables>({ query: UserRoleGroupsByKeywordDocument, ...options })
}
export const UserTypeByIdDocument = gql`
	query UserTypeById($userTypeId: String!) {
		userTypeById(userTypeId: $userTypeId) {
			...UserType
		}
	}
	${UserTypeFragmentDoc}
`

export function useUserTypeByIdQuery(options: Omit<Urql.UseQueryArgs<UserTypeByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserTypeByIdQuery, UserTypeByIdQueryVariables>({ query: UserTypeByIdDocument, ...options })
}
export const UserTypesDocument = gql`
	query UserTypes {
		userTypes {
			...UserType
		}
	}
	${UserTypeFragmentDoc}
`

export function useUserTypesQuery(options?: Omit<Urql.UseQueryArgs<UserTypesQueryVariables>, "query">) {
	return Urql.useQuery<UserTypesQuery, UserTypesQueryVariables>({ query: UserTypesDocument, ...options })
}
export const UserTypesByKeywordDocument = gql`
	query UserTypesByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		userTypesByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userTypes {
				...UserType
			}
		}
	}
	${UserTypeFragmentDoc}
`

export function useUserTypesByKeywordQuery(options: Omit<Urql.UseQueryArgs<UserTypesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<UserTypesByKeywordQuery, UserTypesByKeywordQueryVariables>({ query: UserTypesByKeywordDocument, ...options })
}
export const UsersDocument = gql`
	query Users($filter: UsersFilterInput!, $pagination: PaginatedInput!) {
		users(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useUsersQuery(options: Omit<Urql.UseQueryArgs<UsersQueryVariables>, "query">) {
	return Urql.useQuery<UsersQuery, UsersQueryVariables>({ query: UsersDocument, ...options })
}
export const UsersByCurrentUserRoleGroupDocument = gql`
	query UsersByCurrentUserRoleGroup($userRoleGroupId: String!, $pagination: PaginatedInput!) {
		usersByCurrentUserRoleGroup(userRoleGroupId: $userRoleGroupId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useUsersByCurrentUserRoleGroupQuery(options: Omit<Urql.UseQueryArgs<UsersByCurrentUserRoleGroupQueryVariables>, "query">) {
	return Urql.useQuery<UsersByCurrentUserRoleGroupQuery, UsersByCurrentUserRoleGroupQueryVariables>({ query: UsersByCurrentUserRoleGroupDocument, ...options })
}
export const UsersByDateAndTypeDocument = gql`
	query UsersByDateAndType($filter: UsersByDateAndTypeFilterInput!, $pagination: PaginatedInput!) {
		usersByDateAndType(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useUsersByDateAndTypeQuery(options: Omit<Urql.UseQueryArgs<UsersByDateAndTypeQueryVariables>, "query">) {
	return Urql.useQuery<UsersByDateAndTypeQuery, UsersByDateAndTypeQueryVariables>({ query: UsersByDateAndTypeDocument, ...options })
}
export const UsersByDepartmentDocument = gql`
	query UsersByDepartment($departmentId: String!, $pagination: PaginatedInput!) {
		usersByDepartment(departmentId: $departmentId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useUsersByDepartmentQuery(options: Omit<Urql.UseQueryArgs<UsersByDepartmentQueryVariables>, "query">) {
	return Urql.useQuery<UsersByDepartmentQuery, UsersByDepartmentQueryVariables>({ query: UsersByDepartmentDocument, ...options })
}
export const UsersByKeywordDocument = gql`
	query UsersByKeyword($keyword: String!, $typeId: String, $roleType: UserRoleTypes, $visitorLevel: String, $status: UserStatus, $since: DateTime, $until: DateTime, $pagination: PaginatedInput!) {
		usersByKeyword(keyword: $keyword, typeId: $typeId, roleType: $roleType, visitorLevel: $visitorLevel, status: $status, since: $since, until: $until, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useUsersByKeywordQuery(options: Omit<Urql.UseQueryArgs<UsersByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<UsersByKeywordQuery, UsersByKeywordQueryVariables>({ query: UsersByKeywordDocument, ...options })
}
export const UsersByTypeDocument = gql`
	query UsersByType($typeId: String!, $pagination: PaginatedInput!) {
		usersByType(typeId: $typeId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...User
			}
		}
	}
	${UserFragmentDoc}
`

export function useUsersByTypeQuery(options: Omit<Urql.UseQueryArgs<UsersByTypeQueryVariables>, "query">) {
	return Urql.useQuery<UsersByTypeQuery, UsersByTypeQueryVariables>({ query: UsersByTypeDocument, ...options })
}
export const UsersOfAlertTargetGroupDocument = gql`
	query UsersOfAlertTargetGroup($alertTargetGroupId: String!) {
		usersOfAlertTargetGroup(alertTargetGroupId: $alertTargetGroupId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useUsersOfAlertTargetGroupQuery(options: Omit<Urql.UseQueryArgs<UsersOfAlertTargetGroupQueryVariables>, "query">) {
	return Urql.useQuery<UsersOfAlertTargetGroupQuery, UsersOfAlertTargetGroupQueryVariables>({ query: UsersOfAlertTargetGroupDocument, ...options })
}
export const UsersOfUserGroupDocument = gql`
	query UsersOfUserGroup($userGroupId: String!) {
		usersOfUserGroup(userGroupId: $userGroupId) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useUsersOfUserGroupQuery(options: Omit<Urql.UseQueryArgs<UsersOfUserGroupQueryVariables>, "query">) {
	return Urql.useQuery<UsersOfUserGroupQuery, UsersOfUserGroupQueryVariables>({ query: UsersOfUserGroupDocument, ...options })
}
export const ZoneByIdDocument = gql`
	query ZoneById($zoneId: String!) {
		zoneById(zoneId: $zoneId) {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useZoneByIdQuery(options: Omit<Urql.UseQueryArgs<ZoneByIdQueryVariables>, "query">) {
	return Urql.useQuery<ZoneByIdQuery, ZoneByIdQueryVariables>({ query: ZoneByIdDocument, ...options })
}
export const ZoneGroupByIdDocument = gql`
	query ZoneGroupById($zoneGroupId: String!) {
		zoneGroupById(zoneGroupId: $zoneGroupId) {
			...ZoneGroup
		}
	}
	${ZoneGroupFragmentDoc}
`

export function useZoneGroupByIdQuery(options: Omit<Urql.UseQueryArgs<ZoneGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupByIdQuery, ZoneGroupByIdQueryVariables>({ query: ZoneGroupByIdDocument, ...options })
}
export const ZoneGroupsDocument = gql`
	query ZoneGroups {
		zoneGroups {
			...ZoneGroup
		}
	}
	${ZoneGroupFragmentDoc}
`

export function useZoneGroupsQuery(options?: Omit<Urql.UseQueryArgs<ZoneGroupsQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupsQuery, ZoneGroupsQueryVariables>({ query: ZoneGroupsDocument, ...options })
}
export const ZoneGroupsByFilterDocument = gql`
	query zoneGroupsByFilter($filter: ZoneGroupsFilterInput!, $pagination: PaginatedInput!) {
		zoneGroupsByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zoneGroups {
				...ZoneGroup
			}
		}
	}
	${ZoneGroupFragmentDoc}
`

export function useZoneGroupsByFilterQuery(options: Omit<Urql.UseQueryArgs<ZoneGroupsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupsByFilterQuery, ZoneGroupsByFilterQueryVariables>({ query: ZoneGroupsByFilterDocument, ...options })
}
export const ZoneGroupsByKeywordDocument = gql`
	query ZoneGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		zoneGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zoneGroups {
				...ZoneGroup
			}
		}
	}
	${ZoneGroupFragmentDoc}
`

export function useZoneGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<ZoneGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupsByKeywordQuery, ZoneGroupsByKeywordQueryVariables>({ query: ZoneGroupsByKeywordDocument, ...options })
}
export const ZonesDocument = gql`
	query Zones {
		zones {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useZonesQuery(options?: Omit<Urql.UseQueryArgs<ZonesQueryVariables>, "query">) {
	return Urql.useQuery<ZonesQuery, ZonesQueryVariables>({ query: ZonesDocument, ...options })
}
export const ZonesByFilterDocument = gql`
	query zonesByFilter($filter: ZonesFilterInput!) {
		zonesByFilter(filter: $filter) {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useZonesByFilterQuery(options: Omit<Urql.UseQueryArgs<ZonesByFilterQueryVariables>, "query">) {
	return Urql.useQuery<ZonesByFilterQuery, ZonesByFilterQueryVariables>({ query: ZonesByFilterDocument, ...options })
}
export const ZonesByKeywordDocument = gql`
	query ZonesByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		zonesByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zones {
				...Zone
			}
		}
	}
	${ZoneFragmentDoc}
`

export function useZonesByKeywordQuery(options: Omit<Urql.UseQueryArgs<ZonesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ZonesByKeywordQuery, ZonesByKeywordQueryVariables>({ query: ZonesByKeywordDocument, ...options })
}
export const ZonesInBuildingDocument = gql`
	query ZonesInBuilding($buildingId: String!) {
		zonesInBuilding(buildingId: $buildingId) {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useZonesInBuildingQuery(options: Omit<Urql.UseQueryArgs<ZonesInBuildingQueryVariables>, "query">) {
	return Urql.useQuery<ZonesInBuildingQuery, ZonesInBuildingQueryVariables>({ query: ZonesInBuildingDocument, ...options })
}
export const ZonesOfZoneGroupDocument = gql`
	query zonesOfZoneGroup($zoneGroupId: String!, $pagination: PaginatedInput!) {
		zonesOfZoneGroup(zoneGroupId: $zoneGroupId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zones {
				...Zone
			}
		}
	}
	${ZoneFragmentDoc}
`

export function useZonesOfZoneGroupQuery(options: Omit<Urql.UseQueryArgs<ZonesOfZoneGroupQueryVariables>, "query">) {
	return Urql.useQuery<ZonesOfZoneGroupQuery, ZonesOfZoneGroupQueryVariables>({ query: ZonesOfZoneGroupDocument, ...options })
}
export const ZonesOnBuildingFloorDocument = gql`
	query zonesOnBuildingFloor($buildingId: String!, $floorId: String!) {
		zonesOnBuildingFloor(buildingId: $buildingId, floorId: $floorId) {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useZonesOnBuildingFloorQuery(options: Omit<Urql.UseQueryArgs<ZonesOnBuildingFloorQueryVariables>, "query">) {
	return Urql.useQuery<ZonesOnBuildingFloorQuery, ZonesOnBuildingFloorQueryVariables>({ query: ZonesOnBuildingFloorDocument, ...options })
}
export const AlertsUpdateDocument = gql`
	subscription AlertsUpdate {
		alertsUpdate {
			...Alert
		}
	}
	${AlertFragmentDoc}
`

export function useAlertsUpdateSubscription<TData = AlertsUpdateSubscription>(options: Omit<Urql.UseSubscriptionArgs<AlertsUpdateSubscriptionVariables>, "query"> = {}, handler?: Urql.SubscriptionHandler<AlertsUpdateSubscription, TData>) {
	return Urql.useSubscription<AlertsUpdateSubscription, TData, AlertsUpdateSubscriptionVariables>({ query: AlertsUpdateDocument, ...options }, handler)
}
export const AssetLocationRecordUpdateDocument = gql`
	subscription AssetLocationRecordUpdate($assetId: String!) {
		assetLocationRecordUpdate(assetId: $assetId) {
			...AssetLocationRecord
		}
	}
	${AssetLocationRecordFragmentDoc}
`

export function useAssetLocationRecordUpdateSubscription<TData = AssetLocationRecordUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<AssetLocationRecordUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<AssetLocationRecordUpdateSubscription, TData>
) {
	return Urql.useSubscription<AssetLocationRecordUpdateSubscription, TData, AssetLocationRecordUpdateSubscriptionVariables>({ query: AssetLocationRecordUpdateDocument, ...options }, handler)
}
export const AssetLocationRecordUpdateAtZoneDocument = gql`
	subscription AssetLocationRecordUpdateAtZone($zoneId: String!) {
		assetLocationRecordUpdateAtZone(zoneId: $zoneId) {
			...AssetLocationRecord
		}
	}
	${AssetLocationRecordFragmentDoc}
`

export function useAssetLocationRecordUpdateAtZoneSubscription<TData = AssetLocationRecordUpdateAtZoneSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<AssetLocationRecordUpdateAtZoneSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<AssetLocationRecordUpdateAtZoneSubscription, TData>
) {
	return Urql.useSubscription<AssetLocationRecordUpdateAtZoneSubscription, TData, AssetLocationRecordUpdateAtZoneSubscriptionVariables>({ query: AssetLocationRecordUpdateAtZoneDocument, ...options }, handler)
}
export const DeviceUpdateDocument = gql`
	subscription DeviceUpdate($deviceId: String!) {
		deviceUpdate(deviceId: $deviceId) {
			...Device
		}
	}
	${DeviceFragmentDoc}
`

export function useDeviceUpdateSubscription<TData = DeviceUpdateSubscription>(options: Omit<Urql.UseSubscriptionArgs<DeviceUpdateSubscriptionVariables>, "query"> = {}, handler?: Urql.SubscriptionHandler<DeviceUpdateSubscription, TData>) {
	return Urql.useSubscription<DeviceUpdateSubscription, TData, DeviceUpdateSubscriptionVariables>({ query: DeviceUpdateDocument, ...options }, handler)
}
export const EnrolledTagByDeviceIdDocument = gql`
	subscription enrolledTagByDeviceId($deviceId: String!) {
		enrolledTagByDeviceId(deviceId: $deviceId) {
			...EnrolledTag
		}
	}
	${EnrolledTagFragmentDoc}
`

export function useEnrolledTagByDeviceIdSubscription<TData = EnrolledTagByDeviceIdSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<EnrolledTagByDeviceIdSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<EnrolledTagByDeviceIdSubscription, TData>
) {
	return Urql.useSubscription<EnrolledTagByDeviceIdSubscription, TData, EnrolledTagByDeviceIdSubscriptionVariables>({ query: EnrolledTagByDeviceIdDocument, ...options }, handler)
}
export const EnrolledTagByReaderIdDocument = gql`
	subscription enrolledTagByReaderId($readerId: String!) {
		enrolledTagByReaderId(readerId: $readerId) {
			...EnrolledTag
		}
	}
	${EnrolledTagFragmentDoc}
`

export function useEnrolledTagByReaderIdSubscription<TData = EnrolledTagByReaderIdSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<EnrolledTagByReaderIdSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<EnrolledTagByReaderIdSubscription, TData>
) {
	return Urql.useSubscription<EnrolledTagByReaderIdSubscription, TData, EnrolledTagByReaderIdSubscriptionVariables>({ query: EnrolledTagByReaderIdDocument, ...options }, handler)
}
export const ReaderUpdateDocument = gql`
	subscription ReaderUpdate($readerId: String!) {
		readerUpdate(readerId: $readerId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReaderUpdateSubscription<TData = ReaderUpdateSubscription>(options: Omit<Urql.UseSubscriptionArgs<ReaderUpdateSubscriptionVariables>, "query"> = {}, handler?: Urql.SubscriptionHandler<ReaderUpdateSubscription, TData>) {
	return Urql.useSubscription<ReaderUpdateSubscription, TData, ReaderUpdateSubscriptionVariables>({ query: ReaderUpdateDocument, ...options }, handler)
}
export const ReaderUpdateAtZoneDocument = gql`
	subscription ReaderUpdateAtZone($zoneId: String!) {
		readerUpdateAtZone(zoneId: $zoneId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReaderUpdateAtZoneSubscription<TData = ReaderUpdateAtZoneSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<ReaderUpdateAtZoneSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<ReaderUpdateAtZoneSubscription, TData>
) {
	return Urql.useSubscription<ReaderUpdateAtZoneSubscription, TData, ReaderUpdateAtZoneSubscriptionVariables>({ query: ReaderUpdateAtZoneDocument, ...options }, handler)
}
export const ReportUpdateDocument = gql`
	subscription ReportUpdate($reportId: String!) {
		reportUpdate(reportId: $reportId) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useReportUpdateSubscription<TData = ReportUpdateSubscription>(options: Omit<Urql.UseSubscriptionArgs<ReportUpdateSubscriptionVariables>, "query"> = {}, handler?: Urql.SubscriptionHandler<ReportUpdateSubscription, TData>) {
	return Urql.useSubscription<ReportUpdateSubscription, TData, ReportUpdateSubscriptionVariables>({ query: ReportUpdateDocument, ...options }, handler)
}
export const RestrictedUserLocationRecordsUpdateDocument = gql`
	subscription RestrictedUserLocationRecordsUpdate($recordIds: [String!]!) {
		restrictedUserLocationRecordsUpdate(recordIds: $recordIds) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useRestrictedUserLocationRecordsUpdateSubscription<TData = RestrictedUserLocationRecordsUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<RestrictedUserLocationRecordsUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<RestrictedUserLocationRecordsUpdateSubscription, TData>
) {
	return Urql.useSubscription<RestrictedUserLocationRecordsUpdateSubscription, TData, RestrictedUserLocationRecordsUpdateSubscriptionVariables>({ query: RestrictedUserLocationRecordsUpdateDocument, ...options }, handler)
}
export const UserAuthSessionUpdateDocument = gql`
	subscription userAuthSessionUpdate($sessionId: String!) {
		userAuthSessionUpdate(sessionId: $sessionId) {
			...UserAuthSession
		}
	}
	${UserAuthSessionFragmentDoc}
`

export function useUserAuthSessionUpdateSubscription<TData = UserAuthSessionUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserAuthSessionUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserAuthSessionUpdateSubscription, TData>
) {
	return Urql.useSubscription<UserAuthSessionUpdateSubscription, TData, UserAuthSessionUpdateSubscriptionVariables>({ query: UserAuthSessionUpdateDocument, ...options }, handler)
}
export const UserLocationRecordUpdateDocument = gql`
	subscription UserLocationRecordUpdate($userId: String!) {
		userLocationRecordUpdate(userId: $userId) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserLocationRecordUpdateSubscription<TData = UserLocationRecordUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserLocationRecordUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserLocationRecordUpdateSubscription, TData>
) {
	return Urql.useSubscription<UserLocationRecordUpdateSubscription, TData, UserLocationRecordUpdateSubscriptionVariables>({ query: UserLocationRecordUpdateDocument, ...options }, handler)
}
export const UserLocationRecordUpdateAtZoneDocument = gql`
	subscription UserLocationRecordUpdateAtZone($zoneId: String!) {
		userLocationRecordUpdateAtZone(zoneId: $zoneId) {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserLocationRecordUpdateAtZoneSubscription<TData = UserLocationRecordUpdateAtZoneSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserLocationRecordUpdateAtZoneSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserLocationRecordUpdateAtZoneSubscription, TData>
) {
	return Urql.useSubscription<UserLocationRecordUpdateAtZoneSubscription, TData, UserLocationRecordUpdateAtZoneSubscriptionVariables>({ query: UserLocationRecordUpdateAtZoneDocument, ...options }, handler)
}
export const UserLocationRecordsUpdateDocument = gql`
	subscription UserLocationRecordsUpdate {
		userLocationRecordsUpdate {
			...UserLocationRecord
		}
	}
	${UserLocationRecordFragmentDoc}
`

export function useUserLocationRecordsUpdateSubscription<TData = UserLocationRecordsUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserLocationRecordsUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserLocationRecordsUpdateSubscription, TData>
) {
	return Urql.useSubscription<UserLocationRecordsUpdateSubscription, TData, UserLocationRecordsUpdateSubscriptionVariables>({ query: UserLocationRecordsUpdateDocument, ...options }, handler)
}
