/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import { startCase } from "lodash"
import React, { FormEvent, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateUserTypeMutationVariables, PermissionTags, useCreateUserTypeMutation, UserRoleTypes } from "../graphql"
import { useUserPermissions } from "../hooks"

type CreateUserTypeFormValues = CreateUserTypeMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateUserTypeFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	roleType: yup.string().oneOf(Object.values(UserRoleTypes)).required().label("Role Type"),
})

const initialValues: CreateUserTypeFormValues = {
	label: {
		name: "",
		description: "",
	},
	roleType: undefined as any,
}

export const CreateUserTypeForm: React.FC = () => {
	const [{ fetching }, createUserType] = useCreateUserTypeMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateUserTypeFormValues) => {
		const { data, error } = await createUserType({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createUserType) {
			navigate(`/users/types/${data.createUserType._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateUserTypeFormValues>({ initialValues, validationSchema, onSubmit })

	const { checkForPermissions } = useUserPermissions()

	const userRoleTypes = useMemo(() => {
		const _ = []

		if (checkForPermissions(PermissionTags.CreateShortTermUserType)[0]) {
			_.push(UserRoleTypes.ShortTerm)
		}

		if (checkForPermissions(PermissionTags.CreateWorkingUserType)[0]) {
			_.push(UserRoleTypes.Working)
		}

		if (checkForPermissions(PermissionTags.CreateAdminUserType)[0]) {
			_.push(UserRoleTypes.Admin)
		}

		return _
	}, [])

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.roleType && formik.errors.roleType)} isRequired>
					<FormLabel fontWeight="bold">Role Type</FormLabel>

					<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("roleType")}>
						{userRoleTypes.map((roleType) => (
							<option key={roleType} style={{ backgroundColor: "transparent" }} value={roleType}>
								{startCase(roleType)}
							</option>
						))}
					</Select>

					<FormErrorMessage>{formik.errors.roleType}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
