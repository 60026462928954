import { Center, Flex, HStack, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertSourceTypes, useAlertSourceOfAlertFlowQuery } from "../../graphql"
import { Card, Stat } from "../common"

export type AlertFlowSourceProps = {
	alertFlowId: string
}

export const AlertFlowSource: React.FC<AlertFlowSourceProps> = ({ alertFlowId }) => {
	const [{ data, fetching, error }] = useAlertSourceOfAlertFlowQuery({ variables: { alertFlowId } })

	return (
		<Card title="Source">
			{fetching && !data?.alertSourceOfAlertFlow ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading alert source</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.alertSourceOfAlertFlow ? (
				<Flex justify="center" align="center" flexWrap="wrap">
					<Stat label="Type" value={startCase(data.alertSourceOfAlertFlow.type)} />
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.Zones && data.alertSourceOfAlertFlow.zones?.length && (
						<Stat
							label="Zones"
							value={
								<HStack flexWrap="wrap" justify="center" maxW="xs">
									{data.alertSourceOfAlertFlow.zones.map((o, i) => (
										<Link key={o._id} as={NavLink} to={`/zones/${o._id}`}>
											{o.label.name}
											{i < (data.alertSourceOfAlertFlow?.zones?.length || 0) - 1 && ","}
										</Link>
									))}
								</HStack>
							}
						/>
					)}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.ZoneGroups && data.alertSourceOfAlertFlow.zoneGroups?.length && (
						<Stat
							label="Zone Groups"
							value={
								<HStack flexWrap="wrap" justify="center" maxW="xs">
									{data.alertSourceOfAlertFlow.zoneGroups.map((o, i) => (
										<Link key={o._id} as={NavLink} to={`/zones/groups/${o._id}`}>
											{o.label.name}
											{i < (data.alertSourceOfAlertFlow?.zoneGroups?.length || 0) - 1 && ","}
										</Link>
									))}
								</HStack>
							}
						/>
					)}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.Users && data.alertSourceOfAlertFlow.users?.length && (
						<Stat
							label="Users"
							value={
								<HStack flexWrap="wrap" justify="center" maxW="xs">
									{data.alertSourceOfAlertFlow.users.map((o, i) => (
										<Link key={o._id} as={NavLink} to={`/users/${o._id}`}>
											{o.name}
											{i < (data.alertSourceOfAlertFlow?.users?.length || 0) - 1 && ","}
										</Link>
									))}
								</HStack>
							}
						/>
					)}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.UserGroups && data.alertSourceOfAlertFlow.userGroups?.length && (
						<Stat
							label="Zone Groups"
							value={
								<HStack flexWrap="wrap" justify="center" maxW="xs">
									{data.alertSourceOfAlertFlow.userGroups.map((o, i) => (
										<Link key={o._id} as={NavLink} to={`/users/groups/${o._id}`}>
											{o.label.name}
											{i < (data.alertSourceOfAlertFlow?.userGroups?.length || 0) - 1 && ","}
										</Link>
									))}
								</HStack>
							}
						/>
					)}
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any alert source.
					</Text>
				</Center>
			)}
		</Card>
	)
}
