import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"

import { UserLocationRecordFragment } from "../../graphql"
import { RestrictedEntryListItem } from "./RestrictedEntryListItem"

export type RestrictedEntriesListProps = {
	userLocationRecords: UserLocationRecordFragment[]
	loadMore: () => void
}

export const RestrictedEntriesList: React.FC<RestrictedEntriesListProps> = ({ userLocationRecords, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{userLocationRecords.map((userLocationRecord) => (
				<React.Fragment key={userLocationRecord._id}>
					<RestrictedEntryListItem userLocationRecord={userLocationRecord} />
					<Divider />
				</React.Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
