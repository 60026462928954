import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UsersSelector } from "../components"
import { CreateUserGroupMutationVariables, useCreateUserGroupMutation } from "../graphql"

type CreateUserGroupFormValues = CreateUserGroupMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateUserGroupFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	userIds: yup.array(yup.string()).required().min(1).label("Users"),
})

const initialValues: CreateUserGroupFormValues = {
	label: { name: "", description: "" },
	userIds: [],
}

export const CreateUserGroupForm: React.FC = () => {
	const [{ fetching }, createUserGroup] = useCreateUserGroupMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateUserGroupFormValues) => {
		const { data, error } = await createUserGroup({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createUserGroup) {
			navigate(`/users/groups/${data.createUserGroup._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateUserGroupFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
						<FormLabel fontWeight="bold">Name</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

						<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
						<FormLabel fontWeight="bold">Description</FormLabel>

						<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

						<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
					</FormControl>
				</VStack>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.userIds && formik.errors.userIds)}>
						<FormLabel fontWeight="bold">Users</FormLabel>

						<UsersSelector value={formik.values.userIds} onUpdate={(userIds) => formik.setFieldValue("userIds", userIds)} />
						<Text fontSize="sm" color="error.400">
							{formik.errors.userIds}
						</Text>
					</FormControl>
					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Create
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
