import { Flex, HStack, Link, LinkBox, LinkOverlay, ListItem, Spinner, Text } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { TagFragment, useUserByIdQuery } from "../../graphql"
import { Stat } from "../common"

export type TagListItemProps = {
	tag: TagFragment
}

export const TagListItem: React.FC<TagListItemProps> = ({ tag }) => {
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: tag.assignedToUserId ?? "" }, pause: !tag.assignedToUserId })

	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/tags/${tag._id}`} />
			<HStack w="full" justify="space-between" px="2">
				<Flex flex="1" justify="center" align="center" flexWrap="wrap">
					<Stat
						label="User"
						value={
							tag.assignedToUserId ? (
								fetchingUser ? (
									<Spinner size="sm" />
								) : userError ? (
									<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
								) : !userData?.userById ? (
									<Text color="error.500">No user</Text>
								) : (
									<Link as={NavLink} to={`/users/${tag.assignedToUserId}`}>
										{userData.userById.name}
									</Link>
								)
							) : (
								"Not assigned"
							)
						}
					/>
					<Stat label="UID" value={tag.tagDecimalId} />
					<Stat label="Status" value={tag.status} />
					<Stat label="Battery Status" value={tag.batteryStatus} />
				</Flex>
			</HStack>
		</ListItem>
	)
}
