import { Button, Flex, Heading, Link, Modal, ModalBody, ModalContent, ModalOverlay, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React, { useEffect, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import { AlertTypes, UserLocationRecord, useAlertsUpdateSubscription, useRestrictedUserLocationRecordsUpdateSubscription, UserLocationRecordStatus } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Stat } from "../common/Stat"

export const RestrictedEntriesModal: React.FC = () => {
	const [restrictedEntries, setRestrictedEntries] = useState<UserLocationRecord[]>([])

	const [{ data }] = useAlertsUpdateSubscription()
	const [{ data: restrictedEntriesData }] = useRestrictedUserLocationRecordsUpdateSubscription({ variables: { recordIds: restrictedEntries.map((r) => r._id) }, pause: !restrictedEntries.length })

	useEffect(() => {
		if (data?.alertsUpdate.type === AlertTypes.RestrictedEntry && data.alertsUpdate.userLocationRecord && !restrictedEntries.find((r) => r._id === data.alertsUpdate.userLocationRecord?._id)) {
			setRestrictedEntries((prev) => [data.alertsUpdate.userLocationRecord as UserLocationRecord, ...(prev || [])])
		}
	}, [data])

	useEffect(() => {
		if (
			restrictedEntriesData?.restrictedUserLocationRecordsUpdate &&
			(!restrictedEntriesData.restrictedUserLocationRecordsUpdate.isRestricted || restrictedEntriesData.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) &&
			restrictedEntries.find((r) => r._id === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id)
		) {
			const _restrictedEntries = [...restrictedEntries]
			_restrictedEntries.splice(
				restrictedEntries.findIndex((r) => r._id === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id),
				1
			)

			setRestrictedEntries(_restrictedEntries)
		}
	}, [restrictedEntriesData])

	const [isOpen, setIsOpen] = useState((restrictedEntries.length || 0) > 0)

	const alarm = useMemo(() => new Audio("/sounds/alarm.mp3"), [])

	const [acknowledgedIds, setAcknowledgedIds] = useState<string[]>([])

	useEffect(() => {
		if ((restrictedEntries.filter(({ _id }) => !acknowledgedIds.includes(_id)).length || 0) > 0 && isOpen && alarm.paused) {
			alarm.play()
		} else {
			if (!alarm.paused) alarm.pause()
		}

		return () => {
			if (!alarm.paused) alarm.pause()
		}
	}, [restrictedEntries, isOpen, acknowledgedIds])

	useEffect(() => {
		if ((restrictedEntries.filter(({ _id }) => !acknowledgedIds.includes(_id)).length || 0) > 0 && !isOpen) {
			setIsOpen(true)
		}

		if ((restrictedEntries.filter(({ _id }) => !acknowledgedIds.includes(_id)).length || 0) == 0 && isOpen) {
			setIsOpen(false)
		}

		return () => {
			if (isOpen) setIsOpen(false)
		}
	}, [restrictedEntries, acknowledgedIds])

	return (
		<Modal size="2xl" isCentered isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnOverlayClick={false} closeOnEsc={false}>
			<ModalOverlay bgColor="rgba(255,0,0,0.2)">
				<ModalContent>
					<ModalBody>
						<VStack w="full" p="4">
							<Heading fontSize="lg" color="error.800">
								Restricted Entries
							</Heading>

							<VStack w="full" align="stretch" maxH="xs" p="2" overflow="auto">
								{restrictedEntries
									.filter(({ _id }) => !acknowledgedIds.includes(_id))
									.map((entry) => (
										<Flex key={entry._id}>
											<Stat
												label="User"
												value={
													<Link as={NavLink} to={`/users/${entry.userId}`}>
														{entry.user.name}
													</Link>
												}
											/>
											<Stat
												label="Zone"
												value={
													<Link as={NavLink} to={`/zones/${entry.zoneId}`}>
														{entry.zone.label.name}
													</Link>
												}
											/>
											<Stat label="Since" value={format(new Date(entry.startAt), "MMM dd, yyyy p")} />
											<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(entry.endAt), new Date(entry.startAt)))} />
										</Flex>
									))}
							</VStack>

							<Button colorScheme="error" size="sm" onClick={() => setAcknowledgedIds(restrictedEntries.map((o) => o._id) || [])}>
								Acknowledge
							</Button>
						</VStack>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	)
}
