import loadable from "@loadable/component"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Permissions } from "../components"
import { Scaffold } from "../components/common/Scaffold"
import { PermissionTags } from "../graphql"
import { useDashboardContext, useUserPermissions } from "../hooks"

const DashboardPage = loadable(() => import("../pages/dashboard/Dashboard.page"))

const UsersPage = loadable(() => import("../pages/users/Users.page"))
const CreateUserPage = loadable(() => import("../pages/users/CreateUser.page"))
const UpdateUserProfilePage = loadable(() => import("../pages/users/UpdateUserProfile.page"))
const UpdateVisitorDetailsPage = loadable(() => import("../pages/users/UpdateVisitorDetails.page"))
const UpdateUserPermissionsGroupsPage = loadable(() => import("../pages/users/UpdateUserPermissionsGroups.page"))
const ExtendUserRolePage = loadable(() => import("../pages/users/ExtendUserRole.page"))
const AssignUserRolePage = loadable(() => import("../pages/users/AssignUserRole.page"))
const RemoveCurrentUserRolePage = loadable(() => import("../pages/users/RemoveCurrentUserRole.page"))
const ActivateUserPage = loadable(() => import("../pages/users/ActivateUser.page"))
const DeactivateUserPage = loadable(() => import("../pages/users/DeactivateUser.page"))
const UserPageWrapper = loadable(() => import("../components/auth/UserPageWrapper"))
const ProfilePage = loadable(() => import("../pages/Profile.page"))

const AssetsPage = loadable(() => import("../pages/assets/Assets.page"))
const CreateAssetPage = loadable(() => import("../pages/assets/CreateAsset.page"))
const AssetPage = loadable(() => import("../pages/assets/Asset.page"))
const UpdateAssetPage = loadable(() => import("../pages/assets/UpdateAsset.page"))
const ActivateAssetPage = loadable(() => import("../pages/assets/ActivateAsset.page"))
const DeactivateAssetPage = loadable(() => import("../pages/assets/DeactivateAsset.page"))

const CreateAssetInventoryAuditPage = loadable(() => import("../pages/assets/CreateAssetInventoryAudit.page"))
const CreateAssetPlacementRecordPage = loadable(() => import("../pages/assets/CreateAssetPlacementRecord.page"))

const AssetCategoriesPage = loadable(() => import("../pages/assetCategories/AssetCategories.page"))
const AssetCategoryPage = loadable(() => import("../pages/assetCategories/AssetCategory.page"))
const CreateAssetCategoryPage = loadable(() => import("../pages/assetCategories/CreateAssetCategory.page"))
const UpdateAssetCategoryPage = loadable(() => import("../pages/assetCategories/UpdateAssetCategory.page"))

const ActiveUsersInLastIntervalPage = loadable(() => import("../pages/users/ActiveUsersInLastInterval.page"))
const ActiveUsersPage = loadable(() => import("../pages/users/ActiveUsers.page"))
const OvertimeUsersPage = loadable(() => import("../pages/users/OvertimeUsers.page"))

const ActiveAssetsInLastIntervalPage = loadable(() => import("../pages/assets/ActiveAssetsInLastInterval.page"))
const ActiveAssetsPage = loadable(() => import("../pages/assets/ActiveAssets.page"))
const WeighedAssetsPage = loadable(() => import("../pages/assets/WeighedAssets.page"))

const UserGroupsPage = loadable(() => import("../pages/userGroups/UserGroups.page"))
const UserGroupPage = loadable(() => import("../pages/userGroups/UserGroup.page"))
const CreateUserGroupPage = loadable(() => import("../pages/userGroups/CreateUserGroup.page"))

const AssignTagPage = loadable(() => import("../pages/users/AssignTag.page"))
const FlushTagPage = loadable(() => import("../pages/users/FlushTag.page"))
const ActivateTagOfUserPage = loadable(() => import("../pages/users/ActivateTagOfUser.page"))
const DeactivateTagOfUserPage = loadable(() => import("../pages/users/DeactivateTagOfUser.page"))

const AssignTagToAssetPage = loadable(() => import("../pages/assets/AssignTagToAsset.page"))
const FlushTagOfAssetPage = loadable(() => import("../pages/assets/FlushTagOfAsset.page"))
const ActivateTagOfAssetPage = loadable(() => import("../pages/assets/ActivateTagOfAsset.page"))
const DeactivateTagOfAssePage = loadable(() => import("../pages/assets/DeactivateTagOfAsset.page"))

const UserTypesPage = loadable(() => import("../pages/userTypes/UserTypes.page"))
const UserTypePage = loadable(() => import("../pages/userTypes/UserType.page"))
const CreateUserTypePage = loadable(() => import("../pages/userTypes/CreateUserType.page"))

const MapPage = loadable(() => import("../pages/maps/Map.page"))
const MapUserSearchPage = loadable(() => import("../pages/maps/MapUserSearch.page"))
const MapAssetSearchPage = loadable(() => import("../pages/maps/MapAssetSearch.page"))
const TvPage = loadable(() => import("../pages/Tv.page"))

const TilesPage = loadable(() => import("../pages/tiles/Tiles.page"))
const CreateTilePage = loadable(() => import("../pages/tiles/CreateTile.page"))
const TilePage = loadable(() => import("../pages/tiles/Tile.page"))
const EmbedZonesOnTilePage = loadable(() => import("../pages/tiles/EmbedZonesOnTile.page"))

const ZonesPage = loadable(() => import("../pages/zones/Zones.page"))
const ZonePage = loadable(() => import("../pages/zones/Zone.page"))
const CreateZonePage = loadable(() => import("../pages/zones/CreateZone.page"))
const UpdateZonePage = loadable(() => import("../pages/zones/UpdateZone.page"))
const AddReaderToZonePage = loadable(() => import("../pages/zones/AddReaderToZone.page"))
const RemoveReaderFromZonePage = loadable(() => import("../pages/zones/RemoveReaderFromZone.page"))

const ZoneGroupsPage = loadable(() => import("../pages/ZoneGroups/ZoneGroups.page"))
const ZoneGroupPage = loadable(() => import("../pages/ZoneGroups/ZoneGroup.page"))
const CreateZoneGroupPage = loadable(() => import("../pages/ZoneGroups/CreateZoneGroup.page"))
const UpdateZoneGroupPage = loadable(() => import("../pages/ZoneGroups/UpdateZoneGroup.page"))

const PermissionsGroupsPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroups.page"))
const PermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroup.page"))
const CreatePermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/CreatePermissionsGroup.page"))

const GenerateUserLocationRecordsReportPage = loadable(() => import("../pages/reports/generate/GenerateUserLocationRecordsReport.page"))
const GenerateZoneLocationRecordsReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneLocationRecordsReport.page"))
const GenerateZoneGroupLocationRecordsReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneGroupLocationRecordsReport.page"))
const GenerateBuildingLocationRecordsReportPage = loadable(() => import("../pages/reports/generate/GenerateBuildingLocationRecordsReport.page"))
const GenerateUserAttendanceReportPage = loadable(() => import("../pages/reports/generate/GenerateUserAttendanceReport.page"))
const GenerateReadersStatusLogsReportPage = loadable(() => import("../pages/reports/generate/GenerateReadersStatusLogsReport.page"))
const GenerateUserRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateUserRestrictedEntriesReport.page"))
const GenerateZoneRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneRestrictedEntriesReport.page"))
const GenerateZoneGroupRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneGroupRestrictedEntriesReport.page"))
const GenerateDashboardReportPage = loadable(() => import("../pages/reports/generate/GenerateDashboardReport.page"))
const GenerateActiveUsersAtZonesReportPage = loadable(() => import("../pages/reports/generate/GenerateActiveUsersAtZonesReport.page"))
const GenerateActiveUsersAtZoneGroupsReportPage = loadable(() => import("../pages/reports/generate/GenerateActiveUsersAtZoneGroupsReport.page"))
const GenerateActiveUsersAtBuildingsReportPage = loadable(() => import("../pages/reports/generate/GenerateActiveUsersAtBuildingsReport.page"))
const GenerateVisitorReportPage = loadable(() => import("../pages/reports/generate/GenerateVisitorReport.page"))
const GenerateAssetMovementReportPage = loadable(() => import("../pages/reports/generate/GenerateAssetMovementReport.page"))

const ReportPage = loadable(() => import("../pages/reports/past/Report"))
const ReportsPage = loadable(() => import("../pages/reports/past/Reports"))

const ChangePassword = loadable(() => import("../pages/ChangePassword.page"))
const VerifyEmail = loadable(() => import("../pages/VerifyEmail.page"))

const UserRoleGroupsPage = loadable(() => import("../pages/userRoleGroups/UserRoleGroups.page"))
const CreateUserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/CreateUserRoleGroup.page"))
const UserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/UserRoleGroup.page"))
const UpdateUserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/UpdateUserRoleGroup.page"))

const DepartmentsPage = loadable(() => import("../pages/departments/Departments.page"))
const DepartmentPage = loadable(() => import("../pages/departments/Department.page"))
const CreateDepartmentPage = loadable(() => import("../pages/departments/CreateDepartment.page"))
const AssignDepartmentHeadPage = loadable(() => import("../pages/departments/AssignDepartmentHead.page"))
const RemoveDepartmentHeadPage = loadable(() => import("../pages/departments/RemoveDepartmentHead.page"))

const ReadersPage = loadable(() => import("../pages/readers/Readers.page"))
const ReaderPage = loadable(() => import("../pages/readers/Reader.page"))
const CreateReaderPage = loadable(() => import("../pages/readers/CreateReader.page"))
const UpdateReaderPage = loadable(() => import("../pages/readers/UpdateReader.page"))

const DevicesPage = loadable(() => import("../pages/devices/Devices.page"))
const DevicePage = loadable(() => import("../pages/devices/Device.page"))
const CreateDevicePage = loadable(() => import("../pages/devices/CreateDevice.page"))
const UpdateDevicePage = loadable(() => import("../pages/devices/UpdateDevice.page"))

const TagsPage = loadable(() => import("../pages/tags/Tags.page"))
const TagPage = loadable(() => import("../pages/tags/Tag.page"))
const CreateTagPage = loadable(() => import("../pages/tags/CreateTag.page"))

const AlertTargetGroupsPage = loadable(() => import("../pages/alerts/targetGroups/AlertTargetGroups.page"))
const AlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/AlertTargetGroup.page"))
const CreateAlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/CreateAlertTargetGroup.page"))
const UpdateAlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/UpdateAlertTargetGroup.page"))

const AlertsPage = loadable(() => import("../pages/alerts/Alerts.page"))
const AlertPage = loadable(() => import("../pages/alerts/Alert.page"))

const AlertFlowsPage = loadable(() => import("../pages/alerts/flows/AlertFlows.page"))
const AlertFlowPage = loadable(() => import("../pages/alerts/flows/AlertFlow.page"))
const CreateAlertFlowPage = loadable(() => import("../pages/alerts/flows/CreateAlertFlow.page"))
const UpdateAlertFlowPage = loadable(() => import("../pages/alerts/flows/UpdateAlertFlow.page"))

const ServicesPage = loadable(() => import("../pages/services/Services.page"))
const ServicePage = loadable(() => import("../pages/services/Service.page"))
const CreateServicePage = loadable(() => import("../pages/services/CreateService.page"))

const BuildingsPage = loadable(() => import("../pages/buildings/Buildings.page"))
const BuildingPage = loadable(() => import("../pages/buildings/Building.page"))
const CreateBuildingPage = loadable(() => import("../pages/buildings/CreateBuilding.page"))
const UpdateBuildingPage = loadable(() => import("../pages/buildings/UpdateBuilding.page"))
const DeleteBuildingPage = loadable(() => import("../pages/buildings/DeleteBuilding.page"))
const BuildingFloorPage = loadable(() => import("../pages/buildings/BuildingFloor.page"))
const CreateBuildingFloorPage = loadable(() => import("../pages/buildings/CreateBuildingFloor.page"))
const UpdateBuildingFloorPage = loadable(() => import("../pages/buildings/UpdateBuildingFloor.page"))
const DeleteBuildingFloorPage = loadable(() => import("../pages/buildings/DeleteBuildingFloor.page"))

const TodayRestrictedEntriesPage = loadable(() => import("../pages/restrictedEntries/TodayRestrictedEntries.page"))
const ActiveRestrictedEntriesPage = loadable(() => import("../pages/restrictedEntries/ActiveRestrictedEntries.page"))

const NotFoundPage = loadable(() => import("../pages/NotFound.page"))
const UnauthorizedPage = loadable(() => import("../pages/Unauthorized.page"))

export const RootRouter: React.FC = () => {
	const { checkForSomePermissions } = useUserPermissions()

	useDashboardContext(true)

	return (
		<Routes>
			<Route
				path="/tv"
				element={
					<Permissions tags={[PermissionTags.ReadTile, PermissionTags.ReadTileEmbeddedZone]}>
						<TvPage />
					</Permissions>
				}
			/>
			<Route
				path="/map"
				element={
					<Permissions tags={[PermissionTags.ReadTile, PermissionTags.ReadTileEmbeddedZone]}>
						<MapPage />
					</Permissions>
				}
			/>
			<Route
				path="/map/searchUser"
				element={
					<Permissions tags={[PermissionTags.ReadTile, PermissionTags.ReadTileEmbeddedZone]}>
						<MapUserSearchPage />
					</Permissions>
				}
			/>

			<Route
				path="/map/searchAsset"
				element={
					<Permissions tags={[PermissionTags.ReadTile, PermissionTags.ReadTileEmbeddedZone]}>
						<MapAssetSearchPage />
					</Permissions>
				}
			/>

			<Route
				path="/map/:tileId"
				element={
					<Permissions tags={[PermissionTags.ReadTile, PermissionTags.ReadTileEmbeddedZone]}>
						<MapPage />
					</Permissions>
				}
			/>

			<Route path="/" element={<Scaffold />}>
				<Route
					path="/"
					element={
						checkForSomePermissions(PermissionTags.ReadDisasterManagementDashboard, PermissionTags.ReadConfidentialityDashboard, PermissionTags.ReadAssetManagementDashboard)[0] ? (
							<Permissions tags={[PermissionTags.ReadDisasterManagementDashboard, PermissionTags.ReadConfidentialityDashboard, PermissionTags.ReadAssetManagementDashboard]} checkType="or">
								<DashboardPage />
							</Permissions>
						) : (
							<Navigate to="/profile" />
						)
					}
				/>

				<Route
					path="/users"
					element={
						<Permissions tags={[PermissionTags.ReadShortTermUser, PermissionTags.ReadWorkingUser, PermissionTags.ReadAdminUser]} checkType="or">
							<UsersPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/create"
					element={
						<Permissions tags={[PermissionTags.CreateShortTermUser, PermissionTags.CreateWorkingUser, PermissionTags.CreateAdminUser]} checkType="or">
							<CreateUserPage />
						</Permissions>
					}
				/>

				<Route path="/users/:userId" element={<UserPageWrapper />} />
				<Route
					path="/users/:userId/updateProfile"
					element={
						<Permissions tags={[PermissionTags.UpdateShortTermUser, PermissionTags.UpdateWorkingUser, PermissionTags.UpdateAdminUser]} checkType="or">
							<UpdateUserProfilePage />
						</Permissions>
					}
				/>

				<Route
					path="users/:userId/updateVisitorDetails"
					element={
						<Permissions tags={[PermissionTags.UpdateShortTermUser]}>
							<UpdateVisitorDetailsPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/deactivate"
					element={
						<Permissions tags={[PermissionTags.DeactivateShortTermUser, PermissionTags.DeactivateWorkingUser, PermissionTags.DeactivateAdminUser]} checkType="or">
							<DeactivateUserPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/activate"
					element={
						<Permissions tags={[PermissionTags.ActivateShortTermUser, PermissionTags.ActivateWorkingUser, PermissionTags.ActivateAdminUser]} checkType="or">
							<ActivateUserPage />
						</Permissions>
					}
				/>

				<Route path="/users/:userId/deactivateTag" element={<DeactivateTagOfUserPage />} />
				<Route path="/users/:userId/activateTag" element={<ActivateTagOfUserPage />} />
				<Route path="/users/:userId/assignTag" element={<AssignTagPage />} />
				<Route path="/users/:userId/flushTag" element={<FlushTagPage />} />

				<Route
					path="/users/:userId/updatePermissionsGroups"
					element={
						<Permissions tags={[PermissionTags.UpdateShortTermUserPermissionsGroup, PermissionTags.UpdateWorkingUserPermissionsGroup, PermissionTags.UpdateAdminUserPermissionsGroup]} checkType="or">
							<UpdateUserPermissionsGroupsPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/extendUserRole"
					element={
						<Permissions tags={[PermissionTags.ExtendShortTermRole, PermissionTags.ExtendWorkingRole]} checkType="or">
							<ExtendUserRolePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/assignUserRole"
					element={
						<Permissions tags={[PermissionTags.AssignShortTermRole, PermissionTags.AssignWorkingRole]} checkType="or">
							<AssignUserRolePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/removeUserRole"
					element={
						<Permissions tags={[PermissionTags.RemoveShortTermRole, PermissionTags.RemoveWorkingRole]} checkType="or">
							<RemoveCurrentUserRolePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/types"
					element={
						<Permissions tags={[PermissionTags.ReadShortTermUserType, PermissionTags.ReadWorkingUserType, PermissionTags.ReadAdminUserType]} checkType="or">
							<UserTypesPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/types/create"
					element={
						<Permissions tags={[PermissionTags.CreateShortTermUserType, PermissionTags.CreateWorkingUserType, PermissionTags.CreateAdminUserType]} checkType="or">
							<CreateUserTypePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/types/:userTypeId"
					element={
						<Permissions tags={[PermissionTags.ReadShortTermUserType, PermissionTags.ReadWorkingUserType, PermissionTags.ReadAdminUserType]} checkType="or">
							<UserTypePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/groups"
					element={
						<Permissions tags={[PermissionTags.ReadUserGroup]}>
							<UserGroupsPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/groups/create"
					element={
						<Permissions tags={[PermissionTags.CreateUserGroup]}>
							<CreateUserGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/groups/:userGroupId"
					element={
						<Permissions tags={[PermissionTags.ReadUserGroup]}>
							<UserGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/activeInLastInterval"
					element={
						<Permissions tags={[PermissionTags.ReadShortTermUser, PermissionTags.ReadWorkingUser, PermissionTags.ReadAdminUser]} checkType="or">
							<ActiveUsersInLastIntervalPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/active"
					element={
						<Permissions tags={[PermissionTags.ReadShortTermUser, PermissionTags.ReadWorkingUser, PermissionTags.ReadAdminUser]} checkType="or">
							<ActiveUsersPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/overtime"
					element={
						<Permissions tags={[PermissionTags.ReadShortTermUser, PermissionTags.ReadWorkingUser, PermissionTags.ReadAdminUser]} checkType="or">
							<OvertimeUsersPage />
						</Permissions>
					}
				/>

				<Route path="/profile" element={<ProfilePage />} />

				<Route
					path="/assets"
					element={
						<Permissions tags={[PermissionTags.ReadAsset]}>
							<AssetsPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/create"
					element={
						<Permissions tags={[PermissionTags.CreateAsset]} checkType="or">
							<CreateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId"
					element={
						<Permissions tags={[PermissionTags.ReadAsset]}>
							<AssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateAsset]}>
							<UpdateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId/deactivate"
					element={
						<Permissions tags={[PermissionTags.DeactivateAsset]}>
							<DeactivateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId/activate"
					element={
						<Permissions tags={[PermissionTags.ActivateAsset]}>
							<ActivateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/activeInLastInterval"
					element={
						<Permissions tags={[PermissionTags.ReadAsset]}>
							<ActiveAssetsInLastIntervalPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/active"
					element={
						<Permissions tags={[PermissionTags.ReadAsset]}>
							<ActiveAssetsPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/weighed"
					element={
						<Permissions tags={[PermissionTags.ReadAsset]}>
							<WeighedAssetsPage />
						</Permissions>
					}
				/>

				<Route path="/assets/:assetId/assignTag" element={<AssignTagToAssetPage />} />
				<Route path="/assets/:assetId/flushTag" element={<FlushTagOfAssetPage />} />
				<Route path="/assets/:assetId/activateTag" element={<ActivateTagOfAssetPage />} />
				<Route path="/assets/:assetId/deactivateTag" element={<DeactivateTagOfAssePage />} />

				<Route
					path="/assets/:assetId/audit"
					element={
						<Permissions tags={[PermissionTags.ReadAssetInventoryAudit, PermissionTags.CreateAssetInventoryAudit]}>
							<CreateAssetInventoryAuditPage />
						</Permissions>
					}
				/>
				<Route
					path="/assets/:assetId/move"
					element={
						<Permissions tags={[PermissionTags.ReadAssetPlacementRecord, PermissionTags.CreateAssetPlacementRecord]} checkType="and">
							<CreateAssetPlacementRecordPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/categories"
					element={
						<Permissions tags={[PermissionTags.ReadAssetCategory]}>
							<AssetCategoriesPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/categories/create"
					element={
						<Permissions tags={[PermissionTags.ReadAssetCategory, PermissionTags.CreateAssetCategory]}>
							<CreateAssetCategoryPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/categories/:assetCategoryId"
					element={
						<Permissions tags={[PermissionTags.ReadAssetCategory]}>
							<AssetCategoryPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/categories/:assetCategoryId/update"
					element={
						<Permissions tags={[PermissionTags.ReadAssetCategory, PermissionTags.UpdateAssetCategory]}>
							<UpdateAssetCategoryPage />
						</Permissions>
					}
				/>

				<Route
					path="/restrictedEntries/today"
					element={
						<Permissions tags={[PermissionTags.ReadRestrictedEntry]} checkType="or">
							<TodayRestrictedEntriesPage />
						</Permissions>
					}
				/>

				<Route
					path="/restrictedEntries/active"
					element={
						<Permissions tags={[PermissionTags.ReadRestrictedEntry]} checkType="or">
							<ActiveRestrictedEntriesPage />
						</Permissions>
					}
				/>

				<Route
					path="/zones"
					element={
						<Permissions tags={[PermissionTags.ReadZone]}>
							<ZonesPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/create"
					element={
						<Permissions tags={[PermissionTags.CreateZone]}>
							<CreateZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId"
					element={
						<Permissions tags={[PermissionTags.ReadZone]}>
							<ZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateZone]}>
							<UpdateZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/addReader"
					element={
						<Permissions tags={[PermissionTags.AddReaderToZone]}>
							<AddReaderToZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/:readerId/remove"
					element={
						<Permissions tags={[PermissionTags.RemoveReaderFromZone]}>
							<RemoveReaderFromZonePage />
						</Permissions>
					}
				/>

				<Route
					path="/zones/groups"
					element={
						<Permissions tags={[PermissionTags.ReadZoneGroup]}>
							<ZoneGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/create"
					element={
						<Permissions tags={[PermissionTags.CreateZoneGroup]}>
							<CreateZoneGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/:zoneGroupId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateZoneGroup]}>
							<UpdateZoneGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/:zoneGroupId"
					element={
						<Permissions tags={[PermissionTags.ReadZoneGroup]}>
							<ZoneGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/permissionsGroups"
					element={
						<Permissions tags={[PermissionTags.ReadPermissionsTags, PermissionTags.ReadPermissionsGroup]}>
							<PermissionsGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/create"
					element={
						<Permissions tags={[PermissionTags.ReadPermissionsTags, PermissionTags.ReadPermissionsGroup, PermissionTags.CreatePermissionsGroup]}>
							<CreatePermissionsGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/:permissionsGroupId"
					element={
						<Permissions tags={[PermissionTags.ReadPermissionsTags, PermissionTags.ReadPermissionsGroup]}>
							<PermissionsGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/userRoleGroups"
					element={
						<Permissions tags={[PermissionTags.ReadWorkingRoleGroup, PermissionTags.ReadShortTermRoleGroup]} checkType="or">
							<UserRoleGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/create"
					element={
						<Permissions tags={[PermissionTags.CreateWorkingRoleGroup, PermissionTags.CreateShortTermRoleGroup]} checkType="or">
							<CreateUserRoleGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/:userRoleGroupId"
					element={
						<Permissions tags={[PermissionTags.ReadWorkingRoleGroup, PermissionTags.ReadShortTermRoleGroup]} checkType="or">
							<UserRoleGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/:userRoleGroupId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateWorkingRoleGroup, PermissionTags.UpdateShortTermRoleGroup]} checkType="or">
							<UpdateUserRoleGroupPage />
						</Permissions>
					}
				/>

				<Route path="/reports/past" element={<ReportsPage />} />
				<Route path="/reports/past/:reportId" element={<ReportPage />} />

				<Route
					path="/reports/generate/locationRecords/users"
					element={
						<Permissions tags={[PermissionTags.CreateUserLocationRecordsReport]}>
							<GenerateUserLocationRecordsReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/locationRecords/zones"
					element={
						<Permissions tags={[PermissionTags.CreateZoneLocationRecordsReport]}>
							<GenerateZoneLocationRecordsReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/locationRecords/zoneGroups"
					element={
						<Permissions tags={[PermissionTags.CreateZoneGroupLocationRecordsReport]}>
							<GenerateZoneGroupLocationRecordsReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/locationRecords/buildings"
					element={
						<Permissions tags={[PermissionTags.CreateBuildingLocationRecordsReport]}>
							<GenerateBuildingLocationRecordsReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/userAttendance"
					element={
						<Permissions tags={[PermissionTags.CreateUserAttendanceReport]}>
							<GenerateUserAttendanceReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/dashboard"
					element={
						<Permissions tags={[PermissionTags.CreateDashboardReport]}>
							<GenerateDashboardReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/readersStatusLogs"
					element={
						<Permissions tags={[PermissionTags.CreateReaderStatusLogsReport]}>
							<GenerateReadersStatusLogsReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/restrictedEntries/users"
					element={
						<Permissions tags={[PermissionTags.CreateUserRestrictedEntryReport]}>
							<GenerateUserRestrictedEntriesReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/restrictedEntries/zones"
					element={
						<Permissions tags={[PermissionTags.CreateZoneRestrictedEntryReport]}>
							<GenerateZoneRestrictedEntriesReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/activeUsersAtZones"
					element={
						<Permissions tags={[PermissionTags.CreateActiveUsersReport]}>
							<GenerateActiveUsersAtZonesReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/activeUsersAtZoneGroups"
					element={
						<Permissions tags={[PermissionTags.CreateActiveUsersReport]}>
							<GenerateActiveUsersAtZoneGroupsReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/activeUsersAtBuildings"
					element={
						<Permissions tags={[PermissionTags.CreateActiveUsersReport]}>
							<GenerateActiveUsersAtBuildingsReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/visitor"
					element={
						<Permissions tags={[PermissionTags.CreateVisitorReport]}>
							<GenerateVisitorReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/assetMovement"
					element={
						<Permissions tags={[PermissionTags.CreateAssetLocationRecordsReport]}>
							<GenerateAssetMovementReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/restrictedEntries/zoneGroups"
					element={
						<Permissions tags={[PermissionTags.CreateZoneGroupRestrictedEntryReport]}>
							<GenerateZoneGroupRestrictedEntriesReportPage />
						</Permissions>
					}
				/>

				<Route path="/changePassword" element={<ChangePassword />} />
				<Route path="/verifyEmail" element={<VerifyEmail />} />

				<Route
					path="/departments"
					element={
						<Permissions tags={[PermissionTags.ReadDepartment]}>
							<DepartmentsPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/create"
					element={
						<Permissions tags={[PermissionTags.CreateDepartment]}>
							<CreateDepartmentPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/:departmentId"
					element={
						<Permissions tags={[PermissionTags.ReadDepartment]}>
							<DepartmentPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/:departmentId/assignHead"
					element={
						<Permissions tags={[PermissionTags.ReadDepartment, PermissionTags.UpdateDepartment, PermissionTags.AssignDepartmentHead]}>
							<AssignDepartmentHeadPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/:departmentId/removeHead"
					element={
						<Permissions tags={[PermissionTags.ReadDepartment, PermissionTags.UpdateDepartment, PermissionTags.RemoveDepartmentHead]}>
							<RemoveDepartmentHeadPage />
						</Permissions>
					}
				/>

				<Route
					path="/readers"
					element={
						<Permissions tags={[PermissionTags.ReadReader]}>
							<ReadersPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/:readerId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateReader]}>
							<UpdateReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/:readerId"
					element={
						<Permissions tags={[PermissionTags.ReadReader]}>
							<ReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/create"
					element={
						<Permissions tags={[PermissionTags.CreateReader]}>
							<CreateReaderPage />
						</Permissions>
					}
				/>

				<Route
					path="/devices"
					element={
						<Permissions tags={[PermissionTags.ReadDevice]}>
							<DevicesPage />
						</Permissions>
					}
				/>
				<Route
					path="/devices/:deviceId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateDevice]}>
							<UpdateDevicePage />
						</Permissions>
					}
				/>
				<Route
					path="/devices/:deviceId"
					element={
						<Permissions tags={[PermissionTags.ReadDevice]}>
							<DevicePage />
						</Permissions>
					}
				/>
				<Route
					path="/devices/create"
					element={
						<Permissions tags={[PermissionTags.CreateDevice]}>
							<CreateDevicePage />
						</Permissions>
					}
				/>

				<Route
					path="/tags"
					element={
						<Permissions tags={[PermissionTags.ReadTag]}>
							<TagsPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags/create"
					element={
						<Permissions tags={[PermissionTags.CreateTag]}>
							<CreateTagPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags/:tagId"
					element={
						<Permissions tags={[PermissionTags.ReadTag]}>
							<TagPage />
						</Permissions>
					}
				/>

				<Route
					path="/alerts"
					element={
						<Permissions tags={[PermissionTags.ReadAlert]}>
							<AlertsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/:alertId"
					element={
						<Permissions tags={[PermissionTags.ReadAlert]}>
							<AlertPage />
						</Permissions>
					}
				/>

				<Route
					path="/alerts/targetGroups"
					element={
						<Permissions tags={[PermissionTags.ReadAlertTargetGroup]}>
							<AlertTargetGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/create"
					element={
						<Permissions tags={[PermissionTags.CreateAlertTargetGroup]}>
							<CreateAlertTargetGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/:alertTargetGroupId"
					element={
						<Permissions tags={[PermissionTags.ReadAlertTargetGroup]}>
							<AlertTargetGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/:alertTargetGroupId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateAlertTargetGroup]}>
							<UpdateAlertTargetGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/alerts/flows"
					element={
						<Permissions tags={[PermissionTags.ReadAlertFlow]}>
							<AlertFlowsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/create"
					element={
						<Permissions tags={[PermissionTags.CreateAlertFlow]}>
							<CreateAlertFlowPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/:alertFlowId"
					element={
						<Permissions tags={[PermissionTags.ReadAlertFlow]}>
							<AlertFlowPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/:alertFlowId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateAlertFlow]}>
							<UpdateAlertFlowPage />
						</Permissions>
					}
				/>

				<Route
					path="/services"
					element={
						<Permissions tags={[PermissionTags.ReadService]}>
							<ServicesPage />
						</Permissions>
					}
				/>
				<Route
					path="/services/create"
					element={
						<Permissions tags={[PermissionTags.CreateService]}>
							<CreateServicePage />
						</Permissions>
					}
				/>
				<Route
					path="/services/:serviceId"
					element={
						<Permissions tags={[PermissionTags.ReadService]}>
							<ServicePage />
						</Permissions>
					}
				/>

				<Route
					path="/zones/buildings"
					element={
						<Permissions tags={[PermissionTags.ReadBuilding]}>
							<BuildingsPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/create"
					element={
						<Permissions tags={[PermissionTags.CreateBuilding]}>
							<CreateBuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateBuilding]}>
							<UpdateBuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/delete"
					element={
						<Permissions tags={[PermissionTags.DeleteBuilding]}>
							<DeleteBuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId"
					element={
						<Permissions tags={[PermissionTags.ReadBuilding]}>
							<BuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/addFloor"
					element={
						<Permissions tags={[PermissionTags.CreateBuildingFloor]}>
							<CreateBuildingFloorPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/floors/:floorId"
					element={
						<Permissions tags={[PermissionTags.ReadBuildingFloor]}>
							<BuildingFloorPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/floors/:floorId/update"
					element={
						<Permissions tags={[PermissionTags.UpdateBuildingFloor]}>
							<UpdateBuildingFloorPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/floors/:floorId/delete"
					element={
						<Permissions tags={[PermissionTags.DeleteBuildingFloor]}>
							<DeleteBuildingFloorPage />
						</Permissions>
					}
				/>

				<Route
					path="/tiles"
					element={
						<Permissions tags={[PermissionTags.ReadTile]}>
							<TilesPage />
						</Permissions>
					}
				/>

				<Route
					path="/tiles/create"
					element={
						<Permissions tags={[PermissionTags.CreateTile]} checkType="or">
							<CreateTilePage />
						</Permissions>
					}
				/>

				<Route
					path="/tiles/:tileId"
					element={
						<Permissions tags={[PermissionTags.ReadTile]}>
							<TilePage />
						</Permissions>
					}
				/>
				<Route
					path="/tiles/:tileId/embedZones"
					element={
						<Permissions tags={[PermissionTags.ReadTile]}>
							<EmbedZonesOnTilePage />
						</Permissions>
					}
				/>
			</Route>
			<Route path="/unauthorized" element={<UnauthorizedPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	)
}
