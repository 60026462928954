import { Heading, HStack, LinkBox, LinkOverlay, ListItem, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ZoneGroupFragment } from "../../graphql"

export type ZoneGroupListItemProps = {
	zoneGroup: ZoneGroupFragment
}

export const ZoneGroupListItem: React.FC<ZoneGroupListItemProps> = ({ zoneGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/zones/groups/${zoneGroup._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start">
					<Heading fontSize="md" color="grayscale.title">
						{zoneGroup.label.name}
					</Heading>
				</VStack>
			</HStack>
		</ListItem>
	)
}
