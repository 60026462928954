/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Heading, HStack, InputGroup, InputRightAddon, NumberInput, NumberInputField, Text, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AssetDetails } from "../components"
import { CreateAssetInventoryAuditMutationVariables, AssetFragment, useCreateAssetInventoryAuditMutation } from "../graphql"

type CreateAssetInventoryAuditFormValues = Pick<CreateAssetInventoryAuditMutationVariables["input"], "inventoryWeightInKG">

const validationSchema = yup.object<Record<keyof CreateAssetInventoryAuditFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	inventoryWeightInKG: yup.number().required().label("Inventory Weight (in KG)"),
})

const initialValues: CreateAssetInventoryAuditFormValues = {
	inventoryWeightInKG: 0,
}

type CreateAssetInventoryAuditFormProps = {
	asset: AssetFragment
}

export const CreateAssetInventoryAuditForm: React.FC<CreateAssetInventoryAuditFormProps> = ({ asset }) => {
	const [{ fetching }, createAssetInventoryAudit] = useCreateAssetInventoryAuditMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ inventoryWeightInKG }: CreateAssetInventoryAuditFormValues) => {
		const { data, error } = await createAssetInventoryAudit({
			input: { assetId: asset._id, inventoryWeightInKG: parseFloat(inventoryWeightInKG as unknown as string) },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createAssetInventoryAudit) {
			navigate(`/assets/${asset._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateAssetInventoryAuditFormValues>({
		initialValues,
		validationSchema,
		onSubmit,
	})

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<VStack w="full" align="stretch">
					<Heading fontSize="lg" color="blackAlpha.700">
						Asset
					</Heading>
					<AssetDetails asset={asset} disableActions />
				</VStack>
				<HStack alignItems="flex-end">
					<FormControl isInvalid={Boolean(formik.touched.inventoryWeightInKG && formik.errors.inventoryWeightInKG)} isRequired>
						<FormLabel fontWeight="bold">Inventory Weight</FormLabel>
						<InputGroup>
							<NumberInput
								roundedLeft="xl"
								variant="filled"
								bgColor="grayscale.input-background"
								placeholder="Enter weight"
								_placeholder={{ color: "grayscale.placeholer" }}
								{...formik.getFieldProps("inventoryWeightInKG")}
								onChange={(valueAsString) => formik.setFieldValue("inventoryWeightInKG", valueAsString || 0)}
							>
								<NumberInputField />
							</NumberInput>
							<InputRightAddon>
								<Text>KG</Text>
							</InputRightAddon>
						</InputGroup>

						<FormErrorMessage>{formik.errors.inventoryWeightInKG}</FormErrorMessage>
					</FormControl>
					<FormControl isReadOnly>
						<FormLabel fontWeight="bold">Count</FormLabel>
						<InputGroup>
							<NumberInput roundedLeft="xl" variant="filled" bgColor="grayscale.input-background" value={Math.round(formik.values.inventoryWeightInKG / (asset.weightInKG || 1)) || 0} isReadOnly>
								<NumberInputField />
							</NumberInput>
							<InputRightAddon>
								<Text>units</Text>
							</InputRightAddon>
						</InputGroup>
					</FormControl>
				</HStack>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
