import { Divider, Flex, List, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { Fragment, useEffect, useState } from "react"
import { UserFragment } from "../../graphql"
import { Stat } from "../common"

export type VisitorPastDetailsProps = {
	user: UserFragment
}

export const VisitorPastDetails: React.FC<VisitorPastDetailsProps> = ({ user }) => {
	const [items, setItems] = useState<typeof user.pastVisitorDetails>()

	useEffect(() => {
		const _ = [...(user.pastVisitorDetails ?? [])]

		_.reverse()

		setItems(_)
	}, [user.pastVisitorDetails])

	return (
		<List spacing={2}>
			{items?.map((pastVisitorDetail) => (
				<Fragment key={user._id}>
					<ListItem _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
						<Flex justify="center" align="center" flexWrap="wrap">
							{pastVisitorDetail?.visitDate && <Stat label="Visit Date" value={format(new Date(pastVisitorDetail?.visitDate), "PP") || "Not available"} />}
							<Stat label="Company Name" value={pastVisitorDetail.companyName || "Not available"} />
							<Stat label="Level" value={pastVisitorDetail.level || "Not available"} />
							<Stat label="Card Number" value={pastVisitorDetail.visitorCardNo || "Not available"} />
							<Stat label="Building" value={pastVisitorDetail.buildingName || "Not available"} />
						</Flex>
					</ListItem>
					<Divider />
				</Fragment>
			))}
		</List>
	)
}
