import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useAssetByIdQuery } from "../../graphql"
import { AssetListItem } from "../assets/AssetListItem"

export const AssetOfAlert: React.FC<{ assetId: string }> = ({ assetId }) => {
	const [{ data, fetching, error }] = useAssetByIdQuery({ variables: { assetId } })

	return (
		<>
			{fetching && !data?.assetById ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading asset</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.assetById ? (
				<AssetListItem asset={data.assetById} />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find the asset.
					</Text>
				</Center>
			)}
		</>
	)
}
