import { PermissionTags } from "../../graphql"

export enum PermissionsContextActions {
	SET_PERMISSIONS,
}

export type PermissionsContextState = {
	permissionTags?: PermissionTags[]
}

export type PermissionsContextAction = { type: PermissionsContextActions; payload: PermissionTags[] }
