/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, InputGroup, Select, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { EmailInput, UserFragment, PhoneInput, UpdateUserMutationVariables, UserRoleTypes, useUpdateUserMutation, useUserTypesQuery } from "../graphql"

type UpdateUserProfileFormValues = Omit<UpdateUserMutationVariables["input"], "email" | "phone"> & { email?: EmailInput; phone?: PhoneInput }

const validationSchema = yup.object<Record<keyof UpdateUserProfileFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	name: yup.string().required().label("Name"),
	email: yup
		.object({
			address: yup.string().label("Address"),
		})
		.nullable()
		.label("Email"),
	phone: yup
		.object({
			number: yup.string().label("Number"),
			countryCode: yup.string().label("Country Code"),
		})
		.nullable()
		.label("Phone"),
	address: yup.string().nullable().label("Address"),
	employeeId: yup.string().nullable().label("Employee ID"),
	typeId: yup.string().required().label("Type"),
})

export type UpdateUserProfileFormProps = {
	user: UserFragment
}

export const UpdateUserProfileForm: React.FC<UpdateUserProfileFormProps> = ({ user }) => {
	const initialValues: UpdateUserProfileFormValues = {
		name: user.name,
		typeId: user.typeId,
		email: user.email?.address ? { address: user.email.address } : undefined,
		phone: {
			countryCode: user.phone?.countryCode || "+91",
			number: user.phone?.number || "",
		},
		address: user.address,
		employeeId: user.employeeId,
	}

	const [{ fetching }, updateUser] = useUpdateUserMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateUserProfileFormValues) => {
		const { data, error } = await updateUser({
			userId: user._id,
			input: { ...values, email: values.email?.address ? values.email : undefined, phone: values.phone?.number ? values.phone : undefined },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateUser) {
			navigate(`/users/${data.updateUser._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateUserProfileFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: userTypesData, error: userTypesError, fetching: userTypesFetching }] = useUserTypesQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.name && formik.errors.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("name")} />

					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.typeId && formik.errors.typeId)} isRequired>
					<FormLabel fontWeight="bold">Type</FormLabel>

					{userTypesFetching ? (
						<Text>Fetching types</Text>
					) : userTypesError ? (
						<VStack>
							<Text>Couldn&apos;t fetch types</Text>
							<Text>{userTypesError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !userTypesData?.userTypes.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch types</Text>
						</VStack>
					) : (
						<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("typeId")}>
							{userTypesData.userTypes.map((type) => (
								<option key={type._id} style={{ backgroundColor: "transparent" }} value={type._id}>
									{type.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.typeId}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean((formik.touched.email as unknown as EmailInput)?.address && (formik.errors.email as unknown as EmailInput)?.address)}>
					<FormLabel fontWeight="bold">Email</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter email" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("email.address")} />

					<FormErrorMessage>{(formik.errors.email as unknown as EmailInput)?.address}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean((formik.touched.phone as unknown as PhoneInput)?.number && (formik.errors.phone as unknown as PhoneInput)?.number)}>
					<FormLabel fontWeight="bold">Phone</FormLabel>

					<InputGroup as={HStack} spacing={1}>
						<Select flex="3" variant="filled" bgColor="grayscale.input-background" roundedRight="none" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("phone.countryCode")}>
							<option value="+91">+91</option>
						</Select>

						<Input flex="7" variant="filled" bgColor="grayscale.input-background" roundedLeft="none" placeholder="Enter phone" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("phone.number")} />
					</InputGroup>
					<FormErrorMessage>{(formik.errors.phone as unknown as PhoneInput)?.number}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.address && formik.errors.address)}>
					<FormLabel fontWeight="bold">Address</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter address" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("address")} />

					<FormErrorMessage>{formik.errors.address}</FormErrorMessage>
				</FormControl>

				{userTypesData?.userTypes.find((t) => t._id === formik.values.typeId)?.roleType === UserRoleTypes.Working && (
					<FormControl isInvalid={Boolean(formik.touched.employeeId && formik.errors.employeeId)}>
						<FormLabel fontWeight="bold">Employee ID</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter employee ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("employeeId")} />

						<FormErrorMessage>{formik.errors.employeeId}</FormErrorMessage>
					</FormControl>
				)}
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
