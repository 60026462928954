import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { UserFragment, useRemoveCurrentUserRoleMutation } from "../../graphql"
import { UserRoleDetails } from "./UserRoleDetails"

export type RemoveCurrentUserRoleProps = {
	user: UserFragment
}

export const RemoveCurrentUserRole: React.FC<RemoveCurrentUserRoleProps> = ({ user }) => {
	const [{ fetching }, remove] = useRemoveCurrentUserRoleMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleRemove = async () => {
		const { error, data } = await remove({ userId: user._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.removeCurrentUserRole) {
			toast({ description: "Removed current user role successfully", status: "success" })
			navigate(`/users/${user._id}`)
			return
		} else {
			toast({ description: "Couldn't remove current user role", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<UserRoleDetails user={user} disableActions />
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to remove the current user role of {user.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/users/${user._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="error" isLoading={fetching} onClick={handleRemove}>
						Remove
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
