import { Button, ButtonGroup, Center, Flex, HStack, Icon, Link, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { Check, X } from "react-feather"
import { NavLink } from "react-router-dom"
import { PermissionTags, useMeQuery, UserFragment, UserRoleTypes, UserStatus, useUserTypeByIdQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type UserProfileProps = {
	user: UserFragment
}

export const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
	const [{ data: meData }] = useMeQuery()
	const { checkForPermissions } = useUserPermissions()

	const canUpdate = useMemo(() => {
		if (user._id === meData?.me?._id) return true

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.UpdateWorkingUser)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.UpdateShortTermUser)[0]
		}

		if (user.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(PermissionTags.UpdateAdminUser)[0]
		}

		return false
	}, [meData?.me?._id])

	const canDeactivate = useMemo(() => {
		if (user.status === UserStatus.Inactive) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.DeactivateWorkingUser)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.DeactivateShortTermUser)[0]
		}

		if (user.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(PermissionTags.DeactivateAdminUser)[0]
		}

		return false
	}, [])

	const canActivate = useMemo(() => {
		if (user.status === UserStatus.Active) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.ActivateWorkingUser)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.ActivateShortTermUser)[0]
		}

		if (user.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(PermissionTags.ActivateAdminUser)[0]
		}

		return false
	}, [])

	const [{ data, fetching, error }] = useUserTypeByIdQuery({ variables: { userTypeId: user.typeId } })

	return (
		<Card
			title="Profile"
			action={
				<ButtonGroup>
					{canUpdate && (
						<Button as={NavLink} to={`/users/${user._id}/updateProfile`} colorScheme="primary" size="sm">
							Update
						</Button>
					)}
					{canDeactivate && (
						<Button as={NavLink} to={`/users/${user._id}/deactivate`} colorScheme="error" size="sm">
							Deactivate
						</Button>
					)}
					{canActivate && (
						<Button as={NavLink} to={`/users/${user._id}/activate`} colorScheme="success" size="sm">
							Activate
						</Button>
					)}
				</ButtonGroup>
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Phone" value={user.phone ? `${user.phone.countryCode} ${user.phone.number}` : "Not available"} />
				<Stat
					label={
						<HStack>
							<Text>Email</Text>
							{user.email?.isVerified ? (
								<Center rounded="full" bgColor="success.500" p="2px">
									<Icon as={Check} color="white" fontSize="xs" />
								</Center>
							) : meData?.me?._id === user._id ? (
								<Button as={NavLink} to="/verifyEmail" variant="link" size="xs" colorScheme="success">
									Verify
								</Button>
							) : (
								<Center rounded="full" bgColor="error.500" p="2px">
									<Icon as={X} color="white" />
								</Center>
							)}
						</HStack>
					}
					value={user.email?.address || "Not available"}
				/>
				<Stat label="Address" value={user.address || "Not available"} />
				<Stat
					label="Type"
					value={
						fetching ? (
							<Spinner size="sm" />
						) : error ? (
							<Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text>
						) : !data?.userTypeById ? (
							<Text color="error.500">No user type</Text>
						) : (
							<Link as={NavLink} to={`/users/types/${user.typeId}`} w="48">
								{data.userTypeById.label.name}
							</Link>
						)
					}
				/>
				<Stat label="Status" value={user.status === UserStatus.Active ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				{user.roleType === UserRoleTypes.Working && <Stat label="Employee Id" value={user.employeeId ? `${user.employeeId}` : "Not available"} />}
				<Stat label="Since" value={format(new Date(user.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
