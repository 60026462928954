import { Button, ButtonGroup, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, useMeQuery, ZoneGroupFragment } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type ZoneGroupDetailsProps = {
	zoneGroup: ZoneGroupFragment
	disableActions?: boolean
}

export const ZoneGroupDetails: React.FC<ZoneGroupDetailsProps> = ({ zoneGroup, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const { checkForSomePermissions } = useUserPermissions()

	const canUpdate = useMemo(
		() => checkForSomePermissions(PermissionTags.UpdateZoneGroup)[0],

		[meData]
	)

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/zones/groups/${zoneGroup._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Name" value={zoneGroup.label.name} />
				<Stat label="Created" value={format(new Date(zoneGroup.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
