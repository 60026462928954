import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { UserGroupFragment, useUsersOfUserGroupQuery } from "../../graphql"
import { Card } from "../common"
import { UsersList } from "../users/UsersList"

export type UsersOfUserGroupProps = {
	userGroup: UserGroupFragment
}

export const UsersOfUserGroup: React.FC<UsersOfUserGroupProps> = ({ userGroup }) => {
	const [{ data, fetching, error }] = useUsersOfUserGroupQuery({ variables: { userGroupId: userGroup._id } })

	return (
		<Card title="Users">
			{fetching && !data?.usersOfUserGroup ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.usersOfUserGroup.length ? (
				<VStack w="full" align="stretch">
					<UsersList users={data.usersOfUserGroup} />
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any users.
					</Text>
				</Center>
			)}
		</Card>
	)
}
