/* eslint-disable @typescript-eslint/indent */
import { Center, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useBuildingsQuery } from "../../graphql"
import { BuildingItem } from "./BuildingItem"

export const BuildingWiseUsers: React.FC = () => {
	const [{ data, fetching, error }] = useBuildingsQuery()

	return (
		<VStack w="full" flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">All buildings</Heading>
			<Flex w="full" flexWrap="wrap">
				{!data?.buildings && fetching ? (
					<Center w="full" py="4">
						<VStack w="full" color="grayscale.label">
							<Text fontSize="sm">Loading buildings</Text>
							<Spinner size="sm" />
						</VStack>
					</Center>
				) : error ? (
					<Center w="full" py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				) : data?.buildings.length ? (
					data.buildings.map((building) => <BuildingItem key={building._id} building={building} />)
				) : (
					<Center w="full" py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							Couldn&apos;t find any buildings.
						</Text>
					</Center>
				)}
			</Flex>
		</VStack>
	)
}
