import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PermissionTags, useMeQuery } from "../../graphql"
import UserPage from "../../pages/users/User.page"
import { Permissions } from "./Permissions"

const UserPageWrapper: React.FC = () => {
	const { userId = "" } = useParams<{ userId: string }>()

	const [{ data: meData }] = useMeQuery()

	const navigate = useNavigate()

	useEffect(() => {
		if (meData?.me?._id === userId) {
			navigate("/profile", { replace: true })
		}
	}, [meData, userId])

	return (
		<Permissions tags={[PermissionTags.ReadShortTermUser, PermissionTags.ReadWorkingUser, PermissionTags.ReadAdminUser]} checkType="or">
			<UserPage />
		</Permissions>
	)
}

export default UserPageWrapper
