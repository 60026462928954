/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Heading, useToast, VStack } from "@chakra-ui/react"
import { addMinutes } from "date-fns"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import "react-calendar/dist/Calendar.css"
import "react-clock/dist/Clock.css"
import DateTimePicker from "react-datetime-picker"
import "react-datetime-picker/dist/DateTimePicker.css"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AssetDetails, ZoneSelector } from "../components"
import { AssetFragment, CreateAssetPlacementRecordMutationVariables, useCreateAssetPlacementRecordMutation } from "../graphql"

type CreateAssetPlacementRecordFormValues = Omit<CreateAssetPlacementRecordMutationVariables["input"], "assetId">

const validationSchema = yup.object<Record<keyof CreateAssetPlacementRecordFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	zoneId: yup.string().required().label("Zone"),
	placementTill: yup.date().required().label("Move Till Date"),
})

const initialValues: CreateAssetPlacementRecordFormValues = {
	zoneId: undefined as any,
	placementTill: addMinutes(new Date(), 10),
}

type CreateAssetPlacementRecordFormProps = {
	asset: AssetFragment
}

export const CreateAssetPlacementRecordForm: React.FC<CreateAssetPlacementRecordFormProps> = ({ asset }) => {
	const [{ fetching }, createAssetInventoryAudit] = useCreateAssetPlacementRecordMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ zoneId, placementTill }: CreateAssetPlacementRecordFormValues) => {
		const { data, error } = await createAssetInventoryAudit({
			input: { assetId: asset._id, zoneId, placementTill },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createAssetPlacementRecord) {
			navigate(`/assets/${asset._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateAssetPlacementRecordFormValues>({
		initialValues,
		validationSchema,
		onSubmit,
	})

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<VStack w="full" align="stretch">
					<Heading fontSize="lg" color="blackAlpha.700">
						Asset
					</Heading>
					<AssetDetails asset={asset} disableActions />
				</VStack>

				<FormControl isInvalid={Boolean(formik.touched.zoneId && formik.errors.zoneId)}>
					<FormLabel fontWeight="bold">Zones</FormLabel>

					<ZoneSelector
						single
						value={formik.values.zoneId ? [formik.values.zoneId] : []}
						onUpdate={(zoneIds) => {
							formik.setFieldValue("zoneId", zoneIds[0])
						}}
					/>
					<FormErrorMessage>{formik.errors.zoneId}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.placementTill && formik.errors.placementTill)}>
					<FormLabel fontWeight="bold">Move till</FormLabel>

					<DateTimePicker
						onChange={(date) => {
							formik.setFieldValue("placementTill", date)
						}}
						value={formik.values.placementTill}
						format="y-MM-dd h:mm:ss a"
						minDate={new Date()}
						clearIcon={null}
						disableCalendar
						disableClock
					/>

					<FormErrorMessage>{formik.errors.placementTill as string}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
