import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react"
import React from "react"
import { AssignTagToAssetByDecimalIdForm, AssignTagToAssetForm } from "../../forms"
import { AssetFragment } from "../../graphql"

export type AssignTagToAssetProps = {
	asset: AssetFragment
}

export const AssignTagToAsset: React.FC<AssignTagToAssetProps> = ({ asset }) => {
	return (
		<VStack w="full" align="stretch" spacing={6}>
			<Tabs variant="enclosed-colored">
				<TabList>
					<Tab _selected={{ color: "white", bg: "purple.400" }}>By Device</Tab>
					<Tab _selected={{ color: "white", bg: "green.400" }}>By Tag Id</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<AssignTagToAssetForm asset={asset} />
					</TabPanel>
					<TabPanel>
						<AssignTagToAssetByDecimalIdForm asset={asset} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</VStack>
	)
}
