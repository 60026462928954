/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ReadersSelector } from "../components"
import { GenerateReadersStatusLogsReportMutationVariables, useGenerateReadersStatusLogsReportMutation } from "../graphql"

type GenerateReadersStatusLogsReportFormValues = GenerateReadersStatusLogsReportMutationVariables["input"]

const validationSchema = yup.object<Record<keyof GenerateReadersStatusLogsReportFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	readerIds: yup.array().of(yup.string()).min(1).required().label("Readers"),
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	sinceDate: yup.date().required().label("Since Date"),
	untilDate: yup.date().required().label("Until Date"),
	readersFilter: yup.object({
		keyword: yup.string().nullable(),
		type: yup.string().label("Type").nullable(),
		isActive: yup.boolean().label("Status").nullable(),
		isAllSelected: yup.boolean().nullable(),
	}),
})

const initialValues: GenerateReadersStatusLogsReportFormValues = {
	readerIds: [],
	label: {
		name: "",
		description: "",
	},
	sinceDate: new Date(),
	untilDate: new Date(),
	readersFilter: {},
}

export const GenerateReadersStatusLogsReportForm: React.FC = () => {
	const [{ fetching }, generateReadersStatusLogsReport] = useGenerateReadersStatusLogsReportMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: GenerateReadersStatusLogsReportFormValues) => {
		const { data, error } = await generateReadersStatusLogsReport({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.generateReadersStatusLogsReport) {
			navigate(`/reports/past/${data.generateReadersStatusLogsReport._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<GenerateReadersStatusLogsReportFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
						<FormLabel fontWeight="bold">Name</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

						<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
						<FormLabel fontWeight="bold">Description</FormLabel>

						<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

						<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
					</FormControl>
					<Popover>
						<PopoverTrigger>
							{formik.values.sinceDate && formik.values.untilDate && (
								<FormControl>
									<FormLabel fontWeight="bold">Date Range</FormLabel>

									<Input variant="filled" bgColor="grayscale.input-background" value={`${format(formik.values.sinceDate, "MMM dd, yyyy")} - ${format(formik.values.untilDate, "MMM dd, yyyy")}`} isReadOnly />
								</FormControl>
							)}
						</PopoverTrigger>
						<PopoverContent w="full" _focus={{ shadow: "none" }}>
							<PopoverBody w="full" p="0">
								<DateRange
									editableDateInputs={true}
									onChange={(item) => {
										if (item?.selection.startDate && item?.selection.endDate) {
											formik.setFieldValue("sinceDate", new Date(item.selection.startDate))
											formik.setFieldValue("untilDate", new Date(item.selection.endDate))
										}
									}}
									moveRangeOnFirstSelection={false}
									ranges={[{ startDate: formik.values.sinceDate, endDate: formik.values.untilDate, key: "selection" }]}
									maxDate={new Date()}
								/>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</VStack>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.readerIds && formik.errors.readerIds)}>
						<FormLabel fontWeight="bold">Readers</FormLabel>

						<ReadersSelector
							value={formik.values.readerIds}
							onUpdate={(readerIds, filter) => {
								formik.setFieldValue("readerIds", readerIds)
								formik.setFieldValue("readersFilter", filter)
							}}
						/>
						<Text fontSize="sm" color="error.400">
							{formik.errors.readerIds}
						</Text>
					</FormControl>
					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Generate
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
