import { Divider, List } from "@chakra-ui/react"
import React from "react"

import { UserLocationRecordFragment, UserLocationRecordStatus } from "../../graphql"
import { ZonedUserLocationListItem } from "./ZonedUserLocationListItem"

export type ZonedUserLocationsListProps = {
	userLocationRecords: UserLocationRecordFragment[]
}

export const ZonedUserLocationsList: React.FC<ZonedUserLocationsListProps> = ({ userLocationRecords }) => {
	return (
		<List spacing={2}>
			{userLocationRecords
				.filter((lr) => lr.status !== UserLocationRecordStatus.Final)
				.map((userLocationRecord) => (
					<React.Fragment key={userLocationRecord._id}>
						<ZonedUserLocationListItem userLocationRecord={userLocationRecord} />
						<Divider />
					</React.Fragment>
				))}
		</List>
	)
}
