import { Button, ButtonGroup, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, useMeQuery, UserFragment, UserRoleTypes } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card } from "../common"
import { VisitorCurrentDetails } from "./VisitorCurrentDetails"
import { VisitorPastDetails } from "./VisitorPastDetails"

export type VisitorDetailsProps = {
	user: UserFragment
}

export const VisitorDetails: React.FC<VisitorDetailsProps> = ({ user }) => {
	const [{ data }] = useMeQuery()

	const [tabIndex, setTabIndex] = useState(0)

	const handleTabsChange = (index: number) => {
		setTabIndex(index)
	}

	const { checkForPermissions } = useUserPermissions()

	const canUpdate = useMemo(() => {
		if (tabIndex !== 0) return false

		if (user?._id === data?.me?._id) return true

		if (user?.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.UpdateWorkingUser)[0]
		}

		if (user?.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.UpdateShortTermUser)[0]
		}

		if (user?.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(PermissionTags.UpdateAdminUser)[0]
		}

		return false
	}, [JSON.stringify(user), tabIndex])

	return (
		<Card
			title="Visitor Details"
			action={
				<ButtonGroup>
					{canUpdate && (
						<Button as={NavLink} to={`/users/${user._id}/updateVisitorDetails`} colorScheme="primary" size="sm">
							Update
						</Button>
					)}
				</ButtonGroup>
			}
		>
			<Tabs size="sm" w="full" variant="soft-rounded" colorScheme="primary" isLazy lazyBehavior="unmount" index={tabIndex} onChange={handleTabsChange}>
				<TabList px="4">
					<Tab>Current</Tab>
					<Tab>Past</Tab>
				</TabList>
				<TabPanels w="full" pt="2">
					<TabPanel as={VStack} w="full" align="stretch" spacing={4} px="0">
						<VisitorCurrentDetails user={user} />
					</TabPanel>
					<TabPanel as={VStack} w="full" maxH="md" overflowY="auto" align="stretch" spacing={4} px="0">
						<VisitorPastDetails user={user} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Card>
	)
}
