import { Button, ButtonGroup, Flex, Link, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AssetFragment, AssetStatus, AssetTypes, Features, PermissionTags, useAssetCategoryByIdQuery } from "../../graphql"
import { useFeatures, useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type AssetDetailsProps = {
	asset: AssetFragment
	disableActions?: boolean
}

export const AssetDetails: React.FC<AssetDetailsProps> = ({ asset, disableActions }) => {
	const { checkForPermissions } = useUserPermissions()

	const canUpdate = useMemo(() => {
		return checkForPermissions(PermissionTags.UpdateAsset)[0]
	}, [])

	const canDeactivate = useMemo(() => {
		if (asset.status === AssetStatus.Inactive) return false
		else {
			return checkForPermissions(PermissionTags.DeactivateAsset)[0]
		}
	}, [])

	const canActivate = useMemo(() => {
		if (asset.status === AssetStatus.Active) return false
		else {
			return checkForPermissions(PermissionTags.DeactivateAsset)[0]
		}
	}, [])

	const [{ data: categoryData, fetching: fetchingCategory, error: categoryError }] = useAssetCategoryByIdQuery({ variables: { assetCategoryId: asset.categoryId ?? "" }, pause: !asset.categoryId })

	const { isFeatureEnabled } = useFeatures()

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/assets/${asset._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
						{canDeactivate && (
							<Button as={NavLink} to={`/assets/${asset._id}/deactivate`} colorScheme="error" size="sm">
								Deactivate
							</Button>
						)}
						{canActivate && (
							<Button as={NavLink} to={`/assets/${asset._id}/activate`} colorScheme="success" size="sm">
								Activate
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				{isFeatureEnabled(Features.WeighedAssets) && <Stat label="Type" value={asset.type} />}

				<Stat label="Status" value={asset.status === AssetStatus.Active ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />

				{asset.type === AssetTypes.Weighed && asset.weightInKG && <Stat label="Unit Weight" value={`${asset.weightInKG} KG`} />}

				<Stat
					label="Category"
					value={
						fetchingCategory ? (
							<Spinner size="sm" />
						) : categoryError ? (
							<Text color="error.500">{categoryError.message.replace("[GraphQL] ", "")}</Text>
						) : !categoryData?.assetCategoryById ? (
							<Text color="error.500">No category</Text>
						) : (
							<Link as={NavLink} to={`/assets/categories/${asset.categoryId}`}>
								{categoryData?.assetCategoryById.label.name}
							</Link>
						)
					}
				/>

				<Stat label="Since" value={format(new Date(asset.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
