/* eslint-disable @typescript-eslint/indent */
import { Button, ButtonProps, HStack, Icon as CUIIcon, Text } from "@chakra-ui/react"
import React, { Fragment, useMemo } from "react"
import { ChevronDown, Icon } from "react-feather"
import { NavLink, useLocation } from "react-router-dom"
import { PermissionTags, useMeQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"

export type SidebarButtonProps = {
	icon?: Icon
	children: string
	isNested?: boolean
	to?: string
	isActive?: boolean
	permissionTags?: PermissionTags[]
	permissionsType?: "or" | "and"
} & ButtonProps

export const SidebarButton: React.FC<SidebarButtonProps> = ({ icon, children, to, isNested, isActive: isActiveFromProps, permissionTags, permissionsType = "and", ...props }) => {
	const isActive = new RegExp(`^${to}(/|)$`).test(useLocation().pathname)

	const [{ data }] = useMeQuery()
	const { checkForPermissions } = useUserPermissions()

	const isAllowed = useMemo(() => {
		if (!permissionTags?.length) return true

		if (!data?.me) return false

		if (permissionsType === "and") {
			return checkForPermissions(...permissionTags)[0]
		} else {
			return permissionTags.some((tag) => checkForPermissions(tag)[0])
		}
	}, [data, permissionTags, children])

	if (!isAllowed) return <Fragment />

	return !isNested && to ? (
		<Button as={NavLink} to={to} w="full" bgColor={isActive || isActiveFromProps ? "blackAlpha.100" : "transparent"} _hover={{ bgColor: "white" }} colorScheme="whiteAlpha" {...props}>
			<HStack w="full" spacing={4}>
				{icon && <CUIIcon as={icon} fontSize={isActive || isActiveFromProps ? "lg" : "md"} color="grayscale.title" />}
				<Text fontWeight={isActive || isActiveFromProps ? "black" : "normal"} textAlign="left" fontSize="sm" transition="font-weight .2s ease-in-out">
					{children}
				</Text>
			</HStack>
		</Button>
	) : (
		<Button w="full" bgColor={isActive || isActiveFromProps ? "blackAlpha.100" : "transparent"} _hover={{ bgColor: "white" }} colorScheme="whiteAlpha" {...props}>
			<HStack w="full" justify="space-between">
				<HStack w="full" spacing={4}>
					{icon && <CUIIcon as={icon} fontSize={isActive || isActiveFromProps ? "lg" : "md"} color="grayscale.title" />}
					<Text fontWeight={isActive || isActiveFromProps ? "black" : "normal"} textAlign="left" fontSize="sm" transition="font-weight .2s ease-in-out">
						{children}
					</Text>
				</HStack>
				<CUIIcon as={ChevronDown} color="grayscale.title" />
			</HStack>
		</Button>
	)
}
