import { VStack } from "@chakra-ui/react"
import React from "react"
import { AddReaderToZoneForm } from "../../forms"
import { ZoneFragment } from "../../graphql"

export type AddReaderToZoneProps = {
	zone: ZoneFragment
}

export const AddReaderToZone: React.FC<AddReaderToZoneProps> = ({ zone }) => {
	return (
		<VStack w="full" spacing={6} maxW="sm">
			<AddReaderToZoneForm zone={zone} />
		</VStack>
	)
}
