import { useCallback, useEffect, useState } from "react"
import { Features, useMeQuery, useSiteByIdQuery } from "../graphql"

export const useFeatures = () => {
	const [features, setFeatures] = useState<Features[]>()

	const [{ data: meData }] = useMeQuery()

	const [{ data: siteData }] = useSiteByIdQuery({ variables: { siteId: meData?.me?.siteId ?? "" }, pause: !meData?.me?.siteId })

	useEffect(() => {
		setFeatures(siteData?.siteById.features)
	}, [siteData?.siteById.features])

	const isFeatureEnabled = useCallback(
		(feature: Features) => {
			return features?.includes(feature)
		},
		[features]
	)

	return { features, isFeatureEnabled }
}
