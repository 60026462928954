/* eslint-disable @typescript-eslint/indent */
import { Box, Center, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { useZonesOfZoneGroupQuery, ZoneGroupFragment, ZonesOfZoneGroupQueryVariables } from "../../graphql"
import { ZoneItem } from "./ZoneItem"

export type ZoneGroupZoneWiseUsersProps = {
	zoneGroup: ZoneGroupFragment
}

export const ZoneGroupZoneWiseUsers: React.FC<ZoneGroupZoneWiseUsersProps> = ({ zoneGroup }) => {
	const [pagination, setPagination] = useState<ZonesOfZoneGroupQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useZonesOfZoneGroupQuery({ variables: { zoneGroupId: zoneGroup._id, pagination } })

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.zonesOfZoneGroup.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [JSON.stringify(data), fetching])

	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			nextPage()
		}
	}, [inViewport])

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">All zones</Heading>
			{fetching && !data?.zonesOfZoneGroup ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading zones</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.zonesOfZoneGroup.zones && data.zonesOfZoneGroup.zones.length ? (
				<Flex w="full" flexWrap="wrap">
					{data.zonesOfZoneGroup.zones.map((zone) => (
						<ZoneItem key={zone._id} zone={zone} />
					))}
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any zones.
					</Text>
				</Center>
			)}
			<Box w="full" h="2" ref={ref as any} />
		</VStack>
	)
}
