import { Button, ButtonGroup, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AssetCategoryFragment, PermissionTags } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type AssetCategoryDetailsProps = {
	assetCategory: AssetCategoryFragment
	disableActions?: boolean
}

export const AssetCategoryDetails: React.FC<AssetCategoryDetailsProps> = ({ assetCategory, disableActions }) => {
	const { checkForSomePermissions } = useUserPermissions()

	const canUpdate = useMemo(() => checkForSomePermissions(PermissionTags.UpdateAssetCategory)[0], [])

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/assets/categories/${assetCategory._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Name" value={assetCategory.label.name} />
				<Stat label="Created" value={format(new Date(assetCategory.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
