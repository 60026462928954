import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ZoneFragment } from "../../graphql"
import { ZoneListItem } from "./ZoneListItem"

export type ZonesListProps = {
	zones: ZoneFragment[]
	loadMore: () => void
}

export const ZonesList: React.FC<ZonesListProps> = ({ zones, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{zones.map((zone) => (
				<>
					<ZoneListItem key={zone._id} zone={zone} />
					<Divider />
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
