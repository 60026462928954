import { Button, Center, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React, { useCallback } from "react"
import { File } from "react-feather"
import {
	urqlClient,
	UserByIdDocument,
	UserByIdQuery,
	UserByIdQueryVariables,
	UserLocationRecordStatus,
	useUserLocationRecordsAtZoneQuery,
	useUserLocationRecordUpdateAtZoneSubscription,
	ZoneByIdDocument,
	ZoneByIdQuery,
	ZoneByIdQueryVariables,
} from "../../graphql"
import { exportCSVData, getDurationInWords } from "../../utils"
import { Card } from "../common"
import { ZonedUserLocationsList } from "./ZonedUserLocationsList"

export type UserLocationRecordsAtZoneProps = {
	zoneId: string
}

export const UserLocationRecordsAtZone: React.FC<UserLocationRecordsAtZoneProps> = ({ zoneId }) => {
	const [{ data, fetching, error }] = useUserLocationRecordsAtZoneQuery({ variables: { zoneId } })

	useUserLocationRecordUpdateAtZoneSubscription({ variables: { zoneId } })

	const handleExport = useCallback(async () => {
		if (!data?.userLocationRecordsAtZone.length || !data.userLocationRecordsAtZone[0]) return

		let csv = "Date, Asset, Zone, Start, End, Duration, Status, Restricted"

		for (const record of data.userLocationRecordsAtZone) {
			const { data: userData } = await urqlClient.query<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, { userId: record.userId }).toPromise()

			if (!userData?.userById) return

			const { data: zoneData } = await urqlClient.query<ZoneByIdQuery, ZoneByIdQueryVariables>(ZoneByIdDocument, { zoneId: record.zoneId }).toPromise()

			if (!zoneData?.zoneById) return

			csv = csv.concat(
				`\n${format(new Date(record.startAt), "MMM dd yyyy")}, ${userData.userById.name}, ${zoneData.zoneById.label.name}, ${format(new Date(record.startAt), "MMM dd yyyy p")}, ${format(
					new Date(record.endAt),
					"MMM dd yyyy p"
				)}, ${getDurationInWords(differenceInSeconds(new Date(record.endAt), new Date(record.startAt)))}, ${record.status}, ${record.isRestricted ? "True" : "False"}`
			)
		}

		return exportCSVData(csv, `${data?.userLocationRecordsAtZone[0]._id}-${format(new Date(), "MMM dd, yyyy p")}`)
	}, [JSON.stringify(data?.userLocationRecordsAtZone)])

	return (
		<Card
			title={`${data?.userLocationRecordsAtZone.filter((lr) => lr.status !== UserLocationRecordStatus.Final).length || 0} Users`}
			action={
				data?.userLocationRecordsAtZone.length && (
					<Button size="sm" colorScheme="primary" rightIcon={<Icon as={File} color="white" />} onClick={handleExport}>
						Export
					</Button>
				)
			}
			alwaysShowAction
		>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userLocationRecordsAtZone.length ? (
				<VStack w="full" align="stretch">
					<ZonedUserLocationsList userLocationRecords={data.userLocationRecordsAtZone} />
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						No current users found
					</Text>
				</Center>
			)}
		</Card>
	)
}
