import { Center, chakra, Heading, LinkBox, LinkOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { DashboardTypes } from "../../constants"
import { BuildingFragment, useActiveUserIdsQuery, useZonesInBuildingQuery } from "../../graphql"
import { useDashboardContext } from "../../hooks"

export type BuildingItemProps = {
	building: BuildingFragment
}

export const BuildingItem: React.FC<BuildingItemProps> = ({ building }) => {
	const { type } = useDashboardContext()

	const [{ data, fetching, error }] = useActiveUserIdsQuery({ variables: { isRestricted: type === DashboardTypes.CONFIDENTIALITY } })

	const [{ data: zonesData }] = useZonesInBuildingQuery({ variables: { buildingId: building._id } })

	const activeUsersInBuilding = useMemo(
		() => data?.activeUserIds.zoneWiseUsers.filter((z) => zonesData?.zonesInBuilding?.map((z) => z._id).includes(z.zoneId)).flatMap((z) => z.userIds) || [],
		[JSON.stringify(data), JSON.stringify(zonesData?.zonesInBuilding)]
	)

	return (
		<VStack
			w="xs"
			maxW="3xs"
			as={LinkBox}
			align="flex-start"
			px="6"
			py="4"
			m="1"
			bgColor="grayscale.background"
			transition="background-color, border-color .1s ease-in"
			borderWidth="4px"
			borderStyle="solid"
			borderColor={activeUsersInBuilding.length ? "green.500" : "red.100"}
			_hover={{ bgColor: "primary.50", borderColor: "primary.200" }}
			rounded="xl"
			cursor="pointer"
			justify="space-between"
		>
			<LinkOverlay as={NavLink} to={`/zones/buildings/${building._id}`} />
			<VStack align="flex-start" spacing="1">
				<Heading fontSize="sm" color="grayscale.title">
					{building.label.name}
				</Heading>
				<Text fontSize="xs" color="grayscale.label">
					{building.label.description}
				</Text>
			</VStack>

			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : (
				<VStack align="stretch">
					<Heading fontSize="2xl" color={activeUsersInBuilding.length ? "green.500" : "red.400"}>
						{activeUsersInBuilding.length || 0}{" "}
						<chakra.span fontSize="sm" fontWeight="normal">
							users
						</chakra.span>
					</Heading>
				</VStack>
			)}
		</VStack>
	)
}
