import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useServiceByIdQuery } from "../../graphql"
import { ServiceListItem } from "../services/ServiceListItem"

export const ServiceOfAlert: React.FC<{ serviceId: string }> = ({ serviceId }) => {
	const [{ data, fetching, error }] = useServiceByIdQuery({ variables: { serviceId } })

	return (
		<>
			{fetching && !data?.serviceById ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading service</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.serviceById ? (
				<ServiceListItem service={data.serviceById} />
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find the service.
					</Text>
				</Center>
			)}
		</>
	)
}
