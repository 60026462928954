import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { AssetFragment } from "../../graphql"
import { AssetListItem } from "./AssetListItem"

export type AssetsListProps = {
	assets: AssetFragment[]
	loadMore?: () => void
	onAssetClick?: (asset: AssetFragment) => void
}

export const AssetsList: React.FC<AssetsListProps> = ({ assets, loadMore, onAssetClick }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{assets.map((asset) => (
				<>
					<AssetListItem key={asset._id} asset={asset} onAssetClick={onAssetClick} />
					<Divider />
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
