/* eslint-disable @typescript-eslint/indent */
import { Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useZoneGroupsByFilterQuery, ZoneGroupsByFilterQueryVariables, ZoneGroupsFilterInput } from "../../graphql"

export type ZoneGroupsSelectorProps = {
	onUpdate: (zoneGroupIds: string[], filter: ZoneGroupsFilterInput) => void
	value: string[]
}

export const ZoneGroupsSelector: React.FC<ZoneGroupsSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<ZoneGroupsByFilterQueryVariables>({ filter: { keyword: "" }, pagination: { limit: 20, page: 1 } })

	const [{ data, fetching, error }] = useZoneGroupsByFilterQuery({ variables })

	const [keyword, setKeyword] = useState("")

	const isZoneGroupSelected = useCallback((zoneGroupId: string) => value.includes(zoneGroupId), [value])

	const isAllSelected = useMemo(() => data?.zoneGroupsByFilter.zoneGroups.map((r) => r._id).every((rid) => value.includes(rid)), [data, value])
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				filter: {
					...prev.filter,
					keyword,
				},
			}))
			onUpdate([...value], { keyword, isAllSelected })
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (zoneGroupId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, zoneGroupId], { isAllSelected, keyword })
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(zoneGroupId), 1)
			onUpdate(_value, { isAllSelected, keyword })
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
			</HStack>
			{fetching && !data?.zoneGroupsByFilter.zoneGroups.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading zone groups</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.zoneGroupsByFilter.zoneGroups.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any zone group.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked
												? onUpdate(
														data.zoneGroupsByFilter.zoneGroups.map((r) => r._id),
														{ isAllSelected, keyword }
												  )
												: onUpdate([], { isAllSelected, keyword })
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>Zone Group</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.zoneGroupsByFilter.zoneGroups.map((zoneGroup) => (
								<Tr key={zoneGroup._id}>
									<Td>
										<Checkbox isChecked={isZoneGroupSelected(zoneGroup._id)} onChange={(e) => handleChange(zoneGroup._id, e.target.checked)} />
									</Td>
									<Td>{zoneGroup.label.name}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more zone groups</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
