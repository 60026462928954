import { Select, VStack } from "@chakra-ui/react"
import React from "react"

export type ReaderStatusSelectorProps = {
	onUpdate: (isActive: boolean) => void
	value?: boolean
}

export const ReaderStatusSelector: React.FC<ReaderStatusSelectorProps> = ({ onUpdate, value }) => {
	return (
		<VStack>
			<Select flex="1" maxW="24" variant="filled" bgColor="grayscale.input-background" placeholder="Status" value={value ? "Active" : "Inactive"} onChange={(e) => onUpdate(e.target.value === "Active")}>
				{["Active", "Inactive"].map((status) => (
					<option key={status} style={{ backgroundColor: "transparent" }} value={status}>
						{status}
					</option>
				))}
			</Select>
		</VStack>
	)
}
