import { PermissionsContextAction, PermissionsContextActions, PermissionsContextState } from "./types"

export const initialState: PermissionsContextState = {}

export const permissionsContextReducer = (state: PermissionsContextState, action: PermissionsContextAction): PermissionsContextState => {
	switch (action.type) {
		case PermissionsContextActions.SET_PERMISSIONS: {
			return { permissionTags: action.payload }
		}

		default:
			return state
	}
}
