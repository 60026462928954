import { Center, Heading, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { useLatestAssetInventoryAuditsQuery } from "../../graphql"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"

export const TotalWeighedAssets: React.FC = () => {
	const [{ data, fetching, error }, refresh] = useLatestAssetInventoryAuditsQuery()

	useQueryRefresher(() => refresh({ requestPolicy: "network-only" }))

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">Total weighed assets</Heading>
			{!data?.latestAssetInventoryAudits && fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.latestAssetInventoryAudits ? (
				<VStack align="stretch">
					<Link as={NavLink} to="/assets/weighed">
						<Heading color="primary.400">{Intl.NumberFormat("en-IN").format(data.latestAssetInventoryAudits.reduce((a, b) => a + b.assetCount, 0))}</Heading>
					</Link>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any data.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
