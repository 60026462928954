import React, { Fragment, PropsWithChildren, useMemo } from "react"
import { Navigate } from "react-router-dom"
import { PermissionTags, useMeQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"

export type PermissionsProps = {
	tags: PermissionTags[]
	checkType?: "and" | "or"
}

export const Permissions: React.FC<PermissionsProps & PropsWithChildren> = ({ tags, checkType = "and", children }) => {
	const [{ data }] = useMeQuery()

	const { checkForPermissions, checkForSomePermissions } = useUserPermissions()

	const [isAllowed, requiredTags] = useMemo(() => {
		if (!data?.me) return [false, tags]

		if (checkType === "and") {
			return checkForPermissions(...tags)
		}

		return checkForSomePermissions(...tags)
	}, [data, tags, children])

	return isAllowed ? <Fragment>{children}</Fragment> : <Navigate to={`/unauthorized?requiredPermissions=${requiredTags.join(", ")}`} />
}
